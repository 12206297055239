var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-record-select"},[_c('div',{staticClass:"header"},[(_vm.singleImage)?_c('div',{staticClass:"image"},[(this.$store.state.list.isChatID.imId.length > 15)?_c('img',{attrs:{"src":_vm.imgUrl,"alt":""}}):_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":_vm.imgUrl}})])]):_vm._e(),_c('div',{staticClass:"name"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.inputIsShow),expression:"!inputIsShow"}],style:({
          cursor:
            _vm.$store.state.list.isChatID.isGroup == true &&
            _vm.isOwner &&
            !_vm.$store.state.list.isChatID.isStrategyGroup &&
            !_vm.$store.state.list.isChatID.isOpportunityGroup
              ? 'pointer'
              : '',
        }),on:{"click":_vm.showInput}},[_vm._v(_vm._s(_vm.name))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputName),expression:"inputName"},{name:"show",rawName:"v-show",value:(
          _vm.inputIsShow &&
          _vm.$store.state.list.isChatID.isGroup == true &&
          _vm.isOwner &&
          !_vm.$store.state.list.isChatID.isStrategyGroup &&
          !_vm.$store.state.list.isChatID.isOpportunityGroup
        ),expression:"\n          inputIsShow &&\n          $store.state.list.isChatID.isGroup == true &&\n          isOwner &&\n          !$store.state.list.isChatID.isStrategyGroup &&\n          !$store.state.list.isChatID.isOpportunityGroup\n        "}],attrs:{"id":"changeText","type":"text"},domProps:{"value":(_vm.inputName)},on:{"blur":_vm.hiddenInput,"input":function($event){if($event.target.composing){ return; }_vm.inputName=$event.target.value}}})]),(_vm.$store.state.list.isChatID.isGroup == true && _vm.peoplenumber != '')?_c('div',{staticClass:"peoples"},[_vm._v(" ("+_vm._s(_vm.peoplenumber)+") ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.$store.state.list.isChatID.isGroup == true &&
        (_vm.$store.state.list.isChatID.isStrategyGroup == true ||
          _vm.$store.state.list.isChatID.isOpportunityGroup == true)
      ),expression:"\n        $store.state.list.isChatID.isGroup == true &&\n        ($store.state.list.isChatID.isStrategyGroup == true ||\n          $store.state.list.isChatID.isOpportunityGroup == true)\n      "}],staticClass:"strategy",on:{"click":_vm.customerDetails}},[(_vm.isAccount)?_c('u',[_vm._v(_vm._s(_vm.$t("label.weixin.customer.details")))]):_vm._e(),(!_vm.isAccount)?_c('u',[_vm._v(_vm._s(_vm.$t("vue_label_chat_opportunity_details")))]):_vm._e()]),(
        !_vm.inputIsShow &&
        _vm.$store.state.list.isChatID.isGroup == true &&
        _vm.isOwner &&
        !_vm.$store.state.list.isChatID.isStrategyGroup &&
        !_vm.$store.state.list.isChatID.isOpportunityGroup
      )?_c('span',{staticClass:"iconfont iconbianjiqianming-changtai edict",style:({
        cursor:
          _vm.$store.state.list.isChatID.isGroup == true &&
          !_vm.$store.state.list.isChatID.isStrategyGroup &&
          !_vm.$store.state.list.isChatID.isOpportunityGroup
            ? 'pointer'
            : '',
      }),on:{"click":_vm.showInput}}):_vm._e()]),_c('div',{staticClass:"chat-type"},[_c('svg',{staticClass:"icon",attrs:{"slot":"reference","aria-hidden":"true"},on:{"mouseover":_vm.moveMemberIcon,"mouseleave":_vm.leaveMemberIcon,"click":_vm.addMember},slot:"reference"},[_c('use',{attrs:{"href":_vm.isMoveMember == false ? _vm.moveMemberPic : _vm.leaveMemberPic}})])]),_c('select-member',{attrs:{"isShowB":_vm.isShow},on:{"changeShow":_vm.changeShow}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }