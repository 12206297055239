<template>
  <div class="userChineselogin">
    <el-dialog
      :visible="userloginDialog"
      top="15%"
      append-to-body
      :width="'371px'"
      :close-on-click-modal="false"
      :before-close="closeuserloginDialog"
    >
      <div slot="title" class="header-title">
        <div style="display: flex">
          <svg
            class="logoIcon"
            aria-hidden="true"
            slot="reference"
            style="width: 24px; height: 24px; margin-right: 8px"
          >
            <use href="#icon-trrtIcon"></use>
          </svg>
          <div>{{ $t("label.callcenter.Tianrun.RONGTONG") }}</div>
        </div>
      </div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        :rules="rules"
        label-position="top"
      >
        <el-form-item prop="seatsNum">
          <label slot="label"
            ><span style="color: red">*</span>&nbsp;{{
              $t("label.callcenter.Seat.number")
            }}:</label
          >
          <el-input
            v-model="ruleForm.seatsNum"
            :placeholder="$t('label.callcenter.please.select .seatID')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <label slot="label"
            ><span style="color: red">*</span>&nbsp;{{
              $t("label_emailsync_form_emialpassword")
            }}:</label
          >
          <el-input
            type="password"
            v-model="ruleForm.password"
            :placeholder="$t('label.weixin.input.password')"
            size="mini"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <span>
          <el-button @click="closeuserloginDialog('ruleForm')" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button size="mini" @click="submitForm('ruleForm')" type="primary">
            {{ $t("label.emailsync.form.signin") }}
          </el-button></span
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    userloginDialog: {
      type: Boolean,
      default: false,
    },
    userCno: {
      type: String,
      default: "",
    },
  },
  watch: {
    userloginDialog: function () {
      this.ruleForm.seatsNum = this.userCno;
    },
  },
  data() {
    var seatsNumvalidate = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(this.$i18n.t("label.callcenter.please.select .seatID"))
        );
      } else {
        callback();
      }
    };
    var passwordvalidate = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$i18n.t("label.weixin.input.password")));
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        seatsNum: "",
        password: "",
      },
      rules: {
        seatsNum: [{ validator: seatsNumvalidate, trigger: "blur" }],
        password: [{ validator: passwordvalidate, trigger: "blur" }],
      },
    };
  },
  methods: {
    closeuserloginDialog() {
      this.$refs.ruleForm.resetFields();
      this.$emit("closeuserlogindialog");
    },

    // 登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            bindCno: this.ruleForm.seatsNum,
            tianRunPwd: this.ruleForm.password,
          };
          this.$emit("loginSuccess", params);
        } else {
          // 登录失败
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.userChineselogin {
  ::v-deep .el-form--label-top .el-form-item__label {
    height: 26px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
}
</style>