<!--
 * @Author: your name
 * @Date: 2021-09-09 09:44:18
 * @LastEditTime: 2022-01-12 15:04:26
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\injectionComponent\index.vue
-->
<template>
  <Preview />
</template>

<script>
/**
 * 用于注入组件
 */
import Preview from "./components/Preview.vue";
export default {
  components: {
    Preview,
  },
};
</script>
