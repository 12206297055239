<template>
  <div class="input">
    <input
      type="text"
      :placeholder="$t('label_tabpage_searchz')"
      v-model="input"
      @focus="searchOnfocus"
    />
    <div class="search">
      <svg class="icon" aria-hidden="true"><use href="#icon-search"></use></svg>
    </div>
    <div v-if="input != ''" class="delete" @click="retuenMainPage">
      <svg class="icon delete" aria-hidden="true">
        <use href="#icon-quxiao"></use>
      </svg>
    </div>
  </div>
</template>

<script>
/*
 *群策聊天页面左侧输入框功能
 */
export default {
  name: "search",
  data() {
    return {
      //当前输入的关键字
      input: "",
      //判断当前输入框是否属于获取到焦点状态
      isSearch: false,
    };
  },
  mounted() {
    this.$Bus.$on("deliver-add-button",this.ebFn1),
      this.$Bus.$on("search-display",this.ebFn2);
    this.$Bus.$on("change-search-input", this.ebFn3);
  },
  beforeDestroy(){
    this.$Bus.$off("deliver-add-button",this.ebFn1),
    this.$Bus.$off("search-display",this.ebFn2);
    this.$Bus.$off("change-search-input", this.ebFn3);
  },
  watch: {
    input(val) {
      if (val != "") {
        this.$Bus.$emit("deliver-input-isSelect", true);
      } else {
        this.$Bus.$emit("deliver-input-isSelect", false);
      }
      this.$Bus.$emit("deliver-input-value", val);
    },
  },
  methods: {
    ebFn1 (val){
      this.isSearch = val;
      this.input = "";
    },
    ebFn2(val){
        this.input = val;
      },
    ebFn3(isSearch, input){
      this.isSearch = isSearch;
      this.input = input;
    },
    /*
     *关闭搜索页面
     */
    retuenMainPage() {
      this.isSearch = false;
      this.input = "";
    },
    /*
     *更改当前输入框焦点状态
     */
    searchOnfocus() {
      this.isSearch = true;
    },
  },
};
</script>

<style lang='scss'>
.input {
  position: relative;
  float: left;
  font-size: 12px;
  input {
    width: 191px;
    height: 22px;
    background: #fff;
    border: 1px solid #dedcda;
    border-radius: 3px;
    padding-left: 20px;
    box-sizing: border-box;
    outline: none;
  }
  input:focus {
    border: 1px solid #006dcc;
  }
  .search {
    position: absolute;
    left: 6px;
    top: 2px;
    width: 12px;
    height: 12px;
    .icon {
      width: 100%;
      height: 100%;
      display: inline-block;
    }
  }
  .delete {
    cursor: pointer;
    position: absolute;
    right: 3px;
    top: 3px;
    width: 14px;
    height: 14px;
  }
}
</style>
