<template>
  <div class="createCase">
    <!-- 个案 -->
    <el-dialog
      :visible="peopleCreateCase"
      top="15%"
      :width="'420px'"
      :title="$t('vue_label_normal_case_sf')"
      :close-on-click-modal="false"
      :before-close="closeCreateCaseDialog"
    >
      <div>
        <el-radio-group v-model="radio" @change="radioChange">
          <!-- 新建 -->
          <el-radio label="1">{{
            $t("label.import.page1.newcreate")
          }}</el-radio>
          <!-- 选择现有 -->
          <el-radio label="2">{{
            $t("vue_label_lead_select_existing")
          }}</el-radio>
        </el-radio-group>
        <div class="serveCase" v-show="showInput">
          <el-form
            ref="serveForm"
            :model="serveForm"
            :rules="serveRules"
            label-width="125px"
          >
            <el-form-item
              :label="$t('front-kefu-moudle-server-request-num')"
              prop="serveInput"
            >
              <el-input v-model="serveForm.serveInput" @focus="openSearch">
                <i slot="suffix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- 取 消 -->
        <el-button @click="closeCreateCaseDialog">{{
          $t("label.tabpage.cancel")
        }}</el-button>
        <!-- 确 定 -->
        <el-button type="primary" @click="confirmCreateCase('serveForm')">{{
          $t("setup.layout.button.ok")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 新增/修改记录 -->
    <create-edit-obj
      ref="createEditObj"
      :objectApi="objectApi"
      :object-name="tabName"
      :prefix="prefix"
      :vistorId="vistorId"
      :phoneemail="phoneemail"
      @save="save"
    >
    </create-edit-obj>
    <!-- 提示 -->
    <el-dialog
      :title="$t('UG.ST.001')"
      :visible.sync="dialogVisible"
      width="50%"
      top="5%"
      :before-close="handleClose"
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :checked="checked"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        @changeSelect="changeSelectOwner"
      />
    </el-dialog>
  </div>
</template>
<script>
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
export default {
  props: {
    peopleCreateCase: {
      type: Boolean,
      default: false,
    },
    caseId: {
      type: String,
      default: "",
    },
    vistorId: {
      type: String,
      default: "",
    },
    phoneemail: {
      type: String,
      default: "",
    },
  },
  components: {
    createEditObj,
  },
  data() {
    return {
      radio: "2",
      serveForm: {
        serveInput: "",
        serveInputId: "",
      },
      showInput: true,
      serveRules: {
        serveInput: [
          {
            required: true,
            message: this.$i18n.t("front-kefu-moudle-please-input-servernum"),
            trigger: "blur",
          },
        ],
      },
      objectApi: "cloudcccase",
      tabName: this.$i18n.t("vue_label_normal_case_sf"),
      prefix: "008",
      fieldId: "ffe201100005026OYPGU",
      relevantPrefix: "008",
      checked: false,
      relevantObjId: "201100000005024CxOSz",
      dialogVisible: false,
      type: "",
      savecaseId: "",
      caseName:""
    };
  },
  methods: {
    save() {
      this.savecaseId = this.$refs.createEditObj.caseId;
      this.caseName = this.$refs.createEditObj.caseName;
      this.$emit("confirmCreateCase", this.savecaseId,this.caseName, "1");
      this.radio = "2";
      this.serveForm.serveInput = "";
      this.showInput = true;
    },
    // 关闭会话框
    closeCreateCaseDialog() {
      // this.radio= "2",
      // this.serveForm.serveInput = ""
      this.$emit("closeCreateCaseDialog");
      this.save();
      // this.$emit("confirmCreateCase",this.savecaseId,"1")
    },

    // 单选切换
    radioChange(val) {
      this.type = val;
      if (val == "1") {
        // this.$refs.createEditObj.add();
        this.showInput = false;
      } else if (val == "2") {
        this.showInput = true;
      }
    },

    // 确定自建个案
    confirmCreateCase(formName) {
      if (this.type == "1") {
        this.$refs.createEditObj.add();
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$emit(
              "confirmCreateCase",
              this.serveForm.serveInputId,
              this.serveForm.serveInput,
              this.type ? this.type : this.radio
            );
            this.radio = "2";
            this.serveForm.serveInput = "";
          } else {
            return false;
          }
        });
      }
    },

    // 打开搜索页面
    // openSearch() {},
    // 查找所有人
    openSearch() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    changeSelectOwner(row) {
      this.dialogVisible = false;
      this.serveForm.serveInput = row.data.name;
      this.serveForm.serveInputId = row.data.id;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.serveCase {
  margin-top: 15px;
  font-size: 14px;
}
</style>