<template>
  <div class="window-content">
    <div class="chat-none-box" v-if="!isChat">
      <div class="chat-none">
        <svg class="icon" aria-hidden="true" slot="reference">
          <use href="#icon-nomessage"></use>
        </svg>
      </div>
    </div>
    <div class="chat-body-window" v-if="isChat">
      <chatRecordSelect></chatRecordSelect>
      <div
        class="chat-content"
        :style="{
          height:
            isSelect == '聊天' ? 'calc(100% - 195px)' : 'calc(100% - 87px)',
        }"
      >
        <add-chat-window v-show="isSelect == '聊天'"></add-chat-window>
      </div>
      <tool-bar v-show="isSelect == '聊天'"></tool-bar>
    </div>
  </div>
</template>

<script>
/*
 *群策聊天页面右边部分对话框整体功能
 */
import chatRecordSelect from "./components/chat-record-select";
import addChatWindow from "./components/add-chat-window";
import toolBar from "./components/toolbar";

export default {
  name: "window-content",
  data() {
    return {
      //判断当前是否打开某个对话聊天内容
      isChat: false,
      isSelect: "聊天",
      //最近发送的一条消息
      peopleDetail: {},
    };
  },
  methods: {
    ebFn1(){
      this.isChat = false;
    },
    ebFn2(){
      this.isSelect = "聊天";
    },
    ebFn3(){
      this.isChat = true;
    },
    ebFn4(){
      this.isSelect = "聊天";
    },
    ebFn5(val){
      this.isSelect = val;
    },
    ebFn6(item){
      this.peopleDetail = item;
    },
  },
  mounted() {
    this.$Bus.$on("delete-list-remove", this.ebFn1);

    this.$Bus.$on("change-select-window", this.ebFn2);

    this.$Bus.$on("deliver-current-number", this.ebFn3);
    this.$Bus.$on("change-window-select", this.ebFn4);
    this.$Bus.$on("deliver-chat-function",this.ebFn5 );
    this.$Bus.$on("deliver-select-conversation", this.ebFn6);
  },
  beforeDestroy() {
    this.$Bus.$off("delete-list-remove", this.ebFn1);

    this.$Bus.$off("change-select-window", this.ebFn2);

    this.$Bus.$off("deliver-current-number", this.ebFn3);
    this.$Bus.$off("change-window-select", this.ebFn4);
    this.$Bus.$off("deliver-chat-function",this.ebFn5 );
    this.$Bus.$off("deliver-select-conversation", this.ebFn6);
  },
  watch: {
    peopleDetail(val) {
      if (val != {}) {
        this.isChat = true;
      }
    },
  },
  components: {
    chatRecordSelect,
    addChatWindow,
    toolBar,
  },
};
</script>

<style lang='scss'>
.window-content {
  width: 100%;
  height: 100%;
  .chat-none-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .chat-none {
      width: 190px;
      height: 140px;
      user-select: none;
      .icon {
        width: 100%;
        height: 100%;
      }
    }
  }
  .chat-body-window {
    width: 100%;
    height: 100%;
    .chat-content {
      width: 100%;
      height: 286px;
      background: rgb(245, 245, 245);
    }
  }
}
</style>