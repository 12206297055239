<template>
  <div class="addChat-box">
    <div class="addChat" @click="addContants">
      <svg class="icon" aria-hidden="true" slot="reference">
        <use href="#icon-add"></use>
      </svg>
    </div>
    <select-contants
      :isShowB="isShow"
      @changeShow="changeShow"
    ></select-contants>
    <!-- 开通账号 label_ccchat_create_account -->
    <el-dialog
      width="40%"
      :title="$t('label_ccchat_create_account')"
      custom-class="delete-dialog"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :lock-scroll="true"
    >
      <span>
        <!-- 您尚未开通聊天账号，是否立即开通账号？ -->
        {{ $t("vue_label_chat_haventactivate") }}
        <!-- {{ $t('vue_label_chat_deleting_chat') }} -->
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="openAccount">
          {{ $t("vue_label_chat_conformactivation") }}
          <!-- 确认开通 -->
        </el-button>
        <el-button type="info" @click="cancel">
          {{ $t("vue_label_chat_activelater") }}
          <!-- 稍后开通 -->
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*
 * 点击创建新聊天相关功能逻辑
 */
import selectContants from "./select-contants";
import job from "@/views/chat/js/request.js";

export default {
  name: "addChat",
  data() {
    return {
      //判断新建聊天组件是否展示
      isShow: false,
      //判断开通群策账号弹窗是否展示
      dialogVisible: false,
    };
  },
  methods: {
    /*
     * 开通环信账号
     */
    async openAccount() {
      let result = await job.createImUser();
      if (result.data.createSuccessList.length > 0) {
        this.$message({
          showClose: true,
          type: "success",
          message: this.$i18n.t("vue_label_chat_activesuccess"),
        });
        this.dialogVisible = false;
        this.$Bus.$emit("chat-login-again", true);
      } else {
        this.$message({
          showClose: true,
          type: "error",
          message: this.$i18n.t("vue_label_chat_activefail"),
        });
        this.dialogVisible = false;
      }
    },
    /*
     * 取消开通环信账号
     */
    cancel() {
      this.dialogVisible = false;
    },
    /*
     * 点击添加新聊天按钮判断展示什么弹窗
     */
    addContants() {
      if (localStorage.getItem("login") == "fail") {
        this.dialogVisible = true;
      } else {
        this.isShow = true;
        this.$Bus.$emit("deliver-add-button", false);
      }
    },
    /*
     * 更改新建聊天组件展示变量
     */
    changeShow(val) {
      this.isShow = val;
    },
    ebFn1() {
      this.isShow = false;
    },
    ebFn2() {
      this.addContants();
    },
  },
  mounted() {
    this.$Bus.$on("close-window-addMember", this.ebFn1);
    this.$Bus.$on("show-add-card", this.ebFn2);
  },
  beforeDestroy() {
    this.$Bus.$off("close-window-addMember", this.ebFn1);
    this.$Bus.$off("show-add-card", this.ebFn2);
  },
  components: {
    selectContants,
  },
};
</script>

<style lang="scss">
.addChat-box {
  .addChat {
    cursor: pointer;
    margin-left: 7px;
    float: left;
    width: 29px;
    height: 22px;
    .icon {
      width: 100%;
      height: 100%;
    }
    .delete-dialog {
      .dialog-footer {
        display: flex;
        justify-content: center;
        color: #fff;
      }
    }
    ::v-deep .dialog-footer {
      display: revert !important;
    }
  }
  ::v-deep.dialog-footer {
    display: revert !important;
  }
}
</style>