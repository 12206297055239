<template>
  <div
    class="footerBar"
    :style="{
      justifyContent:
        LeftFooterBarList.length > 0 ? 'space-between' : 'flex-end',
    }"
  >
    <!--左侧工具条-->
    <div
      class="footerBarLeft"
      ref="footerBarLeft"
      v-if="LeftFooterBarList.length > 0"
    >
      <div class="itemBox" ref="box">
        <div
          class="item"
          :class="itemTab.id == currentActive || itemTab.popupFlag == true ? 'active' : ''"
          v-for="itemTab in footerBarLeftList"
          :key="itemTab.id"
          @click="clickTab(itemTab)"
        >
          <svg class="iconImg" aria-hidden="true">
            <use :href="'#' + JSON.parse(itemTab.icon)['code']"></use>
          </svg>
          <div class="itemTitle">{{ itemTab.label }}</div>
          <!--弹出时右上角的箭头-->
          <i class="el-icon-top-right iconTopRight" v-if="itemTab.popupFlag"> </i>
        </div>
      </div>
      <!--更多-->
      <!--当moreList里面有数据（即isShowMore为true），但是footerBarLeftList里面没有数据，此时只展示...,不展示更多两个字-->
      <!--当moreList里面有数据（即isShowMore为true），且footerBarLeftList里面有数据，...和更多都展示-->
      <div class="itemBox" ref="more" v-if="isShowMore">
        <el-dropdown @command="clickTab" class="itemMore">
          <div style="height: 100%; display: flex; align-items: center">
            <div class="iconImg">
              <img src="./assets/images/more.png" />
            </div>
            <div class="itemTitleMore" v-if="footerBarLeftList.length > 0">
               {{ $t("label.more") }}
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              :class="itemMore.id == currentActive || itemMore.popupFlag == true ? 'moreActive' : ''"
              v-for="itemMore in moreList"
              :command="itemMore"
              :key="itemMore.id"
            >
              <div style="display: flex; align-items: center;position: relative">
                <svg class="iconImg mr10" aria-hidden="true">
                  <use :href="'#' + JSON.parse(itemMore.icon)['code']"></use>
                </svg>
                <div class="textEllipsis">{{ itemMore.label }}</div>
                  <i class="el-icon-top-right iconTopRight" v-if="itemMore.popupFlag"> </i>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!--右侧呼叫中心-->
    <callCenterBar
      v-if="
        footerBarRightList.length > 0 && footerBarRightList[0].iscustom == '0'
      "
    ></callCenterBar>
    <cc-renderer
      style="margin: 0"
      v-else-if="
        footerBarRightList.length > 0 && footerBarRightList[0].iscustom == '1'
      "
      :componentData="rightComponentData"
      :canvasStyleData="rightCanvasStyleData"
    />
    <!--弹出框-->
    <div
      class="popup"
      ref="myPopup"
      v-if="popupVisible"
      :style="{ width: popupWidth, height: popupHeight }"
    >
      <div class="popupHeader">
        <div class="popupHeaderTitle">
          <svg class="iconImg" aria-hidden="true">
            <use :href="'#' + JSON.parse(popupIcon)['code']"></use>
          </svg>
          <div class="title">{{ popupTitle }}</div>
        </div>
        <div class="popupHeaderBtn">
          <!-- 关闭 -->
          <i class="el-icon-close" @click="closePopup" title="关闭"></i>
          <!-- 弹出 -->
          <i
            class="el-icon-position"
            style="margin-left: 10px"
            @click="popupWindow"
            title="弹出"
          ></i>
        </div>
      </div>
      <div class="popupBody">
        <cc-renderer
          :componentData="componentData"
          :canvasStyleData="canvasStyleData"
          v-loading="loading"
        />
      </div>
    </div>
    <!-- 群策 -->
    <chat ref="QUNCE"></chat>
  </div>
</template>

<script>
import callCenterBar from "@/views/callCenterPhone/callCenterBar.vue";
import chat from "@/views/chat/chat.vue";

export default {
  name: "footerBar",
  components: {
    callCenterBar,
    chat,
  },
  props: {
    LeftFooterBarList: {
      type: Array,
      default: () => [],
    },
    rightFooterBarList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 左侧渲染器所需数据
      componentData: [],
      canvasStyleData: {},
      loading: false,
      // 底部bar左侧工具条数据
      footerBarLeftList: [],
      // 底部bar右侧工具条数据
      footerBarRightList: [],
      // 更多里面的数据
      moreList: [],
      // 当前选中的
      currentActive: "",
      // 弹框显隐
      popupVisible: false,
      // 弹出框的标题
      popupTitle: "",
      // 弹出框的icon
      popupIcon: "",
      // 弹出框的宽
      popupWidth: "",
      // 弹出框的高
      popupHeight: "",
      // 是否展示更多
      isShowMore: false,
      // 右侧渲染器数据
      rightComponentData: [],
      rightCanvasStyleData: {},
      // 点击左侧工具记录当前点击tab的item数据
      clickTabItem: {},
      // 所有弹出窗口各自的clickTabItem数据  用于判断定位到各自已经弹出的窗口
      popupWindowDataMap: new Map(),
      // 所有弹出window窗口的pageApi集合  用于刷新或者关闭的时候通过pageApi定位到当前打开的窗口，拿到打开的window对象 然后关闭
      pageApiArr: [],
    };
  },
  watch: {
    // 监听LeftFooterBarList
    LeftFooterBarList: {
      handler: function (newval){
        if(newval.length>0&&this.rightFooterBarList.length===0){
          this.init();
        }
      },
      immediate: true,
    },
    // 监听rightFooterBarList
    rightFooterBarList: {
      handler: function (newval){
        if(newval.length>0){
          this.footerBarRightList = JSON.parse(
            JSON.stringify(newval)
          );
          if (this.footerBarRightList[0].iscustom == "1") {
            this.getRightCustomPageDetail(this.footerBarRightList[0].attribute);
          } else {
            // 一进页面初始化计算左侧工具条
            this.init();
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener("beforeunload", this.closeAllPopupWindow);
  },
  mounted() {
    // 窗口发生变化重新计算一次
    window.onresize = () => {
      this.init();
    };
    // 右侧工具不需要计算  放到这里执行一次就可以
    // if (this.rightFooterBarList.length > 0) {
    //   this.footerBarRightList = JSON.parse(
    //     JSON.stringify(this.rightFooterBarList)
    //   );
    //   if (this.footerBarRightList[0].iscustom == "1") {
    //     this.getRightCustomPageDetail(this.footerBarRightList[0].attribute);
    //   } else {
    //     // 一进页面初始化计算左侧工具条
    //     this.init();
    //   }
    // } else {
    //   this.init();
    // }
    // 监听弹回事件
    let that = this;
    // 弹出的window窗口弹回、关闭
    window.addEventListener(
      "message",
      function (e) {
        // 弹回
        if (e.data.key === "bounce") {
          // 改变数据的popupFlag状态
          that.handleData(e.data.currentPoupWindowData, false);
          // 删掉map中的这条数据
          that.popupWindowDataMap.delete(e.data.currentPoupWindowData.attribute)
          // 打开弹窗
          that.clickTab(e.data.currentPoupWindowData);
        } else {
            // 关闭
            // 改变数据的popupFlag状态
            that.handleData(e.data.currentPoupWindowData,false)
            // 删掉map中的这条数据
            that.popupWindowDataMap.delete(e.data.currentPoupWindowData.attribute)
            // 当前选中状态置空
            // that.currentActive = ""
        }
      },
      false
    );
    this.$bus.$on("newCLick", this.ebFn);
  },
  beforeDestroy() {
    window.onresize = null
    this.$bus.$off("newCLick", this.ebFn);
  },
  methods: {
    ebFn(val){
      this.handleCall(val);
    },
    // 处理电话
    handleCall(val) {
      let callcenterConf = JSON.parse(localStorage.getItem("callcenterConf"));
      if (callcenterConf?.iscustom == 1) {
        let option = {
          number: val,
        };
        this.$CCDK.CCCall.call(callcenterConf.customcomponentid, option);
      } else {
        this.$bus.$emit("oldCLick", val);
      }
    },
    // 右侧电话条为自定义时调用的方法
    async getRightCustomPageDetail(pageApi) {
      let res = await this.getCustomPageDetail(pageApi);
      if (res.returnCode == 200) {
        this.rightComponentData = JSON.parse(res.data.pageContent);
        this.rightCanvasStyleData = JSON.parse(res.data.canvasStyleData);
      } else {
        this.$message.warning(res.returnInfo);
      }
      this.init();
    },
    // 初始化计算左侧bar的方法
    init() {
      this.clientWidth = document.body.clientWidth - 100;
      this.clientHeight = document.body.clientHeight - 100;
      this.footerBarLeftList = JSON.parse(
        JSON.stringify(this.LeftFooterBarList)
      );
      // 初始化计算左侧工具条
      this.$nextTick(() => {
        // 保证dom更新后再计算  否则拿不到dom元素
        this.getWidth();
      });
    },
    // 计算数组的第几个元素渲染后超出左侧盒子宽度，超出的放更多里面
    getWidth() {
      let arr = [];
      let arrIndex = 0; // 超出盒子宽度的下标
      let num = this.$refs.box?.childNodes.length;
      // 计算出每个元素渲染后dom的宽度
      for (let i = 0; i < num; i++) {
        // 当前按钮宽度 + 当前按钮距离浏览器左边的宽度
        let weight =
          this.$refs.box?.childNodes[i]?.offsetWidth +
          this.$refs.box?.childNodes[i]?.offsetLeft;
        arr.push(weight);
      }
      // 盒子的宽度
      let boxWidth = this.$refs.footerBarLeft?.offsetWidth;
      // 更多的宽度
      let moreWidth = 60;
      // 计算出第几个元素渲染后盒子就装不下了,返回对应元素的索引,都不满足条件返回-1
      arrIndex = arr.findIndex((res) => res > boxWidth - moreWidth);
      if (arrIndex != -1) {
        //  装不下
        this.moreList = this.footerBarLeftList.splice(
          arrIndex,
          arr.length - arrIndex
        );
        this.isShowMore = true;
      } else {
        // 能装下
        this.moreList = [];
        this.isShowMore = false;
      }
    },
    // 获取自定义页面
    getCustomPageDetail(pageApi) {
      let parameter = {
        pageApi: pageApi,
      };
      return this.$devHttp.postFormat(
        `${window.Glod["ccex-dev"]}/custom/pc/1.0/post/detailCustomPage`,
        parameter
      );
    },
    // 点击每项
    async clickTab(item) {
      // 判断打开的是否为群策
      if (item.customcomponentid === "cloudcc_im_ccchat") {
        this.popupVisible = false;
        this.$refs.QUNCE.chatSwitch();
        this.currentActive = item.id;
        if (!this.$refs.QUNCE.isShowChat) {
          this.currentActive = "";
        }
      } else {
        // 自定义页面
        if (item.openwith == "rightSide") {
          // rightSide表示当打开方式为新tab的方式
          this.currentActive = item.id;
          // 关闭群策弹窗
          this.$refs.QUNCE.closeChat();
          this.popupVisible = false;
          let arr = JSON.parse(sessionStorage.getItem("timeChuo")) || [];
          let idx = arr.findIndex((item0) => {
            return item0.id == item.id;
          });
          if (idx !== -1) {
            let pageId = JSON.parse(sessionStorage.getItem("timeChuo"))[idx]
              .pageId;

            let options = {
              refresh: false,
            };
            window.$CCDK.CCPage.reOpenPage(pageId, options);
          } else {
            let obj = {
              pageApi: item.attribute,
            };
            let options = {
              openPlace: "tab",
              openMode: "_blank",
              // openMode: item.appid,
              title: item.label,
            };
            let pageId = window.$CCDK.CCPage.openCustomPage(obj, options);
            arr.push({
              id: item.id,
              pageId: pageId,
            });
            sessionStorage.setItem("timeChuo", JSON.stringify(arr));
          }
        } else {
          // dialog方式
          //  如果当前点击项已经是window弹出状态了  那么再点击的时候 不需要再打开dialog再去点击弹出 只需要重新定位到已经打开的窗口
          let isHas = this.popupWindowDataMap.has(item.attribute);
          if (isHas) {
            let getMapItem = this.popupWindowDataMap.get(item.attribute);
            if (getMapItem.popupFlag) {
              let routeData = this.$router.resolve({
                path: "/customPageWindowPopup",
                query: {
                  clickTabItem: JSON.stringify(getMapItem),
                },
              });
              // 打开window  建pageApi作为window的name
              window.open(routeData.href, getMapItem.attribute);
            }
            return;
          }
          // 判断当前点击的是否是已经打开的dialog  如果当前点击的和当前打开的dialog一样，那么再点击关闭
          if (this.clickTabItem.id == item.id) {
            // 当点击的已经打开  再点击就关闭  当点击的是关闭的 再点击就打开
            if (this.popupVisible) {
              this.popupVisible = false;
              this.currentActive = "";
            } else {
              this.popupVisible = true;
              this.currentActive = item.id;
            }
          } else {
            // 点击的不是当前打开的dialog
            // 弹窗显示
            this.popupVisible = true;
            this.currentActive = item.id;
          }
          // 记录当前点击的item  1、给点击dialog弹出window窗口方法用 2、判断当前点击的是否是已经打开的dialog
          this.clickTabItem = item;
          // 关闭群策弹窗
          this.$refs.QUNCE.closeChat();
          // 动态设置弹出框的宽高、标题、icon
          this.popupWidth =
            (item.panelwidth < this.clientWidth
              ? item.panelwidth
              : this.clientWidth) + "px";
          this.popupHeight =
            (item.panelheight < this.clientHeight
              ? item.panelheight
              : this.clientHeight) + "px";
          this.popupTitle = item.label;
          this.popupIcon = item.icon;
          // 请求弹出框的页面数据
          this.loading = true;
          this.componentData = [];
          this.canvasStyleData = {};
          let res = await this.getCustomPageDetail(item.attribute);
          if (res.returnCode == 200) {
            this.componentData = JSON.parse(res.data.pageContent);
            this.canvasStyleData = JSON.parse(res.data.canvasStyleData);
          } else {
            this.$message.warning(res.returnInfo);
          }
          this.loading = false;
        }
      }
    },
    // 点击dialog弹出window窗口
    popupWindow() {
      // 弹窗隐藏
      this.popupVisible = false;
      // 所有数据  找到当前弹出的item  添加popupFlag为true的弹出标识
      this.handleData(this.clickTabItem, true);
      // 弹出window 窗口时  用popupFlag控制选中样式  不使用currentActive控制，
      // 因为当既有弹出窗口，也有弹出dialog时  如果在弹回的时候清除currentActive  则会把弹出dialog的选中状态也清除
      this.currentActive = "";
      //  将this.clickTabItem放到map中  this.clickTabItem.attribute为pageApi具有唯一性
      this.popupWindowDataMap.set(this.clickTabItem.attribute,this.clickTabItem)
      let routeData = this.$router.resolve({
        path: "/customPageWindowPopup",
        query: {
          clickTabItem: JSON.stringify(this.clickTabItem),
        },
      });
      // 打开window  this.clickTabItem.attribute为pageApi 将pageApi作为window的name
      window.open(
        routeData.href,
        this.clickTabItem.attribute,
        `width=${this.clickTabItem.panelwidth},height=${this.clickTabItem.panelheight},left=400,top=100`
      );
      // 以下是将this.clickTabItem.attribute即pageApi存起来  当前页面刷新或者关闭的方法用
      let res = this.pageApiArr.some(item => {
          if (item == this.clickTabItem.attribute){
              return true
          } else {
              return false
          }
      })
      if(!res) {
          this.pageApiArr.push(this.clickTabItem.attribute);
          localStorage.setItem("pageApiArr", JSON.stringify(this.pageApiArr));
      }
    },
    // 给数据添加弹出弹回标识  当弹出时给当前数据添加popupFlag为true的弹出标识  当弹回时为false
    // currentItem当前弹出或弹回的数据对象  flag 弹出true 弹回false
    handleData(currentItem, flag) {
      let arrData = [...this.footerBarLeftList, ...this.moreList];
      arrData.forEach((item) => {
        if (item.id == currentItem?.id) {
          this.$set(item, "popupFlag", flag);
          // item.popupFlag = flag
        }
      });
      // window窗口弹回和关闭  去掉对应的pageApiArr中的数据
      if (!flag) {
        let idx = this.pageApiArr.findIndex(item => item == currentItem.attribute)
        if(idx != -1) {
          this.pageApiArr.splice(idx,1)
          // 删掉之后再保存一下
          if (this.pageApiArr.length > 0) {
            localStorage.setItem("pageApiArr", JSON.stringify(this.pageApiArr));
          } else {
            localStorage.removeItem("pageApiArr");
          }
        }
      }
    },
    // 当前页面刷新与关闭时  关闭所有弹出的window窗口
    closeAllPopupWindow() {
      if (!localStorage.getItem("pageApiArr")) {
        return;
      }
      let routeData = this.$router.resolve({
        path: "/customPageWindowPopup",
        query: {
          clickTabItem: JSON.stringify(this.clickTabItem),
        },
      });
      let pageApiArr = JSON.parse(localStorage.getItem("pageApiArr"));
      pageApiArr.forEach((item) => {
        // item为每个页面打的pageApi 用item就可以定位到已经打开的window窗口
        // 打开window  建pageApi作为window的name
        let oNewWindow = window.open(routeData.href, item);
        if (oNewWindow) {
          oNewWindow.close();
        }
      });
      localStorage.removeItem("pageApiArr");
    },
    // 弹窗关闭
    closePopup() {
      this.currentActive = false;
      this.popupVisible = false;
    },
  },
  destroyed() {
    this.closeAllPopupWindow();
  },
};
</script>
<style scoped>
/*移入el-dropdown-menu__item的样式*/
::v-deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #e0edf8;
  color: #333333;
}
/*去掉下拉的倒三角*/
::v-deep.el-popper .popper__arrow::after {
  border-width: 0;
}
::v-deep.el-popper .popper__arrow {
  border-width: 0;
}
.moreActive {
  background: #e0edf8;
  color: #333333;
}
.textEllipsis {
  /*溢出...显示*/
  max-width: 98px;
  overflow: hidden; /* 溢出隐藏 */
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
  text-overflow: ellipsis; /* ...代替隐藏的内容 */
}
.iconImg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.iconTopRight {
  font-size: 8px;
  position: absolute;
  top: 0;
  right: -25px;
}
.mr10 {
  margin-right: 10px;
}
</style>
<style lang="scss" scoped>
.footerBar {
  width: 100%;
  // width: calc(100% - 44px);
  height: 44px;
  background: #ffffff;
  box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.12);
  // position: fixed;
  // bottom: 0;
  /*background: red;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
  .footerBarLeft {
    /*留一个最小宽度来放图标*/
    min-width: 35px;
    height: 100%;
    flex: 1;
    /*background: greenyellow;*/
    margin-right: 155px;
    overflow: hidden;
    display: flex;
    .itemBox {
      height: 100%;
      display: flex;
      .item,
      .itemMore {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
        flex-shrink: 0;
        .iconImg {
          width: 18px;
          height: 18px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .itemTitle,
        .itemTitleMore {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          margin-left: 10px;
          cursor: pointer;
        }
      }
      .item {
        position: relative;
        .iconTopRight {
          font-size: 8px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .active {
        background: rgba(2, 109, 204, 0.12);
        border-bottom: 3px solid #026dcc;
      }
    }
  }
  .footerBarRight {
    height: 100%;
    overflow: hidden;
    /*width: 500px;*/
    /*background: yellow;*/
  }
  .popup {
    position: fixed;
    bottom: 50px;
    left: 70px;
    z-index: 2900;
    width: 336px;
    height: 475px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    .popupHeader {
      width: 100%;
      height: 40px;
      border-bottom: 2px solid #b583d9;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      .popupHeaderTitle {
        height: 100%;
        display: flex;
        align-items: center;
        .iconImg {
          width: 18px;
          height: 18px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          margin-left: 10px;
        }
      }
      .popupHeaderBtn {
        display: flex;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .popupBody {
      width: 100%;
      flex: 1;
      overflow: auto;
    }
  }
  ::v-deep .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #e0edf8 !important;
    color: #333333 !important;
  }
  .el-dropdown-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 10px 0;
    margin: 5px 0;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    /*width: 152px;*/
    /*height: 96px;*/
    /*background: #FFFFFF;*/
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.36);
  }
  .popper__arrow {
    display: none !important;
  }
}
</style>
