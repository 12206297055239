<template>
  <div class="chat" v-if="isShowQunCe">
    <button
      class="group-button"
      @mouseover="changeButtonColorSelect"
      @mouseleave="changeButtonColorNoSelect"
      v-if="false"
      :style="{ top: topHeight }"
    >
      <div class="image">
        <div class="no-read" v-show="noReadCount > 99">99+</div>
        <div class="no-read" v-show="noReadCount <= 99 && noReadCount > 0">
          {{ noReadCount }}
        </div>
        <svg class="icon" aria-hidden="true" slot="reference">
          <use :href="buttonSelect ? buttonYellows : buttonGreys"></use>
        </svg>
      </div>
      <span
        :style="{ color: buttonSelect ? '#E5851A' : '#888' }"
        v-show="showAllIcon"
        >{{ $t("label_mobile_buttom_team") }}</span
      >
    </button>
    <div class="chat-body" v-show="isShowChat">
      <div class="close-button" @click="closeChat">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-chahao"></use>
        </svg>
      </div>
      <div class="chat-list">
        <listHeader></listHeader>
        <listContent></listContent>
      </div>
      <div class="line"></div>
      <div class="chat-window">
        <windowContent></windowContent>
      </div>
    </div>
    <audio src="@/assets/addContantImage/audio.mp3" id="audio"></audio>
  </div>
</template>

<script>
/**
 * 群策
 * 功能：实时通讯功能
 */
import waterMark from "@/utils/waterMark/callMark.js";
import getTimeStringAutoShort from "@/utils/changeTime.js";
import VueCookies from "vue-cookies";
import listHeader from "./list-header.vue";
import listContent from "./list-content.vue";
import windowContent from "./window-content.vue";
import { mapActions } from "vuex";
import instance from "@/config/httpConfig";
import job from "@/views/chat/js/request.js";
export default {
  name: "chat",
  data() {
    return {
      //判断当前路由是否显示群策
      routeQunCe: true,
      //判断当前用户是否登录
      isLogin: "false",
      //展示整个群策图标
      showAllIcon: false,
      //判断群策按钮是拖拽还是点击
      isDrap: false,
      //接口返回的按钮开关
      switchGroup: false,
      //聊天按钮的显示与隐藏
      isShowButton: false,
      //聊天页面的显示与隐藏
      isShowChat: false,
      //用户登录环信的账号
      loginCount: "",
      //用户登录环信的密码
      loginKey: "",
      //用户ID
      loginId: "",
      //获取群消息群成员头像
      objMsg: {},
      //群策图标是否选中
      buttonSelect: false,
      //群策按钮未移入图标
      buttonGreys: "#icon-qunce",
      //群策按钮移入图标
      buttonYellows: "#icon-qunce1",
      //收到群聊邀请数组
      groupInviteArr: [],
      //收到消息数组
      getMessageArr: [],
      //当前收到消息下标
      messageIndex: 999999999999999999999999999999999999999999999999999,
      //当前收到群聊消息下标
      groupInviteIndex: 9999999999999999999999999999999999999999999999999,
      //聊天列表
      messageArrHuanxin: [],
    };
  },
  async mounted() {
    this.loginChat();
    //初始登录获取群聊邀请
    setTimeout(() => {
      this.getFirstGroup();
    }, 500);
    //初始登录获取的消息
    setTimeout(() => {
      this.getFirstMessage();
    }, 1500);
    this.$Bus.$on("open-chat-window", this.ebFn1);
    this.$Bus.$on("close-chat-window",this.ebFn2);
    this.$Bus.$on("chat-login-again",this.ebFn3);
    this.$nextTick(() => {
      this.drapFunction();
    });

    let self = this;
    this.$imconn.listen({
      //登录回调
      onOpened: function () {
        localStorage.setItem("login", "success");
        if (self.isLogin == "false") {
          self.changeUserIsteam("true");
        }
        self.$Bus.$emit("judge-is-login", "success");
      },
      //失败回调
      onError(val) {
        if (val.type == 1) {
          localStorage.setItem("login", "fail");
          if (self.isLogin == "true") {
            self.changeUserIsteam("false");
          }
        }
      },
      //收到自定义消息
      onCustomMessage(val) {
        if (val.customEvent == "CARD") {
          val.contentsType = "CARD";
          val.type = "groupchat";
          self.getMessageArr.push(val);
        }
      },
      //收到文字消息
      onTextMessage(val) {
        let objKey = Object.keys(val.ext);
        if (objKey.length > 0) {
          return false;
        }

        self.getMessageArr.push(val);
      },
      //收到图片
      onPictureMessage: function (val) {
        self.getMessageArr.push(val);
      },
      //收到文件
      onFileMessage: function (val) {
        self.getMessageArr.push(val);
      },
      //收到位置消息
      onLocationMessage: function (val) {
        self.getMessageArr.push(val);
      },
      //收到音频消息
      onAudioMessage: function (val) {
        self.getMessageArr.push(val);
      },
      //收到群聊邀请
      onPresence: function (val) {
        self.groupInviteArr.push(val);
      },
      //收到自定义消息
      onCmdMessage: function (val) {
        //更改群组名称
        let index = self.$store.state.list.chatList.findIndex(
          (res) => res.imId == val.to
        );
        if (index >= 0) {
          setTimeout(() => {
            self.getGroupInformation(val.to);
          }, 500);
        }
      },
    });
  },
  beforeDestroy(){
    this.$Bus.$off("open-chat-window", this.ebFn1);
    this.$Bus.$off("close-chat-window",this.ebFn2);
    this.$Bus.$off("chat-login-again",this.ebFn3);
  },
  computed: {
    /**
     * 判断是否展示群策功能
     */
    isShowQunCe() {
      return VueCookies.get("countryCode") == "CN" &&
        !this.$store.state.userInfoObj.ispartner &&
        this.routeQunCe
        ? true
        : false;
    },
    /**
     * 未读消息条数
     */
    noReadCount() {
      return this.$store.state.list.chatList.length > 0
        ? this.$store.getters.totalNoRead
        : 0;
    },
    /**
     * 群策按钮距离顶部高度
     */
    topHeight() {
      return window.innerHeight * 0.79 + "px";
    },
    /**
     * 当前页面路由
     */
    routePath() {
      return this.$route.path;
    },
  },
  watch: {
    isShowQunCe: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (this.isShowQunCe) {
            this.loginChat();
          }
        }
      },
    },
    routePath: {
      handler(val) {
        if (
          val.indexOf("systemSettings") == 1 ||
          val.indexOf("profileSetting") == 1 ||
          val.indexOf("print-") == 1
        ) {
          this.routeQunCe = false;
        }
      },
      immediate: true,
      deep: true,
    },
    //监听收到群聊消息数组
    groupInviteArr: {
      async handler(newVal) {
        if (newVal.length > this.groupInviteIndex) {
          await this.groupListenFun(newVal[newVal.length - 1]);
        }
      },
      immediate: true,
      deep: true,
    },
    //监听收到消息数组
    getMessageArr: {
      async handler(newVal) {
        if (newVal.length > this.messageIndex) {
          let messageType = "";
          switch (newVal[newVal.length - 1].contentsType) {
            case "TEXT":
              messageType = newVal[newVal.length - 1].data;
              break;
            case "IMAGE":
              messageType = `[${this.$i18n.t("label.icon.155")}]`;
              break;
            case "FILE":
              messageType = `[${this.$i18n.t("FL")}]`;
              break;
            case "LOCATION":
              messageType = `[${this.$i18n.t("label.position")}]`;
              break;
            case "VOICE":
              messageType = `[${this.$i18n.t("vue_label_chat_voice")}]`;
              break;
            case "CARD":
              messageType = `[${this.$i18n.t(
                "vue_label_chat_group_assistant_notification"
              )}]`;
              break;
          }
          await this.listenFunc(newVal[newVal.length - 1], messageType);
        }
      },
      deep: true,
    },
    isShowChat: {
      handler(val) {
        if (val == true) {
          let index = this.$store.state.list.chatList.findIndex(
            (res) => res.imId == this.$store.state.list.isChatID.imId
          );
          if (index >= 0) {
            this.$store.commit("changeIsSelectNoRead", index);
          }
          if (this.switchGroup == true) {
            this.isShowButton = false;
          }
        } else {
          if (this.switchGroup == true) {
            this.isShowButton = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ebFn1(){
      this.isShowChat = true;
    },
    ebFn2(){
      this.isShowChat = false;
    },
    ebFn3 (){
      this.onLogin({
        username: this.loginCount,
        password: this.loginKey,
      });
    },
    /**
     * 群策初始化登录判断
     */
    async loginChat() {
      let QunceState = await job.getQunceState();
      if (
        QunceState.result != undefined &&
        QunceState.result != null &&
        QunceState.result == true &&
        QunceState.data != undefined &&
        QunceState.data != null &&
        QunceState.data == "true"
      ) {
        localStorage.setItem("chatOpen", "true");
        this.switchGroup = true;
        this.isShowButton = true;
        localStorage.setItem("binding", this.$CCDK.CCToken.getToken());
        // this.$store.commit("changeImgBinding");
        let result = await job.login();
        if (result.returnInfo == "Success!" && result.returnCode == 1) {
          this.isLogin = result.data.isteam;
          localStorage.setItem("username", result.data.userName);
          localStorage.setItem("myid", result.data.userId);
          this.loginCount = result.data.imUserId;
          this.loginKey = result.data.imUserPwd;
          this.loginId = result.data.userId;
          let urlRes = await job.geturl({});
          if (urlRes.result == true && urlRes.data) {
            this.$WebIM.logger.disableAll();
            localStorage.setItem("domainName", urlRes.data);
            if (
              localStorage.getItem("userInfo") &&
              this.loginCount !=
                JSON.parse(localStorage.getItem("userInfo")).userId
            ) {
              this.$store.commit("clearChatList");
              this.$store.commit("deleteIschatId");
              if (this.isLogin == "true") {
                this.onLogin({
                  username: this.loginCount,
                  password: this.loginKey,
                });
              } else {
                this.$store.commit("getChatList", []);
                localStorage.setItem("login", "fail");
              }
            } else {
              this.$store.commit("deleteIschatId");
              if (this.isLogin == "true") {
                this.onLogin({
                  username: this.loginCount,
                  password: this.loginKey,
                });
              } else {
                this.$store.commit("getChatList", []);
                localStorage.setItem("login", "fail");
              }
            }
            this.$Bus.$emit("changename-and-img", true);
            this.$Bus.$emit("show-chat-list", true);
          }
        }
      } else {
        localStorage.setItem("chatOpen", "false");
      }
    },
    /**
     * 更改是否开通群策用户状态
     */
    async changeUserIsteam(isTeam) {
      await job.changeUserIsteam(this.loginId, isTeam);
    },
    /**
     * 获取群聊中用户的名称
     */
    getGroupUserName(index, imId) {
      let name = "";
      this.$store.state.list.chatList[index].members.forEach((res) => {
        if (res.easemobImId == imId) {
          name = res.memberUserName;
        }
      });
      return name;
    },
    /**
     * 初始页面获取群聊邀请
     */
    async getFirstGroup() {
      for (let i = 0; i < this.groupInviteArr.length; i++) {
        await this.groupListenFun(this.groupInviteArr[i]);
      }
      this.groupInviteIndex = this.groupInviteArr.length;
    },
    /**
     * 初始页面获取的消息
     */
    async getFirstMessage() {
      let messageType = "";
      for (let i = 0; i < this.getMessageArr.length; i++) {
        switch (this.getMessageArr[i].contentsType) {
          case "TEXT":
            messageType = this.getMessageArr[i].data;
            break;
          case "IMAGE":
            messageType = `[${this.$i18n.t("label.icon.155")}]`;
            break;
          case "FILE":
            messageType = `[${this.$i18n.t("FL")}]`;
            break;
          case "LOCATION":
            messageType = `[${this.$i18n.t("label.position")}]`;
            break;
          case "VOICE":
            messageType = `[${this.$i18n.t("vue_label_chat_voice")}]`;
            break;
          case "CARD":
            messageType = `[${this.$i18n.t(
              "vue_label_chat_group_assistant_notification"
            )}]`;
            break;
        }
        await this.listenFunc(this.getMessageArr[i], messageType);
      }
      this.messageIndex = this.getMessageArr.length;
    },
    /**
     * 实现群策按钮拖拽功能
     */
    drapFunction() {
      let self = this;
      // 获取DOM元素
      let dragDiv = document.getElementsByClassName("group-button")[0];
      // 鼠标按下事件 处理程序
      let putDown = function (event) {
        dragDiv.style.cursor = "pointer";
        let offsetX = parseInt(dragDiv.style.left); // 获取当前的x轴距离
        let offsetY = parseInt(dragDiv.style.top); // 获取当前的y轴距离
        let innerX = event.clientX - offsetX; // 获取鼠标在方块内的x轴距
        let innerY = event.clientY - offsetY; // 获取鼠标在方块内的y轴距
        // 鼠标移动的时候不停的修改div的left和top值
        document.onmousemove = function (event) {
          self.isDrap = true;
          dragDiv.style.left = event.clientX - innerX + "px";
          dragDiv.style.top = event.clientY - innerY + "px";
          // 边界判断
          if (parseInt(dragDiv.style.left) <= 0) {
            dragDiv.style.left = "0px";
          }
          if (parseInt(dragDiv.style.top) <= 0) {
            dragDiv.style.top = "0px";
          }
          if (
            parseInt(dragDiv.style.left) >=
            window.innerWidth - parseInt(dragDiv.style.width)
          ) {
            dragDiv.style.left =
              window.innerWidth - parseInt(dragDiv.style.width) + "px";
          }
          if (parseInt(dragDiv.style.top) >= window.innerHeight - 50) {
            dragDiv.style.top = window.innerHeight - 50 + "px";
          }
        };
        // 鼠标抬起时，清除绑定在文档上的mousemove和mouseup事件
        // 否则鼠标抬起后还可以继续拖拽方块
        document.onmouseup = function () {
          if (self.isDrap == false) {
            self.chatSwitch();
          } else {
            self.isDrap = false;
          }
          self.isDrap = false;
          document.onmousemove = null;
          document.onmouseup = null;
          // 清除border
          dragDiv.style.borderStyle = "";
          dragDiv.style.borderColor = "";
          dragDiv.style.borderWidth = "";
        };
      };
      // 绑定鼠标按下事件
      if (dragDiv) {
        dragDiv?.addEventListener("mousedown", putDown, false);
      }
    },
    ...mapActions(["onLogin", "onGetCurrentMsgList", "changeInitialMedia"]),
    /**
     * 更改按钮选中与非选中状态的颜色
     */
    changeButtonColorSelect() {
      this.showAllIcon = true;
      this.buttonSelect = true;
    },
    /**
     * 更改按钮选中与非选中状态的颜色
     */
    changeButtonColorNoSelect() {
      this.showAllIcon = false;
      this.buttonSelect = false;
    },
    /**
     * 获取cookie
     */
    getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
      else return null;
    },
    /**
     * 关闭聊天页面
     */
    closeChat() {
      this.isShowChat = false;
    },
    /**
     * 控制打开或关闭聊天页面
     */
    chatSwitch() {
      this.isShowChat = this.isShowChat == true ? false : true;
      if (this.isShowChat == true) {
        this.$Bus.$emit("chat-window-toBottom");
        sessionStorage.getItem("isGetList");
        if (sessionStorage.getItem("isGetList") == null) {
          this.getChatListHuanxin();
          sessionStorage.setItem("isGetList", "true");
        }
      }
      if (this.isShowChat == true) {
        if (localStorage.getItem("openWater") == "true") {
          waterMark(
            document.getElementsByClassName("chat-body")[0],
            700 + "px",
            550 + "px",
            "19999",
            "chart"
          );
        }
      }
    },
    /**
     * 收到消息处理逻辑
     */
    async getImUserImg(val, id, index, chatId) {
      let imgValue = "";
      if (val.contentsType == "VOICE") {
        var options = { url: val.url };
        options.headers = {
          Accept: "audio/mp3",
        };
        options.onFileDownloadComplete = (response) => {
          let objectURL = this.$WebIM.utils.parseDownloadResponse.call(
            this.$imconn,
            response
          );
          this.objMsg = {
            id: chatId,
            type: "voice",
            data: {
              url: objectURL,
              size: Math.floor(response.size / 1000),
              initialUrl: val.url,
            },
            timestamp: new Date().getTime(),
            img: imgValue,
            isListen: false,
            isplay: false,
            name: this.getGroupUserName(index, val.from),
            userid: id,
          };
          this.$store.commit("updateMsg", { index: index, obj: this.objMsg });
          this.$store.commit("changeListPosition", index);
        };
        this.$WebIM.utils.download.call(this.$imconn, options);

        return;
      }
      switch (val.contentsType) {
        case "TEXT":
          this.objMsg = {
            id: chatId,
            type: "text",
            data: val.data,
            timestamp: new Date().getTime(),
            img: imgValue,
            name: this.getGroupUserName(index, val.from),
            userid: id,
          };
          break;
        case "IMAGE":
          this.objMsg = {
            id: chatId,
            type: "image",
            data: val.url,
            timestamp: new Date().getTime(),
            img: imgValue,
            name: this.getGroupUserName(index, val.from),
            userid: id,
          };
          break;
        case "FILE":
          this.objMsg = {
            id: chatId,
            type: "file",
            data: {
              url: val.url,
              filename: val.filename,
              filelength: val.file_length,
            },
            timestamp: new Date().getTime(),
            img: imgValue,
            name: this.getGroupUserName(index, val.from),
            userid: id,
          };
          break;
        case "LOCATION":
          this.objMsg = {
            id: chatId,
            type: "location",
            data: val.addr,
            timestamp: new Date().getTime(),
            img: imgValue,
            name: this.getGroupUserName(index, val.from),
            userid: id,
          };
          break;
        case "CARD":
          this.objMsg = {
            id: chatId,
            type: "card",
            data: val.customExts,
            timestamp: new Date().getTime(),
            img: imgValue,
            name: this.getGroupUserName(index, val.from),
            userid: id,
          };
          break;
      }
      this.$store.commit("updateMsg", { index: index, obj: this.objMsg });
      this.$store.commit("changeListPosition", index);
    },
    /**
     * 获取用户相关信息
     */
    async getImidUser(val, index, chatId) {
      let user = await job.getUserInformation(val.from);
      if (user.result == true) {
        let id = user.data.length > 0 ? user.data[0].id : "";
        this.getImUserImg(val, id, index, chatId);
      }
    },
    /**
     * 收到消息更新聊天记录
     */
    async updateMsgList(val, index, imgUrl) {
      if (val.type == "chat") {
        let imgValue = "";

        imgValue = imgUrl;
        if (val.contentsType == "VOICE") {
          var options = { url: val.url };
          options.headers = {
            Accept: "audio/mp3",
          };
          options.onFileDownloadComplete = (response) => {
            let objectURL = this.$WebIM.utils.parseDownloadResponse.call(
              this.$WebIM.conn,
              response
            );
            this.objMsg = {
              id: 1,
              type: "voice",
              data: {
                url: objectURL,
                size: Math.floor(response.size / 1000),
                initialUrl: val.url,
              },
              timestamp: new Date().getTime(),
              img: imgValue,
              isListen: false,
              isplay: false,
            };
            let findIndex = this.$store.state.list.chatList.findIndex(
              (res) => res.imId == val.from
            );
            this.$store.commit("updateMsg", {
              index: findIndex,
              obj: this.objMsg,
            });
            this.$store.commit("changeListPosition", findIndex);
          };
          this.$WebIM.utils.download.call(this.$imconn, options);
          return;
        }

        switch (val.contentsType) {
          case "TEXT":
            this.objMsg = {
              id: 1,
              type: "text",
              data: val.data,
              timestamp: new Date().getTime(),
              img: imgValue,
            };
            break;
          case "IMAGE":
            this.objMsg = {
              id: 1,
              type: "image",
              data: val.url,
              timestamp: new Date().getTime(),
              img: imgValue,
            };
            break;
          case "FILE":
            this.objMsg = {
              id: 1,
              type: "file",
              data: {
                url: val.url,
                filename: val.filename,
                filelength: val.file_length,
              },
              timestamp: new Date().getTime(),
              img: imgValue,
            };
            break;
          case "LOCATION":
            this.objMsg = {
              id: 1,
              type: "location",
              data: val.addr,
              timestamp: new Date().getTime(),
              img: imgValue,
            };
            break;
          case "CARD":
            this.objMsg = {
              id: 1,
              type: "card",
              data: val.customExts,
              timestamp: new Date().getTime(),
              img: imgValue,
              name: this.getGroupUserName(index, val.from),
            };
            break;
        }
        let findIndex = this.$store.state.list.chatList.findIndex(
          (res) => res.imId == val.from
        );
        this.$store.commit("updateMsg", { index: findIndex, obj: this.objMsg });
        this.$store.commit("changeListPosition", findIndex);
      } else {
        let findIndex2 = this.$store.state.list.chatList.findIndex(
          (res) => res.imId == val.to
        );
        await this.getImidUser(val, findIndex2);
      }
    },
    /**
     * 收到群邀请的回调
     */
    async groupListenFun(val) {
      let self = this;
      let groupIndex = self.$store.state.list.chatList.findIndex(
        (res) => res.imId == val.gid
      );
      if (groupIndex < 0 && val.type == "removedFromGroup") {
        self.$store.commit("updateChatID", self.$store.state.list.isChatID);
        self.$store.commit("updateChatListNoRead", 0);
        self.$store.commit("deleteChatListLeft", groupIndex);
        return false;
      }
      if (groupIndex >= 0) {
        if (
          val.gid == self.$store.state.list.isChatID.imId &&
          val.type != "removedFromGroup" &&
          val.type != "direct_joined"
        ) {
          self.$Bus.$emit("group-add-delete-member", val.gid);
        }
        if (val.type == "direct_joined") {
          self.$store.commit("removedFromGroup", {
            gid: val.gid,
            message: "",
          });
          self.$store.commit("updateChatID", self.$store.state.list.isChatID);
          self.$store.commit("updateChatListNoRead", 0);
        }
        return false;
      }
      var optionss = {
        groupId: val.gid,
        success: function (resp) {
          let ownerId = "";
          resp.data[0].affiliations.filter((res) => {
            if (res.owner) {
              ownerId = res.owner;
            }
          });
          if (ownerId != JSON.parse(localStorage.getItem("userInfo")).userId) {
            if (val.type == "direct_joined" && groupIndex < 0) {
              instance
                .post(`easemobim/getUserInfoByImIds`, { ids: ownerId })
                .then((result) => {
                  if (result.result == true) {
                    let objs = {};
                    objs.invite = result.data[0].name;
                    objs.isInvite = "你";
                    objs.isNowGroup = true;
                    self.$store.commit("changeInvite", objs);
                  }
                });
              instance
                .post(`easemobim/group/getEasemobImGroupInfo`, { id: val.gid })
                .then((res) => {
                  if (res.result == true) {
                    let obj = {};
                    obj.imgUrl = "";
                    obj.imId = val.gid;
                    obj.name = res.data.name;
                    obj.id = val.gid;
                    obj.type = "group";
                    obj.msg = [];
                    obj.noRead = 1;
                    obj.lastWord = self.$i18n.t(
                      "vue_label_chat_invitedtojoin",
                      { invite: self.$store.state.list.groupInvite.invite }
                    );
                    obj.invite = self.$store.state.list.groupInvite;
                    obj.members = res.data.members;
                    if (res.data.objid == "account") {
                      obj.isStrategyGroup = true;
                    } else if (res.data.objid == "opportunity") {
                      obj.isOpportunityGroup = true;
                    }
                    let date = new Date();
                    let hour = date.getHours();
                    let minute = date.getMinutes();
                    if (minute <= 9) {
                      minute = "0" + minute;
                    }
                    let time = `${hour}:${minute}`;
                    obj.time = time;
                    obj.timestamp = date.getTime();
                    self.$store.commit("updateChatList", obj);
                    let index = self.$store.state.list.chatList.findIndex(
                      (res) => res.imId == val.gid
                    );
                    let obj4 = {};
                    obj4.id = 3;
                    obj4.type = "invite";
                    obj4.data = self.$i18n.t("vue_label_chat_invitedtojoin", {
                      invite: self.$store.state.list.groupInvite.invite,
                    });
                    obj4.timestamp = new Date().getTime();
                    self.$store.commit("updateMsg", {
                      index: index,
                      obj: obj4,
                    });
                    self.$Bus.$emit("changePositionAdd", 1);
                    var audio = document.getElementById("audio");
                    audio.play();
                  }
                });
            }
          }
        },
        error: function (error) {
          self.$message({
            message: error,
            type: "warning",
          });
        },
      };
      self.$imconn.getGroupInfo(optionss);
    },
    /**
     * 收到消息的回调
     */
    async listenFunc(val, describe) {
      let self = this;
      let chatId;
      let from;
      if (val.from == self.loginCount) {
        chatId = 2;
        from = val.to;
      } else {
        chatId = 1;
        from = val.type == "chat" ? val.from : val.to;
      }
      if (from == self.$store.state.list.isChatID.imId) {
        let index = self.$store.state.list.chatList.findIndex(
          (res) => res.imId == from
        );
        let date = new Date();
        let hour = date.getHours();
        let minute = date.getMinutes();
        if (minute <= 9) {
          minute = "0" + minute;
        }
        let time = `${hour}:${minute}`;
        self.$store.commit("changeTimestampWord", {
          index: index,
          time: time,
          date: date,
          message: describe,
        });
        if (self.isShowChat == false) {
          self.$store.commit("getMessageNoRead", index);
        }
        if (val.type == "chat") {
          let imgValue = "";

          imgValue = self.$store.state.list.isChatID.imgUrl;
          if (val.contentsType == "VOICE") {
            let options = { url: val.url };
            options.headers = {
              Accept: "audio/mp3",
            };
            options.onFileDownloadComplete = (response) => {
              let objectURL = self.$WebIM.utils.parseDownloadResponse.call(
                self.$WebIM.conn,
                response
              );
              self.objMsg = {
                id: chatId,
                type: "voice",
                data: {
                  url: objectURL,
                  size: Math.floor(response.size / 1000),
                  initialUrl: val.url,
                },
                isListen: false,
                isplay: false,
                timestamp: new Date().getTime(),
                img: imgValue,
                userid: self.$store.state.list.isChatID.id,
              };
              let findIndex = self.$store.state.list.chatList.findIndex(
                (res) => res.imId == from
              );
              self.$store.commit("updateMsg", {
                index: findIndex,
                obj: self.objMsg,
              });
              self.$store.commit("changeListPosition", findIndex);
            };
            self.$WebIM.utils.download.call(self.$imconn, options);
            var audio = document.getElementById("audio");
            audio.play();
            return;
          }

          switch (val.contentsType) {
            case "TEXT":
              self.objMsg = {
                id: chatId,
                type: "text",
                data: val.data,
                timestamp: new Date().getTime(),
                img: imgValue,
                userid: self.$store.state.list.isChatID.id,
              };
              break;
            case "IMAGE":
              self.objMsg = {
                id: chatId,
                type: "image",
                data: val.url,
                timestamp: new Date().getTime(),
                img: imgValue,
                userid: self.$store.state.list.isChatID.id,
              };
              break;
            case "FILE":
              self.objMsg = {
                id: chatId,
                type: "file",
                data: {
                  url: val.url,
                  filename: val.filename,
                  filelength: val.file_length,
                },
                timestamp: new Date().getTime(),
                img: imgValue,
                userid: self.$store.state.list.isChatID.id,
              };
              break;
            case "LOCATION":
              self.objMsg = {
                id: chatId,
                type: "location",
                data: val.addr,
                timestamp: new Date().getTime(),
                img: imgValue,
                userid: self.$store.state.list.isChatID.id,
              };
              break;
            case "CARD":
              self.objMsg = {
                id: chatId,
                type: "card",
                data: val.customExts,
                timestamp: new Date().getTime(),
                img: imgValue,
                name: this.getGroupUserName(index, from),
              };
              break;
          }
          let findIndex = self.$store.state.list.chatList.findIndex(
            (res) => res.imId == from
          );
          self.$store.commit("updateMsg", {
            index: findIndex,
            obj: self.objMsg,
          });
          self.$store.commit("changeListPosition", findIndex);
        } else {
          let findIndex2 = self.$store.state.list.chatList.findIndex(
            (res) => res.imId == from
          );
          await self.getImidUser(val, findIndex2, chatId);
        }
        self.$Bus.$emit("changePosition", 0);
      } else {
        let index = self.$store.state.list.chatList.findIndex(
          (res) => res.imId == from
        );
        if (index < 0 || self.$store.state.list.chatList == []) {
          if (val.type == "chat") {
            let result = await job.getUserInformation(from);
            if (result.result == true) {
              let obj = {};
              obj.imgUrl = "";
              obj.imId = from;

              obj.name = result.data[0].name;
              obj.id = result.data[0].id;
              obj.type = "user";
              obj.noRead = 1;
              obj.lastWord = describe;
              let date = new Date();
              let hour = date.getHours();
              let minute = date.getMinutes();
              if (minute <= 9) {
                minute = "0" + minute;
              }
              let time = `${hour}:${minute}`;
              obj.time = time;
              obj.timestamp = date.getTime();
              obj.msg = [];
              self.$store.commit("updateChatList", obj);
              let imgValue = "";

              imgValue = obj.imgUrl;
              if (val.contentsType == "VOICE") {
                let options = { url: val.url };
                options.headers = {
                  Accept: "audio/mp3",
                };
                options.onFileDownloadComplete = (response) => {
                  let objectURL = self.$WebIM.utils.parseDownloadResponse.call(
                    self.$WebIM.conn,
                    response
                  );
                  self.objMsg = {
                    id: chatId,
                    type: "voice",
                    data: {
                      url: objectURL,
                      size: Math.floor(response.size / 1000),
                      initialUrl: val.url,
                    },
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    isListen: false,
                    isplay: false,
                    userid: result.data[0].id,
                  };
                  let findIndex = self.$store.state.list.chatList.findIndex(
                    (res) => res.imId == from
                  );
                  self.$store.commit("updateMsg", {
                    index: findIndex,
                    obj: self.objMsg,
                  });
                  self.$store.commit("changeListPosition", findIndex);
                };
                self.$WebIM.utils.download.call(self.$imconn, options);
                let audio = document.getElementById("audio");
                audio.play();
                return;
              }

              switch (val.contentsType) {
                case "TEXT":
                  self.objMsg = {
                    id: chatId,
                    type: "text",
                    data: val.data,
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    userid: result.data[0].id,
                  };
                  break;
                case "IMAGE":
                  self.objMsg = {
                    id: chatId,
                    type: "image",
                    data: val.url,
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    userid: result.data[0].id,
                  };
                  break;
                case "FILE":
                  self.objMsg = {
                    id: chatId,
                    type: "file",
                    data: {
                      url: val.url,
                      filename: val.filename,
                      filelength: val.file_length,
                    },
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    userid: result.data[0].id,
                  };
                  break;
                case "LOCATION":
                  self.objMsg = {
                    id: chatId,
                    type: "location",
                    data: val.addr,
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    userid: result.data[0].id,
                  };
                  break;
                case "CARD":
                  self.objMsg = {
                    id: chatId,
                    type: "card",
                    data: val.customExts,
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    name: this.getGroupUserName(index, from),
                    userid: result.data[0].id,
                  };
                  break;
              }
              let findIndex = self.$store.state.list.chatList.findIndex(
                (res) => res.imId == from
              );
              self.$store.commit("updateMsg", {
                index: findIndex,
                obj: self.objMsg,
              });
              self.$store.commit("changeListPosition", findIndex);
              self.$Bus.$emit("changePositionAdd", 1);
            }
          }
          if (val.type == "groupchat") {
            let res = await job.getgroupmessage(from);
            if (res.result == true) {
              let obj = {};
              obj.imgUrl = "";
              obj.imId = from;
              obj.name = res.data.name;
              obj.id = from;
              obj.type = "group";
              obj.noRead = 1;
              obj.lastWord = describe;
              obj.msg = [];
              obj.members = res.data.members;
              if (res.data.objid == "account") {
                obj.isStrategyGroup = true;
              } else if (res.data.objid == "opportunity") {
                obj.isOpportunityGroup = true;
              }
              let date = new Date();
              let hour = date.getHours();
              let minute = date.getMinutes();
              if (minute <= 9) {
                minute = "0" + minute;
              }
              let time = `${hour}:${minute}`;
              obj.time = time;
              obj.timestamp = date.getTime();
              self.$store.commit("updateChatList", obj);
              let findIndex2 = self.$store.state.list.chatList.findIndex(
                (res) => res.imId == from
              );
              await self.getImidUser(val, findIndex2, chatId);
              self.$Bus.$emit("changePositionAdd", 1);
            }
          }
        } else if (index >= 0 || self.$store.state.list.chatList == []) {
          let date = new Date();
          let hour = date.getHours();
          let minute = date.getMinutes();
          if (minute <= 9) {
            minute = "0" + minute;
          }
          let time = `${hour}:${minute}`;
          self.$store.commit("changeTimestampWord", {
            index: index,
            time: time,
            date: date,
            message: describe,
          });
          self.$store.commit("getMessageNoRead", index);
          if (val.type == "chat") {
            let imgValue = "";
            imgValue = self.$store.state.list.chatList[index].imgUrl;
            if (val.contentsType == "VOICE") {
              var options = { url: val.url };
              options.headers = {
                Accept: "audio/mp3",
              };
              options.onFileDownloadComplete = (response) => {
                let objectURL = self.$WebIM.utils.parseDownloadResponse.call(
                  self.$WebIM.conn,
                  response
                );
                self.objMsg = {
                  id: chatId,
                  type: "voice",
                  data: {
                    url: objectURL,
                    size: Math.floor(response.size / 1000),
                    initialUrl: val.url,
                  },
                  timestamp: new Date().getTime(),
                  img: imgValue,
                  isListen: false,
                  isplay: false,
                  userid: self.$store.state.list.chatList[index].id,
                };
                let findIndex = self.$store.state.list.chatList.findIndex(
                  (res) => res.imId == from
                );
                self.$store.commit("updateMsg", {
                  index: findIndex,
                  obj: self.objMsg,
                });
                self.$store.commit("changeListPosition", findIndex);
              };
              self.$WebIM.utils.download.call(self.$imconn, options);
              let audio = document.getElementById("audio");
              audio.play();
              return;
            }

            switch (val.contentsType) {
              case "TEXT":
                self.objMsg = {
                  id: chatId,
                  type: "text",
                  data: val.data,
                  timestamp: new Date().getTime(),
                  img: imgValue,
                  userid: self.$store.state.list.chatList[index].id,
                };
                break;
              case "IMAGE":
                self.objMsg = {
                  id: chatId,
                  type: "image",
                  data: val.url,
                  timestamp: new Date().getTime(),
                  img: imgValue,
                  userid: self.$store.state.list.chatList[index].id,
                };
                break;
              case "FILE":
                self.objMsg = {
                  id: chatId,
                  type: "file",
                  data: {
                    url: val.url,
                    filename: val.filename,
                    filelength: val.file_length,
                  },
                  timestamp: new Date().getTime(),
                  img: imgValue,
                  userid: self.$store.state.list.chatList[index].id,
                };
                break;
              case "LOCATION":
                self.objMsg = {
                  id: chatId,
                  type: "location",
                  data: val.addr,
                  timestamp: new Date().getTime(),
                  img: imgValue,
                  userid: self.$store.state.list.chatList[index].id,
                };
                break;
              case "CARD":
                self.objMsg = {
                  id: chatId,
                  type: "card",
                  data: val.customExts,
                  timestamp: new Date().getTime(),
                  img: imgValue,
                  name: this.getGroupUserName(index, from),
                  userid: self.$store.state.list.chatList[index].id,
                };
                break;
            }
            let findIndex = self.$store.state.list.chatList.findIndex(
              (res) => res.imId == from
            );
            self.$store.commit("updateMsg", {
              index: findIndex,
              obj: self.objMsg,
            });
            self.$store.commit("changeListPosition", findIndex);
          } else {
            let findIndex2 = self.$store.state.list.chatList.findIndex(
              (res) => res.imId == from
            );
            let user = await job.getUserInformation(val.from);
            if (user.result == true) {
              let userId = user.data.length > 0 ? user.data[0].id : "";
              let imgValue = "";
              if (val.contentsType == "VOICE") {
                let options = { url: val.url };
                options.headers = {
                  Accept: "audio/mp3",
                };
                options.onFileDownloadComplete = (response) => {
                  let objectURL = this.$WebIM.utils.parseDownloadResponse.call(
                    this.$imconn,
                    response
                  );
                  this.objMsg = {
                    id: chatId,
                    type: "voice",
                    data: {
                      url: objectURL,
                      size: Math.floor(response.size / 1000),
                      initialUrl: val.url,
                    },
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    isListen: false,
                    isplay: false,
                    name: this.getGroupUserName(index, val.from),
                    userid: userId,
                  };
                  this.$store.commit("updateMsg", {
                    index: findIndex2,
                    obj: this.objMsg,
                  });
                  this.$store.commit("changeListPosition", findIndex2);
                };
                this.$WebIM.utils.download.call(this.$imconn, options);
                let audio = document.getElementById("audio");
                audio.play();
                return;
              }
              switch (val.contentsType) {
                case "TEXT":
                  this.objMsg = {
                    id: chatId,
                    type: "text",
                    data: val.data,
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    name: this.getGroupUserName(index, val.from),
                    userid: userId,
                  };
                  break;
                case "IMAGE":
                  this.objMsg = {
                    id: chatId,
                    type: "image",
                    data: val.url,
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    name: this.getGroupUserName(index, val.from),
                    userid: userId,
                  };
                  break;
                case "FILE":
                  this.objMsg = {
                    id: chatId,
                    type: "file",
                    data: {
                      url: val.url,
                      filename: val.filename,
                      filelength: val.file_length,
                    },
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    name: this.getGroupUserName(index, val.from),
                    userid: userId,
                  };
                  break;
                case "LOCATION":
                  this.objMsg = {
                    id: chatId,
                    type: "location",
                    data: val.addr,
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    name: this.getGroupUserName(index, val.from),
                    userid: userId,
                  };
                  break;
                case "CARD":
                  self.objMsg = {
                    id: chatId,
                    type: "card",
                    data: val.customExts,
                    timestamp: new Date().getTime(),
                    img: imgValue,
                    name: this.getGroupUserName(index, val.from),
                    userid: userId,
                  };
                  break;
              }
              this.$store.commit("updateMsg", {
                index: findIndex2,
                obj: this.objMsg,
              });
              this.$store.commit("changeListPosition", findIndex2);
            }
          }
          if (index != 0) {
            self.$Bus.$emit("changePosition", 1);
          }
        }
      }
      audio = document.getElementById("audio");
      audio.play();
    },
    /**
     * 获取群组信息并更新名称
     */
    async getGroupInformation(id) {
      let res = await job.getgroupmessage(id);
      if (res.result) {
        this.$store.commit("changeGroupNameCmd", {
          id: id,
          name: res.data.name,
        });
      }
    },
    /**
     * 获取用户信息并拼接到列表数组中
     */
    async setUserInfo() {
      let userResult = await job.getUserInfoList({
        groupId: "",
        pageNum: "",
        pageSize: "10000",
        searchKey: "",
      });
      if (userResult.result) {
        this.messageArrHuanxin = this.messageArrHuanxin.map((item) => {
          if (item.type == "user") {
            let findIndex = userResult.data.findIndex(
              (res) => res.imUserId == item.imId
            );
            let obj = {
              id: userResult.data[findIndex].userId,
              name: userResult.data[findIndex].userName,
            };
            return { ...item, ...obj };
          } else {
            return { ...item };
          }
        });
        this.$store.commit("getChatList", this.messageArrHuanxin);
      }
    },
    /**
     * 获取群组信息并拼接到列表数组中
     */
    async setGroupInfo() {
      let groupArr = [];
      this.messageArrHuanxin.forEach((res) => {
        if (res.type == "group") {
          groupArr.push(res.imId);
        }
      });
      let groupResult = [];
      let groupResults = await job.getgroupmessages(groupArr.join());
      if (groupResults.result) {
        for (let groupResultItem in groupResults.data) {
          groupResult.push(groupResults.data[groupResultItem]);
        }
      }
      this.messageArrHuanxin = this.messageArrHuanxin.map((item) => {
        if (item.type == "group") {
          let obj = {};
          let findIndex = groupResult.findIndex((res) => res.id == item.imId);
          if (findIndex >= 0) {
            obj = {
              id: item.imId,
              name: groupResult[findIndex].name,
              members: groupResult[findIndex].members,
            };
            if (
              groupResult[findIndex].groupType == "strategy" &&
              groupResult[findIndex].objid == "account"
            ) {
              obj.isStrategyGroup = true;
            } else if (
              groupResult[findIndex].groupType == "strategy" &&
              groupResult[findIndex].objid == "opportunity"
            ) {
              obj.isOpportunityGroup = true;
            }
          }
          return { ...item, ...obj };
        } else {
          return { ...item };
        }
      });
      this.$store.commit("getChatList", this.messageArrHuanxin);
      // }
    },
    /**
     * 首页获取环信聊天列表
     */
    getChatListHuanxin() {
      this.$imconn
        .getSessionList()
        .then((data) => {
          //消息列表
          if (data.data) {
            data.data.channel_infos.forEach((listItem) => {
              //每一条记录处理赋值
              let obj = {};
              obj.imId = listItem.channel_id.substring(
                listItem.channel_id.indexOf("_") + 1,
                listItem.channel_id.indexOf("@")
              );
              obj.type = obj.imId.length > 15 ? "user" : "group";
              //设置最后一条聊天记录信息
              switch (JSON.parse(listItem.meta.payload).bodies[0].type) {
                case "txt":
                  obj.lastWord = JSON.parse(
                    listItem.meta.payload
                  ).bodies[0].msg;
                  break;
                case "img":
                  obj.lastWord = `[${this.$i18n.t("label.icon.155")}]`;
                  break;
                case "file":
                  obj.lastWord = `[${this.$i18n.t("FL")}]`;
                  break;
                case "loc":
                  obj.lastWord = `[${this.$i18n.t("label.position")}]`;
                  break;
                case "audio":
                  obj.lastWord = `[${this.$i18n.t("vue_label_chat_voice")}]`;
                  break;
                case "custom":
                  obj.lastWord = `[${this.$i18n.t(
                    "vue_label_chat_group_assistant_notification"
                  )}]`;
                  break;
              }
              //设置时间戳
              obj.timestamp = listItem.meta.timestamp;
              //设置未读数
              obj.noRead = 0;
              obj.msg = [];
              obj.getChatList = false;
              obj.time = getTimeStringAutoShort(obj.timestamp, false);
              this.messageArrHuanxin.push(obj);
            });
            if (this.messageArrHuanxin.length == 0) {
              this.$store.commit("getChatList", []);
            }
            //判断列表中是否有单聊用户
            let userIndex = this.messageArrHuanxin.findIndex(
              (res) => res.type == "user"
            );
            if (userIndex >= 0) {
              this.setUserInfo();
            }
            //判断列表中是否有群聊用户
            let groupIndex = this.messageArrHuanxin.findIndex(
              (res) => res.type == "group"
            );
            if (groupIndex >= 0) {
              this.setGroupInfo();
            }
          }

          /**
    返回参数说明
    channel_infos - 所有会话
    channel_id - 会话id, username@easemob.com表示单聊，groupid@conference.easemob.com表示群聊
    meta - 最后一条消息
    unread_num - 当前会话的未读消息数
    
    data{
        channel_infos:[
            {
                channel_id: 'easemob-demo#chatdemoui_username@easemob.com',
                meta: {},
                unread_num: 0
            },
            {
                channel_id: 'easemob-demo#chatdemoui_93734273351681@conference.easemob.com',
                meta: {
                    from: "easemob-demo#chatdemoui_zdtest@easemob.com/webim_1610159114836",
                    id: "827197124377577640",
                    payload: "{"bodies":[{"msg":"1","type":"txt"}],"ext":{},"from":"zdtest","to":"93734273351681"}",
                    timestamp: 1610161638919,
                    to: "easemob-demo#chatdemoui_93734273351681@conference.easemob.com"
                },
                unread_num: 0
            }
        ]
    }
    */
        })
        .catch((error) => {
          if (error.data) {
            let errObj = JSON.parse(error.data);
            this.$message.error(errObj.error_description);
          }
        });
    },
  },
  components: {
    listHeader,
    listContent,
    windowContent,
  },
};
</script>

<style lang="scss" scoped>
.chat {
  .group-button {
    outline: none;
    z-index: 9999;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    position: fixed;
    right: 0;
    height: 40px;
    background: #ffffff;
    border-radius: 100px 0 0 100px;
    border: 6px solid #f3f2f2;
    border-right: none;
    display: flex;
    .image {
      position: relative;
      margin-left: 10px;
      margin-right: 5px;
      width: 25px;
      height: 24px;
      display: inline-block;
      transform: translate(0, 5px);
      .no-read {
        position: absolute;
        right: -3px;
        top: -3px;
        border-radius: 8px;
        background: #e2403c;
        min-width: 18px;
        color: white;
        font-size: 10px;
        height: 14px;
        line-height: 14px;
        text-align: center;
        z-index: 2;
      }
      .icon {
        transform: scale(1.7);
        margin-top: 2px;
      }
    }
    span {
      margin-right: 5px;
      display: inline-block;
      transform: translate(0, 5px);
      color: #888;
      font-size: 12px;
      font-family: "MicrosoftYaHei";
      user-select: none;
    }
  }
  .chat-body {
    z-index: 1999;
    position: fixed;
    bottom: 50px;
    left: 70px;
    width: 700px;
    height: 550px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);
    .close-button {
      position: absolute;
      right: 10px;
      top: 5px;
      width: 10px;
      cursor: pointer;
      z-index: 999;
      .icon {
        font-size: 12px;
      }
    }
    .chat-list {
      width: 250px;
      height: 100%;
      float: left;
    }
    .line {
      width: 1px;
      height: 100%;
      background: #dedcda;
      float: left;
    }
    .chat-window {
      width: calc(100% - 251px);
      height: 100%;
      float: left;
    }
  }
}
.el-button--primary {
  background-color: #e58516 !important;
  border-color: #e58516 !important;
  color: white !important;
  span {
    color: white !important;
  }
}
</style>
