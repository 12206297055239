<template>
  <div class="select-members" v-show="isShow">
    <div class="select-member">
      <el-input clearable v-model="input"></el-input>
      <svg class="icon search-img" aria-hidden="true">
        <use href="#icon-search"></use>
      </svg>
      <div class="tooltip" v-if="filterList.length > 0">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-questiona"></use>
        </svg>
        <span>
          <!-- 只能选择使用群策的用户聊天 -->
          {{ $t("vue_label_chat_onlychosechat") }}
        </span>
      </div>
      <div class="list-box">
        <div class="list" v-if="filterList.length > 0">
          <el-checkbox-group v-model="checkList">
            <div class="item" v-for="(item, index) in filterList" :key="index">
              <el-checkbox
                :disabled="
                  $store.state.list.isChatID.imId == item.easemobImId ||
                  sameIdArr.indexOf(item.id) >= 0
                    ? true
                    : false
                "
                :label="item.id"
                @change="getCheckList(item)"
                >{{ item.name }}</el-checkbox
              >
              <div class="item-image">
                <img :src="imgsUrl + item.id + imgssurl" alt />
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <div class="noChatPeo" v-if="filterList.length == 0">
          <img src="@/assets/listImage/gandup.png" alt="" />
          <div class="word">{{ $t("vue_label_SMS_nodata") }}</div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="already-select">
      <div class="title">
        <div class="name">
          {{ $t("label.file.library.managemembers.addmember") }}
        </div>
        <div class="is-select">
          {{ $t("label.event.select") }}/{{ checkNumber }}
        </div>
      </div>
      <div class="list-box">
        <div class="list">
          <!-- 当前群中已经有的群成员 -->
          <div class="item" v-for="(item, index) in allpeople" :key="index">
            <div class="image">
              <svg class="icon" aria-hidden="true" v-if="allpeople.length > 1">
                <use
                  :href="
                    item.type == 'owner'
                      ? '#icon-' + ownerMemberImg
                      : '#icon-' + commonMemberImg
                  "
                ></use>
              </svg>
            </div>
            <div class="name">{{ item.name }}</div>
            <div
              class="delete"
              @click="deleteJoinMember(item, index)"
              v-if="isOwner == true && item.id != myId"
            >
              <svg class="icon" aria-hidden="true">
                <use href="#icon-quxiao"></use>
              </svg>
            </div>
          </div>
          <div
            class="item"
            v-for="(item, index) in isSelectList"
            :key="item.id"
          >
            <div class="image">
              <svg class="icon" aria-hidden="true">
                <use href="#icon-memberCom"></use>
              </svg>
            </div>
            <div class="name">{{ item.name }}</div>
            <div class="delete" @click="deleteSelect(index)">
              <svg class="icon" aria-hidden="true">
                <use href="#icon-quxiao"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="button">
        <div class="create" @click="createChat">
          {{ $t("label.report.action.addtodashboard6") }}
        </div>
        <div class="cancel" @click="cancelAdd">
          {{ $t("label.ems.cancel") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
 * 基于当前聊天创建新的群组或邀请用户加入群聊
 */
import job from "@/views/chat/js/request.js";
import VueCookies from "vue-cookies";

export default {
  //判断是否展示弹框
  props: {
    isShowB: {
      type: Boolean,
      default: false,
    },
  },
  name: "selectContants",
  data() {
    return {
      //用户头像
      imgsUrl: `${localStorage.getItem(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=`,
       //用户头像
      imgssurl: `&binding=${this.$CCDK.CCToken.getToken()}`,
      //普通群成员头像
      commonMemberImg: "memberCom",
      //群主头像
      ownerMemberImg: "groupLeader",
       //判断是否展示弹窗
      isShow: false,
      //输入框关键字
      input: "",
      //选中人员列表
      checkList: [],
      //选中成员以及群组
      isSelectList: [],
      //获取的所有成员列表
      peoList: [],
      //当前单聊自己+聊天对象+被邀请人
      Membership: [],
      //群聊所有成员
      allpeople: [],
      //群聊和列表相同的人
      sameIdArr: [],
      //判断当前群当前登录用户是否为群主
      isOwner: false,
      //当前用户的ID
      myId: localStorage.getItem("myid"),
    };
  },
  mounted() {
    this.imgsUrl = `${localStorage.getItem(
      "domainName"
    )}/chatterzone.action?m=showPhoto&userid=`;
    this.imgssurl = `&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`;
    this.$Bus.$off("change-image-top");
    this.$Bus.$on("change-image-top",this.ebFn1);
    this.$Bus.$off("group-add-delete-member");
    this.$Bus.$on("group-add-delete-member", this.ebFn2);

    this.$Bus.$on("deliver-add-button", this.ebFn3),
      this.getUserList();
  },
  beforeDestroy() {
    this.$Bus.$off("change-image-top",this.ebFn1);
    this.$Bus.$off("group-add-delete-member", this.ebFn2);
    this.$Bus.$off("deliver-add-button", this.ebFn3)
  },
  methods: {
    ebFn1 ()  {
      this.imgsUrl = `${VueCookies.get(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=`;
      this.imgssurl = `&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`;
    },
    async ebFn2async (res)  {
      this.allpeople = [];
      this.sameIdArr = [];
      this.isOwner = false;
      await this.getgroupmsg(res);
    },
    ebFn3()  {
      this.isShow = false;
      this.$emit("changeShow", this.isShow);
      this.checkList = [];
      this.isSelectList = [];
      this.input = "";
    },
    /*
     * 创建新的群聊
     */
    async createGroup(id, name, timestamp, ID, groupNames, cloudccId) {
      let result = await job.setNewGroup(id, name, timestamp, ID);
      if (result.result == true && result.data) {
        let obj = {};
        let date = new Date();
        let hour = date.getHours();
        let minute = date.getMinutes();
        if (minute <= 9) {
          minute = "0" + minute;
        }
        let time = `${hour}:${minute}`;
        let obj3 = {};
        obj3.invite = "你";
        obj3.isInvite = groupNames;
        obj3.isNowGroup = true;
        this.$store.commit("changeInvite", obj3);
        obj.invite = obj3;
        obj.name = name;
        obj.id = id;
        obj.imId = result.data._id;
        obj.type = "group";
        obj.timestamp = date.getTime();
        obj.noRead = 0;
        obj.lastWord = this.$i18n.t("vue_label_chat_invitetojoin", {
          invite: this.$store.state.list.groupInvite.isInvite,
        });
        obj.time = time;
        obj.msg = [];
        obj.imgUrl = "";
        obj.memeberImgUrl = cloudccId;
        let obj2 = {};
        obj2.ownerName = obj2.isGroup = true;
        obj2.imId = obj.imId;
        obj2.imgUrl = "";
        obj2.name = obj.name;
        obj2.id = obj.id;
        this.$store.commit("updateChatID", obj2);
        this.$store.commit("updateChatList", obj);
        let index = this.$store.state.list.chatList.findIndex(
          (res) => res.imId == id
        );
        let obj4 = {};
        obj4.id = 3;
        obj4.type = "invite";
        obj4.data = this.$i18n.t("vue_label_chat_invitetojoin", {
          invite: groupNames,
        });
        obj4.timestamp = new Date().getTime();
        this.$store.commit("updateMsg", { index: index, obj: obj4 });
        this.$Bus.$emit("changePosition", 0);
        this.isShow = false;
        this.Membership = [];
        this.checkList = [];
        this.isSelectList = [];
        this.input = "";
        this.$emit("changeShow", this.isShow);
      }
    },
    /*
     * 邀请用户加入群聊
     */
    async invitation(groupId, userIds, memberType, timestamp, inviteNames) {
      let result = await job.addgroupmember(
        groupId,
        userIds,
        memberType,
        timestamp
      );
      if (result.result == true) {
        this.allpeople = [];
        this.sameIdArr = [];
        let index = this.$store.state.list.chatList.findIndex(
          (res) => res.imId == this.$store.state.list.isChatID.imId
        );
        let obj = {};
        obj.id = 3;
        obj.type = "invite";
        obj.data = this.$i18n.t("vue_label_chat_invitetojoin", {
          invite: inviteNames,
        });
        obj.timestamp = new Date().getTime();
        this.$store.commit("updateMsg", { index: index, obj: obj });
        this.$store.commit("updateChatListLastWord", obj.data);
        this.isOwner = false;
      }
    },
    /*
     * 获取当前选中的用户
     */
    getCheckList(item) {
      let index = this.checkList.findIndex((res) => res == item.id);
      if (index < 0) {
        let index2 = this.isSelectList.findIndex((res) => res == item);
        this.isSelectList.splice(index2, 1);
      } else {
        this.isSelectList.push(item);
      }
    },
    /*
     * 获取用户列表
     */
    async getUserList() {
      let userList = await job.searchUser(this.input, "user", 1, 10000);
      if (
        userList.returnInfo == "Search success!" &&
        userList.returnCode == 1
      ) {
        this.peoList = this.peoList = userList.data.userList.filter(
          (item) =>
            item.easemobImId !=
            JSON.parse(localStorage.getItem("userInfo")).userId
        );
        this.peoList.forEach((res) => {
          this.allpeople.forEach((val) => {
            if (val.id == res.id) {
              this.sameIdArr.push(val.id);
            }
          });
        });
      }
    },
    /*
     * 取消新建聊天
     */
    cancelAdd() {
      this.isShow = false;
      this.$emit("changeShow", this.isShow);
      this.checkList = [];
      this.isSelectList = [];
      this.input = "";
    },
    /*
     * 删除群成员
     */
    async deleteGroupMember(item, index) {
      let result = await job.deleteGroupMember(this.ischatid, item.id);
      if (result.result == true) {
        this.sameIdArr = [];
        this.allpeople.splice(index, 1);
        this.peoList.forEach((res) => {
          this.allpeople.forEach((val) => {
            if (val.id == res.id) {
              this.sameIdArr.push(val.id);
            }
          });
        });
        this.isShow = false;
        this.$emit("changeShow", this.isShow);
      }
    },
    /*
     * 群主删除当前群中的成员
     */
    deleteJoinMember(item, index) {
      var options = {
        groupId: this.ischatid, // 群组ID
        username: item.easemobImId, // 将要被加入黑名单的用户名
        success: () => {
          this.deleteGroupMember(item, index);
        },
        error: (e) => {
          this.$message({
            showClose: true,
            message: e,
            type: "error",
            duration: 3000,
          });
        },
      };
      this.$imconn.removeSingleGroupMember(options);
    },
    /*
     * 删除当前选中的用户
     */
    deleteSelect(index) {
      this.isSelectList.splice(index, 1);
      this.checkList.splice(index, 1);
    },
    /*
     * 创建新的聊天
     */
    createChat() {
      if (this.isSelectList.length == 0) {
        return false;
      }
      if (
        this.$store.state.list.isChatID.isStrategyGroup &&
        this.$store.state.list.isChatID.isStrategyGroup == true &&
        this.isOwner == false
      ) {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("vue_label_chat_cannot_invite_users"),
        });
        return false;
      }
      if (this.isgroup.isGroup) {
        let groupId = this.ischatid;
        let userIds = [];
        this.isSelectList.forEach((item) => {
          userIds.push(item.id);
        });
        let struserIds = userIds.join();
        let inviteName = [];
        this.isSelectList.forEach((item) => {
          inviteName.push(item.name);
        });
        let inviteNames = inviteName.join();
        let easemobImIds = [];
        this.isSelectList.forEach((item) => {
          easemobImIds.push(item.easemobImId);
        });
        let option = {
          users: easemobImIds,
          groupId: this.ischatid,
          success: (respData) => {
            this.invitation(
              groupId,
              struserIds,
              "member",
              respData.timestamp,
              inviteNames
            );
            this.checkList = [];
            this.isSelectList = [];
            this.input = "";
          },

          error: (err) => {
            if (err.type == 17) {
              this.$message({
                showClose: true,
                message: "非群主不能邀请用户加入群聊",
                type: "warning",
                duration: 3000,
              });
            }
          },
        };
        this.$imconn.inviteToGroup(option);
      } else {
        let me = {};
        let userinfo = JSON.parse(localStorage.getItem("userInfo"));
        me.easemobImId = userinfo.userId;
        me.imgUrl = localStorage.getItem("ownerimg");
        me.id = localStorage.getItem("myid");
        me.name = localStorage.getItem("username");
        let other1 = {};
        other1.easemobImId = this.isgroup.imId;
        other1.imgUrl = this.isgroup.imgUrl;
        other1.id = this.isgroup.id;
        other1.name = this.isgroup.name;
        this.Membership.unshift(me);
        this.isSelectList.forEach((item) => this.Membership.push(item));
        this.Membership.push(other1);
        let ID = this.Membership.map((res) => res.id).join();
        let groupId = this.Membership.map((res) => res.easemobImId);
        let cloudccId = this.Membership.map((res) => {
          let obj = {};
          obj.name = res.name;
          obj.imgUrl = res.imgUrl;
          return obj;
        });
        let groupName = this.Membership.map((res) => res.name).join();
        let groupNames = this.Membership.map((res) => res.name);
        groupNames.splice(0, 1);
        groupNames = groupNames.join();
        var options = {
          data: {
            groupname: groupName, // 群组名
            members: groupId, // 用户名组成的数组
            public: false, // pub等于true时，创建为公开群
            approval: true, // approval为true，加群需审批，为false时加群无需审批
            allowinvites: true, // true：允许群成员邀请人加入此群，false：只有群主才可以往群里加人
            inviteNeedConfirm: false, // 邀请加群，被邀请人是否需要确认。true 为需要被邀请者同意才会进群
          },
          success: (respData) => {
            this.createGroup(
              respData.data.groupid,
              groupName,
              respData.timestamp,
              ID,
              groupNames,
              cloudccId
            );
          },
          error: (err) => {
            this.$message({
              showClose: true,
              message: err,
              type: "error",
              duration: 3000,
            });
          },
        };
        this.$imconn.createGroupNew(options);
      }
      this.isShow = false;
      this.$emit("changeShow", this.isShow);
    },
    /*
     * 获取当前群成员
     */
    async getgroupmsg(val) {
      this.allpeople = [];
      if (this.$store.state.list.isChatID.isGroup == true) {
        let groupmsg = await job.getgroupmessage(val);
        if (groupmsg.result == true && groupmsg.data) {
          this.$Bus.$emit(
            "get-group-number-again",
            groupmsg.data.members.length
          );
          this.allpeople = [];
          groupmsg.data.members.forEach((res) => {
            if (
              res.memberType == "owner" &&
              res.memberUserId == localStorage.getItem("myid")
            ) {
              this.isOwner = true;
            }
            let obj = {};
            obj.easemobImId = res.easemobImId;
            obj.name = res.memberUserName;
            obj.id = res.memberUserId;
            obj.type = res.memberType;
            this.allpeople.push(obj);
          });
        }
        this.peoList.forEach((res) => {
          this.allpeople.forEach((val) => {
            if (val.id == res.id) {
              this.sameIdArr.push(val.id);
            }
          });
        });
      } else {
        let obj = {};
        obj.name = this.$store.state.list.isChatID.name;
        obj.imgUrl = this.$store.state.list.isChatID.imgUrl;
        this.allpeople.push(obj);
      }
    }
  },
  watch: {
    input() {
      this.getUserList();
    },
    isOwner(newVal, oldVal) {
      if (newVal != oldVal) {
        //传递群主消息
        this.$Bus.$emit("deliver-groupOwner", newVal);
      }
    },
    //选中成员总数
    isShowB(val) {
      this.isShow = val;
    },
    ischatid: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.allpeople = [];
          this.sameIdArr = [];
          this.isOwner = false;
          if (this.$store.state.list.isChatID.isGroup) {
            this.getgroupmsg(newVal);
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    //当前聊天imid
    ischatid() {
      return this.$store.state.list.isChatID.imId;
    },
    //当前选中用户数量
    checkNumber() {
      return this.allpeople.length + this.checkList.length;
    },
    //关键字选中的用户
    filterList() {
      return this.peoList;
    },
    isgroup() {
      return this.$store.state.list.isChatID;
    },
  }
};
</script>

<style lang='scss'>
.select-members {
  position: absolute;
  right: 15px;
  top: 72px;
  width: 401px;
  background: #fff;
  height: 337px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  z-index: 222;
  .select-member {
    position: relative;
    float: left;
    width: 200px;
    height: 100%;
    .noChatPeo {
      text-align: center;
      img {
        width: 150px;
        margin-top: 50px;
      }
      .word {
        color: #999;
        margin-top: 10px;
      }
    }
    .tooltip {
      padding: 5px 0 0 5px;
      line-height: 0;
      .icon {
        width: 20px;
        height: 20px;
        display: inline-block;
      }
      span {
        font-size: 12px;
        color: #888;
        vertical-align: 3px;
      }
    }
    .list-box {
      width: 100%;
      height: calc(100% - 73px);
      overflow-y: auto;
      overflow-x: hidden;
      .list {
        width: 100%;
        padding-left: 10px;
        box-sizing: border-box;
        .item {
          position: relative;
          .item-image {
            position: absolute;
            left: 20px;
            top: 0;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            overflow: hidden;
            margin-top: 6px;
            img {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            }
          }
          .el-checkbox {
            display: block;
            width: 100%;
            height: 34px;
            line-height: 34px;
            .el-checkbox__inner {
              border-color: #dcdcdc;
            }
            .el-checkbox__inner:hover {
              border-color: #dcdcdc;
            }
            .el-checkbox__inner {
              width: 12px;
              height: 12px;
              border-radius: 50%;
            }
            .el-checkbox__input.is-checked .el-checkbox__inner::after {
              transform: rotate(45deg) scaleY(1) translate(-1px, -1px);
            }
            .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background-color: #006dcc;
              border-color: #006dcc;
            }
            .el-checkbox__input.is-checked + .el-checkbox__label {
              color: #333;
            }
            .el-checkbox__input {
              position: initial;
              top: 0;
            }
            .el-checkbox__input {
              top: 0 !important;
            }
            .el-checkbox__label {
              font-size: 12px;
              padding-left: 38px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 135px;
              transform: translateY(4px);
            }
          }
        }
      }
    }
    .search-img {
      position: absolute;
      left: 16px;
      top: 17px;
      width: 12px;
      height: 12px;
    }
    .el-input {
      margin-top: 12px;
      margin-left: 10px;
      width: 180px;
      line-height: 0;
      .el-input__inner {
        height: 22px;
        padding-left: 24px;
        background-color: #f3f2f2;
        font-size: 12px;
      }
      .el-input__suffix {
        transform: translate(0, -9px);
      }
    }
  }
  .line {
    float: left;
    width: 1px;
    height: 100%;
    background-color: #dcdcdc;
  }
  .already-select {
    position: relative;
    float: left;
    width: 200px;
    height: 100%;
    padding: 13px 5px 13px 12px;
    box-sizing: border-box;
    .title {
      width: 100%;
      line-height: 19px;
      overflow: hidden;
      padding-right: 15px;
      .name {
        font-size: 14px;
        font-weight: bold;
        float: left;
      }
      .is-select {
        color: #777;
        float: right;
      }
    }
    .list-box {
      width: 100%;
      height: calc(100% - 46px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: 16px;
      .list {
        width: 100%;
        .item {
          width: 100%;
          height: 22px;
          line-height: 22px;
          overflow: hidden;
          margin-bottom: 10px;
          .image {
            width: 15px;
            overflow: hidden;
            box-sizing: border-box;
            float: left;
            .icon {
              width: 15px;
              height: 15px;
              display: inline-block;
            }
          }
          .name {
            float: left;
            margin-left: 6px;
            margin-right: 3px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 120px;
            font-size: 12px;
          }
          .delete {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            float: left;
            cursor: pointer;
            line-height: 25px;
            .icon {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    ::v-deep .el-checkbox__input{
      top: 0 !important;
    }
    .button {
      width: 100%;
      position: absolute;
      bottom: 13px;
      right: 16px;
      .create {
        padding: 0 13px;
        height: 26px;
        border-radius: 4px;
        background-color: #006dcc;
        font-family: "MicrosoftYaHei";
        line-height: 26px;
        text-align: center;
        color: #fff;
        float: right;
        cursor: pointer;
      }
      .cancel {
        padding: 0 13px;
        height: 26px;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        box-sizing: border-box;
        font-family: "MicrosoftYaHei";
        line-height: 26px;
        text-align: center;
        float: right;
        margin-right: 10px;
        cursor: pointer;
        background: white;
      }
    }
  }
  ::v-deep.el-checkbox__input {
    top: 0 !important;
  }
}
</style>