<template>
  <el-container
    id="home_content"
    class="home_content"
    :class="{ isShowprobation2: isShowprobation }"
  >
    <div id="full-screen">
      <!-- 左侧应用程序列表 -->

      <div
        v-show="isShowApps"
        class="applicationList"
        ref="applicationBox"
        @mouseenter="toggleApplication(false, 'mouse')"
        @mouseleave="toggleApplication(true, 'mouse')"
      >
        <el-menu
          class="el-menu-vertical"
          :class="isCollapse ? 'closeMenu' : 'openMenu'"
          :default-active="defaultActive"
          :collapse="false"
          :collapse-tooltip="false"
          @select="changeApplication"
          background-color="#3c465c"
          text-color="#fff"
          active-text-color="#080707"
          :style="{ zIndex: zIndex + 70 }"
        >
          <el-menu-item
            class="first-menu"
            :class="scrolling ? 'bottom-shadow' : ''"
            :disabled="true"
            index="0"
          >
            <!-- 应用程序菜单收起显示图标 -->
            <i v-show="isCollapse" class="menu-icon pull-left">
              <img
                class="application-icon"
                :src="require('./assets/images/cloudcc-logo.svg')"
              />
            </i>
            <!-- 应用程序菜单展开显示图标 -->

            <i
              v-show="!isCollapse"
              class="appcursorBox menu-icon pull-left"
              @click.stop="changShowApp"
            >
              <el-tooltip
                popper-class="appsMenuBox"
                :visible-arrow="false"
                :enterable="false"
                effect="dark"
                :content="realisShowApps ? $t('c885') : $t('c886')"
                placement="left"
                :style="{ zIndex: zIndex + 71 }"
              >
                <svg class="icon" aria-hidden="true">
                  <use
                    :href="realisShowApps ? '#icon-shouqi' : '#icon-zhankai'"
                    fill="#fff"
                  ></use>
                </svg>
              </el-tooltip>
            </i>

            <span class="application-logo menu-title pull-left" slot="title">
              <el-image
                style="width: 140.46px; height: 25.75px"
                :src="logourl"
                fit="scale-down"
              ></el-image>
            </span>
          </el-menu-item>
          <div
            class="pull-left application-item-box scrollBoxUnique"
            @scroll="handleScroll"
          >
            <el-menu-item
              :index="item.id"
              v-for="item in applicationarr"
              :key="item.id"
              @mouseover.native="mouseoverMenuItem(item)"
              @mouseleave.native="mouseleaveMenuItem"
            >
              <i class="menu-icon pull-left">
                <!-- 6个应用程序展示对应图标和匹配上的 -->
                <svg
                  v-show="item.appstyle"
                  class="icon"
                  aria-hidden="true"
                  slot="reference"
                >
                  <use
                    :href="
                      defaultActive === item.id || mouseoverMenuId === item.id
                        ? `${defaultIconList[Number(item.appstyle) - 1]}-sel`
                        : `${defaultIconList[Number(item.appstyle) - 1]}`
                    "
                    :fill="defaultActive === item.id ? '' : '#fff'"
                  ></use>
                </svg>
                <!-- 匹配不上时展示默认引用程序图标 -->
                <svg
                  v-show="!item.appstyle"
                  class="icon"
                  aria-hidden="true"
                  slot="reference"
                >
                  <use
                    :href="
                      defaultActive === item.id || mouseoverMenuId === item.id
                        ? `${defaultIconList[7]}-sel`
                        : `${defaultIconList[7]}`
                    "
                  ></use>
                </svg>
                <!-- 应用程序默认图标选中样式 -->
                <!-- <img
                  v-show="
                    applicationIcon[item.id] === undefined &&
                    (defaultActive === item.id || mouseoverMenuId === item.id)
                  "
                  :src="require('./assets/images/default-icon2.svg')"
                /> -->
              </i>
              <span class="menu-title pull-left" slot="title">
                {{ item.name }}
              </span>
            </el-menu-item>
          </div>
          <el-menu-item
            class="all-application-menu"
            :class="scrolling ? 'top-shadow' : ''"
            index="more"
          >
            <i class="menu-icon menu-icon-more pull-left">
              <svg class="icon" aria-hidden="true" slot="reference">
                <use
                  :href="
                    this.defaultActive === 'more'
                      ? '#icon-more-sel'
                      : '#icon-more1'
                  "
                ></use>
              </svg>
            </i>
            <span class="menu-title pull-left" slot="title">
              {{ $t("label_tabpage_allapplications") }}
            </span>
          </el-menu-item>
        </el-menu>
      </div>
      <!-- 页面主体 -->
      <div :class="isShowApps ? 'main-box' : 'main-box-all'">
        <!-- todo 判断是否是新版服务控制台  从而加载不同样式的菜单-->
        <!-- 应用程序下的菜单 -->
        <el-header>
          <div
            :class="showSelectMenu ? 'topMenuClassSearch' : 'topMenuClass'"
            ref="topMenuClass"
          >
            <!-- 菜单图标 -->
            <img
              v-show="!isShowApps"
              @mouseenter="changeShow(true)"
              class="menuIconHeader"
              :src="require('./assets/images/cloudcc-logo.svg')"
            />
            <!-- 导航样式为控制台样式  展示控制台菜单-->
            <!--服务控制台菜单-->
            <console-top-menu
              v-if="$store.state.navigationStyle"
              :applicationName="applicationName"
              :showMenus.sync="showMenus"
              :moreMenus="moreMenus"
              :applicationarr="applicationarr"
              :menupostion="menupostion"
              :changeLoad="frontLoading"
              :topTitleWidth="topTitleWidth"
              :topLeft="topLeft"
              :allMenus="allMenus"
              @clickshowMenu="clickshowMenu"
              @cancelLoading="cancelLoading"
              @clickmoreMenu="clickmoreMenu"
              @deletesurplus="deletesurplus"
              @addsurplus="addsurplus"
              @changeshowmenus="changeshowmenus"
              @tabapplication="tabapplication"
              @canceltemporary="canceltemporary"
              @Refreshapplicationview="Refreshapplicationview"
              @setfrontLoading="setfrontLoading"
              :isShowTabAllMenu="isShowTabAllMenu"
              ref="topMenu"
            >
            </console-top-menu>
            <!-- 顶部菜单 -->
            <topMenu
              v-if="!$store.state.navigationStyle"
              :applicationName="applicationName"
              :showMenus.sync="showMenus"
              :moreMenus="moreMenus"
              :applicationarr="applicationarr"
              :menupostion="menupostion"
              :changeLoad="frontLoading"
              :topTitleWidth="topTitleWidth"
              :allMenus="allMenus"
              @clickshowMenu="clickshowMenu"
              @cancelLoading="cancelLoading"
              @clickmoreMenu="clickmoreMenu"
              @deletesurplus="deletesurplus"
              @addsurplus="addsurplus"
              @changeshowmenus="changeshowmenus"
              @tabapplication="tabapplication"
              @canceltemporary="canceltemporary"
              @Refreshapplicationview="Refreshapplicationview"
              @setfrontLoading="setfrontLoading"
              :isShowTabAllMenu="isShowTabAllMenu"
              ref="topMenu"
            >
            </topMenu>
          </div>
          <topTitle
            ref="topTitle"
            :allmenulist="quickAdd"
            :logourl="logourl"
            :frontLoading="rightLoading"
          />
        </el-header>
        <!--主体内容 -->
        <el-main
          v-loading="contentLoading"
          style="flex: 1"
          ref="cacheContainer"
        >
          <div class="main">
            <div class="main-left">
              <keep-alive max="10">
                <router-view
                  v-if="$route.meta.keepAlive && !$route.meta.workbench"
                  :key="$route.fullPath"
                  ref="routerView"
                />
              </keep-alive>
              <router-view
                v-if="!$route.meta.keepAlive && !$route.meta.workbench"
              />
              <!-- 环信聊天页面，keepAlive无法缓存iframe，所以使用v-show -->
              <template v-if="isWorkbench">
                <workbench v-show="$route.meta.workbench"></workbench>
              </template>

              <div v-if="flag" style="width:100%;height:100%">
                <consoleIframeCustomPage
                  v-show="$route.name == 'consoleIframeCustomPage'"
                ></consoleIframeCustomPage>
              </div>
            </div>
            <div
              class="main-right"
              :style="{ width: rightBarWidth }"
              v-if="showRightBar"
            >
              <div class="main-right-btn" @click="changeRightBarStatus">
                <i v-if="rightBarStatus" class="el-icon-caret-right"></i>
                <i v-else class="el-icon-caret-left"></i>
              </div>
              <rightBar ref="rightBarCom"></rightBar>
            </div>
          </div>
        </el-main>
        <footerBar
          ref="footerBar"
          v-if="footerBarList.length > 0"
          :LeftFooterBarList="LeftFooterBarList"
          :rightFooterBarList="rightFooterBarList"
        ></footerBar>
      </div>
    </div>
    <!-- 用户首次开户成功进入系统后 显示推荐应用程序的 弹窗 -->
    <applicationPopup v-if="isFirstUseing"></applicationPopup>
    <!-- <callCenterBar v-if="isShowCallBar"></callCenterBar> -->

    <!-- 更多应用程序 -->
    <applicationStartUp
      ref="applicationPage"
      :isApplicationAlert="showMoreApplication"
      :allApplicationList="allApplicationList"
      @changeApplication="changeApplication"
      @showApplicaMenuCancal="showApplicaMenuCancal"
    ></applicationStartUp>
  </el-container>
</template>

<script>
/**
 * 项目home页，三明治结构，最下层为路由，用于切换视图页和详情页等其他特殊页面
 */
import { mapState } from "vuex";
import { PopupManager } from "element-ui/src/utils/popup";
import topMenu from "@/views/header/menu.vue";
import topTitle from "@/views/header/topTitle.vue";
import consoleTopMenu from "@/views/header/consoleMenu.vue";
import applicationPopup from "@/views/applicationPopup/index.vue";
import * as LayoutApi from "./api.js";
import VueCookies from "vue-cookies";
import debounce from "lodash.debounce";
import generatelevel1Menu from "@/utils/generatelevel1Menu.js";
import consoleIframeCustomPage from "@/views/injectionComponent/index.vue";
import {
  getobjectlist,
  getSetupInfo,
  getConsoleTab,
  consoleSaveTab,
} from "./api.js";
import * as request from "./api.js";
import footerBar from "./footerBar";
// import workbench from '@/views/customerWorkbench/workbench.vue'
import rightBar from "./components/rightBar";
export default {
  name: "home",
  components: {
    workbench: () => import("@/views/customerWorkbench/workbench.vue"),
    topMenu,
    topTitle,
    applicationPopup,
    // callCenterBar: () => import("@/views/callCenterPhone/callCenterBar.vue"),
    applicationStartUp: () =>
      import("@/views/applicationProgram/components/applicationStartUp"),
    consoleTopMenu,
    footerBar,
    rightBar,
    consoleIframeCustomPage,
  },
  data() {
    // 生成应用图标数据
    const defaultIconList = new Array(50)
      .fill(null)
      .map((item, index) => `#icon-appicon${index + 1}`);
    return {
      // 是否有环信聊天
      isWorkbench: false,
      flag: false,
      // 全部应用程序菜单
      allApplicationList: [],
      // 当前项目弹窗层级，保证左侧应用程序菜单层级最大
      zIndex: this.$store.state.home.zIndex,
      allMenus: [],
      topTitleWidth: 0,
      // 头部左侧部分
      topLeft: 0,
      contentLoading: false, //主体加loading
      isFirstUseing: this.$route.query.firstL === "true" ? true : false,
      realisShowApps: true, // app菜单是否展示的状态标识，区别于isShowApps
      isShowApps: true, // app菜单是否展示
      isCollapse: true, // app菜单是否收起
      defaultActive: "", // 默认选中应用程序
      curAppId: "", // 点击全部应用程序前选中的应用程序id
      logourl: "", // 当前应用程序图标
      mouseoverMenuId: "", // 鼠标悬浮应用程序id
      showMoreApplication: false, // 是否展示全部应用程序
      cacheProgramMenu: [], // 已缓存的应用程序和对应菜单
      isCacheProgram: false, // 是否有已缓存的应用程序
      choseProgram: [], //切换选中的应用程序
      oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
      scrollTop: 0, // 记录当前的滚动距离
      scrolling: false, // 应用程序列表是否正在滚动
      isShowCcchat: "", //是否显示同事圈主页入口
      isShowTabAllMenu: true, // 是否在可视范围内展示全部菜单按钮
      isShowCallBar: false,
      //是否为伙伴云用户
      Partnercloud: this.$store.state.userInfoObj.ispartner,
      quickAdd: [], // 快速新建menu
      showMenus: [],
      moreMenus: [],
      applicationarr: [],
      offsetWidth: "",
      menupostion: true,
      loading: true,
      // 初始化时选中的菜单项
      startmenu: {},
      menuprops: "",
      applicationName: "",
      // 应用程序id
      applicationId: "",
      // 导航样式
      navigationStyle: "",
      userurl: "",
      RefreshSelect: false,
      defaultIconList, // 图标数据，代替applicationIcon
      // applicationIcon: {
      //   ace20220322MarketCl: "#icon-shichangyun", // 市场云
      //   ace20220322Salesloud: "#icon-xiaoshouyun", // 销售云
      //   ace20220315Busicloud: "#icon-shangwuyun", // 商务云
      //   acecustomerserviceC: "#icon-kefuyun", // 客服云
      //   ace20210219FieldSer: "#icon-xianchangyun", // 现场云
      //   ace2020D253B3984Or6Q: "#icon-xiangmuyun", // 项目云
      //   ace2016498D153F6m7xD:'#icon-shichangyun', // 市场
      //   ace201300836012pVG3h:'#icon-xiangmuyun', // 招生
      //   ace20170FF491F6FaisY:'#icon-jiaowujiaoxue', // 教务教学
      //   ace201300836028Xf24E:'#icon-caiwu', // 财务
      //   ace20165E6EEEEFBQGSY:'#icon-shujujichu' // 基础数据
      // },
      tabIds: [
        //日历
        "acf20200615event",
        //日历管理
        "acf20208AD53CC8sLvgf",
        //同事圈
        "acf201200141050RgtYi",
        //邮件
        "acf20191021FeiAUjdys",
        //销售漏斗
        "acf2015030630xsld",
        //文件
        "acf20171206fileUjdys",
        //价格手册
        "acf2020110PriceBooks",
        //潜在客户公海池
        "acf202011leadmksea",
        //客户公海池
        "acf202011accmksea",
        //仪表板
        "acf100009",
        //短信
        "acf20201216smslist",
        //标情发现
        "acf2020340C93FD7chBP",
        //小组
        "acf20190807sgeAUjdys",
        //派工平台
        "acfFieldServiceSystb",
        //预测
        "acf2013121431foreca",
        //人员资源日历
        "acf20219D0E7E25g4uUv",
        //市场活动表单
        "acf2021070mcforms",
        //社交媒体
        "acf2021070mcsocial",
        //订货平台
        "acf20210713orderpro1",
        //聊天
        "acfConversationTab00",
        // 控制台
        "acfServiceControlTab",
      ],
      frontLoading: true, // 骨架屏
      rightLoading: true,
      isShowprobation: this.$store.state.remaining, //是否显示剩余时间
      // 底部工具条数据
      footerBarList: [],
      LeftFooterBarList: [],
      rightFooterBarList: [],
      // 是否显示菜单搜索框
      showSelectMenu: this.$store.getters.getFromGlobalSearchPath
        ? true
        : false,
      // 是否展示右侧边栏
      showRightBar: false,
      // 右侧边栏是否展开
      rightBarStatus: true,
      // 右侧边栏宽度
      rightBarWidth: "50%",
      rightBarWidthBase: "16px",
    };
  },
  created() {
    // 防止全局搜索表格点击打开新页面搜索菜单不关闭的情况
    if(this.showSelectMenu && this.$route.path && !this.$route.path.includes('/globalsearch/')){
      this.showSelectMenu = false;
    }

    // 主页、仪表板中在添加组件后定位到对应组件位置
    this.$bus.$on("scrollPositionBus", this.ebFn1);
  },
  //销毁监听刷新事件
  destroyed() {
    window.addEventListener("beforeunload", this.beforeunloadHandler);
    this.$bus.$on("scrollPositionBus", this.ebFn1);
    this.$Bus.$on("enterSelectBtn", this.ebFn2);
    this.$Bus.$on("closeSelectBtn", this.ebFn3);
    sessionStorage.removeItem("currentMenu");
    this.$bus.$off("removeCachePage", this.deleteRouterCache);
    window.onresize = null;
    window.$CCDK.CCBus.$off("initSide", this.initSideFn);
    window.$CCDK.CCBus.$off("openSide", this.openSideFn);
    window.$CCDK.CCBus.$off("closeSide", this.closeSideFn);
  },
  //获取浏览器当前窗口宽度
  mounted() {
    if (!this.Partnercloud) {
      this.isShowCallBar = true;
    }
    this.getSetupInfo(); //调取接口是否显示同事圈入口
    //获取当前用户动态域名
    this.userurl = this.$cookies.get("domainName");
    // this.getallmenulist();
    //判断是否为伙伴云用户
    if (this.Partnercloud) {
      this.getPartnerTabList();
    } else {
      this.getapplication();
    }
    this.$store.dispatch("getReportEnableList"); // 调用接口获取报表的权限信息，存储在vuex中，供多个组件使用
    this.$store.dispatch("getCurrencyCode"); // 调用接口获取币种类型，存储在vuex中，供多个组件使用
    //监听页面刷新挂载事件
    window.addEventListener("beforeunload", this.beforeunloadHandler);
    this.topTitleWidth = this.$refs.topTitle.$el.clientWidth;
    // 解决右键进入菜单添加
    if (this.$route.query.CRId) {
      let tagMenu = JSON.parse(localStorage.getItem("clickRightObj")) || {};
      // 设置当前菜单
      this.setItemApplicationTab(tagMenu.id);
      sessionStorage.setItem(
        "currentMenu",
        localStorage.getItem("clickRightObj")
      );
      this.changeshowmenus(tagMenu);
    }
  },
  updated() {
    // 页面刷新，需要判断当前选中的菜单是否在展示菜单中
    let menuId = this.getItemApplicationTab();
    if (
      !this.showMenus.some((item) => item.id === menuId) &&
      sessionStorage.getItem("currentMenu")
    ) {
      // 将当前菜单项添加到allMenus和showMenus中
      // 替换菜单
      let targetMenu = JSON.parse(sessionStorage.getItem("currentMenu"));
      if (menuId === targetMenu.id) {
        this.changeshowmenus(targetMenu);
      }
    }

    this.topLeft = this.$refs.topMenuClass.clientWidth;
    window.onresize = () => {
      this.topTitleWidth = this.$refs.topTitle.$el.clientWidth;
      this.topLeft = this.$refs.topMenuClass.clientWidth;
    };
    //监听点击搜索菜单
    this.$Bus.$on("enterSelectBtn", this.ebFn2);
    //监听关闭搜索菜单
    this.$Bus.$on("closeSelectBtn", this.ebFn3);
    //关闭一级菜单清除缓存
    // if (this.$refs.routerView) {
    this.$bus.$on("removeCachePage", this.deleteRouterCache);
    // }
    // 监听侧边栏状态
    window.$CCDK.CCBus.$on("initSide", this.initSideFn);
    window.$CCDK.CCBus.$on("openSide", this.openSideFn);
    window.$CCDK.CCBus.$on("closeSide", this.closeSideFn);
  },
  watch: {
    $route: {
      handler(to) {
        // 环信聊天
        if (to.meta.workbench) {
          this.isWorkbench = to.meta.workbench;
        }
        if (to.name == "consoleIframeCustomPage") {
          if (to.params.page) {
            this.flag = true;
          }
        }
        //如果是标准对象列表页则给对应的选项卡加样式
        if (to.params.objId) {
          let isShowId = this.showMenus.filter((item) => {
            return item.id == localStorage.getItem("applicationTab");
          });
          if (isShowId.length != 0) {
            this.$Bus.$emit("change-style", isShowId[0].id);
            sessionStorage.setItem("applicationTab", isShowId[0].id);
            localStorage.setItem("applicationTab", isShowId[0].id);
          } else {
            this.clearshowMenu();
            this.clearmoreMenu();
          }
        }
      },
      immediate: true,
    },
    scrollTop(newValue, oldValue) {
      setTimeout(() => {
        if (
          newValue ==
          document.getElementsByClassName("application-item-box")[0].scrollTop
        ) {
          //延时执行后当newValue等于滚动高度，代表滚动结束
          this.oldScrollTop = newValue; //每次滚动结束后都要给oldScrollTop赋值
          this.scrolling = false;
        }
      }, 20); //必须使用延时器，否则每次newValue和滚动高度都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
      if (this.oldScrollTop == oldValue) {
        //每次滚动开始时oldScrollTop与oldValue相等
        this.scrolling = true;
      }
    },
  },
  computed: {
    ...mapState({
      consoleData: (state) => state.consoleData,
    }),
    // 所有菜单的id
    menusIds() {
      let menusIds = [];
      this.allMenus.forEach((item) => {
        menusIds.push(item.id);
      });
      return menusIds;
    },
  },
  methods: {
    /**
     * 清理tableListConfig缓存
     */
    freshCache(item){
      let tableListConfig = {
        prefix: "", // 视图id
        viewId: "", // 视图id
        pageSize: 25, // 每页条数
        skippageNum: 1, // 当前页数
        applicationId: item?.id || '', // 应用id
        sortField: "", // 当前排序字段
        sortDir: "", // 当前排序顺序
      };
      this.$store.commit("setTableListConfig", tableListConfig); // 存储列表条件
    },
    initSideFn(option) {
      let barWidth = option.width || "300px";
      this.rightBarWidthBase = barWidth;
      this.rightBarWidth = barWidth;
      this.showRightBar = true;
      this.$nextTick(() => {
        this.$refs.rightBarCom.getRightCustomPageDetail(option.pageApi);
      });
    },
    openSideFn() {
      this.rightBarStatus = true;
      this.rightBarWidth = this.rightBarWidthBase;
    },
    closeSideFn() {
      this.rightBarStatus = false;
      this.rightBarWidth = "16px";
    },
    ebFn1() {
      setTimeout(() => {
        this.scrollPosition();
      }, 300);
    },
    ebFn2() {
      this.showSelectMenu = true;
    },
    ebFn3() {
      this.showSelectMenu = false;
    },
    // 删除缓存方法
    deleteRouterCache(key) {
      /**
       * @param {Object} cache:类数组对象，存放缓冲的路由组件，键值对形，key为路由路径，value为路由组件Vnode
       * @param {Array} keys:数组，存放缓冲的路由组件的key,即路由路径
       * @param {String} key:字符串，路由组件的key，指定要删除的路由组件key值
       */
      if (!this.$route.meta.keepAlive) {
        return;
      }
      const filterCacheByKey = (cacheList, removeKey) => {
        cacheList[removeKey]?.componentInstance?.$destroy();
        delete cacheList[removeKey];
      };
      const filterParentCache = (key) => {
        const temp = this.$refs.cacheContainer?.$children;
        const idx = temp?.findIndex((item) =>
          item?.$vnode?.data?.key?.includes(key)
        );
        idx > -1 && temp.splice(idx, 1);
      };
      const filterCacheKeyByKey = (cacheKeys, key) => {
        const idx = cacheKeys?.findIndex((_) => _ === key);
        idx > -1 && cacheKeys.splice(idx, 1);
      };

      let instance;
      if (!this.$refs.routerView) {
        const temp = this.$refs.cacheContainer?.children;
        const idx = temp?.findIndex((item) =>
          item?.$vnode?.data?.key?.includes(key)
        );
        idx !== -1 && (instance = temp[idx]?.$vnode?.parent?.componentInstance);
      } else {
        instance = this.$refs.routerView?.$vnode?.parent?.componentInstance;
      }
      if(!instance){
        return;
      }

      // if(!this.$refs.routerView){
      //   return filterParentCache(key)
      // }
      const { cache, keys } = instance,
        cacheKeys = Object.keys(cache || {}),
        removeCacheKey = cacheKeys.filter(item => item.includes(key))[0] || ''
      // let cacheItem
      // removeCacheKey && (cacheItem = cache[removeCacheKey])
      // 存在缓存对象
      if (removeCacheKey) {
        filterCacheByKey(cache, removeCacheKey);
        filterCacheKeyByKey(keys, removeCacheKey);
      }
      filterParentCache(key);
    },
    // 获取底部Bar appId 为应用程序id
    getFootToolByApp(appId) {
      LayoutApi.getFootToolByApp({ appId: appId })
        .then((res) => {
          if (res.result) {
            this.footerBarList = [];
            this.rightFooterBarList = [];
            this.LeftFooterBarList = [];
            this.footerBarList = res.data;

            // 设置缓存数据start
            localStorage.setItem(
              "footerToolBottom",
              this.footerBarList.length > 0 ? "44px" : "0px"
            );
            let result = this.footerBarList.filter((item) => {
              return item.tooltype === "callcenter";
            });
            localStorage.setItem(
              "callcenterConf",
              result.length > 0 ? JSON.stringify(result[0]) : "{}"
            );
            // 设置缓存数据end

            this.footerBarList.length > 0 &&
              this.footerBarList.forEach((item) => {
                // 呼叫中心（自定义、标准的）需要始终放在最右侧，在这处理一下数据分为左侧右侧
                if (item.tooltype == "callcenter") {
                  this.rightFooterBarList.push(item);
                } else {
                  this.LeftFooterBarList.push(item);
                }
              });
            this.$refs.footerBar.closeAllPopupWindow();
          }
        })
        .catch(() => {});
    },
    /**
     * 需要同时在sessionStorage和localStorage中存储applicationTab，防止后期出现左键新窗口或者新标签页打开的情况
     * @param {String} tabId:菜单项id
     */
    setItemApplicationTab(tabId = "") {
      if (!tabId) return;
      localStorage.setItem("applicationTab", tabId);
      sessionStorage.setItem("applicationTab", tabId);
    },
    /**
     * 优先使用sessionStorage中的数据
     */
    getItemApplicationTab() {
      return sessionStorage.getItem("applicationTab")
        ? sessionStorage.getItem("applicationTab")
        : localStorage.getItem("applicationTab");
    },
    // 新版应用程序时获取当前tab数据 appid为应用程序id
    async getConsoleTab(appid) {
      let res = await getConsoleTab({ appid: appid });
      if (res.data) {
        let opentab = JSON.parse(res.data.opentab);
        this.$store.commit("setConsoleData", opentab);
      } else {
        const consoleDataObj = {
          currentObject: "", // 当前菜单名字
          currentObjectId: "", // 当前菜单id
          iscustom: "", // 菜单是 标准 还是自定义
          type: "", // 菜单类型
          objectApi: "",
          objId: "",
          prefix: "",
          viewId: "",
          data: {
            level1Id: "", // 一级菜单当前的选中的菜单
            list: [
              // 一级列表
              // {
              //   name: "aaa", // tab名称
              //   id: "12312wfsdf",// tab id
              //   isfixed: true, // 是否固定选项卡
              //   level2Id:"", // 二级菜单当前的选中的菜单
              //   // 一级菜单下的二级菜单
              //   childer: [
              //     {
              //       name: "aaa",
              //       id: "111",
              //       isfixed: true, // 是否固定选项卡
              //     },
              //     {
              //       name: "bbb",
              //       id: "bbb",
              //       isfixed: true, // 是否固定选项卡
              //       customPage:'1', // 说明是自定义页面
              //     },
              //   ],
              // },
            ],
          },
        };
        this.$store.commit("setConsoleData", consoleDataObj);
      }
      this.setTab();
    },
    // 处理点击全部打开临时菜单  退出登录后 再登录  再下拉菜单中找不到之前打开的临时菜单的问题
    jumpFirstMenu() {
      // 如果是从全部菜单中点出来的临时菜单  点到一级菜单  退出登录  再登录进来 点到当前菜单 此时在下拉菜单中找不到当前这个临时菜单  此时默认到第一个菜单
      if (this.showMenus.length > 0) {
        this.consoleData.currentObject = this.showMenus[0]?.tabName;
        this.consoleData.currentObjectId = this.showMenus[0]?.id;
        // 标准对象、自定义对象
        if (
          (this.showMenus[0].iscustom == "0" ||
            this.showMenus[0].iscustom == "1") &&
          this.showMenus[0].type == "object"
        ) {
          // 菜单对象是自定义的还是标准的 0 标准 1自定义
          this.consoleData.iscustom = this.showMenus[0].iscustom;
          this.consoleData.type = this.showMenus[0].type;
          this.consoleData.objectApi = this.showMenus[0].objectApi;
          this.consoleData.objId = this.showMenus[0].objId;
          this.consoleData.prefix = this.showMenus[0].prefix;
          this.consoleData.viewId = "1";
        } else {
          // 其他特殊的
          this.consoleData.iscustom = "0";
          this.consoleData.type = "object";
          this.consoleData.objectApi = "Account";
          this.consoleData.objId = "account";
          this.consoleData.prefix = "001";
          this.consoleData.viewId = "1";
        }
        this.clickshowMenu(this.showMenus[0]);
        this.$store.commit("setConsoleData", this.consoleData);
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      }
    },
    // 新版服务控制台初始化一进来的tab状态
    setTab() {
      // 处理当前是临时菜单  刷新之后再切回临时菜单  showMenus里面找不见的问题  因为临时菜单只是临时往showMenus里放了一条数据 并没有存在接口里
      if (this.showMenus.length > 0) {
        let currentMenuObj = this.showMenus.find((item) => {
          return (
            item.tabName == this.consoleData.currentObject ||
            item.id == this.consoleData.currentObjectId
          );
        });
        if (!currentMenuObj) {
          this.consoleData.currentObject = this.showMenus[0]?.tabName;
          this.consoleData.currentObjectId = this.showMenus[0]?.id;
        }
      }
      // list大于0可以初步判断有一级tab
      if (this.consoleData.data.list.length > 0) {
        // 当前选中的一级tab
        let level1Id = this.consoleData.data.level1Id;
        // 如果有一二级tab但是选中的不为一二级，而是当前menu
        if (level1Id == "menu") {
          let currentMenuObj = this.showMenus.find((item) => {
            return (
              item.tabName == this.consoleData.currentObject ||
              item.id == this.consoleData.currentObjectId
            );
          });
          this.clickshowMenu({ ...currentMenuObj });
          return;
        }
        // 当前选中的为一级或二级
        // 过滤出当前一级菜单下的二级菜单列表
        this.consoleData.data.list.forEach((item) => {
          if (item.id == level1Id) {
            // 有children说明有二级tab 那么跳转到当前二级tab
            if (item.children && item.children.length > 0) {
              // 过滤出当前选中的二级item(因为要判断当前的二级tab是否是自定义页面)
              let level2Item = item.children.find((citem) => {
                return citem.id == item.level2Id;
              });
              // 有routerName说明是调用ccsdk生成的菜单
              if (level2Item.routerName) {
                this.$router.push({
                  name: level2Item.routerName,
                  params: level2Item.params || {},
                });
              } else if (level2Item.routerPath) {
                this.$router.push({
                  path: level2Item.routerPath,
                  query: level2Item.query || {},
                });
              } else {
                // 判断当前的二级是自定义页面 还是  标准（自定义）对象
                if (level2Item.customPage == "1") {
                  // 新版自定义页面
                  this.$router.push({
                    path: `/commonObjects/console-multi-screen/console-injection-component/${level2Item.pageApi}/${level2Item.id}`,
                  });
                } else {
                  this.$router.push({
                    path: `/commonObjects/console-multi-screen/console-detail/${item.level2Id}/DETAIL`,
                    query: {
                      dataId: item.level2Id,
                    },
                  });
                }
              }
            } else {
              // 没有二级Tab 那么跳转到当前一级tab
              // 有routerName说明是调用ccsdk生成的菜单
              if (item.routerName) {
                this.$router.push({
                  name: item.routerName,
                  params: item.params || {},
                });
              } else if (item.routerPath) {
                this.$router.push({
                  path: item.routerPath,
                  query: item.query || {},
                });
              } else {
                this.$router.push({
                  path: `/commonObjects/console-multi-screen/console-detail/${level1Id}/DETAIL`,
                  query: {
                    dataId: level1Id,
                  },
                });
              }
            }
          }
        });
      } else {
        // 没有一二级菜单此时选中菜单的第一个
        this.jumpFirstMenu();
      }
    },
    // 改变app菜单显隐
    changShowApp() {
      // 控制应用程序展示、隐藏的状态
      this.realisShowApps = !this.realisShowApps;
      // 控制应用程序展示、隐藏
      this.isShowApps = !this.isShowApps;
      // 控制应用程序菜单展开收起
      this.isCollapse = false;
    },
    setfrontLoading(val) {
      this.frontLoading = val;
      this.rightLoading = val;
    },
    // 取消loading
    cancelLoading() {
      this.contentLoading = false;
    },
    // 上浮顶部菜单图标位置展示应用程序图标
    changeShow(flag) {
      // flag:true:打开应用程序菜单，但是离开后需要收起
      if (flag) {
        this.isShowApps = true;
        this.isCollapse = false;
      }
    },
    // 展开/收缩菜单
    toggleApplication(flag) {
      if (!flag) {
        this.zIndex = PopupManager.nextZIndex();
      } else {
        this.zIndex = 2900;
      }
      this.$store.commit("setzIndex", this.zIndex);
      if (
        flag &&
        event.pageX < 128 &&
        event.pageX > 1 &&
        event.clientY > 1 &&
        event.clientY < 48
      ) {
        // 解决鼠标上浮到文字提示上触发离开事件
        // 判断当前鼠标位置，如果是触发菜单上文字提示，此时不收起
        return;
      }
      // 离开时需要判断realisShowApps
      // 若此时应用程序菜单是隐藏状态
      if (flag && !this.realisShowApps) {
        this.isShowApps = false;
      }
      this.isCollapse = flag;
    },
    // 鼠标悬浮应用程序事件
    mouseoverMenuItem(item) {
      this.mouseoverMenuId = item.id;
    },
    // 鼠标离开应用程序事件
    mouseleaveMenuItem() {
      this.mouseoverMenuId = "";
    },
    // 应用程序列表滚动事件
    handleScroll() {
      this.scrollTop = document.getElementsByClassName(
        "application-item-box"
      )[0].scrollTop;
    },
    // 切换应用程序
    /**
     * 切换应用程序,增加防抖
     * @param {String} key:点击应用程序菜单id
     *
     */
    changeApplication: debounce(
      function (key) {
        if (key === "0") {
          // 首个菜单
        } else if (key === "more") {
          // 更多
          // 记录当前应用程序id
          this.curAppId = this.defaultActive;
          this.defaultActive = key;
          this.showMoreApplication = true;
          // this.$refs.applicationPage.getapplication();
        } else {
          // 清空记录的菜单项
          sessionStorage.removeItem("currentMenu");
          // 切换应用程序
          this.defaultActive = key;
          // this.contentLoading = true;
          this.frontLoading = true;
          this.showApplicaMenuCancal();
          let application = this.applicationarr.find((item) => {
            return item.id === key;
          });
          if (application && this.$refs.topMenu) {
            this.logourl = application.logourl;
            this.applicationName = application.name;
            this.applicationId = application.id;
            this.navigationStyle = application.navigationStyle;
            // 是否是控制台样式 存入vuex
            // todo 贺文娟
            this.$store.commit("setNavigationStyle", this.navigationStyle);
            this.tabapplication(application.id);
            // 使用CCApplication存储应用程序对象
            this.$CCDK.CCApplication.setApplication(application);
          }
        }
        this.toggleApplication(true, "click");
        // 导航样式为控制台样式
        setTimeout(() => {
          if (this.$store.state.navigationStyle) {
            this.getConsoleTab(this.applicationId);
          }
        }, 500);
      },
      500,
      { leading: true, trailing: false }
    ),
    // 关闭全部应用程序抽屉
    showApplicaMenuCancal(appId) {
      this.showMoreApplication = false;
      this.defaultActive = appId || this.curAppId;
    },
    // 仪表板跳转前调用仪表板最近查看接口，如果有数据跳转到仪表板页面，无数据跳转到仪表板文件夹列表页
    async getDashboardList() {
      let params = {
        name: "", //搜索内容
        dashboardfolderid: "recentDashboard",
        islightning: "true",
        page: "1",
        pageSize: "10000",
      };
      let data = await request.getDashboardList(params);
      if (data.data.listDashboard && data.data.listDashboard.length > 0) {
        let id = data.data.listDashboard[0].id;
        this.$router.push({
          path: `/dashboardObject/dashboardIndex/${id}`,
          query: {},
        });
      } else {
        this.$router.push({
          path: `/dashboardObject`,
        });
      }
    },
    beforeunloadHandler() {
      sessionStorage.setItem("issave", true);
    },
    /**
     * 功能：
     * 1、根据菜单项分配showMenus和moreMenus,更多菜单和全部菜单切换时使用
     * 2、如果需要新添加菜单项（一般是从全部菜单中点击allMenus中没有的菜单），需要再调用之前清空选中
     * @param {Object} menusObj:需要以该菜单项分割showMenus和moreMenus,位置根据当前展示的倒数第二个索引为准（即替换展示菜单中的最后一个）
     * @param {Object} newMenu:需要添加到所有菜单中的菜单项（需要去重）
     */
    handleMenus(menusObj = {}, newMenu) {
      if (this.menusIds.includes(menusObj.id)) {
        let targetIndex = this.menusIds.indexOf(menusObj.id);
        // 如果所有菜单中不包含新添加项，向allMenus中添加一个新的菜单项
        if (
          newMenu &&
          newMenu.constructor === Object &&
          !this.menusIds.includes(newMenu.id)
        ) {
          // 此时将newMenu插入到allMenus中，
          this.allMenus.forEach((item) => {
            item.isDefaultLaunchTab = "false";
          });
          newMenu.isDefaultLaunchTab = "true";
          this.allMenus.splice(targetIndex, 0, newMenu);
          this.showMenus = this.allMenus.slice(0, targetIndex + 1);
          this.moreMenus = this.allMenus.slice(targetIndex + 1);
          // 更多菜单中添加全部菜单标签
          let obj = {};
          obj.tabName = this.$i18n.t("label.pc.ui.2018.all.tabs");
          obj.id = "allmenu";
          this.moreMenus.push(obj);
          // 是否展示全部菜单项
          this.isShowTabAllMenu = !this.moreMenus.length >= 1;
        } else if (this.moreMenus.some((item) => item.id == newMenu.id)) {
          // 需要添加的菜单项在更多菜单中
          this.showMenus.forEach((item) => {
            item.isDefaultLaunchTab = "false";
          });
          newMenu.isDefaultLaunchTab = "true";
          // 删除展示菜单中多余的菜单项
          let menuObj = this.showMenus.splice(targetIndex);
          // 将需要替换的菜单项加入展示菜单中
          this.showMenus.push(newMenu);
          // 处理更多菜单
          let moreMenus = this.moreMenus.filter(
            (item) => item.id !== newMenu.id
          );
          this.moreMenus = [...menuObj, ...moreMenus];
        }
      }
    },
    /**
     * 初始化菜单选中状态
     */
    inittabstatus() {
      if (sessionStorage.getItem("applicationTab")) {
        this.clearshowMenu();
        this.clearmoreMenu();
        this.showMenus.forEach((item) => {
          if (item.id == sessionStorage.getItem("applicationTab")) {
            this.$nextTick(() => {
              item.isDefaultLaunchTab = "true";
              // this.clickshowMenu(item);
            });
          }
        });
        this.moreMenus.forEach((item) => {
          if (item.id == sessionStorage.getItem("applicationTab")) {
            item.isDefaultLaunchTab = "true";
            // this.clickshowMenu(item);
          }
        });
      } else {
        // 复制的地址、右键新打开的地址（例如：详情、email2cc等） 直接跳转，不再走默认菜单
        if (this.$route.path.indexOf("/commonObjects/detail") != -1 || 
          this.$route.path.indexOf("/CommonObjects/detail") != -1 || 
          this.$route.path == "/add" ) {
          return;
        }
        this.showMenus.forEach((item) => {
         if (item.isDefaultLaunchTab == "true") {
          sessionStorage.setItem("applicationTab", item.id);
          localStorage.setItem("applicationTab", item.id);
          this.clickshowMenu(item);
         }
        })
      }
    },
    // 获取CCChat后台设置信息
    async getSetupInfo() {
      let res = await getSetupInfo();
      if (res.result) {
        this.isShowCcchat = res.data.isEnable;
      }
    },
    //伙伴云用户获取菜单
    async getPartnerTabList() {
      let logo = await LayoutApi.getLogoid();
      if (logo.result) {
        this.logourl = `${VueCookies.get(
          "domainName"
        )}/querysome.action?m=viewImg&fileid=${
          logo.data.partnerlogoimgid
        }&binding=${this.$CCDK.CCToken.getToken()}&wj=${Math.random()}`;
      }
      let res = await LayoutApi.getPartnerTab();
      if (res.result) {
        this.loading = false;
        this.moreMenus = [];
        let filtercalendar = [];
        res.data.forEach((value) => {
          if (this.tabIds.indexOf(value.id) !== -1) {
            value.type = "";
            //任务
          } else if (value.id == "acf20200615task") {
            value.type = "object";
            value.objectApi = "Task";
            value.objId = "task";
            value.prefix = "bfa";
          }
          this.$set(value, "isDefaultLaunchTab", "false");
          filtercalendar.push(value);
        });
        this.allMenus = filtercalendar.filter(
          (item) => item.type != "applauncher" && item.id != "acf000010"
        );
        this.showMenus = filtercalendar.filter(
          (item) => item.type != "applauncher" && item.id != "acf000010"
        );
        this.quickAdd = filtercalendar.filter(
          (item) => item.type != "applauncher" && item.id != "acf000010"
        );
        // 当前本地存储的applicationTab
        let tabId = this.getItemApplicationTab();
        let Isincluded = res.data.some((item) => {
          return item.id == tabId;
        });
        if (tabId && Isincluded) {
          // 保存选项卡id到sessionStorage
          let Isincludedmenu = this.showMenus.filter(
            (item) => item.id == tabId
          );
          this.startmenu = Isincludedmenu[0];
        } else {
          this.startmenu = res.data[0];
        }
        // 是否跳转到记录菜单 issave为true时跳转
        if (sessionStorage.getItem("issave") === "false") {
          if (this.RefreshSelect) {
            this.clickshowMenu(this.showMenus[0]);
            this.$bus.$emit("refareshPage");
          } else {
            this.clickshowMenu(this.startmenu);
          }
        }
        if (tabId == null || tabId == "" || tabId == "none") {
          let resData = JSON.parse(JSON.stringify(res.data));
          let firstItem = resData[0];
          // 导航样式为控制台样式
          if (this.$store.state.navigationStyle) {
            this.getConsoleTab(this.applicationId);
          } else {
            /**
             * 1、如果第一个菜单是自定义页面则强制跳转到主页
             * 2、否则进入到第一个菜单并选中
             */
            if (firstItem.iscustom == "1" && firstItem.type === "page") {
              if (
                this.$route.path === "/homePageObject/standardPage" ||
                this.$route.path === "/"
              ) {
                this.$router.replace("/homePageObject/standardPage");
              }
            } else {
              if (
                this.$route.path === "/" ||
                this.$route.path === "/homePageObject/standardPage"
              ) {
                this.clickshowMenu(this.showMenus[0]);
              }
            }
          }
        }
        if (!this.$store.state.navigationStyle) {
          this.inittabstatus();
        }
        this.RefreshSelect = false;
      }
    },
    //获取应用程序（菜单列表）
    getapplication() {
      let application = this.$store.state.home.homeApplicationId;
      LayoutApi.getApplicationList().then((res) => {
        if (res && res.data && res.data.length > 0) {
          this.applicationarr = res.data;
          // 全部应用程序菜单使用数据
          this.allApplicationList = JSON.parse(JSON.stringify(res.data));
          //appState = 1为默认显示的程序
          let defaultApp = res.data.filter((item) => {
            return item.appState == 1;
          });
          this.logourl = defaultApp[0] ? defaultApp[0].logourl : undefined;

          //判断sessionStorage存储的程序id是否与请求回来的一致
          let findapp = res.data.some((item) => {
            return item.id == application;
          });
          // 判断用户是否开启服务云
          let isOpenService = res.data.some((item) => {
            if (item.id === "ace20210219FieldSer") {
              return true;
            }
          });
          sessionStorage.setItem("isOpenService", isOpenService);
          if (application && findapp) {
            //获取菜单
            this.init(application);
            // 获取底部bar
            this.getFootToolByApp(application);
            let filtername = res.data.filter((item) => {
              return item.id == application;
            });
            if (filtername && filtername[0]) {
              this.applicationName = filtername[0].name;
              this.applicationId = filtername[0].id;
              this.navigationStyle = filtername[0].navigationStyle;
              this.logourl = filtername[0].logourl;
              // 使用CCApplication存储应用程序对象
              this.$CCDK.CCApplication.setApplication(filtername[0]);
            }
          } else {
            if (defaultApp && defaultApp[0]) {
              //获取菜单
              this.init(defaultApp[0].id);
              // 获取底部bar
              this.getFootToolByApp(defaultApp[0].id);
              this.applicationName = defaultApp[0].name;
              this.applicationId = defaultApp[0].id;
              this.navigationStyle = defaultApp[0].navigationStyle;
              this.logourl = defaultApp[0].logourl;
              // 使用CCApplication存储应用程序对象
              this.$CCDK.CCApplication.setApplication(defaultApp[0]);
            } else {
              //获取菜单
              this.init(res.data[0].id);
              // 获取底部bar
              this.getFootToolByApp(res.data[0].id);
              this.applicationName = res.data[0].name;
              this.applicationId = res.data[0].id;
              this.navigationStyle = res.data[0].navigationStyle;
              this.logourl = res.data[0].logourl;
              // 使用CCApplication存储应用程序对象
              this.$CCDK.CCApplication.setApplication(res.data[0]);
            }
          }
        }
        // 是否是控制台样式 存入vuex
        this.$store.commit("setNavigationStyle", this.navigationStyle);
        // 导航样式为控制台样式
        if (this.$store.state.navigationStyle) {
          setTimeout(() => {
            this.getConsoleTab(this.applicationId);
          }, 500);
        }
      });
    },
    //当浏览器窗口小于最后一个选项的右边距时截取数组并添加到更多菜单中（窗口缩小）
    deletesurplus() {
      if (this.showMenus.length != 0) {
        let moremenuarr;
        // 判断下最后一个菜单是否是当前查看的
        let tabId = this.getItemApplicationTab();
        if (this.showMenus[this.showMenus.length - 1].id === tabId) {
          // 截取倒数第两个菜单
          if (this.showMenus.length > 1) {
            moremenuarr = JSON.parse(
              JSON.stringify(
                this.showMenus.splice(this.showMenus.length - 2, 1)
              )
            );
          }
        } else {
          // 截取最后一个菜单
          moremenuarr = JSON.parse(
            JSON.stringify(this.showMenus.splice(this.showMenus.length - 1, 1))
          );
        }
        // 此时要判断moremenuarr是否是数组,并且有一条数据
        if (Array.isArray(moremenuarr) && moremenuarr[0]) {
          // 将此菜单放到更多菜单第一个
          this.moreMenus.unshift(moremenuarr[0]);
          // 如果展示的菜单数大于1，关闭在可视范围内展示全部菜单按钮（正常来说此刻有更多菜单，肯定不能展示全部菜单按钮了）
          this.isShowTabAllMenu = false;
        }
      }
    },
    //当浏览器窗口大于最后一个选项且还有剩余空间展示其他选项时截取数组并添加到导航菜单中(窗口变大)
    addsurplus() {
      // moreMenus中只有全部菜单这个标签
      if (this.moreMenus.length <= 1) {
        // 展示全部菜单按钮
        this.isShowTabAllMenu = true;
        return;
      } else {
        let showmenuarr = this.moreMenus.splice(0, 1);
        // 需要判断showmenuarr是否在展示菜单的索引之后，如果在之后，则添加在索引之后，如果在之前，则放到之前
        if (showmenuarr[0].id != "allmenu") {
          // 获取当前选中菜单id
          let currentId = this.getItemApplicationTab();

          // 获取下一项在所有菜单中的索引
          let lastIndex = this.menusIds.indexOf(showmenuarr[0].id);
          // 获取当前选中菜单在所有菜单中的索引
          let currentIndex = this.menusIds.indexOf(currentId);
          // 如果下一项索引在当前选中菜单之后，直接添加到最后
          if (currentIndex < lastIndex) {
            this.showMenus.push(showmenuarr[0]);
          } else {
            // 如果下一项菜单索引在当前菜单之前，则添加到当前选中菜单之前
            let ary = this.showMenus.pop();
            this.showMenus = [...this.showMenus, showmenuarr[0], ary];
          }
          // 展示全部菜单按钮
          this.isShowTabAllMenu = this.moreMenus.length <= 1;
        }
      }
    },
    //计算菜单字符串长度,最大长度是173
    strlen(str) {
      let len = 0;
      let copystr = str || "";
      // 菜单最多展示8个汉字长度
      if (copystr.length > 8) {
        copystr = copystr.substring(0, 7);
      }
      for (let i = 0; i < copystr.length; i++) {
        let c = copystr.charCodeAt(i);
        //单字节加1
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
          len++;
        } else {
          len += 2;
        }
      }
      return len > 173 ? 173 : len;
    },
    //取消或替换临时菜单刷新视图
    canceltemporary() {
      this.moreMenus = [];
      let copyallmenu = JSON.parse(JSON.stringify(this.allMenus));
      this.showMenus = copyallmenu;
      let obj = {}; //全部菜单
      obj.tabName = this.$i18n.t("label.pc.ui.2018.all.tabs");
      obj.id = "allmenu";
      this.moreMenus.push(obj);
      this.clickshowMenu(this.showMenus[0]);
    },
    // tag为{}时（即点击全部菜单时），不执行this.changeshowmenus方法
    /**
     * 1、计算需要替换的菜单个数
     * 2、调用this.handleMenus重新分配showMenus和moreMenus
     * @param {Object} tag：切换的菜单项
     */
    changeshowmenus(tag = {}) {
      if (!tag || !tag.id || !tag.tabName || this.showMenus < 1) return;
      if (this.$store.state.navigationStyle) {
        tag.istemporary = true;
        // 如果存在，不再添加
        let Isincluded = this.showMenus.some((item) => item.id === tag.id);
        if (!Isincluded) {
          this.showMenus.push(tag);
        }
        return;
      }
      // 设置当前菜单选中
      tag.isDefaultLaunchTab = "true";
      // 如果当前showMenus中包含切换项，无需执行下面代码
      if (this.showMenus.some((item) => item.id == tag.id)) return;
      // 菜单剩余的空间是否满足添加该菜单项
      let isAdd = this.$refs.topMenu.isAdd(tag);
      if (isAdd) {
        // 直接添加到最后
        if (!this.menusIds.includes(tag.id)) {
          // 全部菜单中点击
          this.showMenus.push(tag);
          this.allMenus.push(tag);
        } else {
          // 更多菜单点击
          this.showMenus.push(tag);
          // 删除更多菜单中的项
          this.moreMenus = this.moreMenus.filter((item) => item.id !== tag.id);
        }
      } else {
        // 剩余菜单空间不足以放新菜单项
        // 切换菜单项文本长度
        let tagNameLength = this.strlen(tag.tabName);
        // 展示菜单最后一项长度
        let showNameLength = this.strlen(
          this.showMenus[this.showMenus.length - 1].tabName
        );
        // 如果当前点击项小于替换目标项,直接替换
        if (tagNameLength <= showNameLength) {
          // 重分配展示菜单和更多菜单
          this.handleMenus(this.showMenus[this.showMenus.length - 1], tag);
        } else {
          // 如果当前点击项大于替换目标项,需要计算被替换菜单的个数再进行替换
          // 获取菜单距离编辑菜单按钮之间的距离,有70的空余
          if (!this.$refs.topMenu.$refs.moreMenus) return;
          let moremenuright =
            document.body.offsetWidth -
            this.$refs.topMenu.$refs.moreMenus.getBoundingClientRect().right -
            this.topTitleWidth;
          // 菜单项文字共和
          let strLength = this.showMenus[this.showMenus.length - 1].tabName;
          // 被替换的菜单个数
          let strNum = 1;
          let tagLength = this.showMenus.length;
          for (let i = 1; i <= tagLength; i++) {
            if (this.showMenus[tagLength - i].tabName) {
              // 替换该菜单项需要的长度
              let len =
                tag.tabName.pxWidth("normal 14px Robot") -
                strLength.pxWidth("normal 14px Robot");
              // 需要的长度小于现在的长度
              if (moremenuright - 70 > len && len > 0) {
                if (i !== tagLength) {
                  strLength += this.showMenus[tagLength - i - 1].tabName;
                }
                // 得出替换的菜单项个数i+1
                strNum = i + 1;
              }
            }
          }
          // 重分配展示菜单和更多菜单
          // 需要以该菜单项分割showMenus和moreMenus,位置根据当前展示的倒数第二个索引为准（即替换展示菜单中的最后一个）
          this.handleMenus(
            this.showMenus[this.showMenus.length - strNum - 1],
            tag
          );
        }
      }
    },
    /**
     * tabapplication:切换应用程序
     * @param {String} value:应用程序对应的id
     */
    tabapplication(value) {
      // 是否跳转到记录菜单 issave为true时跳转
      sessionStorage.setItem("issave", false);
      this.RefreshSelect = true;
      this.moreMenus = [];
      this.menuprops = value;
      this.getSetupInfo(); //调取接口是否显示同事圈入口
      // 切换菜单清除菜单缓存
      sessionStorage.removeItem("applicationTab");
      localStorage.removeItem("applicationTab");
      //获取菜单,
      this.init(this.menuprops);
      // 获取底部bar
      this.getFootToolByApp(value);
      this.$refs.topMenu.getmymenusetting();
      localStorage.removeItem("clickRightObj");
    },
    //子组件调用刷新视图。
    Refreshapplicationview(id) {
      this.getApplicationTab();
      this.init(id);
      // 获取底部bar
      this.getFootToolByApp(id);
    },
    // 获取菜单
    getApplicationTab(val) {
      LayoutApi.getApplicationTab({
        id: this.$store.state.home.homeApplicationId,
      })
        .then((res) => {
          this.loading = false;
          let filtercalendar = [];
          this.$store.commit("programMenu", res.data);
          this.cacheProgramMenu.push({
            programId: val,
            menuData: this.$store.state.channel.proMenu,
          });
          // 获取本地存储中的applicationTab
          let getItemTabId = this.getItemApplicationTab();
          res.data.forEach((value) => {
            if (this.tabIds.indexOf(value.id) !== -1) {
              value.type = "";
              //任务
            } else if (
              value.id == "acfTargetmodelstdtab" ||
              value.id == "acfTargetstdtab00" ||
              value.id == "acf2013121431foreca"
            ) {
              value.type = "";
            } else if (value.id == "acf20200615task") {
              value.type = "object";
              value.objectApi = "Task";
              value.objId = "task";
              value.prefix = "bfa";
            }
            filtercalendar.push(value);
          });
          this.allMenus = filtercalendar.filter((item) => {
            if (this.isShowCcchat === "true") {
              return item.type != "applauncher" && item.id != "acf000010";
            } else {
              return (
                item.type != "applauncher" &&
                item.id != "acf000010" &&
                item.id != "acf201200141050RgtYi" &&
                item.id != "acf201300143141ebsD8"
              );
            }
          });
          this.showMenus = filtercalendar.filter((item) => {
            if (this.isShowCcchat === "true") {
              return item.type != "applauncher" && item.id != "acf000010";
            } else {
              return (
                item.type != "applauncher" &&
                item.id != "acf000010" &&
                item.id != "acf201200141050RgtYi" &&
                item.id != "acf201300143141ebsD8"
              );
            }
          });
          let Isincluded = this.showMenus.some((item) => {
            return item.id == getItemTabId;
          });
          if (getItemTabId && Isincluded) {
            // 保存选项卡id到sessionStorage
            let Isincludedmenu = this.showMenus.filter(
              (item) => item.id == getItemTabId
            );
            this.startmenu = Isincludedmenu[0];
          } else {
            this.startmenu = res.data[0];
          }
          let obj = {}; //全部菜单
          obj.tabName = this.$i18n.t("label.pc.ui.2018.all.tabs");
          obj.id = "allmenu";
          this.moreMenus = [];
          this.moreMenus.push(obj);
          // 是否跳转到记录菜单 issave为true时跳转
          if (sessionStorage.getItem("issave") === "false") {
            if (this.RefreshSelect) {
              if (!this.$store.state.navigationStyle) {
                this.clickshowMenu(this.showMenus[0]);
                this.$bus.$emit("refareshPage");
              }
            } else {
              if (!this.$store.state.navigationStyle) {
                this.clickshowMenu(this.startmenu[0]);
              }
            }
          }
          if (
            getItemTabId == null ||
            getItemTabId == "" ||
            getItemTabId == "none"
          ) {
            let resData = JSON.parse(JSON.stringify(res.data));
            let firstItem = resData[0];
            // 导航样式为控制台样式
            if (this.$store.state.navigationStyle) {
              this.getConsoleTab(val);
            } else {
              /**
               * 1、如果第一个菜单时自定义页面则强制跳转到主页
               * 2、否则进入到第一个菜单并选中
               */
              if (firstItem.iscustom == "1" && firstItem.type === "page") {
                if (
                  this.$route.path === "/homePageObject/standardPage" ||
                  this.$route.path === "/"
                ) {
                  this.$router.replace("/homePageObject/standardPage");
                }
              } else {
                if (
                  this.$route.path === "/" ||
                  this.$route.path === "/homePageObject/standardPage"
                ) {
                  let defaultM = this.showMenus.find(item => {
                    return item.isDefaultLaunchTab == "true";
                  })
                  if (defaultM.id) {
                    this.clickshowMenu(defaultM);
                  } else {
                    this.clickshowMenu(this.showMenus[0]);
                  }
                }
              }
            }
          }
          // 加载默认菜单、缓存菜单
          if (!this.$store.state.navigationStyle) {
            this.inittabstatus();
          }
          this.RefreshSelect = false;
          this.quicknew(filtercalendar);
        })
        .catch(() => {});
    },
    // 页面初始化
    /**
     * init: 页面初始化，获取应用程序菜单
     * @param {String} val:应用程序id
     *  */
    init(val) {
      this.freshCache()
      this.isCacheProgram = false;
      this.choseProgram = [];
      this.moreMenus = [];
      this.defaultActive = val;
      // 此时不更新本地存储的状态，只更新vuex中的状态
      this.$store.commit("setHomeApplicationId", val);
      this.loading = true;
      // 获取本地存储中的applicationTab
      let appTab = this.getItemApplicationTab();
      // 应用程序对应的菜单 缓存 this.$store.state.channel.proMenu
      if (this.cacheProgramMenu.length != 0) {
        this.cacheProgramMenu.forEach((item) => {
          if (item.programId === val) {
            // 匹配到当前应用程序下的顶部菜单数据
            this.isCacheProgram = true;
            this.choseProgram = item.menuData;
          }
        });

        if (this.isCacheProgram) {
          this.loading = false;
          let filtercalendar = [];
          this.choseProgram.forEach((value) => {
            if (this.tabIds.indexOf(value.id) !== -1) {
              value.type = "";
              //任务
            } else if (
              value.id == "acfTargetmodelstdtab" ||
              value.id == "acfTargetstdtab00" ||
              value.id == "acf2013121431foreca"
            ) {
              value.type = "";
            } else if (value.id == "acf20200615task") {
              value.type = "object";
              value.objectApi = "Task";
              value.objId = "task";
              value.prefix = "bfa";
            }
            filtercalendar.push(value);
          });
          this.allMenus = filtercalendar.filter((item) => {
            if (this.isShowCcchat === "true") {
              return item.type != "applauncher" && item.id != "acf000010";
            } else {
              return (
                item.type != "applauncher" &&
                item.id != "acf000010" &&
                item.id != "acf201200141050RgtYi" &&
                item.id != "acf201300143141ebsD8"
              );
            }
          });
          this.showMenus = filtercalendar.filter((item) => {
            if (this.isShowCcchat === "true") {
              return item.type != "applauncher" && item.id != "acf000010";
            } else {
              return (
                item.type != "applauncher" &&
                item.id != "acf000010" &&
                item.id != "acf201200141050RgtYi" &&
                item.id != "acf201300143141ebsD8"
              );
            }
          });
          let Isincluded = this.showMenus.some((item) => {
            return item.id == appTab;
          });
          if (appTab && Isincluded) {
            // 保存选项卡id到sessionStorage
            let Isincludedmenu = this.showMenus.filter(
              (item) => item.id == appTab
            );
            this.startmenu = Isincludedmenu[0];
          } else {
            this.startmenu = this.choseProgram[0];
          }
          let obj = {}; //全部菜单
          obj.tabName = this.$i18n.t("label.pc.ui.2018.all.tabs");
          obj.id = "allmenu";
          this.moreMenus.push(obj);
          // 是否跳转到记录菜单 issave为true时跳转
          if (sessionStorage.getItem("issave") === "false") {
            if (this.RefreshSelect) {
              this.clickshowMenu(this.showMenus[0]);
              this.$bus.$emit("refareshPage");
            } else {
              this.clickshowMenu(this.startmenu[0]);
            }
          }
          if (appTab == null || appTab == "" || appTab == "none") {
            let resData = JSON.parse(JSON.stringify(this.choseProgram));
            let firstItem = resData[0];
            // 导航样式为控制台样式
            if (this.$store.state.navigationStyle) {
              this.getConsoleTab(val);
            } else {
              /**
               * 1、如果第一个菜单时自定义页面则强制跳转到主页
               * 2、否则进入到第一个菜单并选中
               */
              if (firstItem.iscustom == "1" && firstItem.type === "page") {
                if (
                  this.$route.path === "/homePageObject/standardPage" ||
                  this.$route.path === "/"
                ) {
                  this.$router.replace("/homePageObject/standardPage");
                }
              } else {
                if (
                  this.$route.path === "/" ||
                  this.$route.path === "/homePageObject/standardPage"
                ) {
                  this.clickshowMenu(this.showMenus[0]);
                }
              }
            }
          }
          if (!this.$store.state.navigationStyle) {
            this.inittabstatus();
          }
          this.RefreshSelect = false;
          this.quicknew(filtercalendar);
        } else {
          this.getApplicationTab(val);
        }
      } else {
        this.getApplicationTab(val);
      }
    },
    //快速新建方法
    async quicknew(filtercalendar) {
      let baseAddList = [
        {
          tabName: this.$i18n.t("label.task"),
          // type: "task",
          objId: "task",
          objectApi: "Task",
          prefix: "bfa",
          iconSrc: "#icon-cloudtab5_norm",
          tabStyle: "cloudtab5_norm",
          isquickcreated: "true",
          type: "object",
        },
        {
          tabName: this.$i18n.t("label.event"),
          // type: "event",
          objId: "event",
          objectApi: "Event",
          prefix: "bef",
          iconSrc: "#icon-cloudtab6_norm",
          tabStyle: "cloudtab6_norm",
          isquickcreated: "true",
          type: "object",
        },
        {
          tabName: this.$i18n.t("label.recordphone"),
          isCall: true,
          // type: "task",
          objId: "task",
          objectApi: "Task",
          prefix: "bfa",
          iconSrc: "#icon-logCall",
          isquickcreated: "true",
          type: "object",
        },
      ];
      let arr = filtercalendar.filter(
        (item) => item.type != "applauncher" && item.id != "acf000010"
      );
      let res = await getobjectlist();
      if (res.result == true) {
        let taskName = res.data.filter((item) => {
          return item.id == "task";
        });
        let eventName = res.data.filter((item) => {
          return item.id == "event";
        });
        if (taskName.length > 0) {
          baseAddList[0].tabName = taskName[0].labelname;
        }
        if (eventName.length > 0) {
          baseAddList[1].tabName = eventName[0].labelname;
        }
        this.quickAdd = baseAddList.concat(arr);
      }
    },
    /**
     * 切换展示菜单选项卡：防抖最后一次有效（这样有延迟效果）
     * 标准对象菜单，自定义对象菜单，自定义页面菜单，web菜单
     * @param {Object} tag:当前点击菜单信息
     */
    clickshowMenu: debounce(function (tag = {}) {
      if (!tag || !tag.id) return;
      this.freshCache(tag)
      // lightning版本自定义页面
      if (
        tag.lightningpageId &&
        tag.lightningpageId !== undefined &&
        tag.lightningpageId !== null &&
        tag.lightningpageId !== "null" &&
        tag.lightningpageId !== ""
      ) {
        VueCookies.set("customePageUrl", tag.pageUrl, { sameSite: 'Strict' });
        // 清空菜单选中样式
        this.clearshowMenu();
        this.clearmoreMenu();
        // 设置选中样式
        tag.isDefaultLaunchTab = "true";
        // 存储选中菜单id
        this.setItemApplicationTab(tag.id);
        // lightningpageId判断页面
        if (tag.lightningpageId.indexOf("#lightning") != -1) {
          this.$router.push({
            path: "/injectionComponent",
            query: {
              page: tag.lightningpageId.split("#")[0],
              button: "Home",
            },
          });
        } else {
          this.$router.push(`/custome/customePage`);
        }
      } else if (tag.type == "page") {
        let symbol;
        if (tag.url.indexOf("?") != -1) {
          symbol = "&";
        } else {
          symbol = "?";
        }
        window.open(
          `${this.userurl}${
            tag.url
          }${symbol}version=version2018&binding=${this.$CCDK.CCToken.getToken()}`
        );
      } else if (tag.type == "web") {
        if (
          tag.url.indexOf("home_mainPage.action") != -1 ||
          tag.url.indexOf("query.action") != -1
        ) {
          this.$message("请切换至横纵版使用。");
        } else {
          this.clearshowMenu();
          this.clearmoreMenu();
          // 暂时不需要设置
          // tag.isDefaultLaunchTab = "true";
          // this.setItemApplicationTab(tag.id)
          window.open(tag.url);
        }
      } else if (tag.type == "script") {
        // 自定义脚本菜单
        let funText = tag.function_code ? tag.function_code : tag.functionCode;
        let newFun = Function(funText);
        newFun();
      } else {
        let show2menu = this.moreMenus.some(item => {
          return item.id == tag.id;
        })
        // 如果在“更多”菜单，添加到
        if (show2menu) {
          this.clickmoreMenu(tag, true);
          return;
        }
        this.clearshowMenu();
        this.clearmoreMenu();
        // 设置选中样式
        tag.isDefaultLaunchTab = "true";
        // 存储选中菜单id
        this.setItemApplicationTab(tag.id);
        this.showMenus.forEach((item) => {
          if (item.id === tag.id) {
            item.isDefaultLaunchTab = "true";
            // 判断是否通用对象 1 表示不是自定义对象
            if (item.id == "p1020207266DDC2nZ3Jp") {
              this.$router.push({
                path: `/actualworklist/listviews`,
              });
              return;
            } else if (item.id == "acfganttchart001") {
              this.$router.push({
                path: `/gantt/gantt`,
              });
            } else if (item.id === "acf20207266DDCLKAIQ1") {
              // 知识文章
              this.$router.push({
                path: `/Knowledgearticles/CloudccKArticle/cloudcc_k_article/k02`,
              });
            } else if (item.id === "acfShiftstdtab00") {
              this.$router.push({
                path: `/commonObjects/views/plan/${item.objectApi}/${item.objId}/${item.prefix}`,
              });
            } else if (item.type === "object") {
              this.$router.push({
                path: `/commonObjects/views/table/${item.objectApi}/${item.objId}/${item.prefix}`,
              });
            } else if (item.id === "acf2020340C93FD7chBP") {
              // 标情发现
              this.$router.push({
                path: `/moodFound`,
              });
              //主页
            } else if (item.id == "acf2021070mcforms") {
              // 市场活动 表单
              this.$router.push({
                path: `/marketForm/marketForm`,
              });
            } else if (item.id == "acf2021070mcsocial") {
              // 市场云社交媒体
              this.$router.push({
                path: `/social/social`,
              });
            } else if (item.id === "acf100000") {
              this.$router.push({
                path: `/reportObject/reportList`,
              });
            } else if (item.id === "acf000001") {
              this.$router.push({
                path: `/homePageObject`,
              });
            } else if (item.id === "acf100009") {
              // this.getDashboardList();
              this.$router.push({
                path: `/dashboardObject`,
              });
            } else if (
              //日历
              item.id === "acf20200615event" ||
              item.id === "acf20208AD53CC8sLvgf"
            ) {
              this.$router.push({
                path: `/CalendarHeader`,
              });
              //文件
            } else if (item.id === "acf20171206fileUjdys") {
              this.$router.push({
                path: `/ccFileCenter`,
              });
              //邮件
            } else if (item.id === "acf20191021FeiAUjdys") {
              this.$router.push({
                path: `/relation`,
              });
              //同事圈
            } else if (item.id === "acf201200141050RgtYi") {
              this.$router.push({
                path: `/colleagueCircle`,
              });
            } else if (item.id == "acf2015030630xsld") {
              this.$router.push({
                path: `/commonObjects/hopper`,
              });
            } else if (item.id == "acf2020110PriceBooks") {
              this.$router.push({
                path: `/priceManual/price-manual`,
              });
            } else if (item.id == "acfTargetmodelstdtab") {
              this.$router.push({
                path: `/targetModel/index`,
              });
            } else if (item.id == "acfTargetstdtab00") {
              this.$router.push({
                path: `/target/index`,
              });
            } else if (item.id == "acf20201216smslist") {
              this.$router.push({
                path: `/noteSms/noteSmsList`,
              });
            } else if (item.id === "acf202011leadmksea") {
              // 公海池部分
              // 潜在客户公海池
              this.$router.push({
                path: `/clientPool/cloudcclead/lead/004`,
              });
            } else if (item.id === "acf202011accmksea") {
              // 客户公海池
              this.$router.push({
                path: `/clientPool/Account/account/001`,
              });
              //派工平台
            } else if (item.id === "acfFieldServiceSystb") {
              if (this.$store.state.navigationStyle) {
                // 当应用程序为控制台菜单时，点击派工平台需要打开一级菜单
                let menuObj = {
                  name: item.tabName,
                  id: item.id,
                  routerName: "consoleDispatchingUnits",
                };
                generatelevel1Menu(this, item, menuObj);
                this.$router.push({
                  path:`/commonObjects/console-multi-screen/console-dispatchingUnits`,
                  query: {
                    t: new Date().getTime()
                  }
                });
              } else {
                this.$router.push({
                  path: `/dispatchingUnits`,
                });
              }
              //小组
            } else if (item.id === "acf20190807sgeAUjdys") {
              this.$router.push({
                path: `/groupObject/groupList`,
              });
              //人员资源日历
            } else if (item.id === "acf20219D0E7E25g4uUv") {
              this.$router.push({
                path: `/resoursecalendar/resoursecalendar`,
              });
            }
            // 预测
            else if (item.id === "acf2013121431foreca") {
              this.$router.push({
                path: `/forecast/forecast`,
              });
              //伙伴云-订货平台
            } else if (item.id === "acf20210713orderpro1") {
              this.$router.push({
                path: `/ordering`,
              });
            } // 系统设置页（写死发布）
            else if (item.id === "systemSettings") {
              this.$router.push({
                path: `/systemSettings`,
              });
              //聊天
            } else if (item.id === "acfConversationTab00") {
              // 导航样式为控制台样式打开一级菜单
              // 菜单所需数据
              let menuObj = {
                name: item.tabName,
                id: item.id,
                // 是否展示tab关闭按钮
                isShowClose: false,
                // tab下拉操作项
                tabAction: [
                  {
                    name: "刷新选项卡",
                    id: "refresh",
                  },
                  {
                    name: "取消固定选项卡",
                    id: "cancelFixed",
                  },
                  {
                    name: "自定义选项卡",
                    id: "custom",
                  },
                ],
                routerPath: "/customerWorkbench/workbench",
              };
              generatelevel1Menu(this, item, menuObj);
              this.$router.push({
                path: `/customerWorkbench/workbench`,
              });
            }
            //控制台
            else if (item.id === "acfServiceControlTab") {
              this.$router.push({
                path: `/commonObjects/views/table/cloudcccase/201100000005024CxOSz/008?isConsole=true`,
              });
            } else {
              this.$router.push({
                path: `/`,
              });
            }
          }
        });
        this.setItemApplicationTab(tag.id);
      }
      localStorage.removeItem("clickRightObj");
    }, 500),
    //切换更多菜单选项卡
    clickmoreMenu(tag, isAccountOpening) {
      // tag为{}时（即点击全部菜单时）
      if (!tag || !tag.id) return;
      // 记录当前操作的菜单项，持久化，防止刷新丢失
      if (tag.id !== "allmenu") {
        sessionStorage.setItem("currentMenu", JSON.stringify(tag));
      }
      // lightning版本自定义页面
      if (
        tag.lightningpageId !== undefined &&
        tag.lightningpageId !== null &&
        tag.lightningpageId !== "null" &&
        tag.lightningpageId !== ""
      ) {
        VueCookies.set("customePageUrl", tag.pageUrl, { sameSite: 'Strict' });
        if (this.$route.path === "/custome/customePage") {
          this.$bus.$emit("changeCustomePageUrl", tag.pageUrl);
        } else {
          // lightningpageId判断页面
          if (tag.lightningpageId.indexOf("#lightning") != -1) {
            this.clearshowMenu();
            this.clearmoreMenu();
            tag.isDefaultLaunchTab = "true";
            this.setItemApplicationTab(tag.id);
            this.$router.push({
              path: "/injectionComponent",
              query: {
                page: tag.lightningpageId.split("#")[0],
                button: "Home",
              },
            });
          } else {
            this.$router.push(`/custome/customePage`);
          }
        }
      } else if (tag.id != "allmenu") {
        if (tag.type == "page") {
          let symbol;
          if (tag.url.indexOf("?") != -1) {
            symbol = "&";
          } else {
            symbol = "?";
          }
          window.open(
            `${this.userurl}${
              tag.url
            }${symbol}version=version2018&binding=${this.$CCDK.CCToken.getToken()}`
          );
        } else if (tag.type == "web") {
          window.open(tag.url);
        } else {
          this.clearshowMenu();
          this.clearmoreMenu();
          // 替换菜单项，重分配showMenus和moreMenus
          this.changeshowmenus(tag);
          this.setItemApplicationTab(tag.id);
          this.allMenus.forEach((item) => {
            if (item.id == tag.id) {
              item.isDefaultLaunchTab = "true";
              if (item.id == "p1020207266DDC2nZ3Jp") {
                this.$router.push({
                  path: `/actualworklist/listviews`,
                });
                return;
              } else if (item.id == "acfganttchart001") {
                this.$router.push({
                  path: `/gantt/gantt`,
                });
              } else if (item.id === "acf20207266DDCLKAIQ1") {
                // 知识文章
                this.$router.push({
                  path: `/Knowledgearticles/CloudccKArticle/cloudcc_k_article/k02`,
                });
              } else if (item.id === "acfShiftstdtab00") {
                this.$router.push({
                  path: `/commonObjects/views/plan/${item.objectApi}/${item.objId}/${item.prefix}`,
                });
              } else if (item.type === "object") {
                this.$router.push({
                  path: `/commonObjects/views/table/${item.objectApi}/${item.objId}/${item.prefix}`,
                });
              } else if (item.id === "acf2020340C93FD7chBP") {
                // 标情发现
                this.$router.push({
                  path: `/moodFound`,
                });
              } else if (item.id == "acf2021070mcforms") {
                // 市场活动 表单
                this.$router.push({
                  path: `/marketForm/marketForm`,
                });
              } else if (item.id == "acf2021070mcsocial") {
                // 市场云社交媒体
                this.$router.push({
                  path: `/social/social`,
                });
              } else if (item.id === "acf100000") {
                this.$router.push({
                  path: `/reportObject/reportList`,
                });
              } else if (item.id === "acf000001") {
                if (
                  this.$store.state.home.homeApplicationId ===
                    "ace2020D253B3984Or6Q" &&
                  this.$cookies.get("clickToPage") == "standardPage"
                ) {
                  this.$router.push({
                    path: `/projectManagement`,
                  });
                } else {
                  this.$router.push({
                    path: `/homePageObject`,
                  });
                }
              } else if (item.id === "acf100009") {
                this.$router.push({
                  path: `/dashboardObject`,
                });
              }
              // else if (item.id === "acf100009") {
              //   this.getDashboardList();
              // }
              else if (
                item.id === "acf20200615event" ||
                item.id === "acf20208AD53CC8sLvgf"
              ) {
                this.$router.push({
                  path: `/CalendarHeader`,
                });
              } else if (item.id === "acf20171206fileUjdys") {
                this.$router.push({
                  path: `/ccFileCenter`,
                });
              } else if (item.id === "acf20191021FeiAUjdys") {
                this.$router.push({
                  path: `/relation`,
                });
              } else if (item.id === "acf201200141050RgtYi") {
                this.$router.push({
                  path: `/colleagueCircle`,
                });
              } else if (item.id == "acf2015030630xsld") {
                this.$router.push({
                  path: `/commonObjects/hopper`,
                });
              } else if (item.id == "acf2020110PriceBooks") {
                this.$router.push({
                  path: `/priceManual/price-manual`,
                });
              } else if (item.id == "acfTargetmodelstdtab") {
                this.$router.push({
                  path: `/targetModel/index`,
                });
              } else if (item.id == "acfTargetstdtab00") {
                this.$router.push({
                  path: `/target/index`,
                });
              } else if (item.id == "acf20201216smslist") {
                this.$router.push({
                  path: `/noteSms/noteSmsList`,
                });
              } else if (item.id === "acf202011leadmksea") {
                // 公海池部分
                // 潜在客户公海池
                this.$router.push({
                  path: `/clientPool/cloudcclead/lead/004`,
                });
              } else if (item.id === "acf202011accmksea") {
                // 客户公海池
                this.$router.push({
                  path: `/clientPool/Account/account/001`,
                });
              } else if (item.id === "acfFieldServiceSystb") {
                //派工平台
                this.$router.push({
                  path: `/dispatchingUnits`,
                });
                //小组
              } else if (item.id === "acf20190807sgeAUjdys") {
                this.$router.push({
                  path: `/groupObject/groupList`,
                });
              } else if (item.id === "acf2013121431foreca") {
                //预测
                this.$router.push({
                  path: `/forecast/forecast`,
                });
              } else if (item.id === "acf20219D0E7E25g4uUv") {
                this.$router.push({
                  path: `/resoursecalendar/resoursecalendar`,
                });
              } //伙伴云-订货平台
              else if (item.id === "acf20210713orderpro1") {
                this.$router.push({
                  path: `/ordering`,
                });
              } // 系统设置页（写死发布）
              else if (item.id === "systemSettings") {
                this.$router.push({
                  path: `/systemSettings`,
                });
                //聊天
              } else if (item.id === "acfConversationTab00") {
                this.$router.push({
                  path: `/customerWorkbench/workbench`,
                });
              }
              //控制台
              else if (item.id === "acfServiceControlTab") {
                this.$router.push({
                  path: `/commonObjects/views/table/cloudcccase/201100000005024CxOSz/008?isConsole=true`,
                });
              } else {
                this.$router.push({
                  path: `/`,
                });
              }
            }
          });
        }
      } else {
        this.$refs.topMenu.showallmenu();
      }
      localStorage.removeItem("clickRightObj");
      // 完成页面跳转后moreMenus中的tag
      // 开户菜单栏执行一下代码
      if (!isAccountOpening) {
        setTimeout(() => {
          this.moreMenus.forEach((item, index) => {
            if (item.id == tag.id && tag.id != "allmenu") {
              this.moreMenus.splice(index, 1);
            }
          });
        }, 300);
      }
    },
    //清除选项卡选中状态
    clearshowMenu() {
      this.showMenus.forEach((item) => {
        item.isDefaultLaunchTab = "false";
      });
    },
    clearmoreMenu() {
      this.moreMenus.forEach((item) => {
        item.isDefaultLaunchTab = "false";
      });
    },
    //添加完组件后定位到最新位置
    scrollPosition() {
      setTimeout(() => {
        var div = document.getElementById("full-screen");
        div.scrollTop = div.scrollHeight;
      }, 300);
    },
    /**
     * 更改右侧bar的状态
     */
    changeRightBarStatus() {
      if (this.rightBarStatus) {
        window.$CCDK.CCSide.close();
      } else {
        window.$CCDK.CCSide.open();
      }
    },
  },
};
</script>


<style lang="scss">
@import "./style/noScopedStyle.scss";
</style>
<style lang="scss" scoped>
@import "./style/scopedStyle.scss";
</style>
