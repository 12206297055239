<template>
  <label for="uploadFile" @click="clickFile">
    <svg
      class="uploadfileicon" aria-hidden="true" slot="reference">
      <use href="#icon-file"></use>
    </svg>
    <input type="file" class="hide" ref="imgDom" @change="fileChange" />
  </label>
</template>

<script>
/*
 * 发送文件消息
 */
  import WebIM from "@/utils/WebIM";
  // import Config from "../../config/index";

  import { mapActions } from "vuex";
  export default {
    data() {
      return {
      };
	},
    methods: {
    /*
     * 文件超出限制
     */
		warning(){
          this.$message.warning(this.$i18n.t("vue_label_file_size_limit_tip"));
		},
      ...mapActions(["sendFileMessage"]),
      /*
       * 点击文件图标
       */
      clickFile() {
        let img = this.$refs.imgDom;
        img && img.click();
      },
      /*
       * 上传文件
       * TODO 当前username、及type不是从pams里取
       */
      fileChange(e) {
        let isRoom = this.type == "chatroom" || this.type == "groupchat";
        let file = WebIM.utils.getFileUrl(e.target);
        if (!file.filename) {
          this.$refs.imgDom.value = null;
          return false;
        }

        let obj = {
          chatType: this.$store.state.list.isChatID.isGroup ? "group" : "contact",
          chatId: this.$store.state.list.isChatID.imId, // TODO 这里在群里面应该取的是ID，后期跟进
          file: file,
          roomType: isRoom,
          callback: () => {
            this.$refs.imgDom.value = null;
          }
        };
        if (file.data.size / 1024 / 1024 >= 10) {
			this.warning();
        }else{
        this.sendFileMessage(obj);
		}
      }
    },
    props: [
      // 聊天类型 contact, group, chatroom
      "type", 
      // 选中的聊天对象
      "chatId" 
    ]
  };
</script>
<style scoped>
  .hide {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    filter: alpha(opacity=0);
    opacity: 0;
    cursor: inherit;
    display: none;
  }

  .uploadfileicon {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
</style>
