<template>
  <div class="right-bar">
    <cc-renderer
      style="margin: 0"
      :componentData="rightComponentData"
      :canvasStyleData="rightCanvasStyleData"
    />
  </div>
</template>

<script>
export default {
  name: "rightBar",
  props: {},
  data() {
    return {
      // 右侧渲染器数据
      rightComponentData: [],
      rightCanvasStyleData: {},
    };
  },
  watch: {},
  mounted() {},
  methods: {
    // 获取自定义页面
    getCustomPageDetail(pageApi) {
      let parameter = {
        pageApi: pageApi,
      };
      return this.$devHttp.postFormat(
        `${window.Glod["ccex-dev"]}/custom/pc/1.0/post/detailCustomPage`,
        parameter
      );
    },
    async getRightCustomPageDetail(pageApi) {
      let res = await this.getCustomPageDetail(pageApi);
      if (res.returnCode == 200) {
        this.rightComponentData = JSON.parse(res.data.pageContent);
        this.rightCanvasStyleData = JSON.parse(res.data.canvasStyleData);
      } else {
        this.$message.warning(res.returnInfo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right-bar {
  height: 100%;
  overflow: auto;
}
</style>