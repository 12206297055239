<template>
  <div class="list-header">
      <search></search>
      <addChat></addChat>
  </div>
</template>

<script>
/*
 *群策头部输入框整体功能
 */
import search from './components/search'
import addChat from './components/addChat'
export default {
    name:'list-header',
    components:{
        search,
        addChat
    }
}
</script>

<style lang='scss'>
.list-header{
    margin: 20px 11px 19px 12px !important;
    overflow: hidden;
}
</style>