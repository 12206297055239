<template>
  <div class="list-content">
    <div class="item-none" v-if="chatList.length == 0 && isSearch == false">
      <p>{{ $t("label.dashboard.noRecord") }}</p>
      <button class="add-chat" @click="addGroup">
        {{ $t("vue_label_chat_create_discussion_now") }}
      </button>
    </div>
    <div
      class="list-long"
      v-if="chatList.length > 0 && isSearch == false && bindingTrue == true"
    >
      <div
        class="item"
        v-for="(item, index) in chatList"
        :key="item.id"
        @contextmenu.prevent="clickRightKey(index)"
        @click="contactTalk(item, index)"
        @mouseover="mouseOver(index)"
        @mouseleave="mouseLeave(index)"
        :style="{
          background:
            isMouceHover == index
              ? active
              : isClickIndex == index
              ? '#EAF0F6'
              : '#fff',
        }"
      >
        <div class="image">
          <div class="noRead" v-show="item.noRead > 0 && item.noRead <= 99">
            {{ item.noRead }}
          </div>
          <div class="noRead" v-show="item.noRead > 99">99+</div>
          <img
            v-if="item.type == 'user'"
            :src="imgurlBefore + item.id + imgUrlAfter"
            alt
            class="headerimg"
          />
          <svg
            class="icon headerimg"
            aria-hidden="true"
            v-if="
              (item.type == 'group' &&
                !item.isStrategyGroup &&
                !item.isOpportunityGroup) ||
              (item.type == 'group' &&
                item.isStrategyGroup == false &&
                item.isOpportunityGroup == false)
            "
          >
            <use href="#icon-group"></use>
          </svg>
          <svg
            class="icon headerimg"
            aria-hidden="true"
            v-if="item.type == 'group' && item.isStrategyGroup == true"
          >
            <use href="#icon-cloudtab11_norm"></use>
          </svg>
          <svg
            class="icon headerimg"
            aria-hidden="true"
            v-if="item.type == 'group' && item.isOpportunityGroup == true"
          >
            <use href="#icon-cloudtab12_norm"></use>
          </svg>
        </div>
        <div class="word-des">
          <div class="title">
            <!-- <div
              v-if="item.isStrategyGroup || item.isOpportunityGroup"
              class="name"
              :style="{ width: item.time.length > 5 ? '30%' : '45%' }"            >
              {{ item.name }}
            </div> -->
            <div
              class="name"
              :style="{ width: item.time.length > 5 ? '65%' : '75%' }"
            >
              {{ item.name }}
            </div>
            <div class="date">{{ item.time }}</div>
            <!-- <div
              class="qunce"
              v-if="item.type == 'group' && item.isStrategyGroup == true"
            >
              {{$t('vue_label_chat_customer_group_strategy')}}
            </div>
            <div
              class="qunce"
              v-if="item.type == 'group' && item.isOpportunityGroup == true"
            >
              {{$t('vue_label_chat_opportunity')}}
            </div> -->
          </div>
          <div class="chat-content">{{ item.lastWord }}</div>
        </div>
        <div class="rightButton" v-show="currentIndex == index">
          <!-- <div class="right-item">置顶对话</div> -->
          <div class="right-item" @click="clearMessage">
            {{ $t("vue_label_chat_empty_message") }}
          </div>
          <div class="right-item" @click.stop="dialogVisible = true">
            {{ $t("label.emailobject.delete") }}
          </div>
        </div>
      </div>
      <el-dialog
        custom-class="delete-dialog"
        :visible.sync="dialogVisible"
        :modal="false"
        :lock-scroll="true"
      >
        <span>
          {{ $t("vue_label_chat_deleting_chat") }}
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="closedialog">{{
            $t("label.sure.delete")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="search-data" v-if="isSearch == true">
      <div
        class="search-no-result"
        v-if="
          contactsList.length == 0 &&
          groupList.length == 0 &&
          strategyGroup.length == 0 &&
          opportunityGroup.length == 0
        "
      >
        {{ $t("vue_label_chat_no_search_results") }}
      </div>
      <div class="contacts" v-if="contactsList.length > 0">
        <div class="title">{{ $t("label.exchange.sync.state.contact") }}</div>
        <div
          class="item"
          v-for="(item, index) in contactsList"
          :key="index"
          @click="newContactTalk(item)"
        >
          <div class="image">
            <img :src="imgurlBefore + item.id + imgUrlAfter" alt />
          </div>
          <div class="name">{{ item.name }}</div>
        </div>
        <div
          class="get-more"
          @click="searchContactMore"
          v-show="isMore && contactsList.length > 1"
        >
          {{ $t("label.viewall") }}
        </div>
      </div>
      <div class="group-chat" v-if="groupList.length > 0">
        <div class="title">{{ $t("vue_label_chat_group_chat") }}</div>
        <div
          class="item"
          v-for="(item, index) in groupList"
          :key="index"
          @click="newGroupTalk(item)"
        >
          <div class="image">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-group"></use>
            </svg>
          </div>
          <div class="name">
            <div class="group-name">{{ item.name }}</div>
            <div class="contain" v-show="item.searchMatchingMembers != ''">
              {{ $t("contain") }}：
              <span>{{ item.searchMatchingMembers }}</span>
            </div>
          </div>
        </div>
        <div
          class="get-more"
          @click="searchGroupMore"
          v-show="groupIsMore && groupList.length > 1"
        >
          {{ $t("label.viewall") }}
        </div>
      </div>
      <!-- 客户群策列表 -->
      <div class="group-chat" v-if="strategyGroup.length > 0">
        <div class="title">
          {{ $t("vue_label_chat_customer_group_strategy") }}
        </div>
        <div
          class="item"
          v-for="(item, index) in strategyGroup"
          :key="index"
          @click="newstrategyGroupTalk(item)"
        >
          <div class="image">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-cloudtab11_norm"></use>
            </svg>
          </div>
          <div class="name">
            <div class="group-name">{{ item.name }}</div>
            <div class="contain" v-show="item.searchMatchingMembers != ''">
              {{ $t("contain") }}：
              <span>{{ item.searchMatchingMembers }}</span>
            </div>
          </div>
        </div>
        <div
          class="get-more"
          @click="searchQunCeMore"
          v-show="qunCeIsMore && strategyGroup.length > 1"
        >
          {{ $t("label.viewall") }}
        </div>
      </div>
      <!-- 业务机会群策列表 -->
      <div class="group-chat" v-if="opportunityGroup.length > 0">
        <div class="title">{{ $t("vue_label_chat_opportunity") }}</div>
        <div
          class="item"
          v-for="(item, index) in opportunityGroup"
          :key="index"
          @click="newOpportunityGroupTalk(item)"
        >
          <div class="image">
            <svg class="icon headerimg" aria-hidden="true">
              <use href="#icon-cloudtab12_norm"></use>
            </svg>
          </div>
          <div class="name">
            <div class="group-name">{{ item.name }}</div>
            <div class="contain" v-show="item.searchMatchingMembers != ''">
              {{ $t("contain") }}：
              <span>{{ item.searchMatchingMembers }}</span>
            </div>
          </div>
        </div>
        <div
          class="get-more"
          @click="searchOpporMore"
          v-show="opporIsMore && opportunityGroup.length > 1"
        >
          {{ $t("label.viewall") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 群策聊天框左侧列表
 */
import job from "@/views/chat/js/request.js";
import { mapActions } from "vuex";
import VueCookies from "vue-cookies";

export default {
  name: "list-content",
  data() {
    return {
      //头像请求
      imgurlBefore: `${localStorage.getItem(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=`,
      imgUrlAfter: `&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`,
      //判断登录是否成功
      bindingTrue: false,
      //获取的一对一联系人的列表
      contactsList: [],
      //获取的群组的列表
      groupList: [],
      //机会群策列表显示查看更多
      opporIsMore: true,
      //获取机会群策列表
      opportunityGroup: [],
      //获取客户群策列表
      strategyGroup: [],
      //判断是否在搜索联系人状态
      isSearch: false,
      //搜索框关键字
      inputValue: "",
      //显示添加成员页面
      isShowAdd: false,
      //当前右键的项目
      currentIndex: null,
      //当前点击的项目
      isClickIndex: null,
      //删除对话提示弹框绑定变量
      dialogVisible: false,
      //用户列表显示查看更多
      isMore: true,
      //群聊列表显示查看更多
      groupIsMore: true,
      //客户群策列表显示查看更多
      qunCeIsMore: true,
      //鼠标移入事件样式
      active: [],
      isMouceHover: null,
    };
  },
  watch: {
    isClickIndex(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$Bus.$emit("change-click-member", "change");
      }
    },
  },
  async mounted() {
    this.imgurlBefore = `${localStorage.getItem(
      "domainName"
    )}/chatterzone.action?m=showPhoto&userid=`;
    this.imgUrlAfter = `&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`;
    this.$Bus.$off("change-image-top");
    this.$Bus.$on("change-image-top", this.ebFn1);
    //创建群策，群与人员都存在
    this.$Bus.$on("create-strategy-allexist",this.ebFn2);
    //创建群策，群存在人员不存在
    this.$Bus.$on("create-strategy-groupexist", this.ebFn3);
    //创建群策，群与人员都不存在
    this.$Bus.$on("create-strategy-allnotexist", this.ebFn4);
    this.$Bus.$on("deliver-input-value", this.ebFn5);
    this.$Bus.$on("deliver-input-isSelect", this.ebFn6);
    this.$Bus.$on("show-chat-list", this.ebFn7);
    if (this.chatList.length > 0) {
      this.$store.commit("changeListDate");
    }
    this.$Bus.$on("deliver-current-number", this.ebFn8);
    this.$Bus.$on("changePosition", this.ebFn9);
    this.$Bus.$on("changePositionAdd", this.ebFn10);
    this.$Bus.$on("deliver-add-button", this.ebFn11)
    document.addEventListener("click", this.clickFn);
  },
  beforeDestroy() {
    this.$Bus.$off("change-image-top", this.ebFn1);
    //创建群策，群与人员都存在
    this.$Bus.$off("create-strategy-allexist",this.ebFn2);
    //创建群策，群存在人员不存在
    this.$Bus.$off("create-strategy-groupexist", this.ebFn3);
    //创建群策，群与人员都不存在
    this.$Bus.$off("create-strategy-allnotexist", this.ebFn4);
    this.$Bus.$off("deliver-input-value", this.ebFn5);
    this.$Bus.$off("deliver-input-isSelect", this.ebFn6);
    this.$Bus.$off("show-chat-list", this.ebFn7);
    this.$Bus.$off("deliver-current-number", this.ebFn8);
    this.$Bus.$off("changePosition", this.ebFn9);
    this.$Bus.$off("changePositionAdd", this.ebFn10);
    this.$Bus.$off("deliver-add-button", this.ebFn11)
    document.removeEventListener("click", this.clickFn);
  },
  computed: {
    chatList() {
      return this.$store.state.list.chatList;
    },
  },
  methods: {
    ...mapActions(["changeInitialMedia"]),
    ebFn1() {
      this.imgurlBefore = `${VueCookies.get(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=`;
      this.imgUrlAfter = `&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`;
    },
    ebFn2 (item) {
      this.newstrategyGroupTalk(item);
      this.$Bus.$emit("open-chat-window", true);
    },
    ebFn3(item) {
      this.newstrategyGroupTalk(item);
      let groupId = item.id;
      let option = {
        users: [JSON.parse(localStorage.getItem("userInfo")).userId],
        groupId: [groupId],
        success: (respData) => {
          this.invitation(
            groupId,
            localStorage.getItem("myid"),
            "member",
            respData.timestamp
          );
        },

        error: (err) => {
          this.$message({
            showClose: true,
            message: err,
            type: "error",
            duration: 3000,
          });
        },
      };
      this.$imconn.inviteToGroup(option);
    },
    ebFn4(item, id) {
      this.createGroupH(
        item.groupIdArr,
        item.groupName,
        item.groupNames,
        item.userIdArr,
        id,
        item.strategyType
      );
    },
    ebFn5(val) {
      this.inputValue = val;
      if (this.inputValue) {
        this.isSearch = true;
        this.isMore = true;
        this.groupIsMore = true;
        this.qunCeIsMore = true;
        this.getSearchUserList(3);
        this.getSearchGroupList(3);
        this.getSearchStrategyGroup(3);
        this.getSearchOpporGroup(3);
      } else {
        this.groupList = [];
        this.contactsList = [];
        this.strategyGroup = [];
      }
    },
    ebFn6(val) {
      this.isSearch = val;
    },
    ebFn7() {
      this.imgurlBefore = `${localStorage.getItem(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=`;
      this.bindingTrue = true;
    },
    ebFn8(val) {
      // this.currentIndex = val;
      this.isClickIndex = val;
    },
    ebFn9(index) {
      this.isClickIndex = index;
    },
    ebFn10() {
      this.isClickIndex++;
    },
    ebFn11(val) {
      this.isSearch = val;
    },
    clickFn() {
      if (this.currentIndex != null) {
        this.currentIndex = null;
      }
    },
    /**
     * 鼠标移入列表高亮
     */
    mouseOver(index) {
      this.isMouceHover = index;
      this.active = "#f1eded";
    },
    /**
     * 鼠标移出列表不高亮
     */
    mouseLeave() {
      this.isMouceHover = null;
    },
    /**
     * 搜索更多联系人
     */
    searchContactMore() {
      this.getSearchUserList(2000000);
      this.isMore = false;
    },
    /**
     * 搜索更多群聊
     */
    searchGroupMore() {
      this.getSearchGroupList(2000000);
      this.groupIsMore = false;
    },
    /**
     * 搜索更多客户群策
     */
    searchQunCeMore() {
      this.getSearchStrategyGroup(2000000);
      this.qunCeIsMore = false;
    },
    /**
     * 搜索更多机会群策
     */
    searchOpporMore() {
      this.getSearchOpporGroup(2000000);
      this.opporIsMore = false;
    },
    /**
     * 邀请用户进群
     */
    async invitation(groupId, userIds, memberType, timestamp) {
      let result = await job.addgroupmember(
        groupId,
        userIds,
        memberType,
        timestamp
      );
      if (result.result == true) {
        this.$Bus.$emit("group-add-delete-member", groupId);
        this.$Bus.$emit("open-chat-window", true);
      }
    },
    /**
     * 清空消息
     */
    clearMessage() {
      this.$store.commit("clearChatMsg", this.currentIndex);
    },
    /**
     * 删除用户
     */
    closedialog() {
      if (
        this.$store.state.list.isChatID.imId ==
        this.$store.state.list.chatList[this.currentIndex].imId
      ) {
        this.$store.commit("deleteIschatId");
        this.$Bus.$emit("delete-list-remove", false);
      }
      this.$store.commit("deleteChatListLeft", this.currentIndex);
      if (this.currentIndex < this.isClickIndex) {
        this.isClickIndex--;
      } else if (this.currentIndex == this.isClickIndex) {
        this.isClickIndex = null;
      }
      // this.$store.state.list.chatList.splice(this.currentIndex, 1);
      this.dialogVisible = false;
    },
    /**
     * 关键字列表模糊搜索
     */
    coverString(subStr, str) {
      var reg = eval("/" + subStr + "/ig");
      return reg.test(str);
    },
    /**
     * 创建普通群聊
     */
    async createGroup(
      id,
      name,
      timestamp,
      ID,
      groupNames,
      recordId,
      groupIdArr,
      strategyType
    ) {
      let result = await job.setNewGroupSta(id, name, timestamp, ID, recordId);
      if (result.result == true && result.data) {
        let obj = {};
        let obj2 = {};
        let date = new Date();
        let hour = date.getHours();
        let minute = date.getMinutes();
        if (minute <= 9) {
          minute = "0" + minute;
        }
        let time = `${hour}:${minute}`;
        let res = await job.getgroupmessage(result.data._id);
        if (res.result == true) {
          if (groupIdArr.length > 1) {
            let obj3 = {};
            obj3.invite = "你";
            obj3.isInvite = groupNames;
            obj3.isNowGroup = true;
            this.$store.commit("changeInvite", obj3);
            obj.invite = obj3;
            obj.lastWord = this.$i18n.t("vue_label_chat_invitetojoin", {
              invite: this.$store.state.list.groupInvite.isInvite,
            });
          } else {
            obj.lastWord = "";
          }
          obj.name = name;
          obj.id = id;
          obj.imId = result.data._id;
          obj.type = "group";
          obj.timestamp = date.getTime();
          if (strategyType == "account") {
            obj.isStrategyGroup = true;
            obj2.isStrategyGroup = true;
          } else if (strategyType == "opportunity") {
            obj.isOpportunityGroup = true;
            obj2.isOpportunityGroup = true;
          }
          obj.members = res.data.members;
          obj.noRead = 0;
          obj.time = time;
          obj.msg = [];
          obj.imgUrl = "";
          obj2.ownerName = obj2.isGroup = true;
          obj2.imId = obj.imId;
          obj2.imgUrl = "";
          obj2.name = obj.name;
          obj2.id = obj.id;
          this.$store.commit("updateChatID", obj2);
          this.$Bus.$emit("deliver-select-conversation", obj2);
          this.$store.commit("updateChatList", obj);
          let index = this.$store.state.list.chatList.findIndex(
            (res) => res.imId == id
          );
          if (groupIdArr.length > 1) {
            let obj4 = {};
            obj4.id = 3;
            obj4.type = "invite";
            obj4.data = this.$i18n.t("vue_label_chat_invitetojoin", {
              invite: groupNames,
            });
            obj4.timestamp = new Date().getTime();
            this.$store.commit("updateMsg", { index: index, obj: obj4 });
          }
        }
        this.$Bus.$emit("changePosition", 0);
        this.$Bus.$emit("change-select-window", 0);
        this.$Bus.$emit("open-chat-window", true);
      }
    },
    /**
     * 创建群策群聊
     */
    createGroupH(
      groupIdArr,
      groupName,
      groupNames,
      userIdArr,
      id,
      strategyType
    ) {
      var options = {
        data: {
          groupname: groupName, // 群组名
          // desc: info, // 群组描述
          members: groupIdArr, // 用户名组成的数组
          public: true, // pub等于true时，创建为公开群
          approval: true, // approval为true，加群需审批，为false时加群无需审批
          allowinvites: true, // true：允许群成员邀请人加入此群，false：只有群主才可以往群里加人
          inviteNeedConfirm: false, // 邀请加群，被邀请人是否需要确认。true 为需要被邀请者同意才会进群
        },
        success: (respData) => {
          this.createGroup(
            respData.data.groupid,
            groupName,
            respData.timestamp,
            userIdArr,
            groupNames,
            id,
            groupIdArr,
            strategyType
          );
        },
        error: (err) => {
          this.$message({
            showClose: true,
            message: err,
            type: "error",
            duration: 3000,
          });
        },
      };
      this.$imconn.createGroupNew(options);
    },
    /**
     * 点击聊天列表聊天
     */
    contactTalk(item, index) {
      this.$Bus.$emit("deliver-select-conversation", item);
      this.$Bus.$emit("close-animation");
      this.isClickIndex = index;
      let obj2 = {};
      if (item.type == "user") {
        obj2.isGroup = false;
        obj2.imgUrl = item.imgUrl;
      } else if (item.type == "group") {
        obj2.isGroup = true;
        obj2.imgUrl = "";
      }
      obj2.id = item.id;
      obj2.imId = item.imId;
      obj2.name = item.name;
      if (item.type == "group" && item.isStrategyGroup == true) {
        obj2.isStrategyGroup = true;
      } else if (item.type == "group" && item.isOpportunityGroup == true) {
        obj2.isOpportunityGroup = true;
      }
      this.$store.commit("updateChatID", obj2);
      this.changeInitialMedia();
      this.$Bus.$emit(
        "change-chat-image",
        this.imgurlBefore + item.id + this.imgUrlAfter
      );
      this.$Bus.$emit("click-list", "isClick");
      this.$store.commit("updateChatListNoRead", 0);
      this.$Bus.$emit("change-select-window", 0);
    },
    /**
     * 搜索关键字后新建用户聊天
     */
    newContactTalk(item) {
      this.searchContactTalk(
        item,
        "user",
        false,
        item.easemobImId,
        item.imgUrl,
        false
      );
      this.$Bus.$emit("change-select-window", 0);
    },
    /**
     * 搜索关键字后新建普通群聊天
     */
    newGroupTalk(item) {
      this.searchContactTalk(item, "group", true, item.id, "", false);
      this.$Bus.$emit("change-select-window", 0);
    },
    /**
     * 搜索关键字后新建客户群策群聊天
     */
    newstrategyGroupTalk(item) {
      this.searchContactTalk(item, "group", true, item.id, "", true);
      this.$Bus.$emit("change-select-window", 0);
    },
    /**
     * 搜索关键字后新建机会群策群聊天
     */
    newOpportunityGroupTalk(item) {
      this.searchContactTalk(item, "group", true, item.id, "", true);
      this.$Bus.$emit("change-select-window", 0);
    },
    /**
     * 搜索列表点击创建回话
     */
    async searchContactTalk(
      item,
      type,
      selectIsgroup,
      easemobImId,
      imgUrl,
      isStrategyGroup
    ) {
      let index = this.$store.state.list.chatList.findIndex(
        (res) => res.imId == easemobImId
      );
      if (index < 0) {
        let obj = {};
        let obj2 = {};
        let date = new Date();
        let hour = date.getHours();
        let minute = date.getMinutes();
        if (minute <= 9) {
          minute = "0" + minute;
        }
        let time = `${hour}:${minute}`;
        obj.name = item.name;
        obj.id = item.id;
        obj.imId = easemobImId;
        obj.type = type;
        if (obj.type == "group") {
          let res = await job.getgroupmessage(obj.imId);
          if (res.result == true) {
            obj.members = res.data.members;
          }
        }
        obj.noRead = 0;
        obj.lastWord = "";
        obj.time = time;
        obj.msg = [];
        obj.timestamp = date.getTime();
        if (item.strategyType && item.strategyType == "account") {
          obj.isStrategyGroup = isStrategyGroup;
          obj2.isStrategyGroup = isStrategyGroup;
          obj.imgUrl = "";
          obj2.imgUrl = "";
        } else if (item.strategyType && item.strategyType == "opportunity") {
          obj.isOpportunityGroup = isStrategyGroup;
          obj2.isOpportunityGroup = isStrategyGroup;
          obj.imgUrl = "";
          obj2.imgUrl = "";
        }
        obj2.isGroup = selectIsgroup;
        obj2.imId = obj.imId;
        obj2.name = obj.name;
        obj2.id = obj.id;

        this.$store.commit("updateChatID", obj2);
        this.$store.commit("updateChatList", obj);
        // this.currentIndex = 0;
        this.isClickIndex = 0;
      } else {
        let obj2 = {};
        obj2.isGroup = selectIsgroup;
        obj2.imId = easemobImId;
        if (item.strategyType && item.strategyType == "account") {
          obj2.isStrategyGroup = isStrategyGroup;
          obj2.imgUrl = "";
        } else if (item.strategyType && item.strategyType == "opportunity") {
          obj2.isOpportunityGroup = isStrategyGroup;
          obj2.imgUrl = "";
        }
        obj2.name = item.name;
        obj2.id = item.id;
        this.$store.commit("updateChatID", obj2);
        // this.currentIndex = index;
        this.isClickIndex = index;
      }
      if (type == "user") {
        this.$Bus.$emit(
          "change-chat-image",
          this.imgurlBefore + item.id + this.imgUrlAfter
        );
      }
      this.isSearch = false;
      this.$Bus.$emit("search-display", "");
      this.inputValue = "";
      this.$Bus.$emit("change-search-input", false, "");
      this.$Bus.$emit("deliver-select-conversation", item);
      // this.$imconn.mr_cache = [];
    },
    /**
     * 创建一个新的聊天
     */
    addGroup() {
      this.$Bus.$emit("show-add-card", true);
    },
    /**
     * 点击列表操作项获取当前下标
     */
    clickRightKey(index) {
      this.currentIndex = index;
    },
    /**
     * 获取关键字查询用户列表
     */
    async getSearchUserList(pagesize) {
      let userList = await job.searchUser(this.inputValue, "user", 1, pagesize);
      if (
        userList.returnInfo == "Search success!" &&
        userList.returnCode == 1
      ) {
        this.contactsList = userList.data.userList.filter(
          (item) =>
            item.easemobImId !=
            JSON.parse(localStorage.getItem("userInfo")).userId
        );
      }
    },
    /**
     * 获取关键字查询群聊列表
     */
    async getSearchGroupList(pagesize) {
      let groupList = await job.searchUser(
        this.inputValue,
        "joinGeneralGroup",
        1,
        pagesize
      );
      if (
        groupList.returnInfo == "Search success!" &&
        groupList.returnCode == 1
      ) {
        this.groupList = groupList.data.joinGeneralGroupList;

        this.groupList = this.groupList.map((res) => {
          let value = [];
          value = res.searchMatchingMembers.split(",");

          res.searchMatchingMembers = "";
          value.filter((val) => {
            if (this.coverString(this.inputValue, val) == true) {
              res.searchMatchingMembers = val;
              return;
            }
          });
          return res;
        });
      }
    },
    /**
     * 获取业务机会群策列表
     */
    async getSearchOpporGroup(pagesize) {
      let groupList = await job.searchUser(
        this.inputValue,
        "joinStrategyGroup",
        1,
        pagesize,
        "opportunity"
      );
      if (
        groupList.returnInfo == "Search success!" &&
        groupList.returnCode == 1
      ) {
        this.opportunityGroup = groupList.data.joinStrategyGroupList;
        this.opportunityGroup.forEach((res) => {
          res.strategyType = "opportunity";
        });
        this.opportunityGroup = this.opportunityGroup.map((res) => {
          let value = [];
          value = res.searchMatchingMembers.split(",");

          res.searchMatchingMembers = "";
          value.filter((val) => {
            if (this.coverString(this.inputValue, val) == true) {
              res.searchMatchingMembers = val;
              return;
            }
          });
          return res;
        });
      }
    },
    /**
     * 获取客户群策列表
     */
    async getSearchStrategyGroup(pagesize) {
      let groupList = await job.searchUser(
        this.inputValue,
        "joinStrategyGroup",
        1,
        pagesize,
        "account"
      );
      if (
        groupList.returnInfo == "Search success!" &&
        groupList.returnCode == 1
      ) {
        this.strategyGroup = groupList.data.joinStrategyGroupList;
        this.strategyGroup.forEach((res) => {
          res.strategyType = "account";
        });
        this.strategyGroup = this.strategyGroup.map((res) => {
          let value = [];
          value = res.searchMatchingMembers.split(",");

          res.searchMatchingMembers = "";
          value.filter((val) => {
            if (this.coverString(this.inputValue, val) == true) {
              res.searchMatchingMembers = val;
              return;
            }
          });
          return res;
        });
      }
    },
  },
};
</script>

<style lang='scss'>
.list-content {
  width: 100%;
  height: calc(100% - 61px);
  overflow: auto;
  .el-dialog__header {
    border-bottom: none;
  }
  .el-dialog__footer {
    border-top: none;
  }
  .item-none {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 158px;
    box-sizing: border-box;
    p {
      font-size: 14px;
      color: #b6becc;
    }
    .add-chat {
      cursor: pointer;
      background: none;
      outline: none;
      height: 32px;
      border: 1px solid #d8dde6;
      border-radius: 4px;
      color: #e5851a;
      text-align: center;
      margin: 8px auto;
    }
  }
  .list-long {
    width: 100%;
    .item {
      position: relative;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #eceaea;
      padding: 10px 10px 18px;
      box-sizing: border-box;
      .rightButton {
        position: absolute;
        right: 48px;
        top: 15px;
        min-width: 102px;
        height: 48px;
        background: #fff;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
        z-index: 999;
        .right-item {
          width: 100%;
          height: 24px;
          line-height: 24px;
          text-align: left;
          cursor: pointer;
          padding: 0 15px;
          box-sizing: border-box;
        }
        .right-item:hover {
          background: rgb(243, 242, 242);
        }
        .delete-dialog {
          .dialog-footer {
            display: flex;
            justify-content: center;
            color: #fff;
          }
        }
      }
      .image {
        position: relative;
        width: 40px;
        height: 40px;
        float: left;
        overflow: hidden;
        .noRead {
          position: absolute;
          right: 0;
          top: 0;
          min-width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #e2403c;
          line-height: 16px;
          text-align: center;
          color: white;
        }
        .headerimg {
          border-radius: 50%;
          width: 100%;
          height: 100%;
        }
      }
      .word-des {
        width: calc(100% - 50px);
        float: right;
        .title {
          margin-bottom: 7px;
          overflow: hidden;
          .name {
            font-size: 12px;
            color: #333;
            float: left;
            width: 45%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // margin-right: 100px;
          }
          .date {
            font-size: 10px;
            color: #999999;
            float: right;
          }
          .qunce {
            float: right;
            max-width: 35%;
            height: 18px;
            background-color: #f8e0c5;
            color: #e5851a;
            font-size: 10px;
            text-align: center;
            line-height: 18px;
            margin-right: 5px;
            padding: 0 3px;
            overflow: hidden;
          }
        }
        .chat-content {
          font-size: 12px;
          color: #999;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .search-data {
    .search-no-result {
      width: 100%;
      text-align: center;
    }
    width: 100%;
    .get-more {
      font-size: 12px;
      color: #006dcc;
      padding-left: 12px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .contacts {
      width: 100%;

      .title {
        padding-left: 12px;
        padding-bottom: 5px;
        border-bottom: 1px solid #f5f5f5;
      }
      .item {
        width: 100%;
        height: 58px;
        background-color: #fff;
        overflow: hidden;
        .image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          float: left;
          margin: 9px 14px 9px 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          height: 100%;
          float: left;
          line-height: 58px;
          color: #e5851a;
        }
      }
    }
    .group-chat {
      width: 100%;
      .title {
        padding: 5px 12px;
        border-bottom: 1px solid #f5f5f5;
      }
      .item {
        width: 100%;
        height: 58px;
        .image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          float: left;
          margin: 9px 14px 9px 10px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
          .icon {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          float: left;
          .group-name {
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 12px;
          }
          .contain {
            width: 150px;
            margin-top: 5px;
            color: #777777;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              color: #e5851a;
            }
          }
        }
      }
    }
  }
  .el-dialog {
    width: 440px;
    // height: 162px;
    border-radius: 8px;
  }
  .el-button--primary {
    margin-right: calc(50% - 45px) !important;
    // background-color: #e5851a !important;
    height: 30px !important;
    padding: 0 10px !important;
    border: none !important;
    font-size: 12px !important;
  }
  .el-button--primary:hover {
    // background-color: #e5851a;
    border: none;
  }
  .el-button {
    padding: 0;
    span {
      color: white;
    }
  }
}
// ::-webkit-scrollbar {
//   /*隐藏滚轮*/
//   display: none;
// }
</style>
