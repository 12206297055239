<template>
  <label for="uploadImage" @click="clickFile">
    <svg class="uploadimgicon" aria-hidden="true" slot="reference">
      <use href="#icon-picture"></use>
    </svg>
    <input
      type="file"
      accept="image/*"
      class="hide"
      ref="imgDom"
      @change="pictureChange"
    />
  </label>
</template>

<script>
/*
 * 发送图片消息
 */

  import { mapActions } from "vuex";
  export default {
    name: "upload-img",
    data() {
      return {
      };
    },
    watch: {
    },
    methods: {
      /*
       * 图片超出限制
       */
      warning() {
        this.$message.warning(this.$i18n.t("vue_label_file_size_limit_tip"));
      },
      ...mapActions(["sendImgMessage"]),
      /*
       * 上传图片
       * TODO 当前username、及type不是从pams里取
       */
      pictureChange(e) {
        let isRoom = this.type == "chatroom" || this.type == "groupchat";
        let file = this.$WebIM.utils.getFileUrl(e.target);
        if (!file.filename) {
          this.$refs.imgDom.value = null;
          return false;
        }
        // if (!Config.imgType[file.filetype.toLowerCase()]) {
        //   this.$refs.imgDom.value = null;
        //   this.$message.error("不支持此类型文件");
        //   return;
        // }

        let obj = {
          chatType: this.$store.state.list.isChatID.isGroup ? "group" : "contact",
          chatId: this.$store.state.list.isChatID.imId, //TODO 这里在群里面应该取的是ID，后期跟进
          file: file,
          roomType: isRoom,
          callback: () => {
            this.$refs.imgDom.value = null;
          }
        };
        if (file.data.size / 1024 / 1024 >= 10) {
          this.warning();
        } else {
          this.sendImgMessage(obj);
        }
      },
      /*
       * 点击图片图标
       */
      clickFile() {
        let img = this.$refs.imgDom;
        img && img.click();
      },
    },
    props: [
      // 聊天类型 contact, group, chatroom
      "type", 
      // 选中的聊天对象
      "chatId" 
    ]
  };
</script>
<style scoped>
  .hide {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    filter: alpha(opacity=0);
    opacity: 0;
    cursor: inherit;
    display: none;
  }

  .uploadimgicon {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
</style>
