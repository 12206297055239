<template>
  <div class="all_box">
    <div class="nav_box">
      <ul>
        <li>
          <VueEmojiBox
            targetId="demoText"
            :baseUrl="baseUrl"
            :customEmojis="emojis"
            :customCategories="emojiCategory"
            height="200px"
            @change="bindChange"
            label=" "
            :visible="true"
          />
        </li>
        <li>
          <uploadimg :type="this.type" :chatId="activedKey[type]" />
        </li>
        <li>
          <uploadfile :type="this.type" :chatId="activedKey[type]" />
        </li>
      </ul>
    </div>
    <div class="mian_box">
      <textarea
        name
        id="demoText"
        @keydown.enter="submitMessage"
        autofocus
        v-model="message"
        ref="txtDom"
      ></textarea>
    </div>
    <div class="bottom_box">
      <!-- <span>Enter发送，Ctrl+Enter换行</span> -->
      <button @click="submitMessageButton">
        <b>{{ $t("label_chatter_send") }}</b>
      </button>
    </div>
  </div>
</template>

<script>
/*
 * 聊天内容输入区工具条
 */
import uploadimg from "./uploadimg.vue";
import uploadfile from "./uploadfile.vue";
import VueEmojiBox, { renewHtml } from "vue-emoji-box";
import { emojiCategory, emojis } from "@/assets/emoji";

export default {
  name: "toolbar",
  data() {
    return {
      //当前输入内容
      message: "",
      //表情图标库
      emojiCategory,
      //表情图标库
      emojis,
      //表情图片
      baseUrl: "../../assets/emoji.js",
      //当前聊天信息
      activedKey: {
        contact: "",
        group: "",
        chatroom: "",
      },
      //当前聊天类型
      type: "",
    };
  },
  components: {
    uploadimg,
    uploadfile,
    VueEmojiBox,
  },
  computed: {
    //当前聊天imid
    chatId() {
      return this.$store.state.list.isChatID;
    },
  },
  mounted() {},
  methods: {
    /*
     * 发送文本消息 （单聊）
     */
    sendTextMessage(message) {
      var id = this.$imconn.getUniqueId(); // 生成本地消息id
      var msg = new this.$WebIM.message("txt", id);
      msg.set({
        msg: message, // 消息内容
        to: this.$store.state.list.isChatID.imId, // 接收消息对象（用户id）
        roomType: false,
      });
      this.$imconn.send(msg.body);
    },
    /*
     * 选中表情包事件
     */
    bindChange(item) {
      this.message += item.text;
      this.message += " ";
      document.getElementById("demoText").focus();
      renewHtml;
    },
    /*
     * 发送文本消息（群聊）
     */
    sendTextMessageGroup(message) {
      var id = this.$imconn.getUniqueId(); // 生成本地消息id
      var msg = new this.$WebIM.message("txt", id); // 创建文本消息
      var option = {
        msg: message, // 消息内容
        to: this.$store.state.list.isChatID.imId, // 接收消息对象(群组id)
        roomType: false, // 扩展消息
        chatType: "groupChat",
        success: function () {}, // 对成功的相关定义，sdk会将消息id登记到日志进行备份处理
        fail: function () {}, // 对失败的相关定义，sdk会将消息id登记到日志进行备份处理
      };
      msg.set(option);
      msg.setGroup("groupchat"); // 群聊类型
      this.$imconn.send(msg.body);
    },
    /*
     * 渲染编辑框
     */
    renderTxt(txt = "") {
      let rnTxt = [];
      let match = null;
      const regex = /(\[.*?\])/g;
      let start = 0;
      let index = 0;
      while ((match = regex.exec(txt))) {
        index = match.index;
        if (index > start) {
          rnTxt.push(txt.substring(start, index));
        }
        start = index + match[1].length;
      }
      rnTxt.push(txt.substring(start, txt.length));
      return rnTxt.toString().replace(/,/g, "");
    },
    /*
     * 点击发送按钮发送消息
     */
    submitMessageButton() {
      let str = this.message;
      while (str.lastIndexOf(" ") >= 0) {
        str = str.replace(" ", "");
      }
      if (str.length == 0) {
        this.message = "";
        return false;
      }
      if (this.$store.state.list.isChatID.isGroup == false) {
        this.sendTextMessage(this.renderTxt(this.message));
      } else {
        this.sendTextMessageGroup(this.renderTxt(this.message));
      }
      let index = this.$store.state.list.chatList.findIndex(
        (res) => res.imId == this.$store.state.list.isChatID.imId
      );
      let date = new Date();
      let hour = date.getHours();
      let minute = date.getMinutes();
      if (minute <= 9) {
        minute = "0" + minute;
      }
      let time = `${hour}:${minute}`;
      this.$store.commit("changeTimestampWord", {
        index: index,
        time: time,
        date: date,
        message: this.message,
      });
      let obj = {};
      obj.id = 2;
      obj.type = "text";
      obj.data = this.message;
      obj.timestamp = new Date().getTime();
      obj.img = localStorage.getItem("ownerimg");
      obj.userid = localStorage.getItem("myid");
      this.$store.commit("updateMsg", { index: index, obj: obj });
      this.$Bus.$emit("changePosition", 0);
      this.$store.commit("changeListPosition", index);
      this.message = "";
    },
    /*
     * 发送消息
     */
    submitMessage(e) {
      if (e.keyCode == 13 && e.ctrlKey == false) {
        e.preventDefault();
        let str = this.message;
        while (str.lastIndexOf(" ") >= 0) {
          str = str.replace(" ", "");
        }
        if (str.length == 0) {
          this.message = "";
          return false;
        }
        if (this.$store.state.list.isChatID.isGroup == false) {
          this.sendTextMessage(this.renderTxt(this.message));
        } else {
          this.sendTextMessageGroup(this.renderTxt(this.message));
        }
        let index = this.$store.state.list.chatList.findIndex(
          (res) => res.imId == this.$store.state.list.isChatID.imId
        );
        let date = new Date();
        let hour = date.getHours();
        let minute = date.getMinutes();
        if (minute <= 9) {
          minute = "0" + minute;
        }
        let time = `${hour}:${minute}`;
        this.$store.commit("changeTimestampWord", {
          index: index,
          time: time,
          date: date,
          message: this.message,
        });
        let obj = {};
        obj.id = 2;
        obj.type = "text";
        obj.data = this.message;
        obj.timestamp = new Date().getTime();
        obj.img = localStorage.getItem("ownerimg");
        obj.userid = localStorage.getItem("myid");
        this.$store.commit("updateMsg", { index: index, obj: obj });
        this.$Bus.$emit("changePosition", 0);
        this.$store.commit("changeListPosition", index);
        this.message = "";
      }
      if (e.keyCode == 13 && e.ctrlKey == true) {
        this.message += "\n"; //换行
      }
    },
  },
  watch: {
    chatId: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.message = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.all_box {
  width: 100%;
  height: 126px;
  display: flex;
  flex-direction: column;
  .nav_box {
    width: 100%;
    height: 25px;
    ul {
      width: 100%;
      height: 25px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        margin: 10px 7.5px;
        list-style: none;
        ::v-deep .vemoji-main--content {
          bottom: 25px;
        }
        img {
          width: 15px;
          height: 15px;
        }
        ::v-deep .vemoji-main--target {
          span {
            display: inline-block;
            width: 15px;
            height: 15px;
            background: url("../../../assets/toolbarImage/emoji.png") no-repeat
              center center;
            background-size: 15px 15px;
            margin-top: 2.5px;
          }
        }
      }
    }
  }
  .mian_box {
    flex: 1;
    width: 100%;
    textarea {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      resize: none;
      padding: 10px;
      box-sizing: border-box;
    }
  }
  .bottom_box {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    span {
      font-size: 10px;
      color: #999999;
      letter-spacing: 0;
      line-height: 10px;
      margin-left: 20px;
      margin-bottom: 10px;
    }
    button {
      cursor: pointer;
      background: #006dcc;
      border: 1px solid #006dcc;
      border-radius: 4px;
      outline: none;
      padding: 1px 13px;
      display: inline-block;
      margin-left: 85%;
      height: 26px;
      b {
        display: inline-block;
        font-family: "MicrosoftYaHei";
        color: #ffffff;
        letter-spacing: 0;
      }
    }
  }
  ::v-deep .vemoji-emojis--item__text[data-v-30cf9e5a] {
    margin: 0;
    padding: 0;
    span {
      font-size: 20px;
    }
  }
  ::v-deep .vemoji-main--content__head[data-v-054e508e] {
    padding-left: 28px;
  }
}
</style>