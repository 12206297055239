<template>
  <div class="call-bar-ll">
    <!-- 电话条 -->
    <div class="call-bar">
      <!-- 电话标识 -->
      <!-- 天润 -->
      <div class="iconBox" v-if="countryBool">
        <div v-show="showTianRun" class="showTianRunClass">
          <!-- 未登录 -->
          <div
            class="Unloggedphone_span"
            v-show="notLogin"
            @click="openBar"
            v-loading="loginLoading"
            element-loading-spinner="el-icon-loading"
          >
            <svg class="Unloggedphone" aria-hidden="true" slot="reference">
              <use href="#footer-call"></use>
            </svg>
            <span>Phone</span>
          </div>
          <!-- 已登录 -->
          <svg
            class="TRhasLogclose"
            aria-hidden="true"
            slot="reference"
            v-show="hasLogin"
            @click="upTRbar"
          >
            <use href="#icon-icon_phonestrip_24"></use>
          </svg>
          <!-- 展开中 -->
          <svg
            class="AWShasLogclose"
            aria-hidden="true"
            slot="reference"
            v-show="hasLoginUp"
            @click="closeTRbar"
          >
            <use href="#icon-icon_phonestrip_24"></use>
          </svg>
        </div>
      </div>
      <!-- AWS -->
      <div class="iconBox" v-else>
        <div v-show="showAWS">
          <div class="showTianRunClass">
            <!-- 未登录 -->
            <div
              class="Unloggedphone_span"
              v-show="!onlineBoolIcon"
              @click="open"
            >
              <svg class="Unloggedphone" aria-hidden="true" slot="reference">
                <use href="#icon-icon_setting_16"></use>
              </svg>
            </div>
            <!-- 已登录 -->
            <svg
              class="AWShasLogclose"
              aria-hidden="true"
              slot="reference"
              v-show="onlineBoolIcon"
              @click="close"
            >
              <use href="#icon-icon_phonestrip_24"></use>
            </svg>
          </div>
        </div>
      </div>
      <!-- 电话条展开 -->
      <div v-show="showTianRunBar" class="uniquePhone"></div>
    </div>
    <!-- 登录框 -->
    <div>
      <callCenterLogin
        ref="callCenterLoginRef"
        :userCno="userCno"
        :userlogin-dialog="userloginDialog"
        @closeuserlogindialog="closeuserlogindialog"
        @loginSuccess="loginSuccess"
      />
    </div>
    <!-- 弹屏 -->
    <div class="screen_PopUp" v-show="showPopup">
      <div>
        <div class="popTitle">{{ popInfo.popTitle }}</div>
        <div class="svgIcon">
          <svg
            class=""
            aria-hidden="true"
            slot="reference"
            style="width: 84px; height: 84px; margin-top: 12px"
          >
            <use
              :href="incomeBool ? '#icon-tps_call' : '#icon-tps_connecting'"
            ></use>
          </svg>
        </div>
        <!-- 联系人信息 -->
        <div class="userInfo">
          <div
            :class="['linkman', { 'linkman-active': Identifyuser == '1' }]"
            @click="handleToContactDetail"
          >
            {{ popInfo.linkName }}
          </div>
          <!-- 来电弹屏 -->
          <div v-if="incomeBool">
            <div class="linkArea">
              <span
                >{{ $t("front-kefu-moudle-wait") }}: {{ waitNum
                }}{{ $t("label.service.seconds") }}</span
              >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{ popInfo.phoneCity }}
            </div>
            <div class="linkPhone">{{ popInfo.phone }}</div>
          </div>
          <!-- 外呼弹屏或接听 -->
          <div v-else>
            <div class="linkPhone">{{ popInfo.phone }}</div>
            <div class="linkArea">
              {{ popInfo.phoneCity }}
            </div>
          </div>
        </div>
        <div class="phoneborder">
          {{ popInfo.phone }}
          <svg
            class=""
            aria-hidden="true"
            slot="reference"
            style="width: 16px; height: 16px"
          >
            <use
              :href="
                netstate ? '#icon-tps_signal' : '#icon-tps_signal_interrupt'
              "
            ></use>
          </svg>
        </div>
        <!-- 弹屏按钮 -->
        <div class="operationBtn">
          <div v-if="incomeBool">
            <el-button @click="refusePhone">{{ $t("label.refuse") }}</el-button>
            <el-button type="primary" @click="answerPhone">{{
              $t("chatter.group.accept")
            }}</el-button>
          </div>
          <div v-else>
            <el-button type="danger" @click="hangupPhone">{{
              $t("front-kefu-moudle-hangup")
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 手动创建个案 -->
    <createCase
      ref="createCase"
      :peopleCreateCase="peopleCreateCase"
      :caseId="voiceObj.caseid"
      :phoneemail="phoneOrEmail"
      :vistorId="vistorId"
      @closeCreateCaseDialog="closeCreateCaseDialog"
      @confirmCreateCase="confirmCreateCase"
    ></createCase>
    <div></div>
    <englishlogin
      ref="InstanceName"
      :englogin-dialog="engloginDialog"
      :loginName="loginName"
      @closeenglogindialog="closeenglogindialog"
      @openenglogindialog="openenglogindialog"
      @inittel="inittel"
    ></englishlogin>
    <!-- 生成iframe的地方，可以放在网页任意位置 -->
    <div class="aws-phone" v-show="awsPhone">
      <div class="aws-phone-header">
        <span style="cursor: pointer" @click="openenglogindialog('reload')"
          >重新登录</span
        >
        <i class="el-icon-minus narrow" @click="phoneDown"></i>
        <!-- <i class="el-icon-position" @click="jump"></i> -->
      </div>
      <div
        v-loading="loading"
        id="container-div"
        title="Amazon Connect"
        style="height: 550px"
      ></div>
    </div>
  </div>
</template>
<script>
/**
 * 呼叫中心
 */
import * as callCenterApi from "./api.js"; // 呼叫中心接口
import callCenterLogin from "@/views/callCenterPhone/callCenterLogin.vue";
import createCase from "@/views/callCenterPhone/components/createCase.vue";
import englishlogin from "@/views/systemSettings/components/callCenterSetting/englishlogin.vue";

export default {
  components: {
    callCenterLogin,
    englishlogin,
    createCase,
  },
  computed: {
    // 电话标识高度
    BartopHeight() {
      return window.innerHeight * 0.88 + "px";
    },
  },
  data() {
    return {
      //电话登陆laoding
      loginLoading: false,
      controlReply: false,
      showTianRun: false,
      showAWS: false,
      // 语言判断
      countryBool: true,
      // 未登录
      notLogin: true,
      // 已登录
      hasLogin: false,
      // 展开中
      hasLoginUp: false,
      // 默认不展示天润电话条
      showTianRunBar: false,
      // 引入依赖是否成功
      testBoolA: false,
      testBoolB: false,
      // 天润登录框
      userloginDialog: false,
      // 电话图标是否可以点击触发事件
      ClickBool: true,
      // 如果是登录再退出
      onlineCloseBool: false,
      // 弹屏展示
      showPopup: false,
      // 来电弹屏
      incomeBool: true,
      // 登录的坐席密码
      loginInfoPass: {},
      // 弹屏信息
      popInfo: {
        popTitle: "",
        linkName: "",
        phoneCity: "",
        phone: "",
      },
      // 等待时间
      waitNum: 1,
      // 等待计时器
      timer: null,
      // 网络状态
      netstate: true,
      // 网络计时器
      netTimer: null,
      userCno: "",
      // 语音记录对象
      voiceObj: {
        voicerecording: "", //语音记录
        agentid: "", // 客服
        callingtype: "", // 呼叫类型
        callingnumber: "", // 主叫号码
        callednumber: "", // 被叫号码
        voicestatus: "", // 语音状态
        istransferconversation: "", // 是否转接
        transferconversationtoid: "", // 转接客服
        transferconversationreason: "", // 转接原因
        visitorid: "", // 访客编号
        lbsaddress: "", // 地址
        contactid: "", // 联系人
        accountid: "", // 客户名称
        voicestarttime: "", // 开始时间
        voiceclosetime: "", // 结束时间
        voiceduration: "", // 会话时长
        createbyid: "", // 创建人
        createdate: "", // 创建时间
        customVoicestarttime: 0, // 自计算开始时间
        caseid: "", // 个案id
      },
      caseNumber: "", // 个案编号
      outPhone: "", // 外呼电话号码
      inPhone: "", // 呼入电话号码
      peopleCreateCase: false, // 人工创建个案
      //海外登录
      awsPhone: false,
      engloginDialog: false,
      languageSign: true,
      loginName: "",
      AWSstartTime: "",
      AWSendTime: "",
      callMissed: "", //创建个案标识
      callConnected: "",
      lxrdh: "",
      emailaddress: "",
      phoneOrEmail: "",
      vistorId: "",
      missSign: true,
      num: 0,
      onlineBoolIcon: false,
      loading: false,
      Identifyuser: null,
      identifyMore: false,
      countryCode: this.$cookies.get("countryCode"),
      countryCodeCall:
        this.$store.state.userInfoObj.language == "zh" ? "CN" : "",
    };
  },
  directives: {
    //实现电话条按钮拖拽功能
    drag: {
      bind: function (el, binding, vnode) {
        let odiv = el; //获取当前元素
        let that = vnode.context;
        el.onmousedown = (e) => {
          that.ClickBool = true;
          // 清除文字选中
          document.onselectstart = () => false;
          //算出鼠标相对元素的位置
          // let disX = e.clientX - odiv.offsetLeft;
          let disY = e.clientY - odiv.offsetTop;
          // let left = "";
          let top = "";
          document.onmousemove = (e) => {
            that.ClickBool = false;
            //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            // left = e.clientX - disX;
            top = e.clientY - disY;
            //绑定元素位置到positionX和positionY上面
            //移动当前元素
            // odiv.style.left = left + "px";
            odiv.style.top = top + "px";
            if (parseInt(odiv.style.top) <= 6) {
              odiv.style.top = "6px";
            }
            if (parseInt(odiv.style.top) >= window.innerHeight - 60) {
              odiv.style.top = window.innerHeight - 60 + "px";
            }
          };
          document.onmouseup = () => {
            // 解除文字选中
            document.onselectstart = () => true;
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  mounted() {
    this.judgeShow();
    this.$bus.$on("oldCLick", this.newCLick);
    this.loginAutoa();
  },
  beforeDestroy(){
    this.$bus.$off("oldCLick", this.newCLick);
  },
  methods: {
    // 判断显示
    judgeShow() {
      if (this.countryCodeCall == "CN") {
        this.countryBool = true;
        let paramsInfo = {
          intergrateType: "tianrun",
        };
        // 获取呼叫中心设置
        callCenterApi.getCallCenterInfo(paramsInfo).then((resInfo) => {
          if (resInfo.result && resInfo.data) {
            if (resInfo.data.isEnable == "0") {
              this.showTianRun = false;
            } else if (resInfo.data.isEnable == "1") {
              let params = {
                bindUserId: localStorage.getItem("userId"),
              };
              callCenterApi.getBindByCnoOrUserId(params).then((res) => {
                if (res.data.length !== 0) {
                  this.showTianRun = true;
                } else {
                  this.showTianRun = false;
                }
              });
            }
          }
        });
      } else {
        this.countryBool = false;
        let paramsInfo = {
          intergrateType: "aws",
        };
        // 获取呼叫中心设置
        callCenterApi.getCallCenterInfo(paramsInfo).then((resInfo) => {
          if (resInfo.result && resInfo.data) {
            if (resInfo.data.isEnable == "0") {
              this.showAWS = false;
            } else if (resInfo.data.isEnable == "1") {
              this.showAWS = true;
            }
          }
        });
      }
    },

    // 自动登录判断
    loginAutoa() {
      if (
        this.countryCodeCall == "CN" &&
        sessionStorage.getItem("callcenterAuto")
      ) {
        this.openBar();
      }
    },

    // 获取CRM设置
    getCallCenterInfo(intergrateType) {
      callCenterApi.getCallCenterInfo({ intergrateType }).then((res) => {
        this.callMissed = res.data.callMissed;
        this.callConnected = res.data.callConnected;
      });
    },
    // 点击电话条
    openBar() {
      if (this.ClickBool) {
        this.loginLoading = true;
        let paramsInfo = {
          intergrateType: "tianrun",
        };
        // 获取呼叫中心设置
        callCenterApi.getCallCenterInfo(paramsInfo).then((resInfo) => {
          if (resInfo.result && resInfo.data) {
            if (resInfo.data.isEnable == "0") {
              this.$message(
                this.$i18n.t("front-kefu-moudle-callcentrt-hasclose")
              );
              this.showTianRun = false;
            } else if (resInfo.data.isEnable == "1") {
              this.showTianRun = true;
              // 如果是天润
              this.getTianRunInfo();
            }
          }
        });
      }
    },

    // 判断绑定天润融通
    getTianRunInfo() {
      let params = {
        bindUserId: localStorage.getItem("userId"),
      };
      callCenterApi.getBindByCnoOrUserId(params).then((res) => {
        if (res.data.length == 0) {
          this.$message(this.$i18n.t("front-kefu-moudle-callcentrt-hasclose"));
          this.showTianRun = false;
        } else {
          this.showTianRun = true;
          if (!res.data[0].phoneState || res.data[0].phoneState == "0") {
            // 如果是第一次登录
            if (res.data[0].tianRunPwd == "") {
              this.userCno = res.data[0].bindcno;
              this.userloginDialog = true;
            } else {
              this.importTianRun();
            }
          } else {
            this.$message(this.$i18n.t("front-kefu-moudle-wait-calling"));
          }
        }
      });
    },

    // 登陆成功
    loginSuccess(val) {
      this.importTianRun();
      this.loginInfoPass = val;
    },

    // 引入天润依赖
    importTianRun() {
      // 如果是登录再退出
      if (this.onlineCloseBool) {
        // 登录
        this.loginOperation();
      } else {
        let paramsA = "https://ws-bj.clink.cn/clink-client.js";
        this.appendScript(paramsA, () => {
          this.testBoolA = true;
          this.initBar();
        });
        let paramsB =
          "https://ws-bj.clink.cn/clink-client-ui-vue/ClinkToolbar.umd.min.js";
        this.appendScript(paramsB, () => {
          this.testBoolB = true;
          this.initBar();
        });
      }
    },

    // 初始化天润电话条
    initBar() {
      if (this.testBoolA && this.testBoolB) {
        // 隐藏登录界面、隐藏登录界面中的部分版块以及话务管理参数配置
        const options = {
          isAutoLogin: true, // 展示登录界面
          // 话务管理配置
          showHold: true, // 支持保持
          showConsult: true, //支持咨询,
          showTransfer: true, //支持转移
          showSatisfaction: true, //支持满意度
          showMute: true, //支持静音
          debug: true, // 是否开启debug 默认开启
        };
        const dom = document.getElementsByClassName("uniquePhone")[0];
        var clinkToolbar = new window.ClinkToolbar(options, dom, () => {
          // 登录
          this.loginOperation();
          // 登录后的事件监听
          let that = this;
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.ResponseType.LOGIN,
            function (event) {
              if (event.code === 0) {
                // 登录成功
                that.$message({
                  message: that.$i18n.t("label.user.login.success"),
                  type: "success",
                });
                that.loginLoading = false;
                that.notLogin = false;
                that.hasLogin = true;
                that.hasLoginUp = false;
                that.showTianRunBar = true;
                if (that.userloginDialog) {
                  that.savePassword();
                  that.loginInfoPass = {};
                  that.$nextTick(() => {
                    that.$refs.callCenterLoginRef.closeuserloginDialog();
                  });
                }
                sessionStorage.setItem("callcenterAuto", true);
              } else {
                that.notLogin = true;
                that.hasLogin = false;
                that.hasLoginUp = false;
                that.showTianRunBar = false;
                that.onlineCloseBool = true;
                that.userloginDialog = true;
                // that.loginLoading = false;
              }
            }
          );
          // 退出后的事件监听
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.ResponseType.LOGOUT,
            function (event) {
              if (event.code === 0) {
                that.notLogin = true;
                that.hasLogin = false;
                that.hasLoginUp = false;
                that.showTianRunBar = false;
                that.onlineCloseBool = true;
                that.loginLoading = false;
              }
            }
          );
          // 座席状态改变的监听
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.STATUS,
            (e) => {
              // 如果离线
              if (e.action === "offline") {
                let params = {
                  bindUserId: localStorage.getItem("userId"),
                };
                callCenterApi.getBindByCnoOrUserId(params).then((res) => {
                  if (res.data.length == 0) {
                    that.$message(that.$i18n.t("front-kefu-moudle-has-unbind"));
                  } else {
                    if (res.data[0].tianRunPwd == "") {
                      that.$message(
                        that.$i18n.t("front-kefu-moudle-info-delete-calluser")
                      );
                    } else if (res.data[0].tianRunPwd !== "") {
                      that.$message({
                        message:
                          that.$i18n.t("label.tabpage.exitz") +
                          that.$i18n.t("label.systemlog.s"),
                        type: "success",
                      });
                      sessionStorage.removeItem("callcenterAuto");
                    }
                  }
                  that.notLogin = true;
                  that.hasLogin = false;
                  that.hasLoginUp = false;
                  that.showTianRunBar = false;
                  that.onlineCloseBool = true;
                  that.judgeShow();
                });
              }
              // 软电话登录，呼入座席响铃，来电显示
              if (e.code == "RINGING" && e.action == "ringingIb") {
                that.inPhone = e.customerNumber;
                that.getCallCenterInfo("tianrun");
                // 创建访客记录
                let paramsPhone = {
                  phonenumber: e.customerNumber,
                };
                callCenterApi.creatVisitor(paramsPhone).then((resIdentify) => {
                  let params = {
                    bindUserId: localStorage.getItem("userId"),
                  };
                  callCenterApi.getBindByCnoOrUserId(params).then((res) => {
                    if (
                      res.data[0].phonetype == "3" ||
                      res.data[0].phonetype == "4"
                    ) {
                      let paramsResult = {
                        phone: e.customerNumber,
                      };
                      // 获取号码归属地
                      callCenterApi
                        .getPhoneCity(paramsResult)
                        .then((resResult) => {
                          if (resResult.result) {
                            let paramsphonenumber = {
                              phonenumber: e.customerNumber,
                            };
                            // 识别联系人
                            callCenterApi
                              .IdentifyPhonenumber(paramsphonenumber)
                              .then((resResultUser) => {
                                that.Identifyuser = resResultUser.data.length;
                                that.identifyMore =
                                  resResultUser.data.length !== 1
                                    ? true
                                    : false; // 判断识别是不是多个联系人
                                if (resResultUser.data.length !== 1) {
                                  that.popInfo = {
                                    popTitle: that.$i18n.t(
                                      "front-kefu-moudle-phonein"
                                    ),
                                    linkName: resResult.data.phone,
                                    phoneCity: resResult.data.place,
                                    phone: resResult.data.phone,
                                  };
                                } else {
                                  that.popInfo = {
                                    popTitle: that.$i18n.t(
                                      "front-kefu-moudle-phonein"
                                    ),
                                    linkName:
                                      resResultUser.data[0].cname +
                                      "-" +
                                      resResultUser.data[0].aname,
                                    phoneCity: resResult.data.place,
                                    phone: resResult.data.phone,
                                  };
                                }
                                that.showPopup = true;
                                that.incomeBool = true;
                                that.upTRbar();
                                that.testTime();
                                that.testNet();
                                that.voiceObj.voicerecording = e.mainUniqueId; //语音编号
                                that.voiceObj.agentid = res.data[0].binduserid; // 客服
                                that.voiceObj.callingtype = that.$i18n.t(
                                  "label.callcenter.call.in"
                                ); // 呼叫类型
                                that.voiceObj.callingnumber = e.customerNumber; // 主叫号码
                                that.voiceObj.callednumber = res.data[0].phone; // 被叫号码
                                that.voiceObj.voicestatus =
                                  that.$i18n.t("label.starting"); // 语音状态
                                that.voiceObj.istransferconversation = "false"; // 是否转接
                                that.voiceObj.transferconversationtoid = ""; // 转接客服
                                that.voiceObj.transferconversationreason = ""; // 转接原因
                                that.voiceObj.visitorid = resIdentify.data; // 访客编号
                                that.voiceObj.lbsaddress = resResult.data.place; // 地址
                                that.voiceObj.contactid =
                                  resResultUser.data.length !== 1
                                    ? ""
                                    : resResultUser.data[0].cid; // 联系人
                                that.voiceObj.accountid =
                                  resResultUser.data.length !== 1
                                    ? ""
                                    : resResultUser.data[0].aid; // 客户名称
                                that.voiceObj.voicestarttime = ""; // 开始时间
                                that.voiceObj.voiceclosetime = ""; // 结束时间
                                that.voiceObj.voiceduration = ""; // 会话时长
                                that.voiceObj.createbyid =
                                  res.data[0].binduserid; // 创建人
                                that.voiceObj.createdate = ""; // 创建时间
                              });
                          }
                        });
                    } else {
                      that.upTRbar();
                    }
                  });
                });
              }
              // 所有电话外呼坐席响铃，外呼显示
              if (e.code == "RINGING" && e.action == "ringingAgentOb") {
                // 存外呼电话号码
                that.outPhone = e.customerNumber;
                let paramsResult = {
                  phone: e.customerNumber,
                };
                callCenterApi.getPhoneCity(paramsResult).then((resResult) => {
                  if (resResult.result) {
                    let paramsphonenumber = {
                      phonenumber: e.customerNumber,
                    };
                    callCenterApi
                      .IdentifyPhonenumber(paramsphonenumber)
                      .then((resResultUser) => {
                        that.Identifyuser = resResultUser.data.length;
                        that.identifyMore =
                          resResultUser.data.length !== 1 ? true : false; // 判断识别是不是多个联系人
                        if (resResultUser.data.length !== 1) {
                          that.popInfo = {
                            popTitle: that.$i18n.t(
                              "front-kefu-moudle-connecting"
                            ),
                            linkName: resResult.data.phone,
                            phoneCity: resResult.data.place,
                            phone: resResult.data.phone,
                          };
                        } else {
                          that.popInfo = {
                            popTitle: that.$i18n.t(
                              "front-kefu-moudle-connecting"
                            ),
                            linkName:
                              resResultUser.data[0].cname +
                              "-" +
                              resResultUser.data[0].aname,
                            phoneCity: resResult.data.place,
                            phone: resResult.data.phone,
                          };
                        }
                        that.showPopup = true;
                        that.incomeBool = false;
                        that.upTRbar();
                        that.testNet();
                        that.getCallCenterInfo("tianrun");
                      });
                  }
                });
              }

              // 软电话登录，呼入接听
              if (e.code == "BUSY" && e.action == "busyIb") {
                let paramsResult = {
                  phone: e.customerNumber,
                };
                callCenterApi.getPhoneCity(paramsResult).then((resResult) => {
                  if (resResult.result) {
                    let paramsphonenumber = {
                      phonenumber: e.customerNumber,
                    };
                    callCenterApi
                      .IdentifyPhonenumber(paramsphonenumber)
                      .then((resResultUser) => {
                        that.Identifyuser = resResultUser.data.length;
                        that.identifyMore =
                          resResultUser.data.length !== 1 ? true : false; // 判断识别是不是多个联系人
                        if (resResultUser.data.length !== 1) {
                          that.popInfo = {
                            popTitle: that.$i18n.t(
                              "front-kefu-moudle-connected"
                            ),
                            linkName: resResult.data.phone,
                            phoneCity: resResult.data.place,
                            phone: resResult.data.phone,
                          };
                        } else {
                          that.popInfo = {
                            popTitle: that.$i18n.t(
                              "front-kefu-moudle-connected"
                            ),
                            linkName:
                              resResultUser.data[0].cname +
                              "-" +
                              resResultUser.data[0].aname,
                            phoneCity: resResult.data.place,
                            phone: resResult.data.phone,
                          };
                        }
                        that.showPopup = true;
                        that.incomeBool = false;
                        that.upTRbar();
                        that.closeTestTime();
                        that.testNet();
                        that.voiceObj.createdate = that.formatData(e.startTime); // 创建时间
                        that.voiceObj.voicestarttime = that.formatData(
                          e.startTime
                        ); // 开始时间
                        that.voiceObj.customVoicestarttime = e.startTime; // 自计算开始时间
                        if (that.callConnected == "1") {
                          let paramsSavecase = {
                            callStatus: "1",
                            contactId: that.voiceObj.contactid,
                            accountId: that.voiceObj.accountid,
                            visitorId: that.voiceObj.visitorid,
                            type: "tianrun",
                            phoneoremail: e.customerNumber,
                            generatecase: "Call in",
                          };
                          that.saveCase(paramsSavecase, true);
                        } else if (that.callConnected == "2") {
                          that.phoneOrEmail = e.customerNumber;
                          that.lxrdh = e.customerNumber;
                          that.emailaddress =
                            resResultUser.data.length !== 1
                              ? ""
                              : resResultUser.data[0].cemail;
                        }
                        that.updatePhoneStateIng();
                      });
                  }
                });
              }
              // 咨询响铃
              if (e.code == "RINGING" && e.action == "ringingConsult") {
                let params = {
                  bindUserId: localStorage.getItem("userId"),
                };
                callCenterApi.getBindByCnoOrUserId(params).then((res) => {
                  if (
                    res.data[0].phonetype == "3" ||
                    res.data[0].phonetype == "4"
                  ) {
                    let paramsResult = {
                      phone: e.customerNumber,
                    };
                    // 获取号码归属地
                    callCenterApi
                      .getPhoneCity(paramsResult)
                      .then((resResult) => {
                        if (resResult.result) {
                          let paramsphonenumber = {
                            phonenumber: e.customerNumber,
                          };
                          // 识别联系人
                          callCenterApi
                            .IdentifyPhonenumber(paramsphonenumber)
                            .then((resResultUser) => {
                              that.Identifyuser = resResultUser.data.length;
                              that.identifyMore =
                                resResultUser.data.length !== 1 ? true : false; // 判断识别是不是多个联系人
                              if (resResultUser.data.length !== 1) {
                                that.popInfo = {
                                  popTitle: that.$i18n.t(
                                    "front-kefu-moudle-phonein"
                                  ),
                                  linkName: resResult.data.phone,
                                  phoneCity: resResult.data.place,
                                  phone: resResult.data.phone,
                                };
                              } else {
                                that.popInfo = {
                                  popTitle:
                                    that.$i18n.t("front-kefu-moudle-phonein") +
                                    "(" +
                                    that.$i18n.t("label.icon.186") +
                                    ")",
                                  linkName:
                                    resResultUser.data[0].cname +
                                    "-" +
                                    resResultUser.data[0].aname,
                                  phoneCity: resResult.data.place,
                                  phone: resResult.data.phone,
                                };
                              }
                              that.showPopup = true;
                              that.incomeBool = true;
                              that.upTRbar();
                              that.testTime();
                              that.testNet();
                            });
                        }
                      });
                  }
                });
                that.upTRbar();
              }

              // 咨询接听
              if (e.code == "BUSY" && e.action == "busyConsult") {
                let paramsResult = {
                  phone: e.customerNumber,
                };
                callCenterApi.getPhoneCity(paramsResult).then((resResult) => {
                  if (resResult.result) {
                    let paramsphonenumber = {
                      phonenumber: e.customerNumber,
                    };
                    callCenterApi
                      .IdentifyPhonenumber(paramsphonenumber)
                      .then((resResultUser) => {
                        that.Identifyuser = resResultUser.data.length;
                        that.identifyMore =
                          resResultUser.data.length !== 1 ? true : false; // 判断识别是不是多个联系人
                        if (resResultUser.data.length !== 1) {
                          that.popInfo = {
                            popTitle: that.$i18n.t(
                              "front-kefu-moudle-connected"
                            ),
                            linkName: resResult.data.phone,
                            phoneCity: resResult.data.place,
                            phone: resResult.data.phone,
                          };
                        } else {
                          that.popInfo = {
                            popTitle: that.$i18n.t(
                              "front-kefu-moudle-connected"
                            ),
                            linkName:
                              resResultUser.data[0].cname +
                              "-" +
                              resResultUser.data[0].aname,
                            phoneCity: resResult.data.place,
                            phone: resResult.data.phone,
                          };
                        }
                        that.showPopup = true;
                        that.incomeBool = false;
                        that.upTRbar();
                        that.closeTestTime();
                        that.testNet();
                        that.updatePhoneStateIng();
                      });
                  }
                });
              }

              // 转接响铃
              if (e.code == "RINGING" && e.action == "ringingTransfer") {
                let params = {
                  bindUserId: localStorage.getItem("userId"),
                };
                callCenterApi.getBindByCnoOrUserId(params).then((res) => {
                  if (
                    res.data[0].phonetype == "3" ||
                    res.data[0].phonetype == "4"
                  ) {
                    let paramsResult = {
                      phone: e.customerNumber,
                    };
                    // 获取号码归属地
                    callCenterApi
                      .getPhoneCity(paramsResult)
                      .then((resResult) => {
                        if (resResult.result) {
                          let paramsphonenumber = {
                            phonenumber: e.customerNumber,
                          };
                          // 识别联系人
                          callCenterApi
                            .IdentifyPhonenumber(paramsphonenumber)
                            .then((resResultUser) => {
                              that.Identifyuser = resResultUser.data.length;
                              that.identifyMore =
                                resResultUser.data.length !== 1 ? true : false; // 判断识别是不是多个联系人
                              if (resResultUser.data.length !== 1) {
                                that.popInfo = {
                                  popTitle: that.$i18n.t(
                                    "front-kefu-moudle-phonein"
                                  ),
                                  linkName: resResult.data.phone,
                                  phoneCity: resResult.data.place,
                                  phone: resResult.data.phone,
                                };
                              } else {
                                that.popInfo = {
                                  popTitle: that.$i18n.t(
                                    "front-kefu-moudle-phonein"
                                  ),
                                  linkName:
                                    resResultUser.data[0].cname +
                                    "-" +
                                    resResultUser.data[0].aname,
                                  phoneCity: resResult.data.place,
                                  phone: resResult.data.phone,
                                };
                              }
                              that.showPopup = true;
                              that.incomeBool = true;
                              that.upTRbar();
                              that.testTime();
                              that.testNet();
                            });
                        }
                      });
                  }
                });
                that.upTRbar();
              }

              // 转移接听
              if (e.code == "BUSY" && e.action == "busyTransfer") {
                let paramsResult = {
                  phone: e.customerNumber,
                };
                callCenterApi.getPhoneCity(paramsResult).then((resResult) => {
                  if (resResult.result) {
                    let paramsphonenumber = {
                      phonenumber: e.customerNumber,
                    };
                    callCenterApi
                      .IdentifyPhonenumber(paramsphonenumber)
                      .then((resResultUser) => {
                        that.Identifyuser = resResultUser.data.length;
                        that.identifyMore =
                          resResultUser.data.length !== 1 ? true : false; // 判断识别是不是多个联系人
                        if (resResultUser.data.length !== 1) {
                          that.popInfo = {
                            popTitle: that.$i18n.t(
                              "front-kefu-moudle-connected"
                            ),
                            linkName: resResult.data.phone,
                            phoneCity: resResult.data.place,
                            phone: resResult.data.phone,
                          };
                        } else {
                          that.popInfo = {
                            popTitle: that.$i18n.t(
                              "front-kefu-moudle-connected"
                            ),
                            linkName:
                              resResultUser.data[0].cname +
                              "-" +
                              resResultUser.data[0].aname,
                            phoneCity: resResult.data.place,
                            phone: resResult.data.phone,
                          };
                        }
                        that.showPopup = true;
                        that.incomeBool = false;
                        that.upTRbar();
                        that.closeTestTime();
                        that.testNet();
                        let params = {
                          bindCno: e.cno,
                        };
                        callCenterApi
                          .getBindByCnoOrUserId(params)
                          .then((res) => {
                            let recordParmas = {
                              mainUniqueId: e.mainUniqueId,
                            };
                            // 根据语音记录查询语音记录id
                            callCenterApi
                              .getVoiceByVoicerecording(recordParmas)
                              .then((recordRes) => {
                                let updataObj = {
                                  istransferconversation: "true", // 是否转接
                                  transferconversationtoid:
                                    res.data[0].binduserid, // 转接客服
                                  transferconversationreason: "", // 转接原因
                                  id: recordRes.data, // 语音记录id
                                };
                                callCenterApi
                                  .updateTransfer(updataObj)
                                  .then(() => {});
                              });
                          });
                        that.updatePhoneStateIng();
                      });
                  }
                });
              }

              // 外呼漏接
              if (e.code == "IDLE" && e.action == "ringingCustomerNoAnswerOb") {
                let paramsphonenumber = {
                  phonenumber: that.outPhone,
                };
                callCenterApi
                  .IdentifyPhonenumber(paramsphonenumber)
                  .then((resResultUser) => {
                    that.Identifyuser = resResultUser.data.length;
                    that.identifyMore =
                      resResultUser.data.length !== 1 ? true : false; // 判断识别是不是多个联系人
                    let paramsInfo = {
                      intergrateType: that.countryBool ? "tianrun" : "aws",
                    };
                    // 获取呼叫中心设置
                    callCenterApi
                      .getCallCenterInfo(paramsInfo)
                      .then((resInfo) => {
                        if (resInfo.result && resInfo.data) {
                          if (
                            resInfo.data.callMissed == "3" ||
                            resInfo.data.callMissed == "1"
                          ) {
                            let paramsSavecase = {
                              callStatus: "00",
                              contactId:
                                resResultUser.data.length !== 1
                                  ? ""
                                  : resResultUser.data[0].cid, // 联系人
                              accountId:
                                resResultUser.data.length !== 1
                                  ? ""
                                  : resResultUser.data[0].aid, // 客户名称
                              visitorId: "",
                              type: "tianrun",
                              phoneoremail: e.customerNumber,
                              generatecase: "Call out",
                            };
                            // 呼叫中心保存个案
                            that.saveCase(paramsSavecase);
                          }
                        }
                      });
                  });
              }

              // 呼入漏接
              if (e.code == "IDLE" && e.action == "ringingNoAnswerIb") {
                let paramsphonenumber = {
                  phonenumber: that.inPhone,
                };
                callCenterApi
                  .IdentifyPhonenumber(paramsphonenumber)
                  .then((resResultUser) => {
                    that.Identifyuser = resResultUser.data.length;
                    that.identifyMore =
                      resResultUser.data.length !== 1 ? true : false; // 判断识别是不是多个联系人
                    let paramsInfo = {
                      intergrateType: that.countryBool ? "tianrun" : "aws",
                    };
                    // 获取呼叫中心设置
                    callCenterApi
                      .getCallCenterInfo(paramsInfo)
                      .then((resInfo) => {
                        if (resInfo.result && resInfo.data) {
                          if (
                            resInfo.data.callMissed == "2" ||
                            resInfo.data.callMissed == "1"
                          ) {
                            let paramsSavecase = {
                              callStatus: "01",
                              contactId: "",
                              accountId: "",
                              visitorId: that.voiceObj.visitorid,
                              type: "tianrun",
                              phoneoremail: that.inPhone,
                              generatecase: "Call in",
                            };
                            // 呼叫中心保存个案
                            that.saveCase(paramsSavecase);
                            that.showPopup = false;
                            that.closeTestTime();
                            that.closeTestNet();
                          }
                        }
                      });
                  });
              }

              // 通话结束或整理状态，弹屏消失
              if (e.code == "IDLE" || e.code == "WRAPUP") {
                that.showPopup = false;
                that.closeTestTime();
                that.closeTestNet();
                this.controlReply = false;
              }
              if (
                e.code == "WRAPUP" ||
                (e.code == "IDLE" && e.action == "hangup")
              ) {
                that.voiceObj.voicestatus = that.$i18n.t(
                  "label.htyj.completed"
                ); // 语音状态
                that.voiceObj.voiceclosetime = that.formatData(e.startTime); //结束时间
                that.voiceObj.voiceduration = Math.ceil(
                  (e.startTime - that.voiceObj.customVoicestarttime) / 60
                ); // 持续时间
                if (that.callConnected == "2") {
                  // 打开人工创建个案
                  that.peopleCreateCase = true;
                } else if (that.callConnected == "1") {
                  let dataList = [];
                  dataList.push(that.voiceObj);
                  let paramsSave = {
                    objectApi: "Voice",
                    data: JSON.stringify(dataList),
                    marketsea: "false",
                  };
                  that.save(paramsSave);
                }
                that.updatePhoneStated();
                this.controlReply = false;
              }
            }
          );
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.PREVIEW_OUTCALL_RINGING,
            () => {}
          );
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.PREVIEW_OUTCALL_BRIDGE,
            (e) => {
              // 所有电话登录，呼出接听
              if (e.code == "BUSY" && e.action == "busyOb") {
                let params = {
                  bindUserId: localStorage.getItem("userId"),
                };
                callCenterApi.getBindByCnoOrUserId(params).then((res) => {
                  let paramsResult = {
                    phone: e.customerNumber,
                  };
                  callCenterApi.getPhoneCity(paramsResult).then((resResult) => {
                    if (resResult.result) {
                      let paramsphonenumber = {
                        phonenumber: e.customerNumber,
                      };
                      callCenterApi
                        .IdentifyPhonenumber(paramsphonenumber)
                        .then((resResultUser) => {
                          that.Identifyuser = resResultUser.data.length;
                          that.identifyMore =
                            resResultUser.data.length !== 1 ? true : false; // 判断识别是不是多个联系人
                          if (resResultUser.data.length !== 1) {
                            that.popInfo = {
                              popTitle: that.$i18n.t(
                                "front-kefu-moudle-connected"
                              ),
                              linkName: resResult.data.phone,
                              phoneCity: resResult.data.place,
                              phone: resResult.data.phone,
                            };
                          } else {
                            that.popInfo = {
                              popTitle: that.$i18n.t(
                                "front-kefu-moudle-connected"
                              ),
                              linkName:
                                resResultUser.data[0].cname +
                                "-" +
                                resResultUser.data[0].aname,
                              phoneCity: resResult.data.place,
                              phone: resResult.data.phone,
                            };
                          }
                          that.showPopup = true;
                          that.incomeBool = false;
                          that.upTRbar();
                          that.closeTestTime();
                          that.testNet();
                          that.voiceObj.voicerecording = e.mainUniqueId; //语音编号
                          that.voiceObj.agentid = res.data[0].binduserid; // 客服
                          that.voiceObj.callingtype = that.$i18n.t(
                            "label.callcenter.call.out"
                          ); // 呼叫类型
                          that.voiceObj.callingnumber = res.data[0].phone; // 主叫号码
                          that.voiceObj.callednumber = e.customerNumber; // 被叫号码
                          that.voiceObj.voicestatus =
                            that.$i18n.t("label.starting"); // 语音状态
                          that.voiceObj.istransferconversation = "false"; // 是否转接
                          that.voiceObj.transferconversationtoid = ""; // 转接客服
                          that.voiceObj.transferconversationreason = ""; // 转接原因
                          that.voiceObj.visitorid = ""; // 访客编号
                          that.voiceObj.lbsaddress = resResult.data.place; // 地址
                          that.voiceObj.contactid =
                            resResultUser.data.length !== 1
                              ? ""
                              : resResultUser.data[0].cid; // 联系人
                          that.voiceObj.accountid =
                            resResultUser.data.length !== 1
                              ? ""
                              : resResultUser.data[0].aid; // 客户名称
                          that.identifyMore =
                            resResultUser.data.length !== 1 ? true : false; // 判断识别是不是多个联系人
                          that.voiceObj.voicestarttime = that.formatData(
                            that.timest()
                          ); // 开始时间
                          that.voiceObj.voiceclosetime = ""; // 结束时间
                          that.voiceObj.voiceduration = ""; // 会话时长
                          that.voiceObj.createbyid = res.data[0].binduserid; // 创建人
                          that.voiceObj.createdate = that.formatData(
                            that.timest()
                          ); // 创建时间
                          that.voiceObj.customVoicestarttime = that.timest(); // 自计算开始时间
                          if (that.callConnected == "1") {
                            let paramsSavecase = {
                              callStatus: "1",
                              contactId: that.voiceObj.contactid,
                              accountId: that.voiceObj.accountid,
                              visitorId: that.voiceObj.visitorid,
                              type: "tianrun",
                              phoneoremail: e.customerNumber,
                              generatecase: "Call out",
                            };
                            that.saveCase(paramsSavecase, true);
                          } else if (that.callConnected == "2") {
                            that.phoneOrEmail = e.customerNumber;
                            that.lxrdh = e.customerNumber;
                            that.emailaddress =
                              resResultUser.data.length !== 1
                                ? ""
                                : resResultUser.data[0].cemail;
                          }
                          that.updatePhoneStateIng();
                        });
                    }
                  });
                });
              }
            }
          );
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.CONSULT_START,
            () => {}
          );
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.CONSULT_LINK,
            () => {}
          );
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.CONSULT_ERROR,
            () => {}
          );
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.UNCONSULT,
            () => {}
          );
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.CONSULT_THREEWAY,
            () => {}
          );
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.CONSULT_THREEWAY_UNLINK,
            () => {}
          );
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.CONSULT_TRANSFER,
            () => {}
          );
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.RINGING,
            () => {}
          );
          clinkToolbar.userCustomEvent.on(
            window.ClinkAgent.EventType.KICKOUT,
            () => {
              that.onlineCloseBool = true;
              that.notLogin = true;
              that.hasLogin = false;
              that.hasLoginUp = false;
              that.showTianRunBar = false;
              that.showPopup = false;
              that.closeTestTime();
              that.closeTestNet();
            }
          );
        });
      }
    },

    // 登陆操作
    loginOperation() {
      // 登录操作
      let params = {
        bindUserId: localStorage.getItem("userId"),
      };
      callCenterApi.getBindByCnoOrUserId(params).then((res) => {
        let bindtype = null;
        if (res.data[0].phonetype == "1" || res.data[0].phonetype == "2") {
          bindtype = 1;
        } else if (res.data[0].phonetype == "3") {
          bindtype = 2;
        } else if (res.data[0].phonetype == "4") {
          bindtype = 3;
        }
        let TRparams = {};
        if (this.userloginDialog) {
          TRparams = {
            identifier: res.data[0].enterpriseNo,
            cno: this.loginInfoPass.bindCno,
            password: this.loginInfoPass.tianRunPwd,
            bindTel: res.data[0].phone,
            bindType: bindtype,
            loginStatus: 1,
          };
        } else {
          TRparams = {
            identifier: res.data[0].enterpriseNo,
            cno: res.data[0].bindcno,
            password: res.data[0].tianRunPwd,
            bindTel: res.data[0].phone,
            bindType: bindtype,
            loginStatus: 1,
          };
        }
        window.ClinkAgent.login(TRparams);
      });
    },

    // 保存密码
    savePassword() {
      let params = {
        bindCno: this.loginInfoPass.bindCno,
        tianRunPwd: this.loginInfoPass.tianRunPwd,
      };
      callCenterApi.saveTianRunLogin(params).then((res) => {
        if (!res.result) {
          this.$message({
            message: this.$i18n.t("chatter.save.f"),
            type: "error",
          });
        }
      });
    },

    // 关闭普通用户登录框
    closeuserlogindialog() {
      this.userloginDialog = false;
      this.loginLoading = false;
    },

    // 展开电话条
    upTRbar() {
      this.notLogin = false;
      this.hasLogin = false;
      this.hasLoginUp = true;
      this.showTianRunBar = true;
    },

    // 合上电话条
    closeTRbar() {
      this.notLogin = false;
      this.hasLogin = true;
      this.hasLoginUp = false;
      this.showTianRunBar = false;
    },

    // 来电接听软电话
    answerPhone() {
      window.ClinkAgent.sipLink();
    },

    // 来电拒接软电话
    refusePhone() {
      window.ClinkAgent.refuse();
    },

    // 外呼挂断电话
    hangupPhone() {
      let params = {
        bindUserId: localStorage.getItem("userId"),
      };
      callCenterApi.getBindByCnoOrUserId(params).then((res) => {
        if (res.data[0].phonetype == "3" || res.data[0].phonetype == "4") {
          // 挂断软电话
          window.ClinkAgent.sipUnlink();
        } else {
          // 挂断普通电话
          window.ClinkAgent.previewOutcallCancel();
        }
      });
    },

    // 检测等待时间
    testTime() {
      if (this.timer == null) {
        this.timer = setInterval(() => {
          this.waitNum++;
        }, 1000);
      }
    },

    // 清除等待时间
    closeTestTime() {
      this.timer = clearInterval(this.timer);
      this.waitNum = 1;
    },

    // 检测网络状态
    testNet() {
      if (this.netTimer == null) {
        this.netTimer = setInterval(() => {
          this.netstate = window.navigator.onLine;
        }, 3000);
      }
    },

    // 清除检测网络状态
    closeTestNet() {
      this.netTimer = clearInterval(this.netTimer);
    },

    // 更改通话中状态
    updatePhoneStateIng() {
      let params = {
        bindUserId: localStorage.getItem("userId"),
      };
      callCenterApi.getBindByCnoOrUserId(params).then((res) => {
        let paramsUp = {
          bindUserId: res.data[0].binduserid,
          bindCno: res.data[0].bindcno,
          id: res.data[0].id,
          phoneState: "1",
        };
        callCenterApi.updateClientUser(paramsUp).then(() => {});
      });
    },

    // 更改通话结束状态
    updatePhoneStated() {
      let params = {
        bindUserId: localStorage.getItem("userId"),
      };
      callCenterApi.getBindByCnoOrUserId(params).then((res) => {
        let paramsUp = {
          bindUserId: res.data[0].binduserid,
          bindCno: res.data[0].bindcno,
          id: res.data[0].id,
          phoneState: "0",
        };
        callCenterApi.updateClientUser(paramsUp).then(() => {});
      });
    },

    // 保存语音记录
    save(paramsSave, bool) {
      // 如果是控制台回复 不建个案
      if (this.controlReply) {
        if (this.identifyMore) {
          delete paramsSave.contactId;
          delete paramsSave.accountId;
        }
        callCenterApi.save(paramsSave).then((res) => {
          if (paramsSave.objectApi == "cloudcccase") {
            this.voiceObj.caseid = res.data[0].id;
          }
          this.$bus.$emit("reloadControl", false);
        });
      } else {
        if (this.identifyMore) {
          delete paramsSave.contactId;
          delete paramsSave.accountId;
        }
        callCenterApi.save(paramsSave).then((res) => {
          if (paramsSave.objectApi == "cloudcccase") {
            this.voiceObj.caseid = res.data[0].id;
          }
          if (bool == true) {
            let dataList = [];
            dataList.push(this.voiceObj);
            let paramsSave = {
              objectApi: "Voice",
              data: JSON.stringify(dataList),
              marketsea: "false",
            };
            // 先查权限再新建
            callCenterApi
              .getObjectPermission({ id: "201100000005024CxOSz" })
              .then((res) => {
                if (res.data.add) {
                  this.save(paramsSave);
                }
              });
          }
          this.$bus.$emit("reloadControl", false);
        });
      }
    },

    // 手动确认新建个案
    confirmCreateCase(id, name, type) {
      var dataList = [];
      var paramsSave = {
        marketsea: "false",
      };
      if (type == "2") {
        var caseObj = {
          galy: this.$i18n.t("label.phone"),
          ownerid: localStorage.getItem("userId"),
          lastmodifybyid: localStorage.getItem("userId"),
          vname: this.voiceObj.visitorid,
          // lxrxm: this.voiceObj.contactid,
          // khmc: this.voiceObj.accountid,
          lxrdh: this.lxrdh,
          emailaddress: this.emailaddress,
          id,
        };
        if (this.Identifyuser == "1") {
          caseObj.lxrxm = this.voiceObj.contactid;
          caseObj.khmc = this.voiceObj.accountid;
          caseObj.emailaddress = this.emailaddress;
        }
        dataList.push(caseObj);
        paramsSave.data = JSON.stringify(dataList);
        paramsSave.objectApi = "cloudcccase";
        // this.$router.push({
        //   path: `/commonObjects/detail/${id}/DETAIL`,
        // });
        this.$router.push({
          path: "/controlPanel/control",
          query: {
            caseId: id,
            caseOrder: name,
            viewId: "aec201402480758DdbFK",
          },
        });
        this.save(paramsSave, true);
      } else {
        this.voiceObj.caseid = id;
        dataList.push(this.voiceObj);
        paramsSave.data = JSON.stringify(dataList);
        paramsSave.objectApi = "Voice";
        this.save(paramsSave, false);
      }
      this.peopleCreateCase = false;
    },

    // 保存个案记录
    saveCase(paramsSavecase, jumpBool) {
      // 如果是控制台回复 不建个案
      if (!this.controlReply) {
        // 先查权限再新建
        callCenterApi
          .getObjectPermission({ id: "201100000005024CxOSz" })
          .then((res) => {
            if (res.data.add) {
              if (this.identifyMore) {
                delete paramsSavecase.contactId;
                delete paramsSavecase.accountId;
              }
              // 呼叫中心保存个案
              callCenterApi.saveCase(paramsSavecase).then((res) => {
                // 获取个案id
                this.voiceObj.caseid = res.data.id;
                this.caseNumber = res.data.name;
                if (jumpBool) {
                  // 跳转服务控制台详情页
                  this.$router.push({
                    path: "/controlPanel/control",
                    query: {
                      caseId: this.voiceObj.caseid,
                      caseOrder: this.caseNumber,
                      viewId: "aec201402480758DdbFK",
                    },
                  });
                }
              });
            }
          });
      }
    },

    // 关闭手动自建个案
    closeCreateCaseDialog() {
      this.peopleCreateCase = false;
    },

    // 获取当前十位时间戳
    timest() {
      var tmp = Date.parse(new Date()).toString();
      tmp = tmp.substr(0, 10);
      return tmp;
    },

    // 时间戳转换
    formatData(val) {
      if (val) {
        var date = new Date(val * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var D =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
        var h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":";
        var m =
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) + ":";
        var s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      }
    },

    /**
     * 追加JS对象
     */
    appendScript(item, callback) {
      let scripts = document.body.getElementsByTagName("script");
      for (let i = 0; i < scripts.length; i++) {
        if (item == scripts[i].src) return;
      }
      const s = document.createElement("script");
      callback && (s.onload = callback);
      s.type = "text/javascript";
      s.src = item;
      document.body.appendChild(s);
    },
    subscribeToContactEvents(contact) {
      this.getCallCenterInfo("aws");
      this.voiceObj.voicerecording =
        contact.getContactId() + "," + this.loginName;
      this.voiceObj.agentid = localStorage.getItem("userId");
      this.voiceObj.createbyid = localStorage.getItem("userId");
      this.IdentifyPhonenumber(
        contact.getActiveInitialConnection().getEndpoint().phoneNumber
      );
      // 订阅一个在联系人更新时要调用的方法。
      contact.onRefresh(() => {
        //
      });
      // 订阅要在联系人连接时调用的方法。
      contact.onConnecting(() => {
        //
      });
      // 订阅要在联系挂起时调用的方法。
      contact.onPending(() => {
        //
      });
      // 订阅一个方法，在联系丢失时调用。
      contact.onMissed(() => {
        this.missSign = true;
        var params = {
          callStatus: "",
          // contactId: this.voiceObj.contactid,
          // accountId: this.voiceObj.accountid,
          visitorId: "",
          type: "aws",
          phoneoremail: this.phoneOrEmail,
        };
        if (this.Identifyuser == "1") {
          params.contactId = this.voiceObj.contactid;
          params.accountId = this.voiceObj.accountid;
        }
        if (
          this.voiceObj.callingtype ==
            this.$i18n.t("label.callcenter.call.in") &&
          this.callMissed == "2"
        ) {
          params.callStatus = "01";
          params.visitorId = this.voiceObj.visitorid;
          params.generatecase = "Call in";
          this.saveCase(params);
        } else if (
          this.voiceObj.callingtype ==
            this.$i18n.t("label.callcenter.call.out") &&
          this.callMissed == "3"
        ) {
          params.callStatus = "00";
          params.generatecase = "Call out";
          this.saveCase(params);
        } else if (
          this.voiceObj.callingtype ==
            this.$i18n.t("label.callcenter.call.in") &&
          this.callMissed == "1"
        ) {
          params.callStatus = "01";
          params.generatecase = "Call in";
          this.saveCase(params);
        } else if (
          this.voiceObj.callingtype ==
            this.$i18n.t("label.callcenter.call.out") &&
          this.callMissed == "1"
        ) {
          params.callStatus = "00";
          params.generatecase = "Call out";
          this.saveCase(params);
        }
        //
      });
      // 订阅一个在联系结束或销毁时要调用的方法。
      contact.onEnded(() => {
        this.num = this.num + 1;
        if (this.callConnected == "1" && !this.missSign && this.num % 2 == 0) {
          return;
        }
        this.voiceObj.voicestatus = this.$i18n.t("label.htyj.completed");
        this.voiceObj.voiceclosetime = this.formatData(this.timest());
        this.voiceObj.createdate = this.formatData(this.timest());
        this.voiceObj.voiceduration = Math.ceil(
          (Date.parse(new Date()) - this.AWSstartTime) / (60 * 1000)
        );
        // this.voiceObj.caseid = this.caseId;
        if (this.callConnected == "1" && !this.missSign) {
          let dataList = [];
          dataList.push(this.voiceObj);
          let paramsSave = {
            objectApi: "Voice",
            data: JSON.stringify(dataList),
          };
          this.save(paramsSave, false);
        } else if (this.callConnected == "2" && !this.missSign) {
          this.peopleCreateCase = true;
        }
      });
      // 订阅队列回调联系人传入时要调用的方法。
      contact.onIncoming(() => {
        //
      });
      // 订阅连接联系人时要调用的方法。
      contact.onConnected(() => {
        this.missSign = false;
        this.voiceObj.voicestarttime = this.formatData(this.timest());
        this.AWSstartTime = Date.parse(new Date());
        if (this.callConnected == "1") {
          let params = {
            callStatus: "1",
            // contactId: this.voiceObj.contactid,
            // accountId: this.voiceObj.accountid,
            visitorId: this.voiceObj.visitorid ? this.voiceObj.visitorid : "",
            type: "aws",
            phoneoremail: this.lxrdh || this.emailaddress,
            generatecase:
              this.voiceObj.callingtype ==
              this.$i18n.t("label.callcenter.call.in")
                ? "Call in"
                : "Call out",
          };
          if (this.Identifyuser == "1") {
            params.contactId = this.voiceObj.contactid;
            params.accountId = this.voiceObj.accountid;
          }
          this.saveCase(params, true);
        }
        this.phoneOrEmail = this.lxrdh || this.emailaddress;
        this.vistorId = this.voiceObj.visitorid ? this.voiceObj.visitorid : "";
      });
      // 订阅一个方法，当联系人被接受时调用。
      contact.onAccepted(() => {
        //
      });
      contact.onDestroy(() => {
        this.peopleCreateCase = false;
      });
      contact.accept({
        success: function () {
          //
        },
        failure: function () {
          //
        },
      });
      // 电话呼入时判断
      if (contact.isInbound()) {
        this.creatVisitor(
          contact.getActiveInitialConnection().getEndpoint().phoneNumber
        );
        this.awsPhone = true;
        this.voiceObj.callingtype = this.$i18n.t("label.callcenter.call.in");
        this.voiceObj.voicestatus = this.$i18n.t("label.starting");
        this.voiceObj.callednumber = "";
        this.voiceObj.callingnumber = contact
          .getActiveInitialConnection()
          .getEndpoint().phoneNumber;
        // }
        //
      } else {
        this.voiceObj.callingtype = this.$i18n.t("label.callcenter.call.out");
        this.voiceObj.callingnumber = "";
        this.voiceObj.visitorid = "";
        this.voiceObj.callednumber = contact
          .getActiveInitialConnection()
          .getEndpoint().phoneNumber;
        // }
      }
      if (contact.isConnected()) {
        //
      }
    },
    // 海外初始化
    inittel(name) {
      this.loginName = name;
      this.awsPhone = false;
      this.onlineBoolIcon = true;
      var containerDiv = document.getElementById("container-div");
      var instanceURL = `https://${name}.my.connect.aws/ccp-v2`;
      this.loading = true;
      window.connect.core.initCCP(containerDiv, {
        ccpUrl: instanceURL, // REQUIRED
        loginPopup: true, // optional, defaults to `true`
        loginPopupAutoClose: false, // optional, defaults to `false`
        loginOptions: {
          // optional, if provided opens login in new window
          autoClose: true, // optional, defaults to `false`
          height: 600, // optional, defaults to 578
          width: 400, // optional, defaults to 433
          top: 0, // optional, defaults to 0
          left: 0, // optional, defaults to 0
        },
        region: "eu-central-1", // REQUIRED for `CHAT`, optional otherwise
        softphone: {
          // optional, defaults below apply if not provided
          allowFramedSoftphone: true, // optional, defaults to false
          disableRingtone: false, // optional, defaults to false
          ringtoneUrl: "./ringtone.mp3", // optional, defaults to CCP’s default ringtone if a falsy value is set
        },
        pageOptions: {
          //optional
          enableAudioDeviceSettings: false, //optional, defaults to 'false'
          enablePhoneTypeSettings: true, //optional, defaults to 'true'
        },
        ccpAckTimeout: 3000, //optional, defaults to 3000 (ms)
        ccpSynTimeout: 1000, //optional, defaults to 1000 (ms)
        ccpLoadTimeout: 3000, //optional, defaults to 5000 (ms)
      });
      const eventBus = window.connect.core.getEventBus();
      eventBus.subscribe(window.connect.EventType.TERMINATED, () => {
        document.location.reload();
        // Do stuff...
      });
      eventBus.subscribe(window.connect.EventType.ACKNOWLEDGE, () => {
        this.loading = false;
        // this.awsPhone = true
        // document.location.reload();
        // Do stuff...
      });
      window.connect.contact(this.subscribeToContactEvents);

      // window.connect.agent(this.tel);
    },

    newCLick(phone) {
      this.controlReply = true;
      if (this.countryBool) {
        let params = {
          bindUserId: localStorage.getItem("userId"),
        };
        callCenterApi.getBindByCnoOrUserId(params).then((res) => {
          if (res.data.length == 0) {
            this.$message(this.$i18n.t("front-kefu-moudle-nopower-calluser"));
          } else {
            if (this.notLogin) {
              this.openBar();
            } else {
              if (phone) {
                let params = {
                  tel: phone,
                };
                window.ClinkAgent.previewOutcall(params);
              } else {
                this.upTRbar();
              }
            }
          }
        });
      } else {
        if (!this.onlineBoolIcon) {
          this.open();
        } else {
          this.close();
          var endpoint = "";
          if (phone.length == 11) {
            endpoint = window.connect.Endpoint.byPhoneNumber("+86" + phone);
          } else {
            endpoint = window.connect.Endpoint.byPhoneNumber(phone);
          }
          var agent = new window.connect.Agent();
          agent.connect(endpoint, {
            success: function () {
              //
            },
            failure: function () {
              //
            },
          });
        }
      }
    },

    // 跳转AWS电话盘页面
    jump() {
      var awidth = 320; //窗口宽度
      var aheight = 800; //窗口高度
      var atop = (screen.availHeight - aheight) / 2; //窗口顶部位置
      var aleft = (screen.availWidth - awidth) / 2; //窗口放中央
      var ccpUrl = `https://${this.loginName}.my.connect.aws/ccp-v2`;
      window.open(
        ccpUrl,
        "newwindow",
        "height=465, width=320, top=" +
          atop +
          ", left=" +
          aleft +
          ", toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      );
    },

    creatVisitor(phonenumber) {
      callCenterApi.creatVisitor({ phonenumber }).then((res) => {
        this.voiceObj.visitorid = res.data;
      });
    },
    phoneDown() {
      this.onlineBoolIcon = true;
      this.awsPhone = false;
    },

    // 关闭amazon connect框
    closeenglogindialog() {
      this.engloginDialog = false;
      this.loginLoading = false;
    },
    openenglogindialog() {
      this.engloginDialog = true;
      this.awsPhone = false;
    },
    open() {
      if (this.ClickBool) {
        this.$refs.InstanceName.getInstanceAlias();
      }
    },
    close() {
      this.awsPhone = true;
    },
    IdentifyPhonenumber(phonenumber) {
      // 识别联系人
      let params = {
        phonenumber: phonenumber,
      };
      callCenterApi.IdentifyPhonenumber(params).then((res) => {
        this.lxrdh = phonenumber;
        this.Identifyuser = res.data.length;
        if (res.data.length == 1) {
          this.voiceObj.contactid = res.data[0].cid ? res.data[0].cid : "";
          this.emailaddress = res.data[0].cemail ? res.data[0].cemail : "";
          this.phoneOrEmail = this.lxrdh || this.emailaddress;
          this.voiceObj.accountid = res.data[0].aid;
        }
        this.vistorId = this.voiceObj.visitorid ? this.voiceObj.visitorid : "";
      });
    },
    /**
     * 只有一条数据时进入联系人详情页
     */
    handleToContactDetail() {
      if (this.Identifyuser == "1") {
        this.$router.push({
          path: `/commonObjects/detail/${this.voiceObj.contactid}/DETAIL`,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.call-bar {
  position: relative;
  height: 43px;
  display: flex;
  align-items: center;
  .iconBox {
    z-index: 9999;
    cursor: pointer;
    position: absolute;
    right: 10px;
    .showTianRunClass {
      display: flex;
      align-items: center;
      .Unloggedphone_span {
        display: flex;
        // flex-direction: column;
        align-items: center;
        .Unloggedphone {
          width: 24px;
          height: 24px;
          use {
            // fill: #999999;
          }
        }
        span {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          margin-left: 10px;
          cursor: pointer;
          // color: #999999;
        }
      }
      // .Unloggedphone_span:hover {
      //   use {
      //     fill: #333333;
      //   }
      //   span {
      //     color: #333333;
      //   }
      // }
      .AWShasLogclose {
        width: 24px;
        height: 24px;
        use {
          fill: #333333;
        }
      }
      .TRhasLogclose {
        width: 24px;
        height: 24px;
        use {
          fill: #999999;
        }
      }
    }
  }
  .uniquePhone {
    position: absolute;
    right: 40px;
    ::v-deep .tinet-logout span {
      cursor: pointer;
    }
    ::v-deep .tinet-status-list {
      cursor: pointer;
    }
    ::v-deep .tinet-tel-input-container .tinet-phone-num {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 4px;
      width: 100px !important;
    }
  }
  .lessIcon {
    width: 16px;
    height: 16px;
    margin: 0 12px;
    display: flex;
    cursor: pointer;
  }
}
.screen_PopUp {
  width: 350px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  position: fixed;
  right: 20px;
  bottom: 50px;
  z-index: 9999;
  .popTitle {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #2d6cfc;
    margin-top: 36px;
    text-align: center;
  }
  .svgIcon {
    display: flex;
    justify-content: center;
  }
  .userInfo {
    width: 350px;
    margin-top: 32px;
    margin-bottom: 10px;
    text-align: center;
    padding: 0 15px;
    .linkman {
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #080707;
    }
    .linkman-active {
      cursor: pointer;
      color: #2d6cfc;
    }
    .linkArea {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      margin-top: 8px;
    }
    .linkPhone {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #080707;
      margin-top: 8px;
    }
  }
  .phoneborder {
    width: 318px;
    height: 60px;
    margin: 0 auto;
    background: #f5f7fa;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    svg {
      position: absolute;
      bottom: 4px;
      right: 4px;
    }
  }
  .operationBtn {
    text-align: center;
    margin: 20px 0;
    ::v-deep .el-button {
      width: 124px;
      margin-left: 22px;
    }
  }
}
.aws-phone {
  position: fixed;
  bottom: 47px;
  right: 0;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  z-index: 10000;
  .narrow {
    font-weight: bold;
    padding: 0 10px 10px 10px;
  }
  .aws-phone-header {
    height: 30px;
    // float: right;
    padding-top: 10px;
    display: flex; //flex布局  居右
    justify-content: flex-end;
    background: #ffffff;
    z-index: 10002;
  }
}
::v-deep .el-loading-spinner {
  margin-top: -7px;
}
</style>
