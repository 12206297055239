<template>
  <div class="applicationBox">
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="75%"
      custom-class="dialog"
    >
      <!-- 选择你需要的解决方案 -->
      <div class="dialog-content" v-show="!isNextShow">
        <div class="header">
          <!-- 选择你需要的解决方案 -->
          <div class="header-tittle">{{ $t("c392") }}</div>
          <!-- CloudCC可以帮助您改善整个公司的额流程，我们已经创建了一些使用的解决方案，以帮助您快速入门。
            请首选选择您要设置的解决方案，我们将引导您完成设置。您不用担心，您随后也可以随时根据需要进行更多操作，而这些都不需要任何额外费用。 -->
          <div class="header-text">
            {{ $t("c394") }}
          </div>
        </div>
        <div class="mainBox">
          <div
            class="application-item"
            v-for="(item, index) in applicationArr"
            :key="index"
            :class="{ chooseClass: choseId === index }"
            @click="choseMth(index, item)"
          >
            <div class="leftimg">
              <svg class="icon" aria-hidden="true">
                <use :href="item.img"></use>
              </svg>
            </div>
            <div class="righttext">
              <div>{{ item.p1 }}</div>
              <div>{{ item.p2 }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 选择应用程序后的  下一步 -->
      <div class="dialog-contentTwo" v-show="isNextShow">
        <div class="mainContent">
          <!-- 应用程序 对应的 描述 -->
          <div class="cont-tittle">{{ programDesTittle }}</div>
          <div class="cont-text">
            {{ programDesText }}
          </div>
          <!-- 市场云宣传图 -->
          <div class="cont-img shichang-img" v-if="shichangIMG">
            <img src="./image/shichang.png" alt="" />
            <!-- 网站访客追踪 -->
            <div class="imgone">{{ $t("c505") }}</div>
            <!-- 社交媒体 -->
            <div class="imgtwo">
              {{ $t("vue_label_systemSettings_social") }}
            </div>
            <!-- 邮件 -->
            <div class="imgthree">{{ $t("label.chatter.mail") }}</div>
            <!-- 市场活动 -->
            <div class="imgfour">{{ $t("vue_label_normal_campaign_sf") }}</div>
            <!-- 市场云 -->
            <div class="imgfive">{{ $t("c292") }}</div>
            <!-- 表单 -->
            <div class="imgsix">{{ $t("label.form") }}</div>
            <!-- 自动化日程管理 -->
            <div class="imgseven">{{ $t("c506") }}</div>
            <!-- 潜客培养 -->
            <div class="imgeight">{{ $t("c507") }}</div>
            <!-- ROI投资回报分析 -->
            <div class="imgnine">{{ $t("c508") }}</div>
          </div>
          <!-- 销售云宣传图 -->
          <div class="cont-img xiaoshou-img" v-if="xiaoshouIMG">
            <img src="./image/xiaoshou.png" alt="" />
            <!-- CCChat -->
            <div class="imgone">CCChat</div>
            <!-- 邮件 -->
            <div class="imgtwo">{{ $t("label.chatter.mail") }}</div>
            <div class="imgthree">BI</div>
            <!-- 销售漏斗 -->
            <div class="imgfour">
              {{ $t("label.mobile.quickcreate.default.sellingfunnel") }}
            </div>
            <!-- 联系人 -->
            <div class="imgfive">{{ $t("label.tabpage.contactsz") }}</div>
            <!-- 销售云 -->
            <div class="imgsix">{{ $t("c279") }}</div>
            <!-- 业务机会 -->
            <div class="imgseven">
              {{ $t("vue_label_normal_opportunity_sf") }}
            </div>
            <!-- 客户 -->
            <div class="imgeight">{{ $t("vue_label_normal_account_sf") }}</div>
            <!-- 日历 -->
            <div class="imgnine">
              {{ $t("label.mobile.quickcreate.default.activities") }}
            </div>
            <!-- 潜在客户公海池 -->
            <div class="imgten">{{ $t("vue_label_leadpool") }}</div>
            <!-- 短信 -->
            <div class="imgele">{{ $t("label.setupsms") }}</div>
          </div>
          <!-- 商务云宣传图 -->
          <div class="cont-img shangwu-img" v-if="shangwuIMG">
            <img src="./image/shangwu.png" alt="" />
            <!-- 装箱单 -->
            <div class="imgone">{{ $t("c509") }}</div>
            <!-- 伙伴订货 -->
            <div class="imgtwo">{{ $t("c510") }}</div>
            <!-- 发票 -->
            <div class="imgthree">{{ $t("vue_label_normal_invoice_sf") }}</div>
            <!-- 线上支付 -->
            <div class="imgfour">
              {{ $t("label.partnerCloud.financeSetting.onlinePayment") }}
            </div>
            <div class="imgfive">CPQ</div>
            <!-- 商务云 -->
            <div class="imgsix">{{ $t("c399") }}</div>
            <!-- 电子合同 -->
            <div class="imgseven">{{ $t("c511") }}</div>
            <div class="imgeight">BI</div>
            <div class="imgnine">CCChat</div>
            <!-- 日历 -->
            <div class="imgten">
              {{ $t("label.mobile.quickcreate.default.activities") }}
            </div>
            <!-- 邮件 -->
            <div class="imgele">{{ $t("label.chatter.mail") }}</div>
            <!-- 短信 -->
            <div class="imgtwe">{{ $t("label.setupsms") }}</div>
          </div>
          <!-- 客服云宣传图 -->
          <div class="cont-img kefu-img" v-if="kefuIMG">
            <img src="./image/kefu.png" alt="" />
            <!-- 语音 -->
            <div class="imgone">{{ $t("vue_label_chat_voice") }}</div>
            <!-- 访客 -->
            <div class="imgtwo">{{ $t("c439") }}</div>
            <!-- 个案 -->
            <div class="imgthree">{{ $t("vue_label_normal_case_sf") }}</div>
            <!-- 联系人 -->
            <div class="imgfour">{{ $t("label.tabpage.contactsz") }}</div>
            <!-- 客服云 -->
            <div class="imgfive">{{ $t("c401") }}</div>
            <!-- 客户 -->
            <div class="imgsix">{{ $t("vue_label_normal_account_sf") }}</div>
            <!-- 服务评价 -->
            <div class="imgseven">
              {{ $t("front-console-conversation-title") }}
            </div>
            <!-- 邮件 -->
            <div class="imgeight">{{ $t("label.chatter.mail") }}</div>
            <!-- 会话 -->
            <div class="imgnine">{{ $t("front-console-conversation") }}</div>
          </div>
          <!-- 现场云宣传图 -->
          <div class="cont-img xianchang-img" v-if="xianchangIMG">
            <img src="./image/xianchang.png" alt="" />
            <!-- 服务资源 -->
            <div class="imgone">
              {{ $t("label.service.gantt.service.resources") }}
            </div>
            <!-- 资产 -->
            <div class="imgtwo">{{ $t("label.asset") }}</div>
            <!-- 服务评价 -->
            <div class="imgthree">
              {{ $t("front-console-conversation-title") }}
            </div>
            <!-- 服务预约 -->
            <div class="imgfour">
              {{ $t("label.projectManagement.service.appointment") }}
            </div>
            <!-- 现场云 -->
            <div class="imgfive">{{ $t("c402") }}</div>
            <!-- 工作订单 -->
            <div class="imgsix">{{ $t("lable.PowerProcess.Workorder") }}</div>
            <!-- 考勤表 -->
            <div class="imgseven">{{ $t("c516") }}</div>
            <!-- 费用 -->
            <div class="imgeight">{{ $t("c333") }}</div>
          </div>
          <!-- 项目云宣传图 -->
          <div class="cont-img xiangmu-img" v-if="xiangmuIMG">
            <img src="./image/xiangmu.png" alt="" />
            <!-- 日历 -->
            <div class="imgone">
              {{ $t("label.mobile.quickcreate.default.activities") }}
            </div>
            <!-- 里程碑 -->
            <div class="imgtwo">
              {{ $t("component.milestones.label.title") }}
            </div>
            <!-- 项目成员 -->
            <div class="imgthree">{{ $t("c512") }}</div>
            <!-- 费用 -->
            <div class="imgfour">{{ $t("c333") }}</div>
            <!-- 项目管理 -->
            <div class="imgfive">
              {{ $t("vue_label_appstore_project_management") }}
            </div>
            <!-- 项目云 -->
            <div class="imgsix">{{ $t("c403") }}</div>
            <!-- 甘特图 -->
            <div class="imgseven">
              {{ $t("label.projectManagement.gantt") }}
            </div>
            <!-- 任务 -->
            <div class="imgeight">{{ $t("vue_label_normal_task_sf") }}</div>
            <!-- 实际工作清单 -->
            <div class="imgnine">{{ $t("c513") }}</div>
            <!-- 报表图表 -->
            <div class="imgten">{{ $t("c514") }}</div>
            <!-- 问题 -->
            <div class="imgele">{{ $t("label.tabpage.issuez") }}</div>
          </div>
        </div>
      </div>

      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <!-- 继续 -->
        <el-button
          type="primary"
          :disabled="isshowbtn"
          @click="nextbtn"
          v-show="!isNextShow"
          >{{ $t("label.goon") }}</el-button
        >
        <div class="lastStep" @click="lastStepMth" v-show="isNextShow">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-back1"></use>
          </svg>
          <!-- 选择不同的解决方案 -->
          <span>{{ $t("c396") }}</span>
        </div>
        <!-- 开始体验 -->
        <el-button type="primary" @click="startMth" v-show="isNextShow">{{
          $t("c398")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      dialogVisible: true, // 控制弹窗 开关
      isNextShow: false, // 下一步
      choseId: -1,
      isshowbtn: true, //  未选中应用程序 继续按钮置灰
      choseProgramId: "", //选中的应用程序id
      applicationArr: [
        {
          id: "ace20220322MarketCl",
          img: "#icon-appicon1-sel",
          p1: this.$i18n.t("c292"), // 市场云   多渠道市场营销获客工具
          p2: this.$i18n.t("c404"),
        },
        {
          id: "ace20220322Salesloud",
          img: "#icon-appicon2-sel",
          p1: this.$i18n.t("c279"), // 销售云   自动化销售管理
          p2: this.$i18n.t("c406"),
        },
        {
          id: "ace20220315Busicloud",
          img: "#icon-shangwuyun-sel",
          p1: this.$i18n.t("c399"), // 商务云   业财一体化智能管理
          p2: this.$i18n.t("c441"),
        },
        {
          id: "acecustomerserviceC",
          img: "#icon-kefuyun-sel",
          p1: this.$i18n.t("c401"), // 客服云   一站式客户服务平台
          p2: this.$i18n.t("c409"),
        },
        {
          id: "ace20210219FieldSer",
          img: "#icon-appicon5-sel",
          p1: this.$i18n.t("c402"), // 现场云   可定制现场服务应用
          p2: this.$i18n.t("c408"),
        },
        {
          id: "ace2020D253B3984Or6Q",
          img: "#icon-appicon6-sel",
          p1: this.$i18n.t("c403"), // 项目云   全方位项目管理系统
          p2: this.$i18n.t("c442"),
        },
      ],
      shichangIMG: true,
      xiaoshouIMG: false,
      shangwuIMG: false,
      kefuIMG: false,
      xianchangIMG: false,
      xiangmuIMG: false,
      programDesTittle: "",
      programDesText: "",
    };
  },
  methods: {
    // 选择应用程序
    choseMth(index, item) {
      this.choseId = index;
      this.isshowbtn = false;
      this.choseProgramId = item.id;
    },
    // 下一步
    nextbtn() {
      this.isNextShow = true;
      switch (this.choseProgramId) {
        // 市场云
        case "ace20220322MarketCl":
          this.programDesTittle = this.$i18n.t("c493");
          this.programDesText = this.$i18n.t("c494");
          this.shichangIMG = true;
          this.xiaoshouIMG = false;
          this.shangwuIMG = false;
          this.kefuIMG = false;
          this.xianchangIMG = false;
          this.xiangmuIMG = false;
          break;
        // 销售云
        case "ace20220322Salesloud":
          this.programDesTittle = this.$i18n.t("c495");
          this.programDesText = this.$i18n.t("c496");
          this.shichangIMG = false;
          this.xiaoshouIMG = true;
          this.shangwuIMG = false;
          this.kefuIMG = false;
          this.xianchangIMG = false;
          this.xiangmuIMG = false;
          break;
        // 商务云
        case "ace20220315Busicloud":
          this.programDesTittle = this.$i18n.t("c497");
          this.programDesText = this.$i18n.t("c498");
          this.shichangIMG = false;
          this.xiaoshouIMG = false;
          this.shangwuIMG = true;
          this.kefuIMG = false;
          this.xianchangIMG = false;
          this.xiangmuIMG = false;
          break;
        // 客服云
        case "acecustomerserviceC":
          this.programDesTittle = this.$i18n.t("c499");
          this.programDesText = this.$i18n.t("c500");
          this.shichangIMG = false;
          this.xiaoshouIMG = false;
          this.shangwuIMG = false;
          this.kefuIMG = true;
          this.xianchangIMG = false;
          this.xiangmuIMG = false;
          break;
        // 现场云
        case "ace20210219FieldSer":
          this.programDesTittle = this.$i18n.t("c501");
          this.programDesText = this.$i18n.t("c502");
          this.shichangIMG = false;
          this.xiaoshouIMG = false;
          this.shangwuIMG = false;
          this.kefuIMG = false;
          this.xianchangIMG = true;
          this.xiangmuIMG = false;
          break;
        // 项目云
        case "ace2020D253B3984Or6Q":
          this.programDesTittle = this.$i18n.t("c503");
          this.programDesText = this.$i18n.t("c504");
          this.shichangIMG = false;
          this.xiaoshouIMG = false;
          this.shangwuIMG = false;
          this.kefuIMG = false;
          this.xianchangIMG = false;
          this.xiangmuIMG = true;
          break;
        default:
          break;
      }
    },
    // 上一步
    lastStepMth() {
      this.dialogVisible = true;
      this.isNextShow = false;
    },
    // 开始体验
    startMth() {
      this.dialogVisible = false;
      this.$parent.$parent.changeApplication(this.choseProgramId);
    },
  },
};
</script>
<style lang="scss" scoped>
.applicationBox {
  ::v-deep .el-dialog {
    border-radius: 20px !important;
    background: #f5f6fa;
    // width: 75%;
    max-width: 1440px;
    min-width: 900px;
  }
  ::v-deep .el-dialog__header {
    display: none;
  }
  ::v-deep .el-dialog__body {
    padding: 20px 20px 0 !important;
  }
  ::v-deep .el-dialog__footer {
    border: none;
    padding: 0 80px 70px 0 !important;
  }
  .dialog {
    // height: 66%;
    .dialog-content {
      width: 100%;
      padding: 22px 70px 35px;
      .header {
        .header-tittle {
          text-align: center;
          font-size: 22px;
          font-weight: bold;
          color: #080707;
        }
        .header-text {
          font-size: 17px;
          color: #333333;
          margin-top: 21px;
          text-align: center;
        }
      }
      .mainBox {
        box-sizing: border-box;
        display: flex;
        padding: 3px 30px 0;
        justify-content: space-between;
        flex-wrap: wrap;
        // min-width: 850px;
        .application-item {
          width: 30%;
          height: 120px;
          min-width: 264px;
          display: flex;
          align-items: center;
          cursor: pointer;
          // padding: 30px 0;
          // margin: 0 44px 30px 0;
          margin-top: 37px;
          background: #ffffff;
          border: solid 2px white;
          border-radius: 10px;
          .leftimg {
            text-align: center;
            margin-left: 30px;
            .icon {
              width: 35px;
              height: 35px;
            }
          }
          .righttext {
            margin: 0 20px 0;
            div:nth-of-type(1) {
              font-size: 15px;
              font-weight: bold;
              color: #080707;
            }
            div:nth-of-type(2) {
              margin-top: 8px;
              font-size: 14px;
              color: #080707;
            }
          }
          &:hover {
            box-shadow: 0px 0px 20px 1px rgba(225, 225, 225, 0.8);
          }
        }
        .chooseClass {
          border: none;
          box-sizing: border-box;
          background-image: linear-gradient(#fff, #fff),
            linear-gradient(to bottom right, #2d6cfc, #66d7a4);
          padding: 2px;
          background-clip: content-box, padding-box;
        }
      }
    }
    .dialog-contentTwo {
      .mainContent {
        width: 100%;
        padding: 40px 0 0 0;
        text-align: center;
        .cont-tittle {
          font-size: 22px;
          font-weight: bold;
          color: #080707;
        }
        .cont-text {
          margin-top: 22px;
          font-size: 17px;
          color: #333333;
          text-align: center;
          padding: 0 70px;
        }
        //
        .cont-img {
          width: 770px;
          height: 310px;
          // border: 2px solid rgba(45, 108, 252, 0.08);
          margin: 22px auto 40px;
          font-size: 12px;
          img {
            width: 765px;
            height: 300px;
          }
          div {
            width: 108px;
            text-align: center;
            white-space: normal;
          }
        }
        // 市场云
        .shichang-img {
          position: relative;
          .imgone {
            position: absolute;
            top: 99px;
            left: -9px;
          }
          .imgtwo {
            position: absolute;
            top: 182px;
            left: 51px;
          }
          .imgthree {
            position: absolute;
            top: 204px;
            left: 137px;
          }
          .imgfour {
            position: absolute;
            top: 106px;
            left: 158px;
          }
          .imgfive {
            position: absolute;
            top: 170px;
            left: 311px;
            font-size: 16px;
          }
          .imgsix {
            position: absolute;
            top: 141px;
            right: 207px;
          }
          .imgseven {
            position: absolute;
            bottom: 66px;
            right: 120px;
          }
          .imgeight {
            position: absolute;
            top: 110px;
            right: 50px;
          }
          .imgnine {
            position: absolute;
            bottom: 60px;
            right: -9px;
          }
        }
        // 销售云
        .xiaoshou-img {
          position: relative;
          .imgone {
            position: absolute;
            top: 80px;
            left: 13px;
          }
          .imgtwo {
            position: absolute;
            top: 218px;
            left: -23px;
          }
          .imgthree {
            position: absolute;
            top: 165px;
            left: 67px;
          }
          .imgfour {
            position: absolute;
            bottom: 59px;
            left: 143px;
          }
          .imgfive {
            position: absolute;
            top: 87px;
            left: 179px;
          }
          .imgsix {
            position: absolute;
            top: 170px;
            right: 337px;
            font-size: 16px;
          }
          .imgseven {
            position: absolute;
            bottom: 61px;
            right: 225px;
          }
          .imgeight {
            position: absolute;
            top: 122px;
            right: 200px;
          }
          .imgnine {
            position: absolute;
            top: 82px;
            right: 107px;
          }
          .imgten {
            position: absolute;
            bottom: 73px;
            right: 100px;
          }
          .imgele {
            position: absolute;
            bottom: 135px;
            right: -24px;
          }
        }
        // 商务云
        .shangwu-img {
          position: relative;
          .imgone {
            position: absolute;
            top: 95px;
            left: 1px;
          }
          .imgtwo {
            position: absolute;
            top: 240px;
            left: -9px;
          }
          .imgthree {
            position: absolute;
            top: 166px;
            left: 62px;
          }
          .imgfour {
            position: absolute;
            bottom: 82px;
            left: 135px;
          }
          .imgfive {
            position: absolute;
            top: 107px;
            left: 165px;
          }
          .imgsix {
            position: absolute;
            top: 170px;
            right: 332px;
            font-size: 16px;
          }
          .imgseven {
            position: absolute;
            top: 136px;
            right: 183px;
          }
          .imgeight {
            position: absolute;
            bottom: 61px;
            right: 157px;
          }
          .imgnine {
            position: absolute;
            top: 97px;
            right: 91px;
          }
          .imgten {
            position: absolute;
            bottom: 102px;
            right: 74px;
          }
          .imgele {
            position: absolute;
            top: 123px;
            right: -9px;
          }
          .imgtwe {
            position: absolute;
            bottom: 62px;
            right: -1px;
          }
        }
        // 客服云
        .kefu-img {
          position: relative;
          .imgone {
            position: absolute;
            top: 101px;
            left: -24px;
          }
          .imgtwo {
            position: absolute;
            top: 174px;
            left: 39px;
          }
          .imgthree {
            position: absolute;
            top: 230px;
            left: 143px;
          }
          .imgfour {
            position: absolute;
            top: 106px;
            left: 168px;
          }
          .imgfive {
            position: absolute;
            top: 170px;
            left: 318px;
            font-size: 16px;
          }
          .imgsix {
            position: absolute;
            top: 141px;
            right: 199px;
          }
          .imgseven {
            position: absolute;
            bottom: 66px;
            right: 110px;
          }
          .imgeight {
            position: absolute;
            top: 110px;
            right: 41px;
          }
          .imgnine {
            position: absolute;
            bottom: 60px;
            right: -17px;
          }
        }
        // 现场云
        .xianchang-img {
          position: relative;
          .imgone {
            position: absolute;
            top: 97px;
            left: 13px;
          }
          .imgtwo {
            position: absolute;
            top: 200px;
            left: 60px;
          }
          .imgthree {
            position: absolute;
            top: 230px;
            left: 155px;
          }
          .imgfour {
            position: absolute;
            top: 106px;
            left: 178px;
          }
          .imgfive {
            position: absolute;
            top: 173px;
            left: 332px;
            font-size: 16px;
          }
          .imgsix {
            position: absolute;
            top: 141px;
            right: 187px;
          }
          .imgseven {
            position: absolute;
            bottom: 55px;
            right: 97px;
          }
          .imgeight {
            position: absolute;
            top: 110px;
            right: 30px;
          }
        }
        // 项目云
        .xiangmu-img {
          position: relative;
          .imgone {
            position: absolute;
            top: 97px;
            left: -1px;
          }
          .imgtwo {
            position: absolute;
            top: 240px;
            left: -12px;
          }
          .imgthree {
            position: absolute;
            top: 165px;
            left: 57px;
          }
          .imgfour {
            position: absolute;
            bottom: 83px;
            left: 133px;
          }
          .imgfive {
            position: absolute;
            top: 106px;
            left: 162px;
          }
          .imgsix {
            position: absolute;
            top: 170px;
            right: 341px;
            font-size: 16px;
          }
          .imgseven {
            position: absolute;
            top: 137px;
            right: 185px;
          }
          .imgeight {
            position: absolute;
            bottom: 71px;
            right: 141px;
          }
          .imgnine {
            position: absolute;
            top: 98px;
            right: 89px;
          }
          .imgten {
            position: absolute;
            bottom: 94px;
            right: 45px;
          }
          .imgele {
            position: absolute;
            top: 131px;
            right: -13px;
          }
        }
      }
    }

    .dialog-footer {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      .lastStep {
        position: absolute;
        top: 0;
        margin-left: 80px;
        font-size: 18px;
        color: #2d6cfc;
        .icon {
          width: 28px;
          height: 28px;
        }
        span {
          display: inline-block;
          margin-left: 12px;
          vertical-align: 6px;
        }
      }
      .el-button {
        position: absolute;
        right: 0;
        top: 0;
      }
      ::v-deep .el-button--primary {
        background: #2d6cfc;
      }
      ::v-deep .el-button--primary.is-disabled {
        background: #bbbbbb;
        border-color: white;
      }
    }
  }
}
</style>