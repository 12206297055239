var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footerBar",style:({
    justifyContent:
      _vm.LeftFooterBarList.length > 0 ? 'space-between' : 'flex-end',
  })},[(_vm.LeftFooterBarList.length > 0)?_c('div',{ref:"footerBarLeft",staticClass:"footerBarLeft"},[_c('div',{ref:"box",staticClass:"itemBox"},_vm._l((_vm.footerBarLeftList),function(itemTab){return _c('div',{key:itemTab.id,staticClass:"item",class:itemTab.id == _vm.currentActive || itemTab.popupFlag == true ? 'active' : '',on:{"click":function($event){return _vm.clickTab(itemTab)}}},[_c('svg',{staticClass:"iconImg",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":'#' + JSON.parse(itemTab.icon)['code']}})]),_c('div',{staticClass:"itemTitle"},[_vm._v(_vm._s(itemTab.label))]),(itemTab.popupFlag)?_c('i',{staticClass:"el-icon-top-right iconTopRight"}):_vm._e()])}),0),(_vm.isShowMore)?_c('div',{ref:"more",staticClass:"itemBox"},[_c('el-dropdown',{staticClass:"itemMore",on:{"command":_vm.clickTab}},[_c('div',{staticStyle:{"height":"100%","display":"flex","align-items":"center"}},[_c('div',{staticClass:"iconImg"},[_c('img',{attrs:{"src":require("./assets/images/more.png")}})]),(_vm.footerBarLeftList.length > 0)?_c('div',{staticClass:"itemTitleMore"},[_vm._v(" "+_vm._s(_vm.$t("label.more"))+" ")]):_vm._e()]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.moreList),function(itemMore){return _c('el-dropdown-item',{key:itemMore.id,class:itemMore.id == _vm.currentActive || itemMore.popupFlag == true ? 'moreActive' : '',attrs:{"command":itemMore}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","position":"relative"}},[_c('svg',{staticClass:"iconImg mr10",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":'#' + JSON.parse(itemMore.icon)['code']}})]),_c('div',{staticClass:"textEllipsis"},[_vm._v(_vm._s(itemMore.label))]),(itemMore.popupFlag)?_c('i',{staticClass:"el-icon-top-right iconTopRight"}):_vm._e()])])}),1)],1)],1):_vm._e()]):_vm._e(),(
      _vm.footerBarRightList.length > 0 && _vm.footerBarRightList[0].iscustom == '0'
    )?_c('callCenterBar'):(
      _vm.footerBarRightList.length > 0 && _vm.footerBarRightList[0].iscustom == '1'
    )?_c('cc-renderer',{staticStyle:{"margin":"0"},attrs:{"componentData":_vm.rightComponentData,"canvasStyleData":_vm.rightCanvasStyleData}}):_vm._e(),(_vm.popupVisible)?_c('div',{ref:"myPopup",staticClass:"popup",style:({ width: _vm.popupWidth, height: _vm.popupHeight })},[_c('div',{staticClass:"popupHeader"},[_c('div',{staticClass:"popupHeaderTitle"},[_c('svg',{staticClass:"iconImg",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":'#' + JSON.parse(_vm.popupIcon)['code']}})]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.popupTitle))])]),_c('div',{staticClass:"popupHeaderBtn"},[_c('i',{staticClass:"el-icon-close",attrs:{"title":"关闭"},on:{"click":_vm.closePopup}}),_c('i',{staticClass:"el-icon-position",staticStyle:{"margin-left":"10px"},attrs:{"title":"弹出"},on:{"click":_vm.popupWindow}})])]),_c('div',{staticClass:"popupBody"},[_c('cc-renderer',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"componentData":_vm.componentData,"canvasStyleData":_vm.canvasStyleData}})],1)]):_vm._e(),_c('chat',{ref:"QUNCE"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }