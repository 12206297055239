<template>
  <!--  控制台菜单-->
  <div class="topContent" ref="topContentRef">
    <div class="topContent_left">
      <!-- 应用名称，伙伴云关闭展示应用名称 -->
      <div class="topcontentmenu" v-if="!Partnercloud" ref="applicationName">
        <span class="applicationName">
          {{ applicationName }}
        </span>
      </div>
      <!-- 菜单部分 -->
      <!-- 骨架屏 -->
      <!--      <Wang-skeleton type="line" :options="frontOptions" v-if="frontLoading">-->
      <!--      </Wang-skeleton>-->
      <!-- 菜单内容 -->
      <div class="data_menu" ref="datamenu">
        <!--菜单下拉选-->
        <div
          class="curmenu-text-box"
          :class="consoleData.data.level1Id == 'menu' ? 'cur-menu1' : ''"
        >
          <div class="curmenu-text" @click="clickCurrentMenu($event)">
            {{ consoleData.currentObject }}
          </div>
          <el-dropdown trigger="hover">
            <div ref="menus">
              <i class="el-icon-caret-bottom"></i>
            </div>
            <el-dropdown-menu
              slot="dropdown"
              class="mymore-menus"
              style="
                white-space: nowrap;
                width: 200px;
                margin-left: 45px;
                max-height: 350px;
                background: #475066;
                color: #fff;
              "
            >
              <div class="more-menus-fixed menus-show-all">
                <el-dropdown-item
                  v-for="item in showMenus"
                  :title="item.tabName"
                  :key="item.name"
                  :style="{
                    display: 'flex',
                    fontSize: '14px',
                    background: '#475066',
                    color: '#FFF',
                    padding: '0 4px',
                  }"
                >
                  <span
                    style="
                      display: inline-block;
                      width: 100%;
                      position: relative;
                    "
                    @click.right="clickRight(item, $event)"
                  >
                    <a
                      class="link-dropdown-style"
                      style="
                        width: 90%;
                        min-width: 140px;
                        overflow: hidden;
                        color: #fff;
                        padding-left: 15px;
                      "
                      @click="clickshowMenu(item, $event)"
                      :href="linkHref(item)"
                      :style="{
                        color:
                          item.id == consoleData.currentObjectId
                            ? '#abc4fe'
                            : '#ffffff',
                      }"
                    >
                      {{ item.tabName }}</a
                    >
                    <!--下拉菜单选中-->
                    <img
                      v-if="item.id == consoleData.currentObjectId"
                      src="../home/assets/images/menuActive.png"
                      style="position: absolute; top: 20%; right: 10px"
                    />
                  </span>
                </el-dropdown-item>
              </div>
              <div
                class="all-Menu-Btn"
              >
                <div class="boxItem" @click="clickmoreMenu({}, true, $event)">{{ $t("label.tabpage.alltabs") }}</div>
                <div class="boxItem" @click="closeAllMenus">
                 <!-- 关闭所有菜单 -->
                 {{$t('c2396')}}
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 展示的tab部分 -->
        <div class="tabWrapper" ref="tabWrapper" v-if="aaaCopy.length > 0">
          <vuedraggable
            v-model="aaaCopy"
            @end="tabDragEnd"
            animation="300"
            style="display: flex"
            filter=".forbid"
            id="box"
          >
            <div
              v-for="(item, index) in aaaCopy"
              :key="item.id"
              class="each_menu"
              :ref="`alltabs${index}`"
              :class="{
                forbid: item.isfixed == true,
                'cur-menu': item.id == consoleData.data.level1Id,
              }"
            >
              <span ref="remarkFather">
                <el-tooltip
                  placement="bottom-start"
                  effect="light"
                  :visible-arrow="false"
                  :disabled="isShowTooltip"
                  popper-class="menu-tooltip"
                >
                  <a
                    slot="content"
                    @click="clickTab(item)"
                    v-if="!item.isfixed"
                 >
                  {{ item.name }}
                  </a>
                  <a
                    @click="clickTab(item)"
                    @mouseover="onMouseOver(item.name, index)"
                    v-if="!item.isfixed"
                  >
                    {{ item.name }}
                  </a>
                </el-tooltip>
                <!--锁定状态-->
                <i
                  v-if="item.isfixed"
                  class="el-icon-lock"
                  style="color: #fff"
                  @click="clickTab(item)"
                ></i>
              </span>
              <el-dropdown trigger="click" style="display: flex">
                <!-- tab下拉操作按钮 -->
                <i
                  v-if="item.tabAction.length > 0"
                  class="el-icon-arrow-down el-icon--right"
                  style="margin-right: 5px"
                ></i>
                <!-- 关闭按钮  没有isShowClose或者isShowClose为true的时候都要显示 为false的时候不展示-->
                <i v-if="item.isShowClose != false" class="el-icon-close" @click="closeTab(item, index)"></i>
                <el-dropdown-menu
                  slot="dropdown"
                  class="mymore-menus"
                  style="
                    white-space: nowrap;
                    width: 200px;
                    max-height: 350px;
                    border: 0;
                  "
                >
                  <div class="more-menus-fixed menus-show-all">
                    <el-dropdown-item
                      v-for="(i, idx) in item.tabAction"
                      :title="i.name"
                      :key="i.id"
                      :style="{
                        display: 'flex',
                        fontSize: '14px',
                        padding: '0px',
                      }"
                      @click.native="clickTabHandle(item, index, i, idx)"
                    >
                      <span style="padding-left: 16px" class="link-dropdown-style1">
                        <a style="min-width: 140px; overflow: hidden">
                          {{ i.name }}</a
                        >
                      </span>
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </vuedraggable>
        </div>
        <!-- 更多tab -->
        <el-dropdown
          v-if="isShowMore"
          trigger="click"
          class="moreMenus"
          placement="bottom"
        >
          <p
            @mouseenter="menuenter"
            class="more-menusbox"
            :class="[menupostion ? 'menupostion' : 'menupostion1']"
            ref="moreTabs"
          >
            <span style="font-size: 14px; margin-right: 5px">{{
              $t("label.more")
            }}</span>
            <i class="el-icon-caret-bottom"></i>
          </p>
          <el-dropdown-menu
            v-if="moreTabLists.length > 0"
            slot="dropdown"
            class="mymore-menus"
            style="
              white-space: nowrap;
              width: 200px;
              max-height: 350px;
              top: 0px;
              border: 0;
              color: #333333;
            "
          >
            <div class="more-menus-fixed menus-show-all">
              <el-dropdown-item
                :title="item.name"
                v-for="(item, index) in moreTabLists"
                :key="item.id"
                @click.native="clickmoreTabs(item, index)"
                :style="{
                  fontWeight: item.id == 'allmenu' ? 'bold' : 'normal',
                  display: 'flex',
                  fontSize: '14px',
                  padding: '0px',
                }"
              >
                <a
                  @click.right="clickRight(item, $event)"
                  v-if="item.id != 'allmenu'"
                  class="link-dropdown-style1"
                  :href="linkHrefDropdown(item)"
                  style="
                    min-width: 140px;
                    overflow: hidden;
                    position: relative;
                  "
                  :style="{
                    color:
                      item.id == consoleData.data.level1Id
                        ? '#5789fd'
                        : '#333333',
                  }"
                >
                  <span style="display: block;width:155px;overflow:hidden;margin-left: 16px">{{ item.name }}</span>
                  <!--更多下拉中一级菜单选中状态-->
                  <img
                    v-if="item.id == consoleData.data.level1Id"
                    src="../home/assets/images/menuActive.png"
                    style="position: absolute; top: 30%; right: 10px"
                  />
                </a>
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 全部菜单 dialog -->
    <div class="allmenuBox">
      <el-dialog
        :visible.sync="dialogstatus"
        width="930px"
        :closeOnClickModal="false"
        @closed="closedialog"
      >
        <div style="height: 100%; background: #fff">
          <div class="allmenutop">
            <span style="font-size: 14px">{{
              $t("label.tabpage.alltabs")
            }}</span>
            <div class="seachbox">
              <el-input
                :placeholder="$t('report_label_pleaseinput')"
                v-model="seachinput"
                @input="getseachtext"
                @keyup.enter.native="getallmenulist"
                style="display: flex; fontsize: 12px"
              >
              </el-input>
              <div class="seachicon" @click="getallmenulist">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-search"></use>
                </svg>
              </div>
              <div class="letterseach">
                <ul>
                  <li
                    v-for="(letter, index) in letteroption"
                    :key="index"
                    @click="seachletter(letter.name)"
                  >
                    {{ letter.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div></div>
          </div>
          <div class="allmenulist" v-loading="seachloading">
            <ul class="link-text-color-style">
              <li v-for="item in alllication" :key="item.id">
                <a
                  @click.stop="allmenufun(item, $event)"
                  :title="item.tabName"
                  :href="linkHref(item)"
                  >{{ item.tabName }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 自定义菜单显示内容 -->
    <div class="editmenudialog">
      <el-dialog
        :title="$t('label.changemydisplay.list.custom')"
        :visible.sync="editmymenustatus"
        :before-close="handleClose"
        width="550px"
        ><span>
          <div class="editmymenubody" v-loading="editmenuloading">
            <div>
              <!-- 可选菜单 -->
              <span>{{ $t("label_partner_workspace_tab_option") }}</span>
              <div class="Optionalmenu">
                <!-- 菜单搜索框 -->
                <div class="Optionalmenuseach">
                  <input
                    type="text"
                    class="searchBoxInput"
                    v-model="seachvalue"
                    @input="isempty"
                    @keyup.enter="Optionalmenuseach"
                  />
                  <svg
                    class="icon"
                    aria-hidden="true"
                    style="margin-bottom: 2px"
                    @click="Optionalmenuseach"
                  >
                    <use href="#icon-search"></use>
                  </svg>
                </div>
                <ul>
                  <li
                    v-for="unselectedTab in seachunselectedTabList"
                    :key="unselectedTab.id"
                    :style="{
                      color: unselectedTab.isshow ? '#006DCC' : '#333333',
                    }"
                    @mouseenter="unselectedTabenter(unselectedTab.id)"
                    @mouseleave="unselectedTableave(unselectedTab.id)"
                  >
                    <span
                      :title="unselectedTab.tabName"
                      class="selectedTab-text"
                      >{{ unselectedTab.tabName }}</span
                    >
                    <i
                      class="el-icon-plus"
                      v-show="unselectedTab.isshow"
                      @click="addselect(unselectedTab.id)"
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <span>{{
                $t("label_partner_workspace_tab_selectedoption")
              }}</span>
              <div class="Selectedmenu">
                <ul>
                  <vuedraggable v-model="selectedTabList" @start="drstart">
                    <transition-group>
                      <li
                        v-for="selectedTab in selectedTabList"
                        :key="selectedTab.id"
                        :style="{
                          color: selectedTab.isshow ? '#006DCC' : '#333333',
                        }"
                        @mouseenter="selectedTabTabenter(selectedTab.id)"
                        @mouseleave="selectedTabTableave(selectedTab.id)"
                      >
                        <span
                          :title="selectedTab.tabName"
                          class="selectedTab-text"
                          >{{ selectedTab.tabName }}</span
                        >
                        <i
                          class="el-icon-minus"
                          v-show="selectedTab.isshow"
                          @click="removeselect(selectedTab.id)"
                        ></i>
                      </li>
                    </transition-group>
                  </vuedraggable>
                </ul>
              </div>
            </div>
          </div>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">{{
            $t("label_tabpage_cancel")
          }}</el-button>
          <el-button
            type="primary"
            @click="savemymenusetting"
            :loading="saveloading"
            >{{ $t("label.ems.confirm") }}</el-button
          >
        </span>
      </el-dialog>
    </div>
    <customTab ref="customTab" @comfirm="comfirm"> </customTab>
  </div>
</template>

<script>
/**
 * menu组件
 */
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
import * as LayoutApi from "./api.js";
import { consoleSaveTab } from "@/views/home/api.js";
import vuedraggable from "vuedraggable";
import VueCookies from "vue-cookies";
import debounce from "lodash.debounce";
import pinyinMatch from "pinyin-match";
import waterMark from "@/utils/waterMark/callMark.js";
import { getSetupInfo } from "./api";
import * as request from "./api.js";
import { mapState } from "vuex";
import customTab from "./customTab";
import generatelevel1Menu from "@/utils/generatelevel1Menu.js";
export default {
  props: {
    // 菜单栏上显示的菜单项
    showMenus: {
      type: Array,
      default: () => [],
    },
    // 更多菜单
    moreMenus: {
      type: Array,
      default: () => [],
    },
    // 所有菜单
    allmenulist: {
      type: Array,
      default: () => [],
    },
    menupostion: {
      type: Boolean,
    },
    // 应用程序名称
    applicationName: {
      type: String,
      default: "",
    },
    // 是否在可视范围内展示全部菜单按钮
    isShowTabAllMenu: {
      type: Boolean,
      default: true,
    },
    setting: {
      //是否是设置页面
      type: String,
      default: "",
    },
    changeLoad: {
      type: Boolean,
      default: false,
    },
    topTitleWidth: {
      type: Number,
      default: 0,
    },
    topLeft: {
      type: Number,
      default: 0,
    },
    allMenus: {
      type: Array,
      default: () => [],
    },
  },
  //限制菜单选项中最多展示8个字符
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 8) {
        return value.slice(0, 8) + "...";
      }
      return value;
    },
  },
  data() {
    return {
      isRefresh: false,
      flag: true,
      // 展示在页面中的tab数据
      aaaCopy: [],
      // 更多中的tab数据
      moreTabLists: [],
      isShowMore: false,
      /****************************************************/
      frontLoading: true,
      clickRightObj: {},
      frontOptions: {
        row: 1,
        column: 8,
        width: "60px",
        height: "14px",
        backgroundColor: "#3C465C",
      },
      isShowHidden: false, //更多菜单是否显示滚动条
      isCcchatShow: "true",
      //是否为伙伴云用户
      Partnercloud: this.$store.state.userInfoObj.ispartner,
      showMoreMenu: false,
      seachinput: "",
      seachloading: true,
      editmymenustatus: false,
      dialogstatus: false,
      offsetWidth: 0,
      saveloading: false,
      editmenuloading: false,
      oncestatus: true,
      Optionalmenuinput: "",
      seachvalue: "",
      userurl: "",
      menulist: [],
      alllication: [],
      copyalllication: [],
      selectedTabList: [],
      unselectedTabList: [],
      seachunselectedTabList: [],
      filterunselectedTabList: [],
      letteroption: [
        {
          name: "A",
          value: "A",
        },
        {
          name: "B",
          value: "B",
        },
        {
          name: "C",
          value: "C",
        },
        {
          name: "D",
          value: "D",
        },
        {
          name: "E",
          value: "E",
        },
        {
          name: "F",
          value: "F",
        },
        {
          name: "G",
          value: "G",
        },
        {
          name: "H",
          value: "H",
        },
        {
          name: "I",
          value: "I",
        },
        {
          name: "J",
          value: "J",
        },
        {
          name: "K",
          value: "K",
        },
        {
          name: "L",
          value: "L",
        },
        {
          name: "M",
          value: "M",
        },
        {
          name: "N",
          value: "N",
        },
        {
          name: "O",
          value: "O",
        },
        {
          name: "P",
          value: "P",
        },
        {
          name: "Q",
          value: "Q",
        },
        {
          name: "R",
          value: "R",
        },
        {
          name: "S",
          value: "S",
        },
        {
          name: "T",
          value: "T",
        },
        {
          name: "U",
          value: "U",
        },
        {
          name: "V",
          value: "V",
        },
        {
          name: "W",
          value: "W",
        },
        {
          name: "X",
          value: "X",
        },
        {
          name: "Y",
          value: "Y",
        },
        {
          name: "Z",
          value: "Z",
        },
        {
          name: "ALL",
          value: "ALL",
        },
      ],
      tabIds: [
        //日历
        "acf20200615event",
        //日历管理
        "acf20208AD53CC8sLvgf",
        //同事圈
        "acf201200141050RgtYi",
        //邮件
        "acf20191021FeiAUjdys",
        //销售漏斗
        "acf2015030630xsld",
        //文件
        "acf20171206fileUjdys",
        //价格手册
        "acf2020110PriceBooks",
        //潜在客户公海池
        "acf202011leadmksea",
        //客户公海池
        "acf202011accmksea",
        //仪表板
        "acf100009",
        //短信
        "acf20201216smslist",
        //标情发现
        "acf2020340C93FD7chBP",
        //小组
        "acf20190807sgeAUjdys",
        //派工平台
        "acfFieldServiceSystb",
        //预测
        "acf2013121431foreca",
        //人员资源日历
        "acf20219D0E7E25g4uUv",
        //市场活动表单
        "acf2021070mcforms",
        //社交媒体
        "acf2021070mcsocial",
        //伙伴云-订货平台
        "acf20210713orderpro1",
        //聊天
        "acfConversationTab00",
        // 控制台
        "acfServiceControlTab",
      ],
      renum: 1,
      isShowTooltip: false, //二级菜单悬浮是否显示
    };
  },
  components: {
    vuedraggable,
    customTab,
  },
  created() {
    // 如果toAPP不为空，则跳转到toAPP，给实施提供的全局跳转app的逻辑
    let toAPP = this.$route.query.toAPP;
    if (toAPP) {
      this.$emit("tabapplication", toAPP);
    }

    //计算字符个数如果是英文或符号则算单字符如果是中文则算双字符-在字符串原型上扩展方法
    String.prototype.byteLength = function () {
      var length = 0;
      Array.from(this).map(function (char) {
        if (char.charCodeAt(0) > 255) {
          //字符编码大于255，说明是双字节字符
          length += 2;
        } else {
          length++;
        }
      });

      return length;
    };

    //根据字符长度计算出dom渲染到页面上时的具体宽度px-在字符串原型上扩展方法
    /*** 获取文本px宽度* @param font{String}: 字体样式**/
    String.prototype.pxWidth = function (font) {
      // re-use canvas object for better performance
      var canvas =
          String.prototype.pxWidth.canvas ||
          (String.prototype.pxWidth.canvas = document.createElement("canvas")),
        context = canvas.getContext("2d");

      font && (context.font = font);
      var metrics = context.measureText(this);

      return metrics.width;
    };
  },
  mounted() {
    // 监听menu1.js里面发射的刷新menu1的事件
    this.$bus.$on("refreshTab1", this.ebFn1)
    // 监听menu1.js里面发射的关闭menu1的事件
    this.$bus.$on("closeTab1", this.ebFn2)
    // clickTab
    // 监听menu1.js里面发射的reopen_menu1的事件
    this.$bus.$on("clickTab1", this.ebFn3)
    // 初始化数据
    if (this.showTabLists.length > 0) {
      // 刷新会走这里
      this.isRefresh = true
      this.init(this.showTabLists);
    }
    window.addEventListener('resize', this.windowResizeFn)
    // 关闭加载框
    document.getElementById("lightning-loading").style.display = "none";

    // 添加水印
    let openWater = localStorage.getItem("openWater");
    if (openWater == "true") {
      let domApp = document.getElementById("app");
      if (domApp) {
        waterMark(
          domApp,
          document.documentElement.clientWidth + "px",
          document.documentElement.clientHeight + "px",
          "19998",
          "default"
        );
      }
    }
    this.userurl = this.$cookies.get("domainName");
    this.getallmenulist();
    // 监听应用程序的切换操作，执行切换程序方法
    this.$bus.$on("changeApplication", this.ebFn4);
    //其他页面跳转选项改变样式
    this.$Bus.$on("change-style", this.ebFn5);
    //监听编辑菜单按钮
    this.$Bus.$on("sendEditMenus",this.ebFn6 );

    //如果加载详情页,匹配展示菜单中是否有其父对象选项卡，有则添加选中样式，没有则将详情页所属对象菜单添加到临时菜单中
    this.$Bus.$on("change-detail-style", this.ebFn7);
  },
  destroyed() {
    this.$bus.$off("refreshTab1", this.ebFn1)
    this.$bus.$off("closeTab1", this.ebFn2)
    this.$bus.$off("clickTab1", this.ebFn3)
    window.removeEventListener('resize', this.windowResizeFn)
    this.$bus.$off("changeApplication", this.ebFn4);
    this.$Bus.$off("change-style", this.ebFn5);
    this.$Bus.$off("sendEditMenus",this.ebFn6 );
    this.$Bus.$off("change-detail-style", this.ebFn7);
  },
  watch: {
    showTabLists: {
      handler: function (newVal, oldVal) {
        if (!this.flag) {
          this.flag = true
          return
        }
        if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          // 动态改变一级菜单会走这里 如增加 减少菜单
          this.isRefresh = false;
          this.init(newVal);
        }
      },
      deep: true,
    },
    // 监听moreTabLists  level1Id的作用的实现让当前选中的菜单始终展示出来  不在更多下拉中
    moreTabLists:{
      handler: function (newVal) {
         // 当前选中的菜单始终展示在外面不在更多下拉中  添加新的菜单会走这里
        if(newVal.length > 0) {
          let currentIdx = newVal.findIndex(item => {
            return item.id == this.consoleData.data.level1Id
          })
          let currentItem = newVal[currentIdx]
          if (currentIdx != -1) {
            this.clickmoreTabs(currentItem,currentIdx)
          }
        }
      },
      deep: true,
      immediate: true
    },
    level1Id:{
      handler: function () {
        // 当前选中的菜单始终展示在外面不在更多下拉中  当前已经有了这项了在更多中但是当前选中的不是这项  当点这项时会走这里
        this.$nextTick(() => {
          if(this.moreTabLists.length > 0) {
            let currentIdx = this.moreTabLists.findIndex(item => {
              return item.id == this.consoleData.data.level1Id
            })
            let currentItem = this.moreTabLists[currentIdx]
            if (currentIdx != -1) {
              this.clickmoreTabs(currentItem,currentIdx)
            }
          }
        })
      },
      deep: true,
    },
    showMenus(newVal) {
      let consoleData = this.consoleData;
      // 当前菜单对象
      if (consoleData.currentObject == "") {
        consoleData.currentObject = this.showMenus[0].tabName;
        consoleData.currentObjectId = this.showMenus[0].id;
        // 菜单对象是自定义的还是标准的 0 标准 1自定义
        consoleData.iscustom = this.showMenus[0].iscustom || "0";
        consoleData.type = this.showMenus[0].type || "object";
        consoleData.objectApi = this.showMenus[0].objectApi || "Account";
        consoleData.objId = this.showMenus[0].objId || "account";
        consoleData.prefix = this.showMenus[0].prefix || "001";
        consoleData.viewId = "1";
        this.$store.commit("setConsoleData", consoleData);
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(consoleData),
        });
      }
      this.frontLoading = false;
      if (newVal.length !== 0) {
        let objItem = JSON.parse(localStorage.getItem("clickRightObj"));
        if (objItem) {
          newVal.forEach((item) => {
            item.isDefaultLaunchTab = "false";
            if (item.id == objItem.id) {
              item.isDefaultLaunchTab = "true";
            }
          });
        }
        this.frontLoading = false;
        this.$emit("setfrontLoading", false);
      }
    },
    moreMenus(newVal) {
      let objItem = JSON.parse(localStorage.getItem("clickRightObj"));
      if (objItem) {
        newVal.forEach((item) => {
          if (item?.id == objItem?.id && item.id !== "allmenu") {
            this.$emit("changeshowmenu", item);
          }
        });
      }
    },
    allMenus(newVal) {
      let objItem = JSON.parse(localStorage.getItem("clickRightObj"));
      // 需要判断objItem是否有值
      if (newVal && objItem) {
        if (!newVal.some((item) => item.id === objItem.id)) {
          this.$emit("changeshowmenu", objItem);
        }
      }
    },

    changeLoad(newVal) {
      if (newVal && newVal == true) {
        this.frontLoading = true;
      }
    },
  },
  methods: {
        // 计算字符串宽度（字符串，字号）
    getTextWidth(text, font) {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      context.font = font;
      var metrics = context.measureText(text);
      return parseInt(metrics.width);
    },
    // 鼠标悬浮是否显示
    onMouseOver(value, index) {
      let parentWidth = this.$refs.remarkFather[index].offsetWidth - 4; // 获取元素父级可视宽度
      let contentWidth = this.getTextWidth(value, "normal 14px Robot"); // 获取元素可视宽度
      this.isShowTooltip = contentWidth <= parentWidth;
    },
    ebFn1(tabItem){
      this.refreshTab(tabItem)
    },
    ebFn2(tabItem,tabIndex) {
      this.closeTab(tabItem, tabIndex);
    },
    ebFn3(tabItem){
      this.clickTab(tabItem);
    },
    windowResizeFn () {
      if (this.showTabLists.length > 0) {
        this.init(this.showTabLists);
      }
    },
    ebFn4(e){
      if (e.type && e.type === "pool") {
        // 选择的公海池跳转对应页面
        this.allmenufun(e);
      }
      this.clearotherpage(e.id);
    },
    ebFn5(params) {
      this.clearotherpage(params);
    },
    ebFn6() {
      this.openeditmymenu();
    },
    ebFn7(params){
      // 判断菜单栏上显示的菜单项中是否存在
      let isChange = this.showMenus.filter((item) => {
        return params == item.objId;
      });
      // 判断更多菜单中是否存在
      let isMore = this.moreMenus.filter((item) => {
        return params == item.objId;
      });
      // 在所有菜单中查找
      let isAll = this.alllication.filter((item) => {
        return params == item.objId;
      });
      // 展示菜单中存在，直接加选中标识
      if (isChange.length != 0) {
        this.clearotherpage(isChange[0].id);
        isChange[0].isDefaultLaunchTab = "true";
        sessionStorage.setItem("applicationTab", isChange[0].id);
      } else if (isMore.length != 0) {
        // 更多菜单中存在，删除更多菜单中数据，添加到展示菜单中（更多菜单去重在父组件changeshowmenu方法中执行）
        this.clearotherpage(isMore[0].id);
        isMore[0].isDefaultLaunchTab = "true";
        // 将当前菜单从更多菜单放入到展示菜单中,并且删除更多菜单中该项（此时不能保证顺序）
        setTimeout(() => {
          this.$emit("changeshowmenu", isMore[0], true);
        }, 300);
        sessionStorage.setItem("applicationTab", isMore[0].id);
      } else if (isAll.length != 0) {
        // 全部菜单中存在，将该菜单作为临时菜单展示(更多菜单去重)
        this.clearotherpage(isAll[0].id);
        isAll[0].isDefaultLaunchTab = "true";
        setTimeout(() => {
          this.$emit("changeshowmenu", isAll[0], true);
        }, 300);
        sessionStorage.setItem("applicationTab", isAll[0].id);
      } else {
        // 清空展示、更多菜单中的选中状态
        this.clearshowMenu();
        this.clearmoreMenu();
      }
    },
    // menuData菜单数据
    init(showTabLists) {
      // 必须要用一个变量接收，不能直接绑定showTabLists，因为showTabLists只是展示出来的部分，还有更多下拉里面的数据，直接绑定的话showTabLists变化会直接影响store的consoleData（但此时数据不是完整的）
      this.aaaCopy = JSON.parse(JSON.stringify(showTabLists));
      // 因为要重新计算所以先清除一下
      this.moreTabLists = [];
      this.isShowMore = false;
      this.$nextTick(() => {
        // 保证dom更新后再计算  否则拿不到dom元素
        this.computedshowmenu();
      });
    },
    // 点击当前menu
    clickCurrentMenu(e) {
      // 设置选中样式
      this.consoleData.data.level1Id = "menu";
      // 将数据保存到后端
      consoleSaveTab({
        appid: this.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(this.consoleData),
      });
      // 当前菜单对象
      let currentMenuObj = this.showMenus.find((item) => {
        return (item.tabName == this.consoleData.currentObject || item.id == this.consoleData.currentObjectId);
      });
      // 路由跳转
      this.clickshowMenu(currentMenuObj, e);
    },
    // 点击一级tab每项
    clickTab(tabItem) {
      // 如果当前点击的与当前选中的是一个tab 那么不进行跳转
      if (tabItem.id == this.consoleData.data.level1Id) {
        return;
      }
      // 设置当前选中tab的样式
      this.consoleData.data.level1Id = tabItem.id;
      this.$store.commit("setConsoleData", this.$store.state.consoleData);
      // 将数据保存到后端
      consoleSaveTab({
        appid: this.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(this.consoleData),
      });
      // 当前选中的一级tab
      let level1Id = this.consoleData.data.level1Id;
      // 过滤出当前一级菜单下的二级菜单列表
      this.consoleData.data.list.forEach((item) => {
        if (item.id == level1Id) {
          // 有children说明有二级tab 那么跳转到当前二级tab
          if (item.children && item.children.length > 0) {
            // 过滤出当前选中的二级item
            let level2Item = item.children.find((citem) => {
              return citem.id == item.level2Id;
            });
            // 有routerName说明是调用ccsdk生成的菜单
            if(level2Item.routerName) {
              this.$router.push({
                name: level2Item.routerName,
                params: level2Item.params || {}
              })
            } else if (level2Item.routerPath) {
              this.$router.push({
                path: level2Item.routerPath,
                query: level2Item.query || {},
              });
            } else {
              // 判断当前的二级是自定义页面 还是  标准（自定义）对象
              if (level2Item.customPage == "1") {
                // 新版自定义页面
                this.$router.push({
                  path: `/commonObjects/console-multi-screen/console-injection-component/${level2Item.pageApi}/${level2Item.id}`,
                });
              } else {
                this.$router.push({
                  path: `/commonObjects/console-multi-screen/console-detail/${item.level2Id}/DETAIL`,
                  query: {
                    dataId: item.level2Id,
                  },
                });
              }
            }
          } else {
            // 没有二级Tab 那么跳转到当前一级tab
            // 判断一级是否是通过ccsdk生成的
            if(item.routerName) {
              this.$router.push({
                name: item.routerName,
                params: item.params || {}
              })
            } else if (item.routerPath) {
              this.$router.push({
                path: item.routerPath,
                query: item.query || {},
              });
            } else{
              this.$router.push({
                path: `/commonObjects/console-multi-screen/console-detail/${level1Id}/DETAIL`,
                query: {
                  dataId: level1Id,
                },
              });
            }
          }
        }
      });
    },
    // tab的操作
    /**
     * tabItem   当前tab对象
     * tabIndex  当前tab的下标
     * tabActionItem 当前tab的操作项
     */
    clickTabHandle(tabItem, tabIndex, tabActionItem) {
      if (tabActionItem.id == "refresh") {
        this.refreshTab(tabItem)
      } else if (tabActionItem.id == "fixed") {
        // 固定
        // 将此条数据添加固定标识
        this.consoleData.data.list.forEach((item) => {
          if (item.id == tabItem.id) {
            item.isfixed = true;
          }
        });
        // 将数据固定到第一条
        let firstItem = this.consoleData.data.list.splice(tabIndex, 1);
        this.consoleData.data.list.unshift(...firstItem);
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      } else if (tabActionItem.id == "cancelFixed") {
        // 取消固定
        this.consoleData.data.list.map((item) => {
          if (item.id == tabItem.id) {
            item.isfixed = false;
          }
        });
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      } else if (tabActionItem.id == "custom") {
        // 自定义选项卡
        this.$refs.customTab.open(tabItem);
      } else if (tabActionItem.id == "close") {
        // 关闭
        this.closeTab(tabItem, tabIndex);
      }
    },
    // 刷新tab
    refreshTab(tabItem) {
      // 判断当前是选中状态再刷新
      if (this.consoleData.data.level1Id == tabItem.id) {
        // 刷新
        // 有routerName说明是调用ccsdk生成的菜单
        if (tabItem.routerName) {
          this.$router.push({
            name: tabItem.routerName,
            params: tabItem.params || {},
            query: {
              t: new Date().getTime()
            }
          })
        } else if (tabItem.routerPath) {
          tabItem.query = tabItem.query || {}
          tabItem.query.t = new Date().getTime()
          this.$router.push({
            path: tabItem.routerPath,
            query: tabItem.query || {},
          });
          delete tabItem.query.t
        } else {
          // 判断当前的二级是自定义页面 还是  标准（自定义）对象
          if (tabItem.customPage == "1") {
            // 新版自定义页面
            this.$router.push({
              path: `/commonObjects/console-multi-screen/console-injection-component/${tabItem.pageApi}/${tabItem.id}`,
            });
          } else {
            this.$router.push({
              path: `/commonObjects/console-multi-screen/console-detail/${tabItem.id}/DETAIL`,
              query: {
                dataId: tabItem.id,
                t: new Date().getTime(),
              },
            });
          }
        }
      }
    },
    // 关闭tab
    closeTab(tabItem, tabIndex) {
      // 清除对应的路由缓存页面
      this.$bus.$emit('removeCachePage',tabItem.id)
      // 清除存储localStorage
      localStorage.removeItem(tabItem.id)
      // 关闭的是当前选中的 关闭之后需要重新设置一个当前选中的
      if (tabItem.id == this.consoleData.data.level1Id) {
        // 关闭的是最后一位
        if (tabIndex == this.consoleData.data.list.length - 1) {
          this.consoleData.data.list.splice(tabIndex, 1);
          // 设置当前默认选中的一级菜单
          this.consoleData.data.level1Id =
            this.consoleData.data.list[this.consoleData.data.list.length - 1]
              ?.id || "";
        } else {
          // 关闭的不是最后一位
          // 删除
          this.consoleData.data.list.splice(tabIndex, 1);
          // 设置当前默认选中的一级菜单
          this.consoleData.data.level1Id =
            this.consoleData.data.list[tabIndex]?.id || "";
        }
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
        if (this.consoleData.data.level1Id) {
          // 判断当前选中的一级菜单下是否有二级  如果有二级  还需判断当前选中的二级是否是自定义页面  跳转不同的路由   如果没有二级  跳转当前默认的一级即可
          // 当前选中的一级tab
          let level1Id = this.consoleData.data.level1Id;
          // 过滤出当前一级菜单下的二级菜单列表
          this.consoleData.data.list.forEach((item) => {
            if (item.id == level1Id) {
              // 有children说明有二级tab 那么跳转到当前二级tab
              if (item.children && item.children.length > 0) {
                // 过滤出当前选中的二级item
                let level2Item = item.children.find((citem) => {
                  return citem.id == item.level2Id;
                });
                // 有routerName说明是调用ccsdk生成的菜单
                if(level2Item.routerName) {
                  this.$router.push({
                    name: level2Item.routerName,
                    params: level2Item.params || {}
                  })
                } else if (level2Item.routerPath) {
                  this.$router.push({
                    path: level2Item.routerPath,
                    query: level2Item.query || {},
                  });
                } else {
                  // 判断当前的二级是自定义页面 还是  标准（自定义）对象
                  if (level2Item.customPage == "1") {
                    // 新版自定义页面
                    this.$router.push({
                      path: `/commonObjects/console-multi-screen/console-injection-component/${level2Item.pageApi}/${level2Item.id}`,
                    });
                  } else {
                    this.$router.push({
                      path: `/commonObjects/console-multi-screen/console-detail/${item.level2Id}/DETAIL`,
                      query: {
                        dataId: item.level2Id,
                      },
                    });
                  }
                }
              } else {
                // 没有二级Tab 那么跳转到当前一级tab
                // 判断一级是否是通过ccsdk生成的
                if(item.routerName) {
                  this.$router.push({
                    name: item.routerName,
                    params: item.params || {}
                  })
                } else if (item.routerPath) {
                  this.$router.push({
                    path: item.routerPath,
                    query: item.query || {},
                  });
                } else {
                  this.$router.push({
                    path: `/commonObjects/console-multi-screen/console-detail/${level1Id}/DETAIL`,
                    query: {
                      dataId: level1Id,
                      // t: new Date().getTime(),
                    },
                  });
                }
              }
            }
          });
        } else {
          // 没有一级tab了 选中当前菜单 并跳到对应的菜单
          // 设置选中样式
          this.consoleData.data.level1Id = "menu";
          // 当前菜单信息
          let currentMenuObj = this.showMenus.find((item) => {
            return (item.tabName == this.consoleData.currentObject || item.id == this.consoleData.currentObjectId);
          });
          // 将数据保存到后端
          consoleSaveTab({
            appid: this.$store.state.home.homeApplicationId,
            opentab: JSON.stringify(this.consoleData),
          });
          // 下拉菜单点击派工平台打开一级菜单  当关闭之后会重新打开  添加标识判断
          currentMenuObj.operation = "close";
          // 调用home.vue里的切换菜单的方法
          this.$emit("clickshowMenu", { ...currentMenuObj });
        }
      } else {
        // 关闭的不是当前选中的直接关闭就好了
        this.consoleData.data.list.splice(tabIndex, 1);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      }
    },
    // 修改自定义选项卡dialog 点击确定之后
    comfirm(tabObj) {
      this.consoleData.data.list.forEach((item, index) => {
        if (item.id === tabObj.id && tabObj.name != "") {
          this.consoleData.data.list.splice(index, 1, tabObj);
        }
      });
    },
    // tab拖拽结束
    tabDragEnd() {
      // 拖拽不需要刷新
      this.flag = false
      let result = [...this.aaaCopy, ...this.moreTabLists];
      this.consoleData.data.list = result;
      consoleSaveTab({
        appid: this.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(this.consoleData),
      });
    },
    //计算菜单字符串长度
    strlen(str) {
      let len = 0;
      let copystr = str || "";
      // 菜单最多展示8个汉字长度
      if (copystr.length > 8) {
        copystr = copystr.substring(0, 7);
      }
      for (let i = 0; i < copystr.length; i++) {
        let c = copystr.charCodeAt(i);
        //单字节加1
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
          len++;
        } else {
          len += 2;
        }
      }
      return len;
    },
    /**
     * 一级tab点击更多
     */
    clickmoreTabs(item, index) {
      this.flag = false;
      // 展示菜单的最后一项拿出来
      let lastItem = this.aaaCopy.splice(this.aaaCopy.length - 1, 1);
      // 将点击的这项从更多中截取掉
      this.moreTabLists.splice(index, 1);
      // 将展示菜单的最后一项放到更多中
      this.moreTabLists.unshift(...lastItem);
      // 把点击的这项放到展示菜单的最后一个
      this.aaaCopy.push(item);
      let result = [...this.aaaCopy, ...this.moreTabLists];
      this.consoleData.data.list = result;
      consoleSaveTab({
        appid: this.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(this.consoleData),
      });
      this.clickTab(item);
    },
    /**
     * 关闭编辑菜单弹框触发方法
     */
    handleClose() {
      this.seachvalue = "";
      this.editmymenustatus = false;
    },
    clickRight(item) {
      let consoleData = this.consoleData;
      // 当前菜单对象
      consoleData.currentObject = item.tabName;
      consoleData.currentObjectId = item.id;
      if (item.objId != "" && item.objId != null) {
        // 菜单对象是自定义的还是标准的 0 标准 1自定义
        consoleData.iscustom = item.iscustom;
        consoleData.type = item.type;
        consoleData.objectApi = item.objectApi;
        consoleData.objId = item.objId;
        consoleData.prefix = item.prefix;
        consoleData.viewId = "1";
      }
      this.$store.commit("setConsoleData", consoleData);
      consoleSaveTab({
        appid: this.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(consoleData),
      });
      localStorage.setItem("clickRightObj", JSON.stringify(item));
    },
    // 仪表板跳转前调用仪表板最近查看接口，如果有数据跳转到仪表板页面，无数据跳转到仪表板文件夹列表页
    async getDashboardList() {
      let params = {
        name: "", //搜索内容
        dashboardfolderid: "recentDashboard",
        islightning: "true",
        page: "1",
        pageSize: "10000",
      };
      let data = await request.getDashboardList(params);
      if (data.data.listDashboard && data.data.listDashboard.length > 0) {
        let id = data.data.listDashboard[0].id;
        this.$router.push({
          path: `/dashboardObject/dashboardIndex/${id}`,
          query: {},
        });
      } else {
        this.$router.push({
          path: `/dashboardObject`,
        });
      }
    },

    /**
     * 其他页面切换选项卡,添加菜单选中样式
     * @param {String} params：菜单id
     */
    clearotherpage(params) {
      this.clearshowMenu();
      this.clearmoreMenu();
      this.showMenus.forEach((val) => {
        if (params == val.id) {
          val.isDefaultLaunchTab = "true";
        }
      });
    },
    menuenter() {
      this.showMoreMenu = true;
    },
    menuleave() {
      this.showMoreMenu = false;
    },
    openeditmymenu() {
      this.getmymenusetting();
      this.editmymenustatus = true;
    },
    //关闭临时菜单
    closetemporary() {
      this.$emit("canceltemporary");
    },
    //关闭全部菜单弹框时重新赋值所有数据
    closedialog() {
      this.seachinput = "";
      this.getallmenulist();
    },
    //可选菜单中移入样式
    unselectedTabenter(id) {
      this.unselectedTabList.forEach((item) => {
        if (item.id == id) {
          item.isshow = true;
        }
      });
    },
    //可选菜单中移出样式
    unselectedTableave(id) {
      this.unselectedTabList.forEach((item) => {
        if (item.id == id) {
          item.isshow = false;
        }
      });
    },
    //已选菜单中移入样式
    selectedTabTabenter(id) {
      this.selectedTabList.forEach((item) => {
        if (item.id == id) {
          item.isshow = true;
        }
      });
    },
    //已选菜单中移出样式
    selectedTabTableave(id) {
      this.selectedTabList.forEach((item) => {
        if (item.id == id) {
          item.isshow = false;
        }
      });
    },
    //可选菜单向已选菜单中添加选项
    addselect(id) {
      let addselectval;
      this.seachunselectedTabList.forEach((item, index) => {
        if (item.id == id) {
          item.isshow = false;
          addselectval = this.seachunselectedTabList.splice(index, 1);
        }
      });
      this.selectedTabList.push(addselectval[0]);
      this.filterunselectedTabList.push(addselectval[0]);
    },
    //已选菜单向可选菜单中添加选项
    removeselect(id) {
      if (this.selectedTabList.length === 1) {
        this.$message.warning(this.$i18n.t("label.partnerCloud.selectOne"));
        return false;
      }
      let removeselectval;
      this.selectedTabList.forEach((item, index) => {
        if (item.id == id) {
          item.isshow = false;
          removeselectval = this.selectedTabList.splice(index, 1);
        }
      });
      this.unselectedTabList.unshift(removeselectval[0]);
      this.Optionalmenuseach();
    },
    //拖拽选项时清除样式
    drstart() {
      this.selectedTabList.forEach((item) => {
        item.isshow = false;
      });
    },
    //可选菜单中搜索
    Optionalmenuseach() {
      if (this.seachvalue == "") {
        this.seachunselectedTabList = this.unselectedTabList;
      } else {
        this.seachunselectedTabList = this.unselectedTabList.filter((item) => {
          // 搜索不需要区分大小写
          let tabName = item.tabName.toUpperCase();
          let seachvalue = this.seachvalue.toUpperCase();
          return tabName.includes(seachvalue);
        });
      }
    },
    //监听可选菜单中搜索框是否为空
    isempty() {
      if (this.seachvalue == "") {
        this.unselectedTabList.forEach((item, index) => {
          this.filterunselectedTabList.forEach((value) => {
            if (item.id == value.id) {
              this.unselectedTabList.splice(index, 1);
            }
          });
        });
        this.seachunselectedTabList = this.unselectedTabList;
      }
    },
    //提交编辑后的自定义菜单
    savemymenusetting() {
      this.saveloading = true;
      let filterids = [];
      this.selectedTabList.forEach((item) => {
        filterids.push(item.id);
      });
      let filterIdsStr = filterids.join(",");
      LayoutApi.savemymenusetting({
        id: this.$store.state.home.homeApplicationId,
        tabIds: filterIdsStr,
      })
        .then((res) => {
          this.saveloading = false;
          this.editmymenustatus = false;
          this.seachvalue = "";
          if (res.result == true) {
            this.$emit(
              "Refreshapplicationview",
              this.$store.state.home.homeApplicationId
            );
            this.getmymenusetting();
          }
        })
        .catch(() => {});
    },
    /**
     * getmymenusetting:获取可选菜单及已选菜单
     *
     */
    getmymenusetting() {
      this.editmenuloading = true;
      LayoutApi.getmymenusetting({
        id: this.$store.state.home.homeApplicationId,
      })
        .then((res) => {
          let filterselectedTabList = [];
          res.data.selectedTabList &&
            res.data.selectedTabList.forEach((value) => {
              if (this.tabIds.indexOf(value.id) !== -1) {
                value.type = "";
              } else if (value.id == "acf20200615task") {
                value.type = "object";
                value.objectApi = "Task";
                value.objId = "task";
                value.prefix = "bfa";
              }
              value.isshow = false;
              filterselectedTabList.push(value);
            });
          this.selectedTabList = filterselectedTabList.filter((item) => {
            if (this.isCcchatShow === "true") {
              return item.type != "applauncher" && item.id != "acf000010";
            } else {
              return (
                item.type != "applauncher" &&
                item.id != "acf000010" &&
                item.id != "acf201200141050RgtYi" &&
                item.id != "acf201300143141ebsD8"
              );
            }
          });
          let filterunselectedTabList = [];
          res.data.unselectedTabList &&
            res.data.unselectedTabList.forEach((value) => {
              if (this.tabIds.indexOf(value.id) !== -1) {
                value.type = "";
              } else if (value.id == "acf20200615task") {
                value.type = "object";
                value.objectApi = "Task";
                value.objId = "task";
                value.prefix = "bfa";
              }
              value.isshow = false;
              filterunselectedTabList.push(value);
            });
          this.unselectedTabList = filterunselectedTabList.filter((item) => {
            if (this.isCcchatShow === "true") {
              return item.type != "applauncher" && item.id != "acf000010";
            } else {
              return (
                item.type != "applauncher" &&
                item.id != "acf000010" &&
                item.id != "acf201200141050RgtYi" &&
                item.id != "acf201300143141ebsD8"
              );
            }
          });
          this.editmenuloading = false;
          this.Optionalmenuseach();
          this.$emit("cancelLoading");
        })
        .catch(() => {});
    },
    //计算当前浏览器窗口大小所展示的菜单数量
    computedshowmenu: debounce(function () {
      let arr = [];
      let arrIndex = 0; // 超出盒子宽度的下标
      // box
      let box = document.getElementById("box");
      let num = box?.childNodes.length;
      // 计算出每个元素渲染后dom的宽度
      for (let i = 0; i < num; i++) {
        // 当前按钮宽度 + 当前按钮距离浏览器左边的宽度
        // 通过点击事件动态添加的菜单后的offsetLeft，比刷新后的的offsetLeft的数值多七八十像素 -70 可以优化更多位置靠右显示
        let distance;
        if (this.isRefresh) {
          distance = 30
        } else {
          distance = 120
        }
        let weight =
          box?.childNodes[i].offsetWidth + box?.childNodes[i].offsetLeft - distance;
        arr.push(weight);
      }
      // 一级菜单盒子的宽度  this.topTitleWidth为右侧 设置区域  380右侧编辑菜单等区域  115应用程序名称 70菜单名称 55左上角图标
      let boxWidth = document.documentElement.clientWidth - 380 - 115 - 70 - 55


      // 更多的宽度
      let moreWidth = 70;
      // 计算出第几个元素渲染后盒子就装不下了,返回对应元素的索引,都不满足条件返回-1
      arrIndex = arr.findIndex((res) => res > boxWidth - moreWidth);
      if (arrIndex != -1) {
        //  装不下
        this.moreTabLists = this.aaaCopy.splice(
          arrIndex,
          arr.length - arrIndex
        );

        this.isShowMore = true;
      } else {
        // 能装下
        this.moreTabLists = [];
        this.isShowMore = false;
      }
    }, 0),
    //按照英文字母搜索选项关键字
    seachletter(val) {
      // const pinyinMatch = require("pinyin-match");
      if (val && val != "ALL") {
        let result = [];
        this.copyalllication.forEach((e) => {
          var m = pinyinMatch.match(e.tabName.charAt(0), val);
          if (m) {
            result.push(e);
          }
        });
        this.alllication = result;
      }

      if (val == "ALL") {
        this.alllication = this.copyalllication;
      }
    },
    //监听全部菜单中搜索框是否为空
    getseachtext() {
      if (this.seachinput == "") {
        this.alllication.length = 0;
        if (this.alllication.length == 0) {
          this.getallmenulist();
        }
      }
    },
    // 获取CCChat后台设置信息
    async getSetupInfo() {
      let res = await getSetupInfo();
      if (res.result) {
        this.isCcchatShow = res.data.isEnable;
      }
    },
    //获取全部菜单
    getallmenulist() {
      this.seachloading = true;
      let filtercalendar = [];
      LayoutApi.seachApplicationTab({
        searchKey: this.seachinput,
      })
        .then((res) => {
          this.seachloading = false;
          res.data.forEach((value) => {
            if (this.tabIds.indexOf(value.id) !== -1) {
              value.type = "";
              //任务
            } else if (
              value.id == "acfTargetmodelstdtab" ||
              value.id == "acfTargetstdtab00" ||
              value.id == "acf2013121431foreca"
            ) {
              value.type = "";
            } else if (value.id == "acf20200615task") {
              value.type = "object";
              value.objectApi = "Task";
              value.objId = "task";
              value.prefix = "bfa";
            }
            filtercalendar.push(value);
          });
          this.alllication = filtercalendar.filter((item) => {
            if (this.isCcchatShow === "true") {
              return item.type != "applauncher" && item.id != "acf000010";
            } else {
              return (
                item.type != "applauncher" &&
                item.id != "acf000010" &&
                item.id != "acf201200141050RgtYi" &&
                item.id != "acf201300143141ebsD8"
              );
            }
          });
          this.copyalllication = this.alllication;
        })
        .catch(() => {});
    },
    /**
     * 关闭所有一级菜单
     */
    closeAllMenus() {
      // 清空一级菜单
      this.consoleData.data.list = [];
      // 设置下拉项菜单选中样式
      this.consoleData.data.level1Id = "menu";
      // 当前菜单信息
      let currentMenuObj = this.showMenus.find((item) => {
        return (
          item.tabName == this.consoleData.currentObject ||
          item.id == this.consoleData.currentObjectId
        );
      });
      this.$store.commit("setConsoleData", this.consoleData);
      currentMenuObj.operation = "close";
      // 调用home.vue里的切换菜单的方法
      this.$emit("clickshowMenu", { ...currentMenuObj });
      // 将数据保存到后端
      consoleSaveTab({
        appid: this.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(this.consoleData),
      });
    },
    // 点击切换展示菜单（顶部横向菜单，左侧应用程序不会执行）
    clickshowMenu(item, e) {
      this.consoleData.data.level1Id = "menu";
      let consoleData = this.consoleData;
      // 当前菜单对象
      consoleData.currentObject = item.tabName;
      consoleData.currentObjectId = item.id;
      if (item.objId != "" && item.objId != null) {
        // 菜单对象是自定义的还是标准的 0 标准 1自定义
        consoleData.iscustom = item.iscustom;
        consoleData.type = item.type;
        consoleData.objectApi = item.objectApi;
        consoleData.objId = item.objId;
        consoleData.prefix = item.prefix;
        consoleData.viewId = "1";
      }
      this.$store.commit("setConsoleData", consoleData);
      consoleSaveTab({
        appid: this.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(consoleData),
      });
      e.preventDefault();

      let tableListConfig = {
        prefix: "", // 视图id
        viewId: "", // 视图id
        pageSize: 25, // 每页条数
        skippageNum: 1, // 当前页数
        applicationId: item.id, // 应用id
        sortField: "", // 当前排序字段
        sortDir: "", // 当前排序顺序
      };
      this.$store.commit("setTableListConfig", tableListConfig); // 存储列表条件
      localStorage.setItem("clickRightObj", JSON.stringify(item));
      this.$store.state.List = item;
      VueCookies.set("activeTabObjId", item.objId || item.id, { sameSite: 'Strict' }); //cookie存储菜单id用来特殊处理(mjx)
      this.$emit("clickshowMenu", item);
      this.$bus.$emit("hideTool", false); //改变列表视图设置字段弹框为false
    },
    //点击切换更多菜单
    clickmoreMenu(item, allBool) {
      let tableListConfig = {
        prefix: "", // 视图id
        viewId: "", // 视图id
        pageSize: 25, // 每页条数
        skippageNum: 1, // 当前页数
        applicationId: item.id, // 应用id
        sortField: "", // 当前排序字段
        sortDir: "", // 当前排序顺序
      };
      // let allmenuItem = {};
      // this.moreMenus.map((item) => {
      // if (item.id == "allmenu") {
      //   allmenuItem = item;
      // }
      // });
      this.$store.commit("setTableListConfig", tableListConfig); // 存储列表条件
      this.$store.state.List = item;
      this.$emit("clickmoreMenu", item);

      if (allBool) {
        let allmenuItem = {};
        this.moreMenus.map((item) => {
          if (item.id == "allmenu") {
            allmenuItem = item;
          }
        });
        this.$store.state.List = allmenuItem;
        this.$emit("clickmoreMenu", allmenuItem);
        this.getSetupInfo();
        this.getallmenulist();
      }
      // 点击其余菜单
      else {
        localStorage.setItem("clickRightObj", JSON.stringify(item));
        this.$store.state.List = item;
        this.$emit("clickmoreMenu", item);
      }
    },
    //清除展示菜单选中状态
    clearshowMenu() {
      this.showMenus.forEach((item) => {
        item.isDefaultLaunchTab = "false";
      });
    },
    //清除更多菜单选中状态
    clearmoreMenu() {
      this.moreMenus.forEach((item) => {
        item.isDefaultLaunchTab = "false";
      });
    },
    showallmenu() {
      this.dialogstatus = true;
    },
    //点全部菜单中击切换页面并执行临时菜单方法
    allmenufun(tag, e) {
      this.consoleData.data.level1Id = "menu";
      let consoleData = this.consoleData;
      // 当前菜单对象
      consoleData.currentObject = tag.tabName;
      consoleData.currentObjectId = tag.id;
      if (tag.objId != "" && tag.objId != null) {
        // 菜单对象是自定义的还是标准的 0 标准 1自定义
        consoleData.iscustom = tag.iscustom;
        consoleData.type = tag.type;
        consoleData.objectApi = tag.objectApi;
        consoleData.objId = tag.objId;
        consoleData.prefix = tag.prefix;
        consoleData.viewId = "1";
      }
      this.$store.commit("setConsoleData", consoleData);
      consoleSaveTab({
        appid: this.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(consoleData),
      });
      e.preventDefault();
      let tableListConfig = {
        prefix: "", // 视图id
        viewId: "", // 视图id
        pageSize: 25, // 每页条数
        skippageNum: 1, // 当前页数
        applicationId: tag.id, // 应用id
        sortField: "", // 当前排序字段
        sortDir: "", // 当前排序顺序
      };
      this.$store.commit("setTableListConfig", tableListConfig); // 存储列表条件
      localStorage.setItem("clickRightObj", JSON.stringify(tag));
      // 删除临时菜单项
      let temporAry = this.showMenus.filter((item) => !item.istemporary);
      // 更新showMenus展示菜单数据
      this.$emit("update:showMenus", temporAry);
      localStorage.setItem("applicationTab", tag.id);
      VueCookies.set("activeTabObjId", tag.objId || tag.id, { sameSite: 'Strict' }); //cookie存储菜单id用来特殊处理(mjx)
      // lightning版本自定义页面
      if (
        tag.lightningpageId !== undefined &&
        tag.lightningpageId !== null &&
        tag.lightningpageId !== "null" &&
        tag.lightningpageId !== ""
      ) {
        this.clearshowMenu();
        this.clearmoreMenu();
        this.dialogstatus = false;
        VueCookies.set("customePageUrl", tag.pageUrl, { sameSite: 'Strict' });
        if (this.$route.path === "/custome/customePage") {
          this.$bus.$emit("changeCustomePageUrl", tag.pageUrl);
        } else {
          // this.$router.push(`/custome/customePage`);
          // lightningpageId判断页面
          if (tag.lightningpageId.indexOf("#lightning") != -1) {
            this.clearshowMenu();
            this.clearmoreMenu();
            tag.isDefaultLaunchTab = "true";
            this.defaultLaunchTab = tag;
            sessionStorage.setItem("applicationTab", tag.id);

            this.$router.push({
              path: "/injectionComponent",
              query: {
                page: tag.lightningpageId.split("#")[0],
                button: "Home",
              },
            });
          } else {
            this.$router.push(`/custome/customePage`);
          }
        }
      } else if (tag.type == "page") {
        //page类型的菜单跳转
        let symbol;
        if (tag.url.indexOf("?") != -1) {
          symbol = "&";
        } else {
          symbol = "?";
        }
        window.open(
          `${this.userurl}${
            tag.url
          }${symbol}version=version2018&binding=${VueCookies.get("JSESSIONID")}`
        );
      } else if (tag.type == "web") {
        window.open(tag.url);
      } else if (tag.type == "script"){
        // 自定义脚本菜单
        let funText = tag.function_code ? tag.function_code : tag.functionCode
        let newFun = Function(funText)
        newFun()
      } else {
        this.clearshowMenu();
        this.clearmoreMenu();
        this.dialogstatus = false;
        let showTemporstr = this.strlen(
          this.showMenus[this.showMenus.length - 1].tabName
        );
        let allTemporst = this.strlen(tag.tabName);
        let showiscontain = this.showMenus.some((item) => item.id == tag.id);
        let moreiscontain = this.moreMenus.some((item) => item.id == tag.id);
        if (showiscontain == false && moreiscontain == false) {
          if (allTemporst <= showTemporstr) {
            this.$emit("changeshowmenus", tag);
          } else {
            this.$emit("changeshowmenus", tag);
          }
        } else {
          this.showMenus.forEach((item) => {
            if (tag.id == item.id) {
              item.isDefaultLaunchTab = "true";
            }
          });
          this.moreMenus.forEach((item) => {
            if (tag.id == item.id) {
              item.isDefaultLaunchTab = "true";
            }
          });
        }

        this.alllication.forEach((item) => {
          if (item.id === tag.id) {
            item.isDefaultLaunchTab = "true";
            // 判断是否通用对象 1 表示不是自定义对象
            if (item.id == "p1020207266DDC2nZ3Jp") {
              this.$router.push({
                path: `/actualworklist/listviews`,
              });
              return;
            } else if (item.id == "acf2021070mcforms") {
              // 市场活动 表单
              this.$router.push({
                path: `/marketForm/marketForm`,
              });
            } else if (item.id == "acf2021070mcsocial") {
              // 市场云社交媒体
              this.$router.push({
                path: `/social/social`,
              });
            } else if (item.id == "acfganttchart001") {
              this.$router.push({
                path: `/gantt/gantt`,
              });
            } else if (item.id === "acf20207266DDCLKAIQ1") {
              // 知识文章
              this.$router.push({
                path: `/Knowledgearticles/CloudccKArticle/cloudcc_k_article/k02`,
              });
            }
            // else if (item.id === "acf2011000053244B1ew") {
            //   // 个案详情
            //   this.$router.push({
            //     path: `/controlPanel/control`,
            //   });
            // }
            else if (item.type === "object") {
              this.$router.push({
                path: `/commonObjects/views/table/${item.objectApi}/${item.objId}/${item.prefix}`,
              });
            } else if (item.id === "acf2020340C93FD7chBP") {
              // 标情发现
              this.$router.push({
                path: `/moodFound`,
              });
            } else if (item.id === "acf100000") {
              this.$router.push({
                path: `/reportObject/reportList`,
              });
            } else if (item.id === "acf000001") {
              this.$router.push({
                path: `/homePageObject`,
              });
            } else if (item.id === "acf100009") {
              // this.getDashboardList();
              this.$router.push({
                path: `/dashboardObject`,
              });
            } else if (
              item.id === "acf20200615event" ||
              item.id === "acf20208AD53CC8sLvgf"
            ) {
              this.$router.push({
                path: `/CalendarHeader`,
              });
            } else if (item.id === "acf20171206fileUjdys") {
              this.$router.push({
                path: `/ccFileCenter`,
              });
            } else if (item.id === "acf20191021FeiAUjdys") {
              this.$router.push({
                path: `/relation`,
              });
            } else if (item.id === "acf201200141050RgtYi") {
              this.$router.push({
                path: `/colleagueCircle`,
              });
            } else if (item.id === "acf2015030630xsld") {
              this.$router.push({
                path: `/commonObjects/hopper`,
              });
            } else if (item.id == "acf2020110PriceBooks") {
              this.$router.push({
                path: `/priceManual/price-manual`,
              });
            } else if (item.id == "acfTargetmodelstdtab") {
              this.$router.push({
                path: `/targetModel/index`,
              });
            } else if (item.id == "acfTargetstdtab00") {
              this.$router.push({
                path: `/target/index`,
              });
            } else if (item.id == "acf20201216smslist") {
              this.$router.push({
                path: `/noteSms/noteSmsList`,
              });
            } else if (item.id === "acf202011leadmksea") {
              // 公海池部分
              // 潜在客户公海池
              this.$router.push({
                path: `/clientPool/Lead/lead/004`,
              });
            } else if (item.id === "acf202011accmksea") {
              // 客户公海池
              this.$router.push({
                path: `/clientPool/Account/account/001`,
              });
            } else if (item.id === "acfFieldServiceSystb") {
              //派工平台
              // 当应用程序为控制台菜单时，点击派工平台需要打开一级菜单
              if (this.$store.state.navigationStyle) {
                // 当应用程序为控制台菜单时，点击派工平台需要打开一级菜单
                let menuObj = {
                  name: item.tabName,
                  id: item.id,
                  routerName: 'consoleDispatchingUnits',
                }
                generatelevel1Menu(this,item,menuObj)
                this.$router.push({
                  path:`/commonObjects/console-multi-screen/console-dispatchingUnits`,
                  query: {
                    t: new Date().getTime()
                  }
                });
              } else {
                this.$router.push({
                  path: `/dispatchingUnits`,
                });
              }
              //小组
            } else if (item.id === "acf20190807sgeAUjdys") {
              this.$router.push({
                path: `/groupObject/groupList`,
              });
            } else if (item.id === "acf20219D0E7E25g4uUv") {
              this.$router.push({
                path: `/resoursecalendar/resoursecalendar`,
              });
            } else if (item.id === "acf2013121431foreca") {
              // 预测
              this.$router.push({
                path: `/forecast/forecast`,
              });
            } //伙伴云-订货平台
            else if (item.id === "acf20210713orderpro1") {
              this.$router.push({
                path: `/ordering`,
              });
            }
            // 系统设置页（写死发布）
            else if (item.id === "systemSettings") {
              this.$router.push({
                path: `/systemSettings`,
              });
            }
            //聊天
            else if (item.id === "acfConversationTab00") {
              // 导航样式为控制台样式打开一级菜单
              // 菜单所需数据
              let menuObj = {
                name: item.tabName,
                id: item.id,
                // 是否展示tab关闭按钮
                isShowClose: false,
                // tab下拉操作项
                tabAction: [
                  {
                    name: "刷新选项卡",
                    id: "refresh",
                  },
                  {
                    name: "取消固定选项卡",
                    id: "cancelFixed",
                  },
                  {
                    name: "自定义选项卡",
                    id: "custom",
                  },
                ],
                routerPath: '/customerWorkbench/workbench',
              }
              generatelevel1Menu(this,item,menuObj)
              this.$router.push({
                path: `/customerWorkbench/workbench`,
              });
            }
            //控制台
            else if (item.id === "acfServiceControlTab") {
              this.$router.push({
                path: `/commonObjects/views/table/cloudcccase/201100000005024CxOSz/008?isConsole=true`,
                // path: `/controlPanel/control`,
              });
            } else {
              this.$router.push({
                path: `/`,
              });
            }
          }
        });
      }
    },
  },
  computed: {
    ...mapState({ consoleData: (state) => state.consoleData }),
    showTabLists(state) {
      // 一级tablist
      let level1TabList = JSON.parse(
        JSON.stringify(state.consoleData.data.list)
      );
      // level1TabList.length > 0 &&
      //   level1TabList.forEach((item) => {
      //     if (item.isfixed) {
      //       // 固定选项卡
      //       item.tabAction = [
      //         {
      //           name: "刷新选项卡",
      //           id: "refresh",
      //         },
      //         {
      //           name: "取消固定选项卡",
      //           id: "cancelFixed",
      //         },
      //         {
      //           name: "自定义选项卡",
      //           id: "custom",
      //         },
      //         {
      //           name: "关闭选项卡",
      //           id: "close",
      //         },
      //       ];
      //     } else {
      //       // 非固定选项卡
      //       item.tabAction = [
      //         {
      //           name: "刷新选项卡",
      //           id: "refresh",
      //         },
      //         {
      //           name: "固定选项卡",
      //           id: "fixed",
      //         },
      //         {
      //           name: "自定义选项卡",
      //           id: "custom",
      //         },
      //         {
      //           name: "关闭选项卡",
      //           id: "close",
      //         },
      //       ];
      //     }
      //   });
      return level1TabList;
    },
    level1Id(state) {
      return state.consoleData.data.level1Id
    },
    hrefInfo() {
      return (data) => {
        // 此data包含当前使用对象
        if (!data.type) {
          return "我的type为空，不知道跳到那里去";
        } else {
          return `#/commonObjects/views/table/${data.objectApi}/${data.objId}/${data.prefix}`;
        }
      };
    },
    // 全部菜单的a标签跳转
    linkHref() {
      return (item) => {
        // 判断是否通用对象 1 表示不是自定义对象
        if (item.id == "p1020207266DDC2nZ3Jp") {
          return `/#/actualworklist/listviews`;
        } else if (item.id === "acf2021070mcforms") {
          //市场活动表单
          return `/#/marketForm/marketForm`;
        } else if (item.id === "acf2021070mcsocial") {
          //市场云社交媒体
          return `/#/social/social`;
        } else if (item.id == "acfganttchart001") {
          return `/#/gantt/gantt`;
        } else if (item.id == "acf20207266DDCLKAIQ1") {
          return `/#/Knowledgearticles/CloudccKArticle/cloudcc_k_article/k02`;
        }
        // else if (item.id === "acf2011000053244B1ew") {
        //       // 个案详情
        //   return `/#/controlPanel/control`;
        // }
        else if (item.id === "acfShiftstdtab00") {
          return `/#/commonObjects/views/plan/${item.objectApi}/${item.objId}/${item.prefix}`;
        } else if (item.type === "object") {
          return `/#/commonObjects/views/table/${item.objectApi}/${item.objId}/${item.prefix}`;
        } else if (item.type === "web") {
          return item.url;
        } else if (item.id === "acf100000") {
          return `/#/reportObject/reportList`;
        } else if (item.id === "acf000001") {
          let returnPath = `/#/homePageObject`;
          return returnPath;
        }
        // else if (item.id === "acf2011000053244B1ew") {
        //   // 个案详情
        //   return `/#/controlPanel/control`;
        // }
        else if (item.id === "acf100009") {
          return `/#/dashboardObject`;
        } else if (
          item.id === "acf20200615event" ||
          item.id === "acf20208AD53CC8sLvgf"
        ) {
          return `/#/CalendarHeader`;
        } else if (item.id === "acf20171206fileUjdys") {
          return `/#/ccFileCenter`;
        } else if (item.id === "acf20191021FeiAUjdys") {
          return `/#/relation`;
        } else if (item.id === "acf201200141050RgtYi") {
          return `/#/colleagueCircle`;
        } else if (item.id === "acf2015030630xsld") {
          return `/#/commonObjects/hopper`;
        } else if (item.id === "acf2020110PriceBooks") {
          return `/#/priceManual/price-manual`;
        } else if (item.id === "acfTargetmodelstdtab") {
          return `/#/targetModel/index`;
        } else if (item.id === "acfTargetstdtab00") {
          return `/#/target/index`;
        } else if (item.id == "acf20201216smslist") {
          return `/#/noteSms/noteSmsList`;
        }
        // 公海池部分
        if (item.id === "acf202011leadmksea") {
          // 潜在客户公海池
          return `/#/clientPool/Lead/lead/004`;
        } else if (item.id === "acf2021070mcforms") {
          //市场活动表单
          return `/#/marketForm/marketForm`;
        } else if (item.id === "acf2021070mcsocial") {
          //市场云社交媒体
          return `/#/social/social`;
        } else if (item.id === "acf202011accmksea") {
          // 客户公海池
          return `/#/clientPool/Account/account/001`;
        } else if (item.id === "acfFieldServiceSystb") {
          //派工平台
          return `/#/dispatchingUnits`;
        } else if (item.id === "acf20190807sgeAUjdys") {
          //小组
          return `/#/groupObject/groupList`;
        } else if (item.id === "acf20219D0E7E25g4uUv") {
          //人员资源日历
          return `/#/resoursecalendar/resoursecalendar`;
        } else if (item.id === "acf2013121431foreca") {
          //预测
          return `/#/forecast/forecast`;
        } else if (item.id === "acf20210713orderpro1") {
          //伙伴云-订货平台
          return `/#/ordering`;
        } else if (item.id === "systemSettings") {
          // 系统设置页的顶部路由，写死
          return `/#/systemSettings`;
          //聊天
        } else if (item.id === "acfConversationTab00") {
          return `/#/customerWorkbench/workbench`;
        }
        //控制台
        else if (item.id === "acfServiceControlTab") {
          return `/#/commonObjects/views/table/cloudcccase/201100000005024CxOSz/008?isConsole=true`;
        } else if (item.iscustom === "1") {
          // 自定义页面
          if (
            item.lightningpageId &&
            item.lightningpageId.indexOf("#lightning") != -1
          ) {
            // 老自定义页面
            return `#/injectionComponent?page=${
              item.lightningpageId.split("#")[0]
            }&button=Home`;
          } else {
            // 老自定义页面
            return "#/custome/customePage";
          }
        }
      };
    },
    // 下拉框(更多)的link方法
    linkHrefDropdown() {
      return (item) => {
        // 判断是否通用对象 1 表示不是自定义对象
        if (item.id == "p1020207266DDC2nZ3Jp") {
          return `/#/actualworklist/listviews`;
        } else if (item.id === "acf2021070mcforms") {
          //市场活动表单
          return `/#/marketForm/marketForm`;
        } else if (item.id === "acf2021070mcsocial") {
          //市场云社交媒体
          return `/#/social/social`;
        } else if (item.id == "acfganttchart001") {
          return `/#/gantt/gantt`;
        } else if (item.id == "acf20207266DDCLKAIQ1") {
          return `/#/Knowledgearticles/CloudccKArticle/cloudcc_k_article/k02`;
        }
        //  else if (item.id === "acf2011000053244B1ew") {
        //       // 个案详情
        //   return `/#/controlPanel/control`;
        // }
        else if (item.type === "object") {
          return `/#/commonObjects/views/table/${item.objectApi}/${item.objId}/${item.prefix}`;
        } else if (item.type === "web") {
          return item.url;
        } else if (item.id === "acf100000") {
          return `/#/reportObject/reportList`;
        } else if (item.id === "acf000001") {
          let returnPath = `/#/homePageObject`;
          return returnPath;
        } else if (item.id === "acfganttchart001") {
          return `/#/gantt/gantt`;
        } else if (item.id === "acf100009") {
          return `/#/dashboardObject`;
        } else if (
          item.id === "acf20200615event" ||
          item.id === "acf20208AD53CC8sLvgf"
        ) {
          return `/#/CalendarHeader`;
        } else if (item.id === "acf20171206fileUjdys") {
          return `/#/ccFileCenter`;
        } else if (item.id === "acf20191021FeiAUjdys") {
          return `/#/relation`;
        } else if (item.id === "acf201200141050RgtYi") {
          return `/#/colleagueCircle`;
        } else if (item.id === "acf2015030630xsld") {
          return `/#/commonObjects/hopper`;
        } else if (item.id == "acf20201216smslist") {
          return `/#/noteSms/noteSmsList`;
        } else if (item.id === "acf202011leadmksea") {
          // 公海池部分
          // 潜在客户公海池
          return `/#/clientPool/Lead/lead/004`;
        } else if (item.id === "acf202011accmksea") {
          // 客户公海池
          return `/#/clientPool/Account/account/001`;
        } else if (item.id === "acf20219D0E7E25g4uUv") {
          //人员资源日历
          return `/#/resoursecalendar/resoursecalendar`;
        } else if (item.id === "acfFieldServiceSystb") {
          //派工平台
          return `/#/dispatchingUnits`;
        } else if (item.id === "acf20190807sgeAUjdys") {
          //小组
          return `/#/groupObject/groupList`;
        } else if (item.id === "acf20210713orderpro1") {
          //伙伴云-订货平台
          return `/#/ordering`;
          //聊天
        } else if (item.id === "acfConversationTab00") {
          return `/#/customerWorkbench/workbench`;
        }
        //控制台
        else if (item.id === "acfServiceControlTab") {
          return `/#/commonObjects/views/table/cloudcccase/201100000005024CxOSz/008?isConsole=true`;
          // return `/#/controlPanel/control`
        } else if (item.iscustom === "1") {
          // 自定义页面
          return "#/custome/customePage";
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  cursor: pointer;
}
.tabWrapper {
  height: 100%;
  display: flex;
}
.selectedTab-text {
  display: block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a {
  text-decoration: none !important;
}
input {
  outline-color: invert;
  outline-style: none;
  outline-width: 0px;
  border: none;
  text-shadow: none;
  -webkit-appearance: none;
  -webkit-user-select: text;
  outline-color: transparent;
  box-shadow: none;
}
h3 {
  margin: 0;
  padding: 0;
  list-style: none;
}
::v-deep .mymore-menus {
  ::-webkit-scrollbar {
    width: 3px; /*对垂直流动条有效*/
    height: 84px; /*对水平流动条有效*/
    background: #20233a;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: #abc4fe;
  }
  ::-webkit-scrollbar-track {
    background-color: #20233a;
    border-radius: 9px;
  }
}
::v-deep.el-popper .popper__arrow::after {
  border-width: 0;
}
::v-deep.el-popper .popper__arrow {
  border-width: 0;
}
::v-deep .moreMenus {
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  cursor: pointer;
  color: #fff;
  min-width: 70px;
  /*line-height: 46px;*/

  .more-menusbox:hover {
    color: #abc4fe !important;
  }
}
::v-deep .el-card__body {
  padding: 0;
  span {
    margin-left: 20px;
  }
}
.allmenuBox{
  ::v-deep .el-dialog__header {
    padding: 0 !important;
    border: none !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }
}
.editmenu {
  padding-right: 10px;
  cursor: pointer;
  span {
    font-size: 20px !important;
  }
}
.editicon {
  // position: absolute;
  top: 5px;
  right: 26px;
}
.editmenudialog {
  ::v-deep .el-dialog__header {
    padding: 16px 14px 16px 20px !important;
    border-bottom: 1px solid #dcdcdc !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }
  .editmymenubody {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  .Optionalmenu {
    width: 240px;
    height: 400px;
    border: 1px solid #d8d8d8;
    overflow: scroll;
    padding: 0 10px;
    .Optionalmenuseach {
      position: relative;
      margin-left: -3px;
      .searchBoxInput {
        margin-top: 10px;
        width: calc(100% - 17px);
      }
      img {
        position: absolute;
        left: 0;
        bottom: 3px;
        cursor: pointer;
      }
    }
    ul {
      border-top: 1px solid #dcdcdc;
      li {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
          cursor: pointer;
        }
      }
    }
  }
  .Selectedmenu {
    width: 240px;
    height: 400px;
    border: 1px solid #d8d8d8;
    overflow: scroll;
    padding: 0 10px;
    li {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        cursor: pointer;
      }
      i {
        cursor: pointer;
      }
    }
  }
}
.seachbox {
  position: relative;
  ::v-deep .el-input__inner {
    padding-right: 29px;
    width: 400px;
    height: 33px;
    margin: 0 auto;
  }
  .seachicon {
    position: absolute;
    right: 35px;
    top: 5px;
    cursor: pointer;
  }
  .letterseach {
    margin-top: 9px;
    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      font-size: 12px;
      li {
        color: #999999;
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
}

.applicationName {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  max-width: 115px;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 20px;
}

.menulist {
  .application {
    width: 150px;
    padding-left: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px;
    }
  }
}
.menupostion {
  margin: 0;
  // position: fixed;
  // top: 65px;
  // right: 42px;
}

.menupostion1 {
  margin: 0;
  // position: fixed;
  // top: 65px;
  // left: 264px;
}
.cardimg {
  margin-right: 5px;
  width: 15px;
  height: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}
.allmenutop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  background-color: #f8f8f8;
  .allmenuicon {
    width: 20px;
    height: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h3 {
    font-weight: bold;
    margin-left: 5px;
  }
}
.allmenulist {
  padding-bottom: 10px;
  ul {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 40px 0 40px 100px;
    li {
      width: 33.3%;
      margin-bottom: 15px;
      span {
        cursor: pointer;
      }
    }
  }
}

.topContent {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 0px 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .topContent_left {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .topcontentmenu {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      /*max-width: 145px;*/
      /*overflow:hidden;*/
      /*word-break:keep-all;*/
      /*white-space:nowrap;*/
      /*text-overflow:ellipsis;*/

      span {
        color: #fff;
      }
    }
    .data_left {
      width: 155px;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #080707;
      img {
        width: 21px;
        height: 21px;
        margin-right: 24px;
      }
    }
    .data_menu {
      height: 100%;
      width: calc(100% - 150px);
      display: flex;
      align-items: center;
      user-select: none;
      font-size: 14px;
      .curmenu-text-box {
        display: flex;
        align-items: center;
        padding: 0 15px;
        height: 100%;
        color: #fff;
        cursor: pointer;
        background: #475066;
        .curmenu-text {
          // display: block;
          /*max-width: 200px;*/
          max-width: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 15px;
          &:hover {
            color: #8dc9fc;
          }
        }
        i:hover {
          color: #8dc9fc;
        }
      }
      .each_menu {
        display: flex;
        align-items: center;
        margin-right: 5px;
        color: #fff;
        /*padding: 0 15px;*/
        padding: 0 5px;
        cursor: pointer;
        background: #20233a;
        span {
          display: flex;
          align-items: center;
          padding: 4px 2px;
          // border: 2px #20233a solid;
          a {
            max-width: 70px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            text-align: center;
            height: 30px;
            line-height: 30px;
          }
          a:hover {
            color: #abc4fe !important;
          }
        }
      }
      ::v-deep .el-popper {
        margin-top: 0;
      }
      ::v-deep .el-button--medium {
        border-radius: 0 !important;
      }
      ::v-deep .el-button {
        border-radius: 0 !important;
      }
    }
  }
  // 菜单选中样式
  .cur-menu {
    background: #ffffff !important;
    border-top: 6px solid #006dcc !important;
    color: #333 !important;
    i {
      color: #333 !important;
    }
  }
  .cur-menu1 {
    border-top: 6px solid #006dcc !important;
  }
  .topContent_right {
    position: relative;
    width: 100px;
    height: 40px;
    font-size: 14px;
    color: #080707;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .edit-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.see-more {
  text-align: center;
  color: #006dcc;
  cursor: pointer;
  font-size: 12px;
}

.link-text-color-style {
  li {
    a {
      color: #000 !important;
      font-size: 14px;
      display: inline-block;
      max-width: 150px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.link-dropdown-style {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  &:hover {
    color: #abc4fe;
  }
}
.link-dropdown-style:hover {
  background: #2e344e;
  border-radius: 3px;
}
.link-dropdown-style1 {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
  color: #333333;
  &:hover {
    color: #333333;
  }
}
.link-dropdown-style1:hover {
  background: rgba(238,238,238,0.9);
  border-radius: 3px;
}
.new_tab_all_menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #fff;
  /*line-height: 46px;*/
}
::v-deep.el-dropdown {
  color: #ffffff;
}
::v-deep.el-dropdown-menu,
::v-deep.el-popper {
  /*top: 30px !important;*/
  border: solid 1px #20233a;
  padding: 5px 0 0 0;
  margin-bottom: 0;
  .all-Menu-Btn {
    background: #475066;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-top: 1px #3a3e5e solid;
    margin-top: 8px;
    color: #ffffff;
    .boxItem{
      margin-bottom: 10px;
      cursor: pointer;
      &:hover,
      :active {
        color: #8dc9fc;
      }
    }
  }
}

.menus-show-all {
  overflow: auto;
}
.menus-show-hidden {
  overflow: hidden;
}
.more-menus-fixed {
  max-height: 260px;
}

.all-menus {
  text-align: center;
  border-top: 1px solid #3a3e5e;
  margin-top: 12px;
  a {
    color: #6c98fd !important;
    background: #20233a !important;
    margin-top: 10px;
  }
  .link-dropdown-style:hover {
    color: #2d6cfc !important;
  }
}
</style>
<style lang="scss" >
  .menu-tooltip{
    background-color: #ffffff !important;
    max-width: 500px;
    border-radius: 0;
    padding: 6px;
  }
  </style>
  