<template>
  <div class="chat-record-select">
    <div class="header">
      <div class="image" v-if="singleImage">
        <img
          :src="imgUrl"
          alt
          v-if="this.$store.state.list.isChatID.imId.length > 15"
        />
        <svg class="icon" aria-hidden="true" v-else>
          <use :href="imgUrl"></use>
        </svg>
      </div>
      <div class="name">
        <span
          v-show="!inputIsShow"
          @click="showInput"
          :style="{
            cursor:
              $store.state.list.isChatID.isGroup == true &&
              isOwner &&
              !$store.state.list.isChatID.isStrategyGroup &&
              !$store.state.list.isChatID.isOpportunityGroup
                ? 'pointer'
                : '',
          }"
          >{{ name }}</span
        >
        <input
          id="changeText"
          type="text"
          v-model="inputName"
          v-show="
            inputIsShow &&
            $store.state.list.isChatID.isGroup == true &&
            isOwner &&
            !$store.state.list.isChatID.isStrategyGroup &&
            !$store.state.list.isChatID.isOpportunityGroup
          "
          @blur="hiddenInput"
        />
      </div>
      <div
        class="peoples"
        v-if="$store.state.list.isChatID.isGroup == true && peoplenumber != ''"
      >
        ({{ peoplenumber }})
      </div>
      <div
        class="strategy"
        v-show="
          $store.state.list.isChatID.isGroup == true &&
          ($store.state.list.isChatID.isStrategyGroup == true ||
            $store.state.list.isChatID.isOpportunityGroup == true)
        "
        @click="customerDetails"
      >
        <u v-if="isAccount">{{ $t("label.weixin.customer.details") }}</u>
        <u v-if="!isAccount">{{ $t("vue_label_chat_opportunity_details") }}</u>
      </div>
      <span
        class="iconfont iconbianjiqianming-changtai edict"
        @click="showInput"
        v-if="
          !inputIsShow &&
          $store.state.list.isChatID.isGroup == true &&
          isOwner &&
          !$store.state.list.isChatID.isStrategyGroup &&
          !$store.state.list.isChatID.isOpportunityGroup
        "
        :style="{
          cursor:
            $store.state.list.isChatID.isGroup == true &&
            !$store.state.list.isChatID.isStrategyGroup &&
            !$store.state.list.isChatID.isOpportunityGroup
              ? 'pointer'
              : '',
        }"
      ></span>
    </div>

    <div class="chat-type">
      <svg
        @mouseover="moveMemberIcon"
        @mouseleave="leaveMemberIcon"
        @click="addMember"
        class="icon"
        aria-hidden="true"
        slot="reference"
      >
        <use
          :href="isMoveMember == false ? moveMemberPic : leaveMemberPic"
        ></use>
      </svg>
    </div>
    <select-member :isShowB="isShow" @changeShow="changeShow"></select-member>
  </div>
</template>

<script>
/*
 * 当前聊天用户或群组信息展示逻辑
 */
import selectMember from "./select-member";
import job from "@/views/chat/js/request.js";

export default {
  mounted() {
    this.$Bus.$off("change-image-top");
    this.$Bus.$on("change-image-top",this.ebFn1);
    this.$Bus.$on("deliver-groupOwner", this.ebFn2);
    this.$Bus.$on("change-chat-image", this.ebFn3);
    this.$Bus.$on("change-click-member", this.ebFn4);
    this.$Bus.$on("get-group-number-again", this.ebFn5);

    this.$Bus.$on("change-select-window", this.ebFn6);
    if (this.$store.state.list.isChatID.isGroup == false) {
      this.chatType = "user";
    } else {
      this.chatType = "group";
    }
  },
  beforeDestroy(){
    this.$Bus.$off("change-image-top",this.ebFn1);
    this.$Bus.$off("deliver-groupOwner", this.ebFn2);
    this.$Bus.$off("change-chat-image", this.ebFn3);
    this.$Bus.$off("change-click-member", this.ebFn4);
    this.$Bus.$off("get-group-number-again", this.ebFn5);
    this.$Bus.$off("change-select-window", this.ebFn6);
  },
  name: "chat-record-select",
  data() {
    return {
      //当前聊天头像
      singleImage: `${localStorage.getItem(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=${
        this.$store.state.list.isChatID.id
      }&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`,
      //判断是否是客户群策
      isAccount: true,
      //当前选择功能区
      isSelect: "聊天",
      //当前聊天的类型
      chatType: "",
      //判断当前聊天是否为群聊
      chat: this.$store.state.list.isChatID.isGroup,
      //是否展示更改群名称input框
      inputIsShow: false,
      //更改群名称input框内名称
      inputName: this.$store.state.list.isChatID.name,
      //创建群聊的时间
      timestamp: 0,
      peoplenumber: "",
      //群策客户ID
      strategyId: "",
      //控制添加成员的显示与隐藏
      isShow: false,
      //判断是否将鼠标放在成员图标上
      isMoveMember: false,
      //查看群成员移入图标
      moveMemberPic: "#icon-member",
      //查看群成员移出图标
      leaveMemberPic: "#icon-memberSelect",
      //判断是否是群主
      isOwner: false,
    };
  },
  components: {
    selectMember,
  },
  computed: {
    //当前聊天名称
    inputNames() {
      return this.$store.state.list.isChatID.name;
    },
    //当前聊天头像
    imgUrl() {
      if (this.$store.state.list.isChatID.imId.length > 15) {
        return this.singleImage;
      } else {
        if (this.$store.state.list.isChatID.isStrategyGroup) {
          return "#icon-cloudtab11_norm";
        } else if (this.$store.state.list.isChatID.isOpportunityGroup) {
          return "#icon-cloudtab12_norm";
        } else {
          return "#icon-group";
        }
      }
    },
    //当前聊天名称
    name() {
      return this.$store.state.list.isChatID.name;
    },
    //当前聊天imid
    isChatID() {
      return this.$store.state.list.isChatID.imId;
    },
  },
  methods: {
    ebFn1 (){
      this.singleImage = `${localStorage.getItem(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=${
        this.$store.state.list.isChatID.id
      }&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`;
    },
    ebFn2(res){
      this.isOwner = res ? true : false;
    },
    ebFn3(res){
      this.singleImage = res;
    },
    ebFn4(){
      this.isShow = false;
    },
    ebFn5(res){
      this.peoplenumber = res;
    },
    ebFn6(){
      this.isSelect = "聊天";
    },
    /*
     * 移入成员icon
     */
    moveMemberIcon() {
      this.isMoveMember = true;
    },
    /*
     * 移出成员琼icon
     */
    leaveMemberIcon() {
      this.isMoveMember = false;
    },
    /*
     * 人员图标点击显示人员列表
     */
    addMember() {
      this.isShow = true;
      this.$Bus.$emit("close-window-addMember", false);
    },
    /*
     * 控制添加成员页面的显示与隐藏
     */
    changeShow(val) {
      this.isShow = val;
    },
    /*
     * 群策跳转客户详情
     */
    customerDetails() {
      if (this.strategyId != "") {
        this.$router.push({
          path: `/commonObjects/detail/${this.strategyId}/DETAIL`,
        });
        this.$Bus.$emit("close-chat-window", false);
      }
    },
    /*
     * 获取群策的客户ID
     */
    getStrategyId() {
      if (
        this.$store.state.list.isChatID.isGroup == true &&
        (this.$store.state.list.isChatID.isStrategyGroup == true ||
          this.$store.state.list.isChatID.isOpportunityGroup == true)
      ) {
        this.getStrategyIdPost();
      }
    },
    /*
     * 获取群策的客户ID请求
     */
    async getStrategyIdPost() {
      let result = await job.getStrategyMember(
        this.$store.state.list.isChatID.imId
      );
      if (result.result == true && result.data) {
        this.strategyId = result.data[0].recordId;
        this.isAccount = result.data[0].objid == "account" ? true : false;
      }
    },
    /*
     * 更改群组信息
     */
    async updateGroupDate() {
      let result = await job.updateGroupDate(
        this.$store.state.list.isChatID.imId,
        this.inputName,
        this.timestamp
      );
      if (result.result == true) {
        let index = this.$store.state.list.chatList.findIndex(
          (res) => this.$store.state.list.isChatID.imId == res.imId
        );
        this.$store.commit("changeGroupName", {
          index: index,
          inputName: this.inputName,
        });
        this.sendCmdMsg();
      }
    },
    /*
     * 发送群聊更改名称消息通知
     */
    sendCmdMsg() {
      var id = this.$imconn.getUniqueId(); //生成本地消息id
      var msg = new window.WebIM.message("cmd", id); //创建命令消息

      msg.set({
        to: this.$store.state.list.isChatID.imId, //接收消息对象
        action: "ChangeGroupName", //用户自定义，cmd消息必填
        ext: {}, //用户自扩展的消息内容（群聊用法相同）
        chatType: "groupChat",
      });
      this.$imconn.send(msg.body);
    },
    /*
     * 隐藏input框
     */
    async hiddenInput() {
      if (this.$store.state.list.isChatID.name != this.inputName) {
        this.$store.state.list.isChatID.name = this.inputName;
        let groupmsg = await job.getgroupmessage(
          this.$store.state.list.isChatID.imId
        );
        if ((groupmsg.result = true && groupmsg.data)) {
          this.timestamp = groupmsg.data.createDate;
          //环信更改群组名称
          let option = {
            groupId: this.$store.state.list.isChatID.imId,
            groupName: this.inputName, // 群组名称
            description: "", // 群组简介
          };
          this.$imconn
            .modifyGroup(option)
            .then(() => {
              this.updateGroupDate();
            })
            .catch((error) => {
              this.$message.error(JSON.parse(error.data).error_description);
            });
        }
      }

      this.inputIsShow = false;
    },
    /*
     * 显示名字处的input框
     */
    showInput() {
      if (
        this.$store.state.list.isChatID.isGroup == true &&
        this.isOwner &&
        !this.$store.state.list.isChatID.isStrategyGroup &&
        !this.$store.state.list.isChatID.isOpportunityGroup
      ) {
        setTimeout(() => {
          document.getElementById("changeText").focus();
        }, 100);
        this.inputIsShow = true;
      }
    },
  },
  watch: {
    inputNames(val) {
      this.inputName = val;
    },
    chat(val) {
      if (val == false) {
        this.chatType = "user";
      } else {
        this.chatType = "group";
      }
    },
    isSelect(val) {
      this.$Bus.$emit("deliver-chat-function", val);
    },
    isChatID: {
      handler() {
        if (this.$store.state.list.isChatID.isGroup) {
          this.getStrategyId();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang='scss'>
.chat-record-select {
  width: 100%;
  line-height: 39px;
  height: 62px;
  border-bottom: 1px solid #dedcda;
  padding: 15px 6px 11px 15px;
  box-sizing: border-box;
  position: relative;
  background: rgb(245, 245, 245);
  .header {
    overflow: hidden;
    margin-bottom: 12px;
    float: left;
    .edict {
      margin-left: 5px;
    }
    .image {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      float: left;
      margin-right: 6px;
      overflow: hidden;
      line-height: 32px;
      img {
        width: 100%;
        height: 100%;
      }
      .icon {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      max-width: 230px;
      height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #333;
      float: left;
      font-weight: bold;
      line-height: 32px;
      input {
        outline: none;
        height: 20px;
        width: 230px;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        padding-left: 5px;
        box-sizing: border-box;
      }
    }
    .peoples {
      height: 32px;
      line-height: 32px;
      font-weight: bold;
      float: left;
    }
    .strategy {
      float: left;
      line-height: 32px;
      cursor: pointer;
      margin-left: 10px;
      font-size: 12px;
      u {
        color: #006dcc;
        font-family: "ArialMT";
      }
    }
  }
  .chat-type {
    box-sizing: border-box;
    float: right;
    width: 16px;
    height: 16px;
    margin-right: 20px;
    margin-top: 3px;
    .icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
    .select {
      color: #e5851a;
    }
    span {
      margin-right: 20px;
      font-family: "ArialMT";
      cursor: pointer;
    }
  }
}
</style>