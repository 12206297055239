/**
 * 应用程序为控制台样式  点击特殊菜单生成一级菜单  如点击派工平台、聊天等菜单
 * @param {Object} item:当前点击菜单信息
 * @param {Object} menuObj:要生成的菜单信息
 */
export default function generatelevel1Menu(vue,item,menuObj) {
    // 导航样式为控制台样式
    if (vue.$store.state.navigationStyle) {
        // let res = vue.$store.state.consoleData.data.list.some(level1Item => {
        //     // 说明已经打开了这个菜单
        //     if (level1Item.id == item.id) {
        //         return true
        //     }
        // })
        // 1、菜单里没有此菜单的话再走添加菜单的方法（因为会出现如果有派工平台一级菜单的话，同时当前展示的菜单也是派工平台，此时如果想切到派工平台菜单，不加此判断的话那么会一直定位到派工平台一级菜单）修改bug4129去掉了此判断
        // 2. 当关闭的时候添加了operation为close的标识 如果为close不添加一级菜单  （因为如果不加这个标识的话，如果一级菜单只有派工平台，且菜单也是派工平台，如果关闭了派工平台一级菜单，此时又会生成派工平台一级菜单）
        if(item.operation != 'close') {
            // 生成新的一级菜单
            window.$CCDK.CCMenu.addMenu1(menuObj)
        }
        item.operation = ""
    }
}
