<template>
  <div class="add-chats" ref="addchat" id="window">
    <div class="window-box" id="windowBox" ref="main">
      <li
        :class="[
          item.id == 3
            ? 'invite-member'
            : item.id == 2
            ? 'textRight'
            : 'textLeft',
        ]"
        v-for="(item, index) in msgDataArr"
        :key="index"
      >
        <div
          class="chat-time"
          v-show="
            index == 0 ||
            (index > 0 &&
              timestampArr[index] - timestampArr[index - 1] >= 60 * 1000 * 5)
          "
        >
          {{ timeArr[index] }}
        </div>
        <div class="item-name" v-show="item.id == 1 && item.type != 'card'">
          {{ item.name }}
        </div>
        <div class="item-name" v-show="item.id == 1 && item.type == 'card'">
          {{ $t("vue_label_chat_smart_assistant") }}
        </div>
        <!-- 头像 -->
        <div class="img-box-left" v-if="item.id == 1 && item.type != 'card'">
          <img :src="imgurlBefore + item.userid + imgUrlAfter" alt />
        </div>
        <div class="img-box-right" v-if="item.id == 2 && item.type != 'card'">
          <img :src="imgurlBefore + myid + imgUrlAfter" alt />
        </div>
        <div
          :class="[item.id == 2 ? 'img-box-right' : 'img-box-left']"
          v-if="item.id != 3 && item.type == 'card'"
        >
          <svg class="icon" aria-hidden="true">
            <use href="#icon-header"></use>
          </svg>
        </div>
        <!-- 邀请加入 -->
        <span v-if="item.id == 3">{{ item.data }}</span>
        <!-- 图片 -->
        <el-image
          v-if="item.data != '' && item.type == 'image'"
          :src="item.data"
          @load="loadImg"
          @click="handClick"
          :preview-src-list="[item.data]"
        ></el-image>
        <img
          src="@/assets/touxiangImage/timg.gif"
          alt
          class="picture"
          v-if="item.data == '' && item.type == 'image'"
        />
        <!-- </span> -->
        <!-- 详情页卡片消息 -->
        <!-- 新建 -->
        <span
          v-if="item.type == 'card' && item.data.type == 'NORMAL_CARD'"
          class="span card"
          @click="linkDetail(item.data.recordId)"
        >
          <div class="card-title">
            {{ item.data.title }}
          </div>
          <ul class="body">
            <li
              class="item"
              v-for="(body, index) in item.data.body"
              :key="index"
            >
              <div class="title">{{ body.fieldLable }}</div>
              <div class="content">{{ body.fieldValue }}</div>
            </li>
          </ul>
          <div class="box"></div>
          <div class="click-detail">
            {{ $t("vue_label_chat_click_for_details") }}
          </div>
        </span>
        <!-- 字段跟踪 -->
        <span
          v-if="item.type == 'card' && item.data.type == 'TRACK_CARD'"
          class="span card"
          @click="linkDetail(item.data.recordId)"
        >
          <div class="card-title">{{ item.data.title }}</div>
          <ul class="body">
            <li
              class="items"
              v-for="(body, index) in item.data.body"
              :key="index"
            >
              <span v-if="body.oldField">
                {{
                  $t("vue_label_chat_movedfromto", {
                    name: item.data.changer,
                    label: body.fieldLabel,
                    oldField: body.oldField,
                    newField: body.newField,
                  })
                }}
              </span>
              <span v-if="!body.oldField">
                {{
                  $t("vue_label_chat_movedto", {
                    name: item.data.changer,
                    label: body.fieldLabel,
                    newField: body.newField,
                  })
                }}
              </span>
            </li>
          </ul>
          <div class="box"></div>
          <div class="click-detail">
            {{ $t("vue_label_chat_click_for_details") }}
          </div>
        </span>
        <!-- 审批 -->
        <span
          v-if="item.type == 'card' && item.data.type == 'APPROVAL_CARD'"
          class="span card"
          @click="linkDetail(item.data.recordId)"
        >
          <div class="card-title">
            {{ item.data.title }}
          </div>
          <ul class="body">
            <li class="item">
              {{ item.data.approval_value }}
            </li>
          </ul>
          <div class="box"></div>
          <div class="click-detail">
            {{ $t("vue_label_chat_click_for_details") }}
          </div>
        </span>
        <!-- 文本 -->
        <span v-if="item.type == 'text'" class="span">{{ item.data }}</span>
        <!-- 文件 -->
        <div class="filebox" v-if="item.type == 'file'">
          <div class="iconbox">
            <svg class="icon" aria-hidden="true" slot="reference">
              <use href="#icon-files"></use>
            </svg>
            <div class="fileicon">
              {{ item.data.filename.replace(/.+\./, "") }}
            </div>
          </div>
          <div class="fileminbox">
            <div class="filename" :title="item.data.filename">
              {{ item.data.filename }}
            </div>
            <div class="fileload">
              <div
                class="filesize"
                v-if="(item.data.filelength / 1024 / 1024).toFixed(1) >= 1"
              >
                {{ (item.data.filelength / 1024 / 1024).toFixed(1) + "M" }}
              </div>
              <div
                class="filesize"
                v-if="(item.data.filelength / 1024 / 1024).toFixed(1) < 1"
              >
                {{ (item.data.filelength / 1024).toFixed(1) + "K" }}
              </div>
              <a :href="item.data.url" v-show="item.data.url != ''">{{
                $t("chatter.down")
              }}</a>
              <a href v-show="item.data.url == ''">{{
                $t("label_tabpage_uploadingz")
              }}</a>
            </div>
          </div>
        </div>
        <!-- 音频 -->
        <span
          v-if="item.type == 'voice'"
          class="span"
          @click="changeListenType(index)"
        >
          <div
            class="voice"
            @click="playMedia(index)"
            :style="{ width: item.data.size * 1 + 50 + 'px', height: '17.6px' }"
          >
            <div class="box">
              <div>
                <img :src="yuyin_1" alt="" v-if="item.isplay" />
                <svg class="icon" aria-hidden="true" v-if="!item.isplay">
                  <use href="#icon-yuyin"></use>
                </svg>
              </div>
            </div>
            <audio
              :src="item.data.url"
              :id="'media' + index"
              @ended="isstop()"
            ></audio>
            <div
              style="
                margin-left: 25px;
                display: inline-block;
                transform: translate(0, 2px);
              "
            >
              {{ item.data.size }}″
            </div>
          </div>
          <div
            class="red-spot"
            :id="'redspot' + index"
            v-show="!item.isListen"
          ></div>
        </span>
        <!-- 位置 -->
        <span v-if="item.type == 'location'" class="locationBox span">
          <div id="locationbox">
            <div class="locationmsg">{{ item.data }}</div>
            <img
              src="@/assets/contentImage/location.png"
              alt
              class="location"
            />
          </div>
        </span>
      </li>
      <div class="invite-member" v-if="removeGroupMessage != ''">
        {{ removeGroupMessage }}
      </div>
    </div>
  </div>
</template>

<script>
/*
 *群策聊天页面聊天框各种形式聊天内容渲染与逻辑处理
 */
import { mapActions } from "vuex";
import getTimeStringAutoShort from "@/utils/changeTime.js";
import VueCookies from "vue-cookies";
export default {
  name: "add-chat-window",
  data() {
    return {
      // 当前用户id
      myid: localStorage.getItem("myid"),
      //头像请求
      imgurlBefore: `${VueCookies.get(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=`,
      //头像请求
      imgUrlAfter: `&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`,
      //页面滚动的高度
      scrollH: 0,
      //时间数组
      timeArr: [],
      //时间戳数组
      timestampArr: [],
      //语音图标
      yuyin_1: require("@/assets/contentImage/yuyin_1.gif"),
    };
  },

  computed: {
    //滚动高度
    scrollHeight() {
      return this.scrollH != 0
        ? document.getElementById("window").scrollHeight
        : 0;
    },
    //当前聊天用户的IM信息
    payload() {
      return this.$store.state.list.isChatID;
    },
    //当前聊天用户的imid
    ulInner() {
      return this.$store.state.list.isChatID.imId;
    },
    //当前聊天记录
    msgDataArr() {
      return this.$store.state.list.isChatID != ""
        ? this.$store.getters.getIsSelectList
        : [];
    },
    //当前聊天被管理员移除群聊信息
    removeGroupMessage() {
      return this.$store.getters.removeGroupMessage;
    },
    //变化时间
    timeChange() {
      return this.$store.state.list.timeWindow;
    },
  },
  watch: {
    timeChange: {
      handler(newVal, oldVal) {
        //判断什么时候重新更改时间
        if (newVal != oldVal) {
          this.firstChatTime();
        }
      },
      deep: true,
      immediate: true,
    },
    payload: {
      handler(newVal, oldVal) {
        //获取当前选中数据的历史记录
        if (newVal != oldVal) {
          let findIndex = this.$store.state.list.chatList.findIndex(
            (res) => res.imId == newVal.imId
          );
          if (findIndex >= 0) {
            if (!this.$store.state.list.chatList[findIndex].getChatList) {
              this.$store.commit("changeGetLiatStatus", { id: newVal.imId });
              this.getChatLists();
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "msgDataArr.length": {
      handler() {
        this.changeChatTime();
        this.gobottom();
      },
      deep: true,
      immediate: true,
      // ))
    },
    scrollHeight(val) {
      this.scrollToBottom(val);
    },
    ulInner: {
      handler() {
        this.timeArr = [];
        this.timestampArr = [];
        this.firstChatTime();
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.$Bus.$off("change-image-top");
    this.$Bus.$on("change-image-top",this.ebFn1);
    this.isstop();
    this.$Bus.$on("chat-window-toBottom", this.ebFn2);
    this.$Bus.$on("file-upload-error", this.ebFn3);

    this.$Bus.$on("close-animation",this.ebFn4);
    this.scrollToBottom();
  },
  beforeDestroy() {
    this.$Bus.$off("change-image-top",this.ebFn1);
    this.$Bus.$off("chat-window-toBottom", this.ebFn2);
    this.$Bus.$off("file-upload-error", this.ebFn3);
    this.$Bus.$off("close-animation",this.ebFn4);
  },
  methods: {
    ebFn1 (){
      this.imgurlBefore = `${VueCookies.get(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=`;
      this.imgUrlAfter = `&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`;
    },
    ebFn2(){
      this.gobottom();
    },
    ebFn3(val){
      this.$message({
        showClose: true,
        message: val,
        type: "error",
      });
    },
    ebFn4(){
      this.msgDataArr.forEach((res) => {
        res.isplay = false;
      });
    },
    /*
     *图片类型数据点击后相关处理
     */
    handClick() {
      setTimeout(() => {
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        let domImageImg = document.querySelector(".el-image-viewer__img");
        if (!domImageMask && !domImageImg) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
        domImageImg.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      }, 200);
    },
    /*
     *卡片信息跳转详情
     */
    linkDetail(recordId) {
      this.$Bus.$emit("close-chat-window", false);
      this.$router.push({
        path: `/commonObjects/detail/${recordId}/DETAIL`,
      });
    },
    /*
     *更改音频播放状态
     */
    isstop() {
      this.msgDataArr.forEach((res) => {
        res.isplay = false;
      });
    },
    /*
     *音频的已读与未读
     */
    changeListenType(index) {
      this.$store.commit("changeListenType", index);
    },
    /*
     *音频的播放与暂停
     */
    playMedia(index) {
      var audio = document.getElementById(`media${index}`);
      if (audio.paused) {
        //判断当前的状态是否为暂停，若是则点击播放，否则暂停
        //
        this.msgDataArr.forEach((res, msgindex) => {
          if (index == msgindex) {
            res.isplay = true;
          } else {
            res.isplay = false;
          }
        });
        audio.play();
      } else {
        this.msgDataArr.forEach((res) => {
          res.isplay = false;
        });
        audio.pause();
      }
    },
    /*
     *初始数组中的时间
     */
    firstChatTime() {
      let index = this.$store.state.list.chatList.findIndex(
        (res) => res.imId == this.$store.state.list.isChatID.imId
      );
      let timestampIndex = this.$store.state.list.chatList[index].msg.length;
      if (index >= 0 && timestampIndex > 0) {
        this.$store.state.list.chatList[index].msg.forEach((res) => {
          this.timeArr.push(getTimeStringAutoShort(res.timestamp, true));
          this.timestampArr.push(res.timestamp);
        });
      }
    },
    /*
     *聊天框时间
     */
    changeChatTime() {
      let index = this.$store.state.list.chatList.findIndex(
        (res) => res.imId == this.$store.state.list.isChatID.imId
      );
      let timestampIndex = this.$store.state.list.chatList[index].msg.length;
      if (index >= 0 && timestampIndex > 0) {
        this.timeArr.push(
          getTimeStringAutoShort(
            this.$store.state.list.chatList[index].msg[timestampIndex - 1]
              .timestamp,
            true
          )
        );
        this.timestampArr.push(
          this.$store.state.list.chatList[index].msg[timestampIndex - 1]
            .timestamp
        );
      }
    },
    /*
     *进入聊天页面回到最底部
     */
    gobottom() {
      this.$nextTick(() => {
        let ele = document.getElementById("window");
        ele.scrollTop = ele.scrollHeight;
      });
    },
    /*
     *图片加载成功触发滚动底部事件
     */
    loadImg() {
      this.gobottom();
    },
    /*
     *滚动到页面底部
     */
    scrollToBottom() {
      this.$nextTick(() => {
        var div = document.getElementById("window");
        div.scrollTop = div.offsetHeight;
      });
    },
    ...mapActions(["onGetCurrentMsgList"]),
    /*
     *获取聊天记录
     */
    getChatLists() {
      this.onGetCurrentMsgList({
        name: this.payload.imId,
        isGroup: this.payload.isGroup,
      });
    },
  },
};
</script>

<style lang='scss'>
.add-chats {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-bottom: 1px solid #dedcda;
  background: rgb(245, 245, 245);
  .window-box {
    width: 100%;
    padding: 10px;
    background: rgb(245, 245, 245);
    ul {
      padding: 10px;
      overflow-y: auto;
    }
    .item-name {
      margin-left: 25px;
      margin-bottom: 5px;
      color: #999999;
      font-size: 12px;
    }
    [class*=" el-icon-"],
    [class^="el-icon-"] {
      font-size: 20px;
      color: white;
    }
    .invite-member {
      text-align: center;
      margin: 0 auto;
      color: #999999;
      font-size: 10px;
      border-radius: 4px;
      padding: 0;
      line-height: 24px;
      .invite {
        background: none;
        font-size: 10px;
      }
    }
    li {
      padding: 5px;
      margin-bottom: 10px;
      overflow: hidden;
      .chat-time {
        text-align: center;
        margin: 0 auto;
        color: #999999;
        font-size: 10px;
        padding: 0;
        margin-bottom: 10px;
      }
      .img-box-left {
        width: 26px;
        height: 26px;
        float: left;
        img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
        }
        .icon {
          width: 26px;
          height: 26px;
          border-radius: 50%;
        }
      }
      .img-box-right {
        width: 26px;
        height: 26px;
        float: right;
        img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
        }
        .icon {
          width: 26px;
          height: 26px;
          border-radius: 50%;
        }
      }
    }
    li .locationBox {
      padding: 0;
      background-color: #fff;
      vertical-align: middle;
      border: none;
    }
    li {
      .picture {
        max-height: 200px;
        max-width: 200px;
        margin-right: 5px;
      }
      .el-image {
        margin-left: 5px;
        margin-right: 5px;
        .el-image__inner {
          width: auto;
          height: auto;
        }
        img {
          max-height: 200px;
          max-width: 200px;
        }
      }
      .big-picture {
        max-height: 400px;
        max-width: 400px;
      }
    }
    li .span {
      position: relative;
      border-radius: 4px;
      background-color: #cfe6ff;
      padding: 6px 10px 6px 10px;
      max-width: 320px;
      display: inline-block;
      min-height: 20px;
      text-align: justify;
      text-justify: newspaper;
      word-break: break-all;
      white-space: pre-line;
    }
    li #locationbox {
      max-width: 220px;
      padding: 0;
      background-color: #fff;
      border: 1px solid #f3f2f2;
      img {
        width: 100%;
        vertical-align: middle;
      }
      .locationmsg {
        padding: 6px 16px;
        max-width: 220px;
        font-size: 12px;
        color: #333333;
      }
    }
    .textLeft .card.span {
      position: relative;
      padding: 0;
      border-radius: 10px;
      cursor: pointer;
      border: 1px solid #dedcda;
      background-color: #fff;
      margin-left: 5px;
      max-width: 326px;
      min-width: 250px;
      min-height: 110px;
      box-sizing: border-box;
      overflow: hidden;
      .box {
        height: 24px;
      }
      .click-detail {
        padding-left: 10px;
        width: 100%;
        height: 24px;
        // background-color: #dee4eb;
        // border-radius: 0px 0px 10px 10px;
        line-height: 24px;
        color: #006dcc;
        position: absolute;
        bottom: 0;
      }
      .card-title {
        box-sizing: border-box;
        padding: 10px 10px 0;
        color: #333;
        font-size: 14px;
        font-weight: 550;
      }
      .body {
        box-sizing: border-box;
        padding: 5px 10px 10px;
        margin-bottom: 0;
        .items {
          color: #333;
          margin-top: 5px;
          padding: 0;
          margin-bottom: 0;
        }
        .item {
          color: #333;
          margin-top: 5px;
          padding: 0;
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .title {
            float: left;
            width: 80px;
            color: #888;
            margin-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .content {
            float: left;
            color: #333;
            width: calc(100% - 90px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .textLeft .span {
      position: relative;
      background-color: #eaf0f6;
      margin-left: 10px;
      color: #425b76;
      border-radius: 0px 10px 10px 10px;
      .word {
        color: #666;
        position: absolute;
        bottom: 20px;
        left: 30px;
      }
      #location {
        width: 100%;
        height: 100%;
        padding: 0;
        vertical-align: middle;
      }
      .red-spot {
        position: absolute;
        top: 2px;
        right: -15px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #e2403c;
      }
      .voice {
        max-width: 100px;
        width: 50px;
        line-height: 10px;
        display: flex;
        cursor: pointer;
        img {
          height: 17px;
        }
        .icon {
          height: 17px;
        }
        * {
          margin: 0;
          padding: 0;
        }
      }
    }
    li.textLeft::before {
      content: "";
      background: url("../../../assets/contentImage/headphoto.jpg") no-repeat;
      background-size: cover;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      box-sizing: border-box;
      position: relative;
      left: -10px;
      top: 9px;
    }
    li .filebox1 {
      display: inline-block;
      background-color: #fff;
      justify-content: flex-end;
      border: 1px solid #dcdcdc;
      margin-left: 5px;
      border-radius: 5px;
      div {
        display: inline-block;
        font-size: 12px;
        color: #333333;
      }
      div:nth-child(2) {
        font-size: 15px;
        color: #333333;
      }
      a {
        display: block;
        margin-left: 10px;
        text-decoration: none;
        color: #006dcc;
      }

      .fileicon {
        position: relative;
        width: 60px;
        height: 74px;
        line-height: 100px;
        text-align: center;
        color: #4287d5;
        vertical-align: top;
      }
      .filename {
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space:nowrap;
        text-align: left;
        height: 35px;
        vertical-align: top;
        margin-right: 5px;
        text-align: justify;
        text-justify: newspaper;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //必须结合的属性，将对象作为弹性伸缩盒子模型显示
        -webkit-line-clamp: 2; //控制文本的行数
        -webkit-box-orient: vertical;
      }
      .fileminbox {
        position: absolute;
        top: 5px;
        left: 20px;
      }
      .fileload {
        display: flex;
        align-items: center;
      }
      .filesize {
        color: #777777;
        font-size: 12px;
      }
    }
    li.textLeft .span.card:before {
      // content: "";
      // display: block;
      // width: 0;
      // height: 0;
      // border: none;
      // position: absolute;
      // top: 8px;
      // left: -8px;
    }
    li.textLeft .span:before {
      // content: "";
      // display: block;
      // width: 0;
      // height: 0;
      // border: 4px solid transparent;
      // border-right: 4px solid #f3f2f2;
      // position: absolute;
      // top: 8px;
      // left: -8px;
    }
    li.textRight:after {
      // content: "";
      // background: url("../../../assets/contentImage/头像2.jpg") no-repeat;
      // background-size: cover;
      // width: 26px;
      // height: 26px;
      // border-radius: 50%;
      // box-sizing: border-box;
      // position: relative;
      // right: -10px;
      // top: 9px;
    }
    li.textRight .span:after {
      // content: "";
      // display: block;
      // width: 0;
      // height: 0;
      // border: 4px solid transparent;
      // border-left: 4px solid #cfe6ff;
      // position: absolute;
      // top: 8px;
      // right: -8px;
    }
    li .textRight .sendfile {
      background-color: #fff;
    }
    li .filebox:after {
      display: none !important;
      background-color: #fff;
      content: "";
    }
    .textLeft .filebox {
      display: flex;
      align-items: center;
      float: left;
      justify-content: space-between;
      max-width: 200px;
      height: 74px;
      background-color: #fff;
      border: 1px solid #dcdcdc;
      margin-right: 5px;
      border-radius: 5px;
      margin-left: 5px;
      margin-left: 5px;
      padding: 0px 5px;
      box-sizing: border-box;
      .iconbox {
        position: relative;
      }
      div {
        display: inline-block;
        font-size: 12px;
        color: #333333;
      }
      div:nth-child(2) {
        font-size: 15px;
        color: #333333;
      }
      a {
        font-size: 14px;
        display: block;
        margin-left: 10px;
        text-decoration: none;
        color: #006dcc;
      }
      .icon {
        width: 50px;
        height: 60px;
        margin-top: 5px;
      }
      .fileicon {
        position: absolute;
        bottom: 10px;
        left: 15px;
        font-size: 12px !important;
        color: #4287d5 !important;
      }
      .filename {
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        margin-right: 5px;
        text-align: justify;
        text-justify: newspaper;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //必须结合的属性，将对象作为弹性伸缩盒子模型显示
        -webkit-line-clamp: 2; //控制文本的行数
        -webkit-box-orient: vertical;
      }
      .fileload {
        display: flex;
        align-items: center;
      }
      .filesize {
        color: #777777;
        font-size: 12px;
      }
    }
    .textRight .filebox {
      display: flex;
      align-items: center;
      float: right;
      justify-content: space-between;
      max-width: 200px;
      height: 74px;
      background-color: #fff;
      border: 1px solid #dcdcdc;
      margin-right: 5px;
      border-radius: 5px;
      margin-left: 5px;
      margin-left: 5px;
      padding: 0px 5px;
      box-sizing: border-box;
      .iconbox {
        position: relative;
      }
      div {
        display: inline-block;
        font-size: 12px;
        color: #333333;
      }
      div:nth-child(2) {
        font-size: 15px;
        color: #333333;
      }
      a {
        font-size: 14px;
        display: block;
        margin-left: 10px;
        text-decoration: none;
        color: #006dcc;
      }
      .icon {
        width: 50px;
        height: 60px;
        margin-top: 5px;
      }
      .fileicon {
        position: absolute;
        bottom: 10px;
        left: 15px;
        font-size: 12px !important;
        color: #4287d5 !important;
      }
      .filename {
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        margin-right: 5px;
        text-align: justify;
        text-justify: newspaper;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //必须结合的属性，将对象作为弹性伸缩盒子模型显示
        -webkit-line-clamp: 2; //控制文本的行数
        -webkit-box-orient: vertical;
      }
      .fileload {
        display: flex;
        align-items: center;
      }
      .filesize {
        color: #777777;
        font-size: 12px;
      }
    }
    li {
      list-style: none;
    }
    .textRight {
      text-align: right;
      .span {
        margin-right: 10px;
        background: #425b76;
        border-radius: 10px 0 10px 10px;
        color: #fff;
      }
      .voice {
        max-width: 100px;
        width: 50px;
        line-height: 10px;
        display: flex;
        cursor: pointer;
        img {
          height: 17px;
        }
        .icon {
          height: 17px;
        }
        * {
          margin: 0;
          padding: 0;
        }
      }
    }
    [class^="el-icon-"] {
      background: #606266;
      border-radius: 50%;
    }
  }
  .big-picture {
    max-height: 100%;
    max-width: 100%;
  }
  .el-dialog {
    box-shadow: none;
  }
}
</style>